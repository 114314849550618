
import React, { useState, useEffect, useContext } from 'react';
import { Select, notification } from 'antd';
import { settingContext } from '../'
import { ID_SUC_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO, DEFAULT_VALUE_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO } from "@iso/redux/settings/types"


const { Option } = Select;

const options = [
    { value: 'manual', text: 'Manual' },
    { value: '5', text: '5 minutos' },
    { value: '10', text: '10 minutos' },
    { value: '15', text: '15 minutos' },
]

export default () => {
    const { IdSucursal, initialValues, saveSettingSucursal } = useContext(settingContext)
    const [selectedOption, setselectedOption] = useState(DEFAULT_VALUE_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO)
    const [loading, setloading] = useState(false)

    useEffect(() => {
        setselectedOption(initialValues.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO)?.Valor || DEFAULT_VALUE_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO)
    }, [IdSucursal, initialValues])

    const handleChange = (value) => {
        setloading(true)
        setselectedOption(value)

        saveSettingSucursal({
            Valor: value,
            IdTipoConfiguracion: ID_SUC_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO,
        }).then((result) => {
            notification.success({
                message: 'Tiempo de Solicitar Delivery guardado',
                key: ID_SUC_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO
            })
        }).finally(() => {
            setloading(false)
        })
    }

    return (
        <>
            <Select loading={loading} defaultValue={selectedOption} value={selectedOption} style={{ width: 200 }} onChange={handleChange}>
                {
                    options.map(option => <Option value={option.value}>{option.text}</Option>)
                }
            </Select>
        </>
    );

}