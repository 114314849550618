import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import AgilAuth from "./AgilAuth"
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

// const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
import Dashboard from './containers/Dashboard/Dashboard';


import AuthPage from '@iso/containers/Auth'
import NotFoundPage from '@iso/containers/Pages/404/404'
import ErrorPage from '@iso/containers/Pages/500/500'
import DownloadPOSPage from './containers/Pages/DownloadPOS';

const publicRoutes = [
  {
    path: '/auth',
    exact: true,
    component: AuthPage,
  },
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: AuthPage,
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: NotFoundPage,
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: ErrorPage,
  },
  // parche cache cliente
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: AuthPage,
  },
  {
    path: '/download-pos',
    component: DownloadPOSPage,
  },


  //
  // {
  //   path: PUBLIC_ROUTE.SIGN_UP,
  //   component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  // },
  // {
  //   path: PUBLIC_ROUTE.FORGET_PASSWORD,
  //   component: lazy(() => import('@iso/containers/Pages/ForgotPassword/ForgotPassword')),
  // },
  // {
  //   path: PUBLIC_ROUTE.RESET_PASSWORD,
  //   component: lazy(() => import('@iso/containers/Pages/ResetPassword/ResetPassword')),
  // },
  // {
  //   path: PUBLIC_ROUTE.AUTH0_CALLBACK,
  //   component: lazy(() => import('@iso/containers/Authentication/Auth0/Auth0Callback')),
  // },
  // {
  //   path: PUBLIC_ROUTE.ADMINLOGING,
  //   component: lazy(() => import('@iso/containers/Authentication/Auth0/AuthAdminCallback')),
  //   exact: true,
  // },
];


function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector(state => state.Auth.Token);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <AgilAuth>
          <Router>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))}
              <PrivateRoute path="/dashboard">
                <Dashboard />
              </PrivateRoute>
            </Switch>
          </Router>
        </AgilAuth>
      </Suspense>
    </ErrorBoundary>
  );
}
