import React, { memo, useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Alert,
    AppBar,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { Add, Close } from "@mui/icons-material"
import { COLOR_WHITE } from "../../constants/color"
import { DATE_TYPE_PROGRAMADO, DELIVERY_TYPE_RETIRO, DESTINY_TYPE_CLIENTE, TYPE_ESPECIAL, TYPE_FLASH } from "../../redux/pedidoEspecial/types"
import { setActivePanel, setCloseModalPedidoEspecial } from "../../redux/pedidoEspecial/actions"
import TipoPedido from "./Steps/TipoPedido"
import TipoEntrega from "./Steps/TipoEntrega"
import TipoFechaEntrega from "./Steps/TipoFechaEntrega"
import Origen from "./Steps/Origen"
import Destino from "./Steps/Destino"
import ContenidoAdicional from "./Steps/ContenidoAdicional"
import Productos from "./Steps/Productos"
import Preview from "./Steps/Preview"
import Axios from "axios"
import { daysDiff } from "../../helpers/daysDiff"
import { LAMBDA_POS } from "../../config"
import { SocketContext } from '../Dashboard/Dashboard'

const PedidoEspecial = ({ showForm, setShowForm }) => {
    const dispatch = useDispatch()
    const {
        isOpen,
        type,
        deliveryType,
        origin,
        dateType,
        destiny,
        products,
        content,
        deliveryDate,
        deliveryTime,
        useCar,
        comment,
        tip,
    } = useSelector(state => state?.PedidoEspecial)
    const [preview, setPreview] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showMsg, setShowMsg] = useState({ type: '', msg: '' })
    const { IdSucursal } = useSelector(state => state?.Auth?.payload?.usuarioPerfil?.sucursal)
    const { IdUsuario } = useSelector(state => state?.Auth?.auth)
    const socket = useContext(SocketContext)
    const MEDIO_PAGO_WEBPAY = 2

    const closeModal = async () => {
        await dispatch(setCloseModalPedidoEspecial())
    }

    const changePanel = async (panel) => {
        await dispatch(setActivePanel({ panel }))
    }

    const createOrder = async () => {
        setLoading(true)

        const codigo = (type === TYPE_ESPECIAL) ? 'PEDESPECIAL' : 'PEDAGILFAVOR'

        try {
            const res = await Axios.post(`${LAMBDA_POS}/createPedido/${codigo}/`, {
                Destino: {
                    IdDestino: (type === TYPE_ESPECIAL || destiny?.type === DESTINY_TYPE_CLIENTE) ? destiny?.client?.IdUsuario : destiny?.store?.IdSucursal,
                    alias: {
                        Nombre: (type === TYPE_ESPECIAL || destiny?.type === DESTINY_TYPE_CLIENTE) ? destiny?.client?.Nombre : null,
                        Apellido: (type === TYPE_ESPECIAL || destiny?.type === DESTINY_TYPE_CLIENTE) ? destiny?.client?.Apellido : null,
                        Telefono: (type === TYPE_ESPECIAL || destiny?.type === DESTINY_TYPE_CLIENTE) ? destiny?.client?.Telefono : null,
                        Email: (type === TYPE_ESPECIAL || destiny?.type === DESTINY_TYPE_CLIENTE) ? destiny?.client?.Email : null
                    },
                    address: {
                        direccion: destiny?.address?.direction,
                        lat: destiny?.address?.lat,
                        lng: destiny?.address?.lng,
                        Numeracion: '',
                        Nro: (destiny && destiny?.address && destiny?.address?.additionalInformation) ? destiny?.address?.additionalInformation : '',
                    },
                    esEnvioSucursal: (type === TYPE_ESPECIAL || destiny?.type === DESTINY_TYPE_CLIENTE) ? false : true,
                    IdUsuarioAceptacion: (type === TYPE_ESPECIAL || destiny?.type === DESTINY_TYPE_CLIENTE || !destiny?.storeAdmin) ? null : destiny?.storeAdmin?.IdUsuario,
                }, Origen: {
                    IdSucursalOrigen: (origin) ? origin?.IdSucursal : IdSucursal,
                    LugarOrigen: null,
                    IdSucursalCreacion: IdSucursal,
                    IdUsuarioCreacion: IdUsuario,
                    TipoEntrega: (deliveryType === DELIVERY_TYPE_RETIRO) ? 2 : 1,  /* 1. DELIVERY, 2. RETIRO */
                    TipoFechaEntrega: (dateType === DATE_TYPE_PROGRAMADO) ? 3 : 1,   /* 1. ANTES POSIBLE, 3. PROGRAMADO */
                    EntregaProgramadaFecha: (dateType === DATE_TYPE_PROGRAMADO) ? daysDiff(deliveryDate) + 1 : null,
                    EntregaProgramadaHora: (dateType === DATE_TYPE_PROGRAMADO) ? deliveryTime : null,
                    requiereVehiculo: (useCar) ? useCar : null
                }, Contenido: {
                    contenidoPedido: (type === TYPE_FLASH && content) ? content : '',
                    productos: (type === TYPE_ESPECIAL) ? products : [],
                    Comentario: (comment) ? comment : '',
                    propina: (tip) ? tip : 0,
                    IdTipoDelivery: 1,   /* 1. Dejar en la puerta */
                    IdMedioPago: (type === TYPE_ESPECIAL) ? MEDIO_PAGO_WEBPAY : null    /* FIJO WEBPAY */
                }
            })

            if (res?.data?.data?.isValid) {
                closeModal()
                setShowMsg({ type: 'success', msg: `Pedido ${res?.data?.data?.Codigo} creado correctamente!` })

                socket.emit("NuevoPedido", {
                    IdSucursal: (origin) ? origin?.IdSucursal : IdSucursal,
                    Codigo: res?.data?.data?.Codigo,
                    IdPedido: res?.data?.data?.IdPedido,
                    origin: 'SHOP'
                }, (data) => {
                })
            }
        } catch (err) {
            console.error(err)
            setShowMsg({ type: 'error', msg: `No se pudo crear este pedido. Favor inténtalo de nuevo` })
        }

        setLoading(false)
    }

    const closeMsg = () => {
        setShowMsg({ type: '', msg: '' })
    }

    return (
        <>
            <Snackbar open={(showMsg?.msg) ? true : false} autoHideDuration={6000} onClose={closeMsg}>
                <Alert onClose={closeMsg} severity={showMsg?.type} sx={{ width: '100%' }}>
                    {showMsg?.msg}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ zIndex: 1011 }}
                open={loading}
            >
                <Stack direction='row' alignItems='center'>
                    <CircularProgress sx={{ mr: 2, color: COLOR_WHITE }} />
                    <Typography variant="h5" color={COLOR_WHITE}>
                        {
                            (type === TYPE_ESPECIAL) ?
                                `Creando Pedido Especial. Esto puede tardar unos segundos...`
                                :
                                `Creando Agil Flash. Esto puede tardar unos segundos...`
                        }
                    </Typography>
                </Stack>
            </Backdrop>

            <Dialog
                open={isOpen}
                onClose={closeModal}
                fullScreen
                sx={{ zIndex: 1010 }}
            >
                <DialogTitle>
                    <AppBar color="primary" position="absolute">
                        <Toolbar>
                            <Stack alignItems='center' direction='row' sx={{ flexGrow: 1 }}>
                                <Add />
                                <Typography variant="body1">
                                    Nuevo Pedido Especial
                                </Typography>
                            </Stack>
                            <IconButton onClick={closeModal}>
                                <Close sx={{ color: COLOR_WHITE }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                <DialogContent sx={{ mt: 8 }}>

                    {
                        (preview) ?
                            <Preview setPreview={setPreview} />
                            :
                            <>
                                {/* TIPO PEDIDO */}
                                <TipoPedido changePanel={changePanel} />

                                {/* TIPO ENTREGA */}
                                {
                                    (type) && (
                                        <TipoEntrega changePanel={changePanel} />
                                    )
                                }

                                {/* ORIGEN */}
                                {
                                    (deliveryType) && (
                                        <Origen changePanel={changePanel} />
                                    )
                                }

                                {/* FECHA ENTREGA */}
                                {
                                    (origin) && (
                                        <TipoFechaEntrega changePanel={changePanel} />
                                    )
                                }

                                {/* DESTINO */}
                                {
                                    (dateType) && (
                                        <Destino changePanel={changePanel} />
                                    )
                                }

                                {/* PRODUCTOS */}
                                {
                                    (destiny?.confirm && type === TYPE_ESPECIAL) && (
                                        <Productos changePanel={changePanel} />
                                    )
                                }

                                {/* CONTENIDO ADICIONAL */}
                                {
                                    (destiny?.confirm && type === TYPE_FLASH) && (
                                        <ContenidoAdicional changePanel={changePanel} />
                                    )
                                }
                            </>
                    }

                </DialogContent>
                <DialogActions sx={{ flexDirection: 'column' }}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        onClick={() => (preview) ? createOrder() : setPreview(true)}
                        disabled={(preview) ?
                            false :
                            (
                                (!type || !deliveryType || !origin || !dateType || !destiny?.confirm)
                                || (type === TYPE_ESPECIAL && products.length < 1)
                                || (type === TYPE_FLASH && !content)
                            ) ? true : false
                        }
                    >
                        {
                            (preview) ?
                                `Confirmar y Crear Pedido`
                                :
                                `Confirmar y Revisar`
                        }
                    </Button>
                    {
                        (preview) && (
                            <Button
                                variant="text"
                                size="large"
                                color="inherit"
                                fullWidth
                                onClick={() => setPreview(false)}
                            >
                                Volver y modificar pedido
                            </Button>
                        )
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}

export default memo(PedidoEspecial)