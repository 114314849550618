import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import moment from 'moment-timezone'
import 'moment/locale/es';
import { useSelector } from 'react-redux';
import { PED_ENTRE_TIPO_ENTRE_MESA } from "../../../redux/ordenes/types";
moment.locale('es')

export default ({ order }) => {
    const Auth = useSelector(state => state.Auth)

    const [fechaPagoFormat, setfechaPagoFormat] = useState('')
    const [fechaEstimadaEntregaFormat, setfechaEstimadaEntregaFormat] = useState('')


    useEffect(() => {
        const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona
        const fechaEstimadaEntregaMaxFormat = order?.entrega?.FechaEstimadaEntregaMax && moment.utc(order?.entrega?.FechaEstimadaEntregaMax).tz(tz).format('HH:mm')
        setfechaEstimadaEntregaFormat(moment.utc(order?.entrega?.FechaEstimadaEntrega).tz(tz).format(`dddd DD [de] MMMM, HH:mm ${order?.entrega?.FechaEstimadaEntregaMax ? `- [${fechaEstimadaEntregaMaxFormat}]` : ''}`))
        setfechaPagoFormat(moment.utc(order?.FechaCreacion).tz(tz).format(`dddd DD [de] MMMM, HH:mm`))
    }, [Auth, order])
    return (
        <>
            <Row>
                <Col span={12}> <b> Entrega al Cliente </b> </Col>
                <Col span={12}> {fechaEstimadaEntregaFormat} </Col>
            </Row>
            <Row>
                <Col span={12}> <b> Pago </b> </Col>
                <Col span={12}> {fechaPagoFormat}</Col>

            </Row>
            <Row>
                <Col span={12}> <b> Local </b> </Col>
                <Col span={12}> {Auth.payload.usuarioPerfil.sucursal.Titulo} </Col>
            </Row>
            <Row>
                <Col span={12}> <b> Tipo </b> </Col>
                <Col span={12}> {(order?.entrega?.tipoEntrega !== undefined && order?.entrega?.tipoEntrega !== null && order?.entrega?.tipoEntrega?.Tipo !== undefined && order?.entrega?.tipoEntrega?.Tipo !== null) ? order?.entrega?.tipoEntrega?.Tipo : ""}</Col>
            </Row>

            {
                order?.entrega?.TipoEntrega === PED_ENTRE_TIPO_ENTRE_MESA &&
                <Row>
                    <Col span={12}> <b> Numero de Mesa </b> </Col>
                    <Col span={12}> {order?.mesa?.Nro}</Col>
                </Row>
            }
            <Row>
                <Col span={12}> <b> Estado Pago </b> </Col>
                <Col span={12}> Pago Online </Col>
            </Row>
        </>
    )
}