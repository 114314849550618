import { Carousel, Drawer, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import TrackingOrder from './TrackingOrder'

const iconColor = '#1890ff'

export default ({ order, iconSize = 2.5 }) => {
    const [visible, setVisible] = useState(false)

    // useEffect(() => {
    //     setVisible(false)
    // }, [order.IdPedido])



    const iconMap = <svg width={`${iconSize}em`} height={`${iconSize}em`} viewBox="0 0 16 16" className="bi bi-map" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98l4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z" />
    </svg>
    const iconPin = <svg width={`${iconSize}em`} height={`${iconSize}em`} viewBox="0 0 16 16" className="bi bi-geo-alt" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
        <path fillRule="evenodd" d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
    </svg>
    const iconListCheck = <svg width={`${iconSize}em`} height={`${iconSize}em`} viewBox="0 0 16 16" className="bi bi-list-check" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
    </svg>
    const iconListDots = <svg width={`${iconSize}em`} height={`${iconSize}em`} viewBox="0 0 16 16" className="bi bi-list-ul" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
    </svg>

    return (
        <>
            <div
                style={{
                    cursor: 'pointer',
                    width: `${iconSize}em`,
                    height: `${iconSize}em`,
                    margin: '0 5px',
                    padding: '0',
                    display: 'inline'
                }}
                onClick={() => { setVisible(true) }}>
                <Carousel autoplay dots={false}>
                    {iconMap}
                    {iconListDots}
                    {iconPin}
                    {iconListCheck}
                </Carousel>
            </div>

            <Drawer
                title={`Seguimiento ${order.Codigo}-${order.Corelativo}`}
                placement="right"
                closable
                onClose={() => setVisible(false)}
                visible={visible}
                width='900px'
                bodyStyle={{ padding: '0' }}
                headerStyle={{ display: 'none' }}
                destroyOnClose
            >
                <TrackingOrder order={order} ></TrackingOrder>
            </Drawer>
        </>
    )



}