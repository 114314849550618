import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Store } from '@mui/icons-material'
import { Box, Chip, Typography } from '@mui/material'
import useFilterSucursal from '../../hooks/useFilterSucursal/useFilterSucursal'
import { COLOR_BG_AGIL, COLOR_BG_JUSTO, COLOR_BG_PEDIDOSYA, COLOR_BG_RAPPI, COLOR_BG_UBEREATS, COLOR_WHITE } from '../../constants/color'

const SucursalTitle = ({ sucursal, isCard, platformName, plataformCode }) => {
    const { usuarioPerfiles } = useSelector(state => state?.Auth?.payload)
    const [nombre, setNombre] = useState('')
    const [nameCommerce, setNameCommerce] = useState('')
    const { selectedSucursalIds } = useFilterSucursal()

    useEffect(() => {
        getNombre()
    }, [sucursal])

    const getNombre = () => {
        const filtered = usuarioPerfiles.find((e) => e?.sucursal?.IdSucursal === sucursal)
        if (filtered) {
            setNombre(filtered?.sucursal?.Titulo)
            setNameCommerce(filtered?.sucursal?.comercio?.NombreReal || filtered?.sucursal?.comercio?.Nombre)
        }
    }

    const getColorPlataform = () => {
        switch (plataformCode) {
            case 'UBEREATS': {
                return {
                    background: COLOR_BG_UBEREATS,
                    color: COLOR_WHITE
                }
            }
            case 'PEYA': {
                return {
                    background: COLOR_BG_PEDIDOSYA,
                    color: COLOR_WHITE
                }
            }
            case 'RAPPI': {
                return {
                    background: COLOR_BG_RAPPI,
                    color: COLOR_WHITE
                }
            }
            case 'JUSTO': {
                return {
                    background: COLOR_BG_JUSTO,
                    color: COLOR_WHITE
                }
            }
            default: {
                return {
                    background: COLOR_BG_AGIL,
                    color: COLOR_WHITE
                }
            }
        }
    }

    return (
        <>
            {
                (isCard && selectedSucursalIds.length > 0) && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: (isCard) ? 1 : 0,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: (isCard) ? 1 : 0,
                            }}
                        >
                            {/* <Store
                                fontSize='medium'
                                color='primary'
                                sx={{ mr: 1 }}
                            /> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >

                                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                    {nameCommerce}
                                </Typography>
                                <Typography variant="caption">
                                    {nombre}

                                </Typography>
                            </Box>
                        </Box>
                        <Chip
                            label={platformName ? platformName : 'AGIL'}
                            size="small"
                            sx={{
                                ...getColorPlataform()
                            }}
                        />
                    </Box>
                )
            }

            {
                (!isCard) && (
                    <Typography variant="body1" sx={{ fontSize: '20px', marginLeft: 1 }}>
                        {nombre}
                    </Typography >
                )
            }

        </>
    )
}

export default SucursalTitle