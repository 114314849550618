import { Col, Drawer, Icon, Row, Spin, Switch, Carousel, Popover, notification } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import useWindowSize from '../../../library/hooks/useWindowSize'
import { PRINT_CONTENT_FULL, PRINT_CONTENT_PRODUCTS, PRINT_FORMAT_PUNTO, PRINT_FORMAT_THERMAL } from '../../../redux/ordenes/types'
import { getOrderPdf } from '../../../library/print'
import { print, socketLocalPOSData, socketPrint } from '../../../library/printer-socket'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import { LAMBDA_POS } from '../../../config'
import { Fragment } from 'react'
import { ID_PEDIDO_SEGUIMIENTO_IMPREISON_MANUAL, saveLog } from '../../../library/saveLogs'
import { SocketContext } from '../../Dashboard/Dashboard'


const iconColor = '#1890ff'
const iconSizeDefaultValue = 2.5
const IconPrinter = ({ size = iconSizeDefaultValue }) => <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" className="bi bi-printer" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
    <path d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z" />
    <path fillRule="evenodd" d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z" />
    <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
</svg>
const IconTicket = ({ size = iconSizeDefaultValue }) => <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" className="bi bi-file-earmark-break" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
    <path d="M14 4.5V9h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v7H2V2a2 2 0 0 1 2-2h5.5L14 4.5zM13 12h1v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2h1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-2zM.5 10a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H.5z" />
</svg>

const COLOR_CONSOLE_GREEN = 'rgb(74, 246, 38)'

const SHOW_TYPE_PRINTERS = 'SHOW_TYPE_PRINTERS'
const SHOW_TYPE_PREVIEW_DEFAULT = 'SHOW_TYPE_PREVIEW_DEFAULT'


export default ({ orders, icon = true, text = 'Ticket', iconSize = iconSizeDefaultValue }) => {
    const Auth = useSelector(state => state.Auth)
    const socket = useContext(SocketContext)


    const [loading, setloading] = useState(false)
    const [visibleDrawer, setvisibleDrawer] = useState(false)
    const [defaultTicketsSrc, setdefaultTicketsSrc] = useState([])

    const [tickets, settickets] = useState([])
    const [sucursalPrinters, setsucursalPrinters] = useState([])
    const [selectedPrinters, setselectedPrinters] = useState([])
    const [printing, setprinting] = useState(false)
    const title = `Tickets ${orders.map(o => `${o.Codigo}-${o.Corelativo}`).join(', ')}`

    const [clickedShow, setclickedShow] = useState(false)
    const [showType, setshowType] = useState('')

    const [machineId, setmachineId] = useState(null)
    const [machineIdOriginal, setmachineIdOriginal] = useState(null)


    useEffect(() => {
        const nextPrinters = tickets.reduce((aux, ticket) => [
            ...aux,
            ...ticket.printers
                .filter(printer => !aux.some(printerAux => printerAux.name === printer.name))
                .filter(printer =>
                    machineId && machineIdOriginal ?
                        (printer.machineId === machineId && printer.machineIdOriginal === machineIdOriginal) :
                        (!printer.machineId && !printer.machineIdOriginal)
                )
        ], [])
        setsucursalPrinters(nextPrinters)
        setselectedPrinters(nextPrinters)
    }, [tickets, machineId, machineIdOriginal])


    useEffect(() => {
        getMachineId()
    }, [socketPrint?.connected])

    useEffect(() => {
        setshowType(socketPrint.connected && sucursalPrinters.length ? SHOW_TYPE_PRINTERS : SHOW_TYPE_PREVIEW_DEFAULT)
    }, [socketPrint.connected, sucursalPrinters.length])

    useEffect(() => {
        const debounce = setTimeout(() => {
            showType === SHOW_TYPE_PREVIEW_DEFAULT &&
                visibleDrawer &&
                getDefaultTickets()
        }, 2500);

        return () => {
            clearTimeout(debounce)
        }
    }, [showType])


    function getMachineId() {
        setmachineId(socketLocalPOSData.machineId)
        setmachineIdOriginal(socketLocalPOSData.machineIdOriginal)
    }


    async function handleOnClickShowPrinters() {
        try {
            setloading(true)
            settickets([])
            setprinting(false)
            setshowType(SHOW_TYPE_PRINTERS)

            for (const order of orders) {
                Axios
                    .get(`${LAMBDA_POS}/getTickets/${order.IdSucursal}/${order.Codigo}`)
                    .then(response => {
                        settickets(prev => [
                            ...prev,
                            ...response.data.tickets
                        ])
                    })
                await sleep(0.5)
                break;
            }

            setvisibleDrawer(true)
            setloading(false)
        } catch (error) {
            setvisibleDrawer(false)
            setloading(false)
            settickets([])

            console.error(error)
            notification.error({ message: 'No es posible realizar esta acción en estos momentos' })
        }
    }

    async function getDefaultTickets() {
        try {
            setdefaultTicketsSrc([]);
            (async () => {
                for (const order of orders) {
                    getOrderPdf({
                        IdPublicoPedido: order.IdPublicoPedido,
                        // format: PRINT_FORMAT_THERMAL,
                        // format: PRINT_FORMAT_PUNTO,
                        // content: PRINT_CONTENT_FULL,
                        // content: PRINT_CONTENT_PRODUCTS,
                        getBase64: true,
                    })
                        .then((response) => {
                            setdefaultTicketsSrc(prev => [...prev, response.data.link])
                        })
                    await sleep(0.5)
                }
            })()
        } catch (error) {
            setdefaultTicketsSrc([])
            console.error(error)
            notification.error({ message: 'No es posible realizar esta acción en estos momentos' })
        }
    }


    async function handleOnClickSendToPrint() {
        if (!selectedPrinters.length) {
            return;
        }
        setprinting(true)
        setloading(true)

        socket.emit('StackUpTicketsToPrint', {
            Token: Auth.Token,
            IdsPedido: orders.map(o => o.IdPedido),
            usuario: Auth.auth,
            selectedPrinters
        }, function () {

            setprinting(false)
            setvisibleDrawer(false)

            setTimeout(() => {
                setloading(false)
            }, 5000);
        })
    }

    return <>
        <div style={{ width: `${iconSize}em`, height: `${iconSize}em`, margin: 0, padding: 0, display: 'inline' }}>
            {
                loading ?
                    <Spin indicator={<Icon type="loading" style={{ fontSize: `${iconSize}em` }} />} /> :
                    <a onClick={handleOnClickShowPrinters}>
                        {
                            icon ?
                                <IconPrinter size={iconSize} /> :
                                text

                        }
                    </a>
            }
        </div>

        <Drawer
            title={
                <p>
                    <DefaultTicketViewButton title={title} iconSize={iconSizeDefaultValue / 2} orders={orders} />
                    &nbsp;
                    <Popover
                        content={<span>{title}</span>}
                        title={null}>
                        Impresión {title.substring(0, 25)} ...
                    </Popover>
                </p>
            }
            placement="right"
            closable={true}
            onClose={() => setvisibleDrawer(false)}
            visible={visibleDrawer}
            width={500}
            {
            ...showType === SHOW_TYPE_PRINTERS ?
                {} :
                {
                    bodyStyle: { padding: '0' },
                    headerStyle: { display: 'none' }
                }
            }

        >
            {
                showType === SHOW_TYPE_PRINTERS ?
                    <>
                        <Row gutter={[0, 8]}>
                            {
                                sucursalPrinters.map(printer =>
                                    <Col key={printer.name} span={24}>
                                        <Switch
                                            disabled={printing}
                                            checked={selectedPrinters.includes(printer)}
                                            onChange={checked => {
                                                if (checked) {
                                                    setselectedPrinters(prev => [...prev, printer])
                                                } else {
                                                    setselectedPrinters(prev => prev.filter(p => p !== printer))
                                                }
                                            }}
                                        /> &nbsp; {printer.name}
                                    </Col>
                                )
                            }
                        </Row>
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                width: '100%',
                                // borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                // textAlign: 'right',
                            }}
                        >


                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: sucursalPrinters.length ? 'pointer' : 'not-allowed',
                                }}
                            >
                                <div onClick={handleOnClickSendToPrint}>
                                    {
                                        printing ?
                                            <Spin indicator={<Icon type="loading" style={{ fontSize: `${iconSize * 2.5}em` }} />} /> :
                                            <IconPrinter size={iconSizeDefaultValue * 2.5} />
                                    }
                                </div>
                            </div>
                        </div>
                    </> :
                    <Carousel dotPosition={'left'}>
                        {
                            defaultTicketsSrc.map(ticketSrc =>
                                <TicketIframe
                                    title={ticketSrc}
                                    src={ticketSrc}
                                />
                            )
                        }
                    </Carousel>
            }
        </Drawer>
    </>
}




const DefaultTicketViewButton = ({ title, iconSize, orders }) => {

    const [visibleDrawer, setvisibleDrawer] = useState(false)
    const [defaultTicketsSrc, setdefaultTicketsSrc] = useState([])
    const [loading, setloading] = useState(false)



    async function handleOnClickShowDefaultTickets() {
        try {
            setloading(true)
            setdefaultTicketsSrc([])
            setvisibleDrawer(true);


            (async () => {
                for (const order of orders) {
                    getOrderPdf({
                        IdPublicoPedido: order.IdPublicoPedido,
                        // format: PRINT_FORMAT_THERMAL,
                        // format: PRINT_FORMAT_PUNTO,
                        // content: PRINT_CONTENT_FULL,
                        // content: PRINT_CONTENT_PRODUCTS,
                        getBase64: true,
                    })
                        .then((response) => {
                            setdefaultTicketsSrc(prev => [...prev, response.data.link])
                        })
                    await sleep(0.5)
                }
            })()
        } catch (error) {
            console.error(error)

            setdefaultTicketsSrc([])
            setvisibleDrawer(false)

            notification.error({ message: 'No es posible realizar esta acción en estos momentos' })
        } finally {
            setloading(false)
        }
    }

    async function handleOnClose() {
        setloading(false)
        setdefaultTicketsSrc([])
        setvisibleDrawer(false)

    }

    if (loading) {
        return <Spin indicator={<Icon type="loading" style={{ fontSize: `${iconSize}em` }} />} />
    }

    return <>
        <div style={{ width: `${iconSize}em`, height: `${iconSize}em`, margin: 0, padding: 0, display: 'inline' }}>
            <a onClick={handleOnClickShowDefaultTickets}>
                <IconTicket size={iconSize} />
                {/* <Icon style={{ fontSize: `${iconSize}em` }} type="file-text" /> */}
            </a>
        </div>

        <Drawer
            title={title}
            placement="right"
            closable={true}
            onClose={handleOnClose}
            visible={visibleDrawer}
            width={500}
            bodyStyle={{ padding: '0' }}
            headerStyle={{ display: 'none' }}
        >
            <Carousel dotPosition={'left'}>
                {
                    defaultTicketsSrc.map(ticketSrc =>
                        <TicketIframe
                            title={ticketSrc}
                            src={ticketSrc}
                        />
                    )
                }
            </Carousel>

        </Drawer>
    </>

}

/**
 * 
 * @param {{title:string, src:string}} param0 
 * @returns 
 */
const TicketIframe = ({ title, src }) => {
    const { height } = useWindowSize()
    return src.endsWith('pdf') ?
        <iframe
            title={title}
            src={src}
            frameBorder={0}
            width='100%'
            height={height}
        /> :
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'rgb(82, 86, 89)'
            }}>
            <img src={src} width='50%' height='100%' />
        </div>
}


async function sleep(seg) {
    await new Promise((resolve, reject) => { setTimeout(resolve, seg * 1000) })
}
