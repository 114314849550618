import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { ID_COM_TIPO_DISE_LOGO } from '../../redux/auth/types'

export default ({ collapsed }) => {
    const { sucursal } = useSelector(state => state.Auth.payload.usuarioPerfil)

    const [srcLogo, setsrcLogo] = useState(null)

    useEffect(() => {
        const { Recurso } = sucursal.comercio.disenos.find(d => d.IdTipo === ID_COM_TIPO_DISE_LOGO) || { Recurso: '' };
        setsrcLogo(Recurso)
    }, [sucursal])


    return (
        <div className="isoLogoWrapper">
            {collapsed ? (
                <div>
                    <h3>
                        <Link to="/dashboard">
                            <img
                                alt="POS"
                                style={{ maxWidth: "200px", maxHeight: '70px', }}
                                src={srcLogo}
                            ></img>
                        </Link>
                    </h3>
                </div>
            ) : (
                <h3>
                    <Link to="/dashboard">

                        <img
                            alt='POS'
                            style={{ maxWidth: "100%", maxHeight: '70px', }}
                            src={srcLogo}
                        ></img>
                    </Link>
                </h3>
            )}
        </div>

    )
}
