import React, { useState, useEffect, useCallback } from 'react';
import { Popover, Icon, Badge, Input, Tag, Tabs } from 'antd';
import { useSelector } from 'react-redux';

import Scrollbar from '@iso/components/utility/customScrollBar';
import TopbarDropdownWrapper from './TopbarDropdown.styles';

import { PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG } from '../../redux/ordenes/types'

import moment from 'moment-timezone'
import 'moment/locale/es';
moment.locale('es')

const TAB_HOY = 'TAB_HOY'
const TAB_OTROS = 'TAB_OTROS'

const TopbarNotification = () => {

    const allOrders = useSelector(state => state.Ordenes)

    const [activeTab, setactiveTab] = useState(TAB_HOY)
    const [visible, setVisiblity] = useState(false)
    const [needRead, setneedRead] = useState(0)
    const [notificationsAux, setnotificationsAux] = useState([])
    const [notifications, setnotifications] = useState([])
    const [notificationsOthers, setnotificationsOthers] = useState([])
    const [search, setsearch] = useState({
        value: '',
        valuePrivate: '',
        withDebounce: false
    })

    const getTimersV2 = useCallback(
        () => {
            try {
                const notificationsAuxNext = [
                    ...allOrders.orders,
                    ...allOrders.ordersActive,
                    ...allOrders.ordersScheduled,
                ].reduce((aux, order) => {
                    if (order.timers.autoAccept) {
                        const notificationAutoAccept = {
                            key: `${order.Codigo}-autoAccept`,
                            date: order.timers.autoAccept,
                            name: <span>
                                <Tag color="orange">
                                    {`${order.entrega.TipoFechaEntrega === PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG ? 'p' : ''}${order.Codigo} Aceptación`}
                                </Tag>
                            </span>,
                            notification: order.timers.autoAcceptText,
                            filterThis: '',
                            isForToday: order.timers.isForToday,
                            read: false,
                        }
                        notificationAutoAccept.filterThis = `${order.Codigo}-${order.Corelativo}${notificationAutoAccept.notification}`.toLocaleLowerCase()
                        aux.push(notificationAutoAccept)
                    }

                    if (order.timers.autoDelivery) {
                        const notificationAutoDelivery = {
                            key: `${order.Codigo}-autoDelivery`,
                            date: order.timers.autoDelivery,
                            name: <span>
                                <Tag color="green">
                                    {`${order.entrega.TipoFechaEntrega === PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG ? 'p' : ''}${order.Codigo}${order.Corelativo ? `-${order.Corelativo}` : ''} Solicitar delivery`}
                                </Tag>
                            </span>,
                            notification: order.timers.autoDeliveryText,
                            filterThis: '',
                            isForToday: order.timers.isForToday,
                            read: false
                        }
                        notificationAutoDelivery.filterThis = `${order.Codigo}-${order.Corelativo}${notificationAutoDelivery.notification}`.toLocaleLowerCase()
                        aux.push(notificationAutoDelivery)
                    }

                    return aux
                }, [])

                if (visible) {
                    setTimeout(() => {
                        notificationsAuxNext.forEach(n => {
                            n.read = true
                        })
                    }, 3000)
                }


                setnotificationsAux(prev => {
                    return notificationsAuxNext
                        // .slice(0, 10)
                        .sort((a, b) => a.date - b.date)
                        // .reverse()
                        .map(n => ({
                            ...n,
                            read: !!prev.find(p => p.key === n.key)?.read
                        }))
                })

            } catch (error) {
                console.error('[TIMER] catch', error)
                setnotificationsAux([])
            } finally {

            }
        },
        [allOrders, visible],
    )

    const applySearch = useCallback(
        () => {
            setnotifications(
                notificationsAux.filter(n => search.valuePrivate ? n.isForToday && n.filterThis.includes(search.valuePrivate) : n.isForToday)
            )
            setnotificationsOthers(
                notificationsAux.filter(n => search.valuePrivate ? !n.isForToday && n.filterThis.includes(search.valuePrivate) : !n.isForToday)
            )
        },
        [search, notificationsAux],
    )


    useEffect(() => {
        getTimersV2()
    }, [getTimersV2])

    useEffect(() => {
        setneedRead(notificationsAux.filter(n => !n.read).length)
    }, [notificationsAux])

    useEffect(() => {
        let timeout = null
        if (search.withDebounce && search.value) {
            timeout = setTimeout(applySearch, 1500);
        } else {
            applySearch()
        }

        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [search, applySearch])

    useEffect(() => {
        setnotificationsAux(prev => prev.map(n => ({
            ...n,
            read: true
        })))
    }, [visible])

    const handleVisibleChange = useCallback(
        () => {
            setVisiblity(visible => !visible);
        },
        [],
    )

    const handleOnChangeSearch = useCallback(
        (value, withDebounce) => {
            setsearch({
                value,
                valuePrivate: value.toLocaleLowerCase(),
                withDebounce
            })
        },
        [],
    )

    if (!notifications.length && !notificationsOthers.length) {
        return null
    }


    return (
        <Popover
            content={

                <TopbarDropdownWrapper className="topbarMessage withImg">
                    <div className="isoDropdownHeader">
                        <h4>
                            Temporizadores
                        </h4>
                        <Input.Search value={search.value} placeholder="" onSearch={value => handleOnChangeSearch(value, false)} onChange={e => handleOnChangeSearch(e.currentTarget.value, true)} style={{ width: 100, marginLeft: 30 }} />
                    </div>
                    <div className="isoDropdownBody">
                        <Tabs size='small' activeKey={activeTab} onChange={setactiveTab} type="card" destroyInactiveTabPane>
                            <Tabs.TabPane tab="Hoy" key={TAB_HOY}>
                                <CardsNotification notifications={notifications} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Otros" key={TAB_OTROS}>
                                <CardsNotification notifications={notificationsOthers} />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </TopbarDropdownWrapper>
            }
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
            placement="bottomLeft"
            destroyTooltipOnHide
        >
            <Badge count={needRead} style={{ backgroundColor: '#FFC300' }} >
                <Icon type="clock-circle" theme="twoTone" style={{ fontSize: 30 }} />
            </Badge>
        </Popover>
    );
}


const CardsNotificationComponent = ({ notifications }) => {
    return <Scrollbar style={{ height: 500 }}>
        {notifications.map(notification => (
            <a className="isoDropdownListItem" key={notification.key} href="# ">
                {
                    !notification.read &&
                    <div className="isoImgWrapper">
                        {/* <Icon type="check" /> */}
                        <Icon type="exclamation" />
                    </div>
                }

                <div className="isoListContent">
                    <div className="isoListHead">
                        <h5>{notification.name}</h5>
                        <span className="isoDate">{notification.date}</span>
                    </div>
                    <p>{notification.notification}</p>
                </div>
            </a>
        ))}
    </Scrollbar>
}

const CardsNotification = React.memo(CardsNotificationComponent)


export default React.memo(TopbarNotification)