import React, { useEffect, useState } from 'react';
import { Row, Col, Divider } from 'antd';
import SettingTiempoPreparacion from './SettingTiempoPreparacion';
import SettingTiempoCocina from './SettingTiempoCocina';
import SettingTiempoDelivery from './SettingTiempoDelivery';
import SettingFechaEntregaCocina from './SettingFechaEntregaCocina';
import { validatePrivilegioRecurso } from '../../../library/helpers/validatePrivilegioRecurso';
import { useSelector } from 'react-redux';


export default () => {
    const Auth = useSelector(state => state.Auth)
    const [RESET_HORA_PREPA_COCINA, setRESET_HORA_PREPA_COCINA] = useState(false)

    useEffect(() => {
        setRESET_HORA_PREPA_COCINA(validatePrivilegioRecurso(Auth, 'RESET_HORA_PREPA_COCINA'))
    }, [Auth])

    return <>
        <Row type="flex" justify="end">
            <Col span={18}>
                <h1 style={{ float: 'left' }}><b>Tiempos de Preparación</b></h1>
                <br />
                <SettingTiempoPreparacion />
            </Col>
        </Row>
        <Divider />
        <Row type="flex" justify="end">
            <Col span={18}>
                <h1 style={{ float: 'left' }}><b>Tiempos de Cocina</b></h1>
                <br />
                <div style={{ textAlign: 'center' }}>
                    <SettingTiempoCocina />
                </div>
            </Col>
        </Row>
        {/* <Divider />
        <Row type="flex" justify="end">
            <Col span={18}>
                <h1 style={{ float: 'left' }}><b>Tiempos de Delivery</b></h1>
                <br />
                <div style={{ textAlign: 'center' }}>
                    <SettingTiempoDelivery />
                </div>
            </Col>
        </Row> */}
        {
            RESET_HORA_PREPA_COCINA && <>
                <Divider />
                <Row type="flex" justify="end">
                    <Col span={18}>
                        <h1 style={{ float: 'left' }}><b>Proxima hora de entrega en cocina</b></h1>
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <SettingFechaEntregaCocina />
                        </div>
                    </Col>
                </Row>
            </>
        }
        <Divider />
    </>
}