import React, { useEffect, useState } from 'react';
import { Modal } from "antd"
import { LAMBDA_POS } from '../../../config';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function ({ }) {
    const IdSucursal = Number(localStorage.getItem('LastIdSucursal'))
    const history = useHistory()
    const [downloadLink, setdownloadLink] = useState('')


    useEffect(() => {
        const urlSearchParams = new URLSearchParams(history.location.search)

        let downloadLinkNext = urlSearchParams.get('downloadLink')

        if (!downloadLinkNext) {
            Axios.get(`${LAMBDA_POS}/getDownloadLinkPOS/${IdSucursal}`)
                .then(({ data }) => {
                    setdownloadLink(data.downloadLink)
                }).catch((err) => {
                });
        } else {
            setdownloadLink(downloadLinkNext)
        }

    }, [history])

    useEffect(() => {
        downloadLink && showModal()
    }, [downloadLink])


    function showModal() {
        Modal.warning({
            title: 'Hay una nueva versión disponible .',
            content: <p>
                Hemos actualizado el POS con nuevas mejoras y funcionalidades.
                <br />
                Porfavor, instala esta nueva versión.
            </p>,
            okText: 'Descargar e Instalar',
            onOk: async function () {

                window.location.replace(downloadLink)
                // window.open(downloadLink, '_blank');
                // window.location.href = downloadLink
                // window.location = downloadLink
                showModal()
            },
        })
    }


    return <div
        style={{
            backgroundImage: 'url(https://agil.s3.us-east-2.amazonaws.com/Logos/Logo_Agil_Horizontal.png)',
            backgroundSize: '100% 100%',
            width: '100vw',
            height: '100vh'
        }}
    />
}