import { Box } from '@mui/material';
import { notification } from 'antd';
import React, { lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
const DesktopView = lazy(() => import('./DesktopView'));

export const ID_PRODUCTO_SUCURSAL_ESTADO_ACTIVO = 1
export const ID_PRODUCTO_SUCURSAL_ESTADO_PAUSADO = 2

export const ID_CATEGORIA_ESTADO_ACTIVO = 1
export const ID_CATEGORIA_ESTADO_INACTIVO = 2


export const ID_PRODUCTO_MODIFICADOR_ESTADO_ACTIVO = 1
export const ID_PRODUCTO_MODIFICADOR_ESTADO_INACTIVO = 2

export const ID_OPCION_ESTADO_ACTIVO = 1
export const ID_OPCION_ESTADO_INACTIVO = 2


export default function Mail() {
  const { view, height } = useSelector(state => state.App);

  useEffect(() => {
    notification.warning({
      description: 'Lo que desactives dejará de estar disponible para la venta. Hasta que los actives nuevamente',
      duration: 5,
    })
  }, [])

  return (
    <Box component={'main'} sx={{ overflowY: 'hidden' }} >
      {
        <DesktopView height={height} />
      }
    </Box>
  );
}
