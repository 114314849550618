import { ISucursalNotificacion } from "./reducer"

export const LOAD_ALL = 'LOAD_ALL'
export const LOAD_ALL_SUCCESS = 'LOAD_ALL_SUCCESS'
export const LOAD_RIDERS_WAITING = 'LOAD_RIDERS_WAITING'
export const LEAVE_RIDERS_WAITING = 'LEAVE_RIDERS_WAITING'
export const MARK_AS_READ = 'MARK_AS_READ'
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'

// export const PUT_NEW = 'PUT_NEW'
export const PUT_NEW_SUCCESS = 'PUT_NEW_SUCCESS'

/**
 * 
 * @param {{
 *  createdAt: string
 *  IdSucursal: number
 * }} payload
 * @returns 
 */
export const loadAll = (payload, callback) => ({
    type: LOAD_ALL,
    payload: {
        ...payload,
        callback
    }
})

/**
 * 
 * @param {{Id: number}} payload
 * @returns 
 */
export const markAsRead = (payload, callback) => ({
    type: MARK_AS_READ,
    payload: {
        ...payload,
        callback
    }
})

/**
 * 
 * @param {{notificaciones: ISucursalNotificacion}} payload
 * @returns 
 */
export const pushNew = (payload) => ({
    type: PUT_NEW_SUCCESS,
    payload
})

export const pushRiderWaiting = (payload) => ({
    type: LOAD_RIDERS_WAITING,
    payload
})

export const leaveRiderWaiting = (payload) => ({
    type: LEAVE_RIDERS_WAITING,
    payload
})

