import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Col, Input, Row, Table, Tag, Drawer } from 'antd'
import {
    ID_PEDIDO_ESTADO_ENTREGADO,
    ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL,
    PED_ENTRE_TIPO_ENTRE_DELIVERY,
    PED_ENTRE_TIPO_ENTRE_MESA,
    PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA
} from '../../redux/ordenes/types';
import OrderRiders, { getDistanceText } from './OrderDetails/Order-Riders';
import { fixTelefono } from '../../helpers/fixes';
import currencyFormatter from 'currency-formatter';
import currencyFormatterOptions from '../../config/currencyFormatterOptions.json'
import RateRidersButtons from './Actions/RateRidersButtons';
import Text from 'antd/lib/typography/Text';
import TicketDrawerButton from './Actions/TicketDrawer.Button';
import TrackingOrderButton from './Actions/TrackingOrderButton';
import SplitOrderDeliveryButton from './Actions/SplitOrderDelivery.Button';
import EvidenceButton from './Actions/EvidenceButton';
import PedidoSeguimientoButton from './Actions/PedidoSeguimiento.Button';
import OrderPayment from './OrderDetails/Order-Payment';
import OrderProducts from './OrderDetails/Order-Products';
import OrderTotals from './OrderDetails/Order-Totals';
import ClientHistory from './OrderDetails/Client-History';
import PedidoEstadoTag from './Tags/PedidoEstado.Tag';
import { Chip } from '@mui/material';
import { COLOR_BG_AGIL, COLOR_BG_JUSTO, COLOR_BG_PEDIDOSYA, COLOR_BG_RAPPI, COLOR_BG_UBEREATS, COLOR_WHITE } from '../../constants/color';

const TEXT_RETIRO_TIENDA = 'RETIRO EN TIENDA'
const DRAWER_CONTENT_HISTORY_IN_POS = 'DRAWER_CONTENT_HISTORY_IN_POS'
const TEXT_MESA = 'ENTREGA EN MESA'

export default function () {
    const Auth = useSelector(state => state.Auth)
    const Ordenes = useSelector(state => state.Ordenes)

    const [data, setdata] = useState([])
    const [search, setsearch] = useState('')

    const [drawerData, setdrawerData] = useState({
        title: '',
        visible: false,
        content: null,
        props: {}
    })


    useEffect(() => {
        const debounce = setTimeout(() => {
            if (search) {
                setdata(Ordenes.ordersEnd.filter(order =>
                    order.estado.Nombre.toLowerCase().includes(search) ||
                    order.Codigo.toLowerCase().includes(search) ||
                    `-${order.Corelativo}`.toString().includes(search) ||
                    order.cliente.Nombre.toLowerCase().includes(search) ||
                    // (order.TotalOC).toString().toLowerCase().includes(search) ||
                    (order.TotalOC - order.Propina - order.PropinaAgil - order.CuotaServicioAgil).toString().toLowerCase().includes(search) ||
                    (order.entrega.rider?.nombre || TEXT_RETIRO_TIENDA).toLowerCase().includes(search)
                ))
            } else {
                setdata(Ordenes.ordersEnd)
            }
        }, 1500);

        return () => {
            clearTimeout(debounce)
        }
    }, [Ordenes, search])





    const getColorPlataform = (code) => {
        switch (code) {
            case 'UBEREATS': {
                return {
                    background: COLOR_BG_UBEREATS,
                    color: COLOR_WHITE
                }
            }
            case 'PEYA': {
                return {
                    background: COLOR_BG_PEDIDOSYA,
                    color: COLOR_WHITE
                }
            }
            case 'RAPPI': {
                return {
                    background: COLOR_BG_RAPPI,
                    color: COLOR_WHITE
                }
            }
            case 'JUSTO': {
                return {
                    background: COLOR_BG_JUSTO,
                    color: COLOR_WHITE
                }
            }
            default: {
                return {
                    background: COLOR_BG_AGIL,
                    color: COLOR_WHITE
                }
            }
        }
    }

    const columns = [
        {
            title: 'Estado',
            render: (text, order, index) => <span>
                {
                    order.IdEstado === ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL ?
                        <PedidoEstadoTag pedido={order} >
                            {
                                order.mapsTracking?.coors?.riderUbicacion?.distances?.cliente && <>
                                    &nbsp; {getDistanceText(order.mapsTracking.coors.riderUbicacion.distances.cliente.meters)}
                                </>
                            }
                        </PedidoEstadoTag> :
                        <PedidoEstadoTag pedido={order} />
                }
            </span>,
            sorter: (a, b, sortOrder) => (
                a.estado.Estado > b.estado.Estado ? 1 :
                    a.estado.Estado < b.estado.Estado ? -1 :
                        0
            ),

        },
        {
            title: 'Orden',
            defaultSortOrder: 'descend',
            render: (text, order, index) => <span>
                <b>
                    {`${order.IdTipoPedido === 5 ? order?.extOrder?.CodigoExterno || '' : order.Codigo} ${order.IdTipoPedido === 5 ? order?.extOrder?.CorrelativoExterno || '' : `-${order.Corelativo}`}`}
                </b>
            </span>,
            sorter: (a, b, sortOrder) => (
                a.Corelativo > b.Corelativo ? 1 :
                    a.Corelativo < b.Corelativo ? -1 :
                        0
            ),
        },
        {
            title: 'Cliente',
            render: (text, order, index) => <p>
                <a
                    // onClick={() => order.client && setdrawerData({
                    onClick={() => order.IdTipoPedido !== 5 && setdrawerData({
                        title: `Historial Cliente ${order.cliente ? order.cliente.Nombre : order.sucursalEntrega ? order.sucursalEntrega.Titulo : ''}`,
                        visible: true,
                        content: DRAWER_CONTENT_HISTORY_IN_POS,
                        props: {
                            IdUsuario: order.cliente.IdUsuario
                        }
                    })}
                    style={{ textTransform: 'capitalize' }}
                >
                    {order.IdTipoPedido === 5 ?
                        `${order?.extOrder?.NombreCliente} ${order?.extOrder?.ApellidoCliente}`
                        : `${order.cliente ? order.cliente.Nombre : order.sucursalEntrega ? order.sucursalEntrega.Titulo : ''} ${order.cliente ? order.cliente.Apellido : ''}`}
                </a>
                <br />
                {
                    order.IdTipoPedido === 5 ? order?.extOrder?.TelefonoCliente : order.cliente && order.cliente.Telefono ?
                        fixTelefono(order.cliente.nacionalidad, order.cliente.Telefono) :
                        order.sucursalEntrega && order.sucursalEntrega.Telefono ?
                            fixTelefono(order.sucursalEntrega.comercio.nacionalidad, order.sucursalEntrega.Telefono)
                            :
                            null

                } {order.IdTipoPedido === 5 ? order?.extOrder?.EmailCliente : order.cliente ? order.cliente.Email : order.sucursalEntrega ? order.sucursalEntrega.Email : ''}
                <br />
                {
                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY ? <>
                        {order.direccionCliente ? order.direccionCliente.Direccion : order.sucursalEntrega ? order.sucursalEntrega.Direccion : ''}
                        <br></br>
                        {`Ref: ${order.direccionCliente ? order.direccionCliente.Nro : ''}`}
                    </> : <>
                        {order.sucursal.Direccion}
                    </>
                }
            </p>,
            sorter: (a, b, sortOrder) => (
                a.cliente.Nombre > b.cliente.Nombre ? 1 :
                    a.cliente.Nombre < b.cliente.Nombre ? -1 :
                        0
            ),
        },
        {
            title: 'Plataforma',
            render: (text, order, index) => <Chip
                label={order?.extOrder?.plataforma?.Nombre || 'AGIL'}
                size="small"
                sx={{
                    ...getColorPlataform(order?.extOrder?.plataforma?.Codigo)
                }} />,
            sorter: (a, b, sortOrder) => (
                a?.extOrder?.plataforma?.Nombre > b?.extOrder?.plataforma?.Nombre ? 1 :
                    a?.extOrder?.plataforma?.Nombre < b?.extOrder?.plataforma?.Nombre ? -1 :
                        0
            ),
        },
        {
            title: 'Precio',
            render: (text, order, index) => <span>
                {currencyFormatter.format(order.TotalOC - order.Propina - order.PropinaAgil - order.CuotaServicioAgil, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
            </span>,
            sorter: (a, b, sortOrder) => (
                a.TotalOC > b.TotalOC ? 1 :
                    a.TotalOC < b.TotalOC ? -1 :
                        0
            ),
        },
        {
            title: 'Retiro',
            render: (text, order, index) => <>
                {
                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY && (
                        order.entrega.rider && <p>
                            {order.entrega.rider.avatarImg && <span>
                                <img style={{
                                    borderRadius: '50%',
                                    width: '70px',
                                    height: '70px',
                                    float: 'right'
                                }} src={order.entrega.rider.avatarImg} alt={''} />
                            </span>}
                            {`${order.entrega.rider.nombre} ${order.entrega.rider.apellido}`}
                            <br />
                            {
                                order.entrega.rider.dispositivos[0].numTelefono ?
                                    fixTelefono(order.entrega.rider.nacionalidad, order.entrega.rider.dispositivos[0].numTelefono) :
                                    null
                            }
                            <br />
                            <RateRidersButtons order={order} />
                        </p>
                    )
                }
                {
                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA && (
                        <Text disabled><b> {TEXT_RETIRO_TIENDA}</b></Text>
                    )
                }
                {
                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_MESA && (
                        <Text disabled><b> {TEXT_MESA} Nº{order.mesa?.Nro}</b></Text>
                    )
                }
            </>,
            sorter: (a, b, sortOrder) => (
                (a.entrega.rider?.nombre || '') > (b.entrega.rider?.nombre || '') ? 1 :
                    (a.entrega.rider?.nombre || '') < (b.entrega.rider?.nombre || '') ? -1 :
                        0
            ),
        },
    ]




    return <>
        <Row>
            <Col span={24}>
                <Input width={'100%'} placeholder='Buscar...' onChange={e => setsearch(e.target.value.toLowerCase())} />
            </Col>
            <Col span={24}>
                <Table
                    columns={columns}
                    expandedRowRender={record => <ExpandedRow order={record} />}
                    dataSource={data}
                />
            </Col>
        </Row>
        <Drawer
            title={drawerData.title}
            visible={drawerData.visible}
            onClose={() => setdrawerData({ visible: false })}
            closable
            destroyOnClose
            width={500}
        >
            {
                drawerData.content === DRAWER_CONTENT_HISTORY_IN_POS ?
                    <ClientHistory
                        {...drawerData.props}
                    /> :
                    null
            }
        </Drawer>
    </>


}

function ExpandedRow({ order }) {


    return <Row gutter={[40, 8]}>
        <Col span={12}>
            <div style={{ width: '100%', display: 'flex' }}>
                <TicketDrawerButton orders={[order]} />
                {
                    order.entrega.TipoEntrega !== PED_ENTRE_TIPO_ENTRE_MESA &&
                    <TrackingOrderButton order={order} />
                }

                {
                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                    order.IdPedidoBase === null && (
                        <SplitOrderDeliveryButton order={order} />
                    )
                }
                {
                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                    <EvidenceButton order={order} />
                }
                <PedidoSeguimientoButton order={order} />
            </div>
            {
                order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                <div style={{
                    marginTop: 20,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <OrderRiders order={order} />
                </div>
            }
            <OrderProducts order={order} style={{ marginTop: 20 }} />

        </Col>
        <Col span={12}>
            <OrderPayment order={order} />
            <OrderTotals order={order} style={{ marginTop: 20 }} />
        </Col>
    </Row>
}