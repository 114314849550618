import actions from './actions';
import { ID_PEDIDO_ESTADO_ACEPTADO, ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY, ID_PEDIDO_ESTADO_PENDIENTE } from './types';

const initState = {
    orders: [],
    ordersActive: [],
    ordersReady: [],
    ordersEnd: [],
    ordersRejected: [],
    ordersRefunded: [],
    ordersScheduled: [],
    ordersScheduledSelectedIndex: 0,
};



export default function Ordernes(state = initState, action) {
    switch (action.type) {
        case actions.INIT_ORDER_SUCCESS:
            return {
                ...state,
                orders: action.payload.orders || [],
                ordersActive: action.payload.ordersActive || [],
                ordersReady: action.payload.ordersReady || [],
                ordersEnd: action.payload.ordersEnd || [],
                ordersRejected: action.payload.ordersRejected || [],
                ordersRefunded: action.payload.ordersRefunded || [],
                ordersScheduled: action.payload.ordersScheduled || [],
            }

        case actions.ERROR:
            return {
                ...state,
                error: action.error
            };

        case actions.MOVE_BEETWEEN_LIST_SUCCESS:
            const { IdPedido, to } = action.payload

            const orderlist = whereIsIdPedido(IdPedido, state)

            if (orderlist.order && orderlist.list && orderlist.list !== to) {
                state[orderlist.list] = state[orderlist.list].filter(order => order !== orderlist.order)
                state[to].push(orderlist.order)

                return {
                    ...state
                }
            }

            return state

        case actions.REFRESH_DATA_ORDER_SUCCESS:
            const orderToRefresh = getAllOrdersIncludeChild(state).find(o => o.IdPedido === action.payload.order.IdPedido)

            if (orderToRefresh) {
                Object.keys(action.payload.order).forEach((key) => {
                    orderToRefresh[key] = action.payload.order[key]
                })
            }

            return { ...state }

        case actions.LOAD_MAPS_TRACKING:
            const mapsTracking = action.payload
            const orderToLoadMapsTracking = getAllOrdersIncludeChild(state).find(o => o.IdPedido === mapsTracking.IdPedido)
            if (orderToLoadMapsTracking) {
                orderToLoadMapsTracking.mapsTracking = mapsTracking
            }
            return { ...state }

        case actions.LOAD_PEDIDO_SEGUIMIENTOS:
            const { seguimientos } = action.payload

            const orderToLoadSeguimientos = getAllOrdersIncludeChild(state).find(o => o.Codigo === action.payload.pedido.Codigo)
            if (orderToLoadSeguimientos) {
                orderToLoadSeguimientos.seguimientos = seguimientos
            }
            return { ...state }

        case actions.PUSH_IN_LIST_SUCCESS:
            return {
                ...state,
                [action.payload.list]: [
                    ...state[action.payload.list],
                    action.payload.order
                ]
            }

        case actions.RECEIVE_SOCKET_LIVE_INFO:
            receiveSocketLiveInfo(state, action.payload)
            return { ...state }
        default:
            return state;
    }
}


function whereIsIdPedido(IdPedido, state) {
    const posibleLists = [
        'orders',
        'ordersActive',
        'ordersReady',
        'ordersEnd',
        'ordersRejected',
        'ordersRefunded',
        'ordersScheduled',
    ]

    for (const posibleList of posibleLists) {
        const order = state[posibleList].find(order => order.IdPedido == IdPedido)
        if (order) {
            return {
                order,
                list: posibleList
            }
        }
    }
    return {
        order: null,
        list: ''
    }
}

function getAllOrdersIncludeChild(state) {
    try {
        return [
            ...state.orders,
            ...state.ordersActive,
            ...state.ordersReady,
            ...state.ordersEnd,
            ...state.ordersRejected,
            ...state.ordersRefunded,
            ...state.ordersScheduled,
        ].reduce((aux, o) => {
            aux.push(o)
            if (o.pedidos && o.pedidos.length) {
                aux.push(...o.pedidos)
            }
            return aux
        }, [])
    } catch (error) {
        console.error(error)
        return []
    }
}

/**
 * 
 * @param {(typeof initState)} state
 * @param {{
 * type: 'PEDIDOS_DELAYED_DESPACHO_COCINA' | 'PEDIDOS_DELAYED_WAITING_RIDER'
 * data: any
 * }} param1
 */
function receiveSocketLiveInfo(state, { type, data }) {
    data.forEach(liveData => {
        const pedido = getAllOrdersIncludeChild(state).find(o => o.IdPedido === liveData.IdPedido)

        switch (type) {
            case 'PEDIDOS_DELAYED_DESPACHO_COCINA':
                pedido.delayedDespachoCocina = liveData
                break;
            case 'PEDIDOS_DELAYED_WAITING_RIDER':
                pedido.delayedWaitingRider = liveData
                break;
        }
    })
}