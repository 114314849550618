import { Box, Button, CircularProgress, DialogActions, DialogContent, Grid, Paper, Typography } from '@mui/material';
import moment from 'moment-timezone';

import React, { useCallback, useContext, useState } from 'react'
import { useSelector } from 'react-redux';
import { SocketContext } from '../../containers/Dashboard/Dashboard';

import Swal from 'sweetalert2'
import Picker from 'react-mobile-picker';
const INTERVAL = 15
const MAX_MIN = 120
const INICIO = 15

// Calcular la cantidad de intervalos
const cantidadIntervalos = Math.floor((MAX_MIN - INICIO) / INTERVAL) + 1;
const arregloIntervalos = Array.from({ length: cantidadIntervalos }, (_, index) => INICIO + index * INTERVAL);

export const PrepOrder = ({
    order,
    setSelectedMenu
}) => {
    const Auth = useSelector(state => state.Auth)
    const socket = useContext(SocketContext)
    const IdUsuario = Auth.auth.IdUsuario
    const [minSelected, setMinSelected] = useState({ Minutos: arregloIntervalos[0] })


    const [submitting, setsubmitting] = useState(false)

    const submitOrderChangeTimes = useCallback(
        () => {
            setsubmitting(true)
            Swal.fire({
                title: 'Tiempo Preparación',
                text: `¿Seguro quieres agregar ${minSelected.Minutos} minutos a este pedido? su nueva entrega sera ${moment.utc(order.entrega.FechaEstimadaEntrega).tz(order.sucursal.zonaHoraria.Zona).add(minSelected.Minutos, 'minutes').format('DD-MM-YYYY HH:mm')}`,
                icon: 'warning',
                customClass: {
                    container: 'my-swal'
                },
                showCancelButton: true,
                confirmButtonText: 'Confirmar y cambiar',
                cancelButtonText: 'Cancelar',

            }).then((result) => {
                if (result.isConfirmed) {
                    socket.emit("changeOrderTimes", {
                        IdSucursal: order.IdSucursal,
                        IdPedido: order.IdPedido,
                        IdUsuario,
                        tipo: 'UPDATE_PREP_ORDER',
                        min: minSelected.Minutos

                    }, (data) => {

                        setSelectedMenu(0)
                        setsubmitting(false)

                    })
                } else {
                    setsubmitting(false)
                }
            })

        }, [
        socket,
        order,
        IdUsuario,
        minSelected
    ]
    )


    return (
        <>
            <DialogContent >
                <Typography variant='h6'
                    sx={{
                        marginBottom: 2
                    }}
                >
                    Seleccione Minutos
                </Typography>

                <Picker value={minSelected} onChange={(e) => setMinSelected(e)} wheel="normal" itemHeight={45} height={300} >
                    <Picker.Column key={'Minutos'} name={'Minutos'}>

                        {arregloIntervalos.map(ele => (
                            <Picker.Item key={ele} value={ele}>
                                <Typography sx={{ fontSize: 20 }}>{ele}</Typography>
                            </Picker.Item>
                        ))}

                    </Picker.Column>
                </Picker>


                {minSelected &&
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            marginTop: 10
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant='subtitle2' sx={{ fonSize: 16 }}>Fecha y Hora entrega actual</Typography>

                            <Typography>
                                {moment.utc(order.entrega.FechaEstimadaEntrega).tz(order.sucursal.zonaHoraria.Zona).format('DD-MM-YYYY HH:mm')}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography></Typography>
                            <Typography
                                sx={{
                                    mx: 5
                                }}
                            >
                                ➡
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant='subtitle2' sx={{ fonSize: 16 }}>Fecha y Hora entrega nueva</Typography>
                            <Typography>
                                {moment.utc(order.entrega.FechaEstimadaEntrega).tz(order.sucursal.zonaHoraria.Zona).add(minSelected.Minutos, 'minutes').format('DD-MM-YYYY HH:mm')}
                            </Typography>
                        </Box>
                    </Box>

                }
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={submitting}
                    onClick={() => setSelectedMenu(0)}
                >
                    Volver
                </Button>
                <Button
                    onClick={submitOrderChangeTimes}
                    variant='contained'
                    endIcon={submitting && <CircularProgress size={14} />}
                    disabled={!minSelected || submitting}
                >
                    {minSelected ? `agregar ${minSelected.Minutos} minutos` : 'Agregar'}
                </Button>
            </DialogActions>
        </>
    )
}