
import socketIOClient from "socket.io-client"

export const socketLocalPOSData = {
    printers: [],
    machineId: '',
    machineIdOriginal: '',
}

export const socketPrint = socketIOClient('http://localhost:3003')

socketPrint
    .off('subscribed-as-printer')
    .on('subscribed-as-printer', (data) => {

        console.log('subscribed-as-printer', data)

        Object.keys(data).forEach(key => {
            socketLocalPOSData[key] = data[key]
        })


        lastDataSync && syncSocketLocalvsCloud(lastDataSync)

    })
    .on('disconnect', () => {
        console.log('LAS IMPRESORAS SE HAN DESCONECTADO')
    })
    .on('connect', () => {
        console.log('LAS IMPRESORAS SE HAN CONECTADO')
        socketPrint.emit('subscribe-as-printer')
    })
    .on('reconnect', () => {
        console.log('LAS IMPRESORAS SE HAN RECONECTADO')
    })

/**
 * @returns {Promise<string[]>}
 */
export async function getPrinters() {
    return new Promise((resolve, reject) => {
        if (socketPrint.connected) {
            socketPrint.emit('getPrinters', {}, ({ printers }) => {
                resolve(printers)
            })
        } else {
            resolve([])
        }
    })
}

export async function print({ url, printer }) {
    return new Promise((resolve, reject) => {
        if (socketPrint.connected) {
            socketPrint.emit('print', {
                // url: 'https://agil.s3.us-east-2.amazonaws.com/comercio-qa/7/oc/tickets/ticket-order-1398.pdf',
                // printer: 'Microsoft Print to PDF'
                url,
                printer
            }, result => {

                resolve(result)
            })

        } else {
            resolve(false)
        }

    })

}


let lastDataSync
export async function syncSocketLocalvsCloud(dataSync) {
    lastDataSync = dataSync;
    const { socket, usuario: { IdUsuario }, Token } = dataSync
    socket.emit('UsuarioImpresor', {
        IdUsuario,
        Token,
        socketPrintConnected: socketPrint.connected,
        socketPrintPrinters: socketPrint.connected ? await getPrinters() : [],
        socketPrintmachineId: socketPrint.connected ? socketLocalPOSData.machineId : null,
        socketPrintmachineIdOriginal: socketPrint.connected ? socketLocalPOSData.machineIdOriginal : null,
    }, () => {
    })
}