import {
    Box,
    Container,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from '@mui/material'
import React, { useState, useEffect, memo } from 'react'
import { IMG_AGIL_DELIVERY, IMG_AGIL_RETIRO } from '../../../constants/img/agil'
import { AccessTime, AddCircle, Delete, Email, LocationOn, Person, Phone, RemoveCircle, Store } from '@mui/icons-material'
import { formatNumber } from '../../../helpers/formatNumber'
import { COLOR_RED, COLOR_WHITE } from '../../../constants/color'
import {
    DATE_TYPE_PROGRAMADO,
    DELIVERY_TYPE_DELIVERY,
    PRODUCT_ADD_QUATITY,
    PRODUCT_REMOVE_QUATITY,
    TYPE_ESPECIAL,
    TYPE_FLASH
} from '../../../redux/pedidoEspecial/types'
import { setChangeQuantityProduct, setRemoveProduct, setTip } from '../../../redux/pedidoEspecial/actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

const Preview = ({ setPreview }) => {
    const dispatch = useDispatch()
    const {
        type,
        deliveryType,
        dateType,
        deliveryDate,
        deliveryTime,
        origin,
        destiny,
        content,
        useCar,
        products,
        totalPrice
    } = useSelector(state => state?.PedidoEspecial)
    const { Moneda } = useSelector(state => state?.Auth?.payload?.usuarioPerfil?.sucursal?.comercio?.nacionalidad)
    const [tipSelected, setTipSelected] = useState({ perc: 10, value: 1000 })
    const COSTO_FIJO_AGIL_FLASH = 1000
    const COSTO_FIJO_AUTO = 1000

    useEffect(() => {
        if (type) {
            // SET DEFAUL TIP
            if (type === TYPE_FLASH) {
                changeTip(false, 1000)
            } else {
                changeTip(true, 10)
            }
        }
    }, [type])

    const changeQuantityProduct = async (type, position) => {
        await dispatch(setChangeQuantityProduct({ type, position }))
    }

    const removeProduct = async (position) => {
        await dispatch(setRemoveProduct({ position }))
    }

    const formatEstimatedDelivery = (date, program = false) => {
        const today = moment().format("DD/MM/YYYY")
        const newDate = moment(date).format("DD/MM/YYYY")
        const nameDate = `${moment(date).format("dddd")} ${moment(date).format("DD")}`
        const time = moment(date).format("HH:mm")
        let text = ''

        if (today === newDate) {
            text = `${(program) ? `hoy` : `Hoy`} a las ${time}`
        } else {
            text = `${(program) ? `el` : `El`} ${nameDate} a las ${time}`
        }
        return text
    }

    const formatTime = (time, short = false) => {
        let formatTime = time.toString()
        if (formatTime.length < 4) {
            formatTime = `0${formatTime}`
        }
        formatTime = formatTime.match(/\d{2}/g).join(":")
        if (short) {
            formatTime = formatTime.substring(0, 5)
            return `${formatTime}`
        } else {
            return `${formatTime}:00`
        }
    }

    const changeTip = (isPerc, val) => {
        if (isPerc) {
            setTipSelected({ perc: val, value: (totalPrice * val) })
            dispatch(setTip({ tip: Math.round(totalPrice * (val / 100)) }))

        } else {
            setTipSelected({ perc: (val / 100), value: val })
            dispatch(setTip({ tip: Math.round(val) }))
        }
    }

    const getTotal = (type) => {
        let total = 0
        if (type === TYPE_FLASH) {
            total = (
                COSTO_FIJO_AGIL_FLASH
                + ((deliveryType === DELIVERY_TYPE_DELIVERY) ? destiny?.shippingCost : 0)
                + ((deliveryType === DELIVERY_TYPE_DELIVERY && useCar) ? COSTO_FIJO_AUTO : 0)
                + tipSelected?.value
            )
        } else {
            total = (
                totalPrice
                + ((deliveryType === DELIVERY_TYPE_DELIVERY) ? destiny?.shippingCost : 0)
                + Math.round(totalPrice * (tipSelected?.perc / 100))
            )
        }
        return total
    }

    return (
        <>
            <Container maxWidth='lg'>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h5' fontWeight={600}>
                            {
                                (type === TYPE_ESPECIAL) ?
                                    `Pedido Especial`
                                    :
                                    `Agil Flash`
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            Origen:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} sx={{ px: 3 }}>
                            <Grid item xs={12}>
                                <Stack direction='row' alignItems='center'>
                                    <Box
                                        component='img'
                                        src={(deliveryType === DELIVERY_TYPE_DELIVERY) ? IMG_AGIL_DELIVERY : IMG_AGIL_RETIRO}
                                        sx={{
                                            height: 20,
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <Typography variant='body1' sx={{ ml: 1 }}>
                                        {
                                            (deliveryType === DELIVERY_TYPE_DELIVERY) ?
                                                `Pedido para Delivery`
                                                :
                                                `Pedido para Retiro en Tienda`
                                        }
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row' alignItems='center'>
                                    <AccessTime />
                                    <Typography variant='body1' sx={{ ml: 1 }}>
                                        {
                                            (dateType === DATE_TYPE_PROGRAMADO) ?
                                                `Programado para ${formatEstimatedDelivery(`${deliveryDate} ${formatTime(deliveryTime)}`)}`
                                                :
                                                `Pedido para Hoy lo antes posible`
                                        }
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row' alignItems='center'>
                                    <Store />
                                    <Typography variant='body1' sx={{ ml: 1 }}>
                                        {`Desde sucursal ${origin?.Titulo} (${origin?.Direccion})`}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            Destino:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            (destiny?.client) && (
                                <Grid container spacing={1} sx={{ px: 3 }}>
                                    <Grid item xs={12}>
                                        <Stack direction='row' alignItems='center'>
                                            <Person />
                                            <Typography variant='body1' sx={{ ml: 1 }}>
                                                {destiny?.client?.Nombre} {destiny?.client?.Apellido}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction='row' alignItems='center'>
                                            <Email />
                                            <Typography variant='body1' sx={{ ml: 1 }}>
                                                {destiny?.client?.Email}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction='row' alignItems='center'>
                                            <Phone />
                                            <Typography variant='body1' sx={{ ml: 1 }}>
                                                {destiny?.client?.Telefono}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction='row' alignItems='center'>
                                            <LocationOn />
                                            <Typography variant='body1' sx={{ ml: 1 }}>
                                                Entregar en {destiny?.client?.Direccion}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )
                        }

                        {
                            (destiny?.store) && (
                                <Grid container spacing={1} sx={{ px: 3 }}>
                                    <Grid item xs={12}>
                                        <Stack direction='row' alignItems='center'>
                                            <Store />
                                            <Typography variant='body1' sx={{ ml: 1 }}>
                                                {destiny?.store?.Titulo}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction='row' alignItems='center'>
                                            <LocationOn />
                                            <Typography variant='body1' sx={{ ml: 1 }}>
                                                Entregar en {destiny?.store?.Direccion}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    {
                                        (destiny?.storeAdmin) && (
                                            <Grid item xs={12}>
                                                <Stack direction='row' alignItems='center'>
                                                    <Email />
                                                    <Typography variant='body1' sx={{ ml: 1 }}>
                                                        Entregar a {destiny?.storeAdmin?.Nombre} {destiny?.storeAdmin?.Apellido}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            )
                        }

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            Contenido:
                        </Typography>
                    </Grid>

                    {
                        (type === TYPE_FLASH) ?
                            <Grid item xs={12}>
                                <Typography variant='body1'>
                                    {content}
                                </Typography>
                                <Divider sx={{ py: 1 }} />
                                <Grid container spacing={1}>
                                    <Grid item md={10} xs={8}>
                                        <Typography variant="h6" textAlign='end'>
                                            Agil Flash:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <Typography variant="h6" textAlign='end'>
                                            {`${formatNumber({ num: COSTO_FIJO_AGIL_FLASH, currency: Moneda })} + IVA`}
                                        </Typography>
                                    </Grid>

                                    {
                                        (deliveryType === DELIVERY_TYPE_DELIVERY) && (
                                            <>
                                                <Grid item md={10} xs={8}>
                                                    <Typography variant="h6" textAlign='end'>
                                                        Costo de Envío:
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Typography variant="h6" textAlign='end'>
                                                        {`${formatNumber({ num: destiny?.shippingCost, currency: Moneda })} + IVA`}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )
                                    }

                                    {
                                        (deliveryType === DELIVERY_TYPE_DELIVERY && useCar) && (
                                            <>
                                                <Grid item md={10} xs={8}>
                                                    <Typography variant="h6" textAlign='end'>
                                                        Envío por Auto:
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Typography variant="h6" textAlign='end'>
                                                        {`${formatNumber({ num: COSTO_FIJO_AUTO, currency: Moneda })} + IVA`}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )
                                    }

                                    <Grid item md={10} xs={8}>
                                        <Typography variant="h6" textAlign='end'>
                                            Propina:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select_tip">Propina</InputLabel>
                                            <Select
                                                id="select_tip"
                                                size='small'
                                                value={tipSelected?.value}
                                                label="Propina"
                                                onChange={(ev) => changeTip(false, ev?.target?.value)}
                                            >
                                                <MenuItem value={0}>{`${formatNumber({ num: 0, currency: Moneda })}`}</MenuItem>
                                                <MenuItem value={500}>{`${formatNumber({ num: 500, currency: Moneda })}`}</MenuItem>
                                                <MenuItem value={1000}>{`${formatNumber({ num: 1000, currency: Moneda })}`}</MenuItem>
                                                <MenuItem value={1500}>{`${formatNumber({ num: 1500, currency: Moneda })}`}</MenuItem>
                                                <MenuItem value={2000}>{`${formatNumber({ num: 2000, currency: Moneda })}`}</MenuItem>
                                                <MenuItem value={2500}>{`${formatNumber({ num: 2500, currency: Moneda })}`}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={10} xs={8}>
                                        <Typography variant="h5" textAlign='end' fontWeight={600}>
                                            TOTAL:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <Typography variant="h5" textAlign='end' fontWeight={600}>
                                            {formatNumber({ num: getTotal(TYPE_FLASH), currency: Moneda })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                {
                                    products.map((p, pIndex) => {
                                        return (
                                            <Grid key={pIndex} container spacing={0}>
                                                <Grid item md={2} xs={12}>
                                                    <Stack direction='row' alignItems='center' justifyContent='start'>
                                                        <IconButton
                                                            disabled={(p?.CantidadSeleccionada <= 1) ? true : false}
                                                            onClick={() => changeQuantityProduct(PRODUCT_REMOVE_QUATITY, pIndex)}
                                                        >
                                                            <RemoveCircle
                                                                size={14}
                                                                color={(p?.CantidadSeleccionada <= 1) ? 'inherit' : 'primary'}
                                                            />
                                                        </IconButton>
                                                        <Typography variant="h6" sx={{ mx: 1 }}>
                                                            {p?.CantidadSeleccionada}
                                                        </Typography>
                                                        <IconButton onClick={() => changeQuantityProduct(PRODUCT_ADD_QUATITY, pIndex)}>
                                                            <AddCircle
                                                                size={14}
                                                                color='primary'
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                </Grid>
                                                <Grid item md={7} xs={12}>
                                                    <Box>
                                                        <Typography variant="h6">
                                                            {p?.Nombre}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                        <Typography variant="h6" sx={{ mr: 2 }}>
                                                            {`${formatNumber({ num: p?.CostoTotal, currency: Moneda })}`}
                                                        </Typography>
                                                        <IconButton onClick={() => removeProduct(pIndex)}>
                                                            <Delete
                                                                size={18}
                                                                sx={{
                                                                    bgcolor: COLOR_RED,
                                                                    borderRadius: 100,
                                                                    p: 0.4,
                                                                    color: COLOR_WHITE
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                </Grid>
                                                {
                                                    (p.Comentario) && (
                                                        <>
                                                            <Grid item md={2} xs={12} />
                                                            <Grid item md={10} xs={12}>
                                                                <Typography variant="caption">
                                                                    {p.Comentario}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )
                                                }

                                                {
                                                    (p.modificadores.length > 0) && (
                                                        <>
                                                            <Grid item md={2} xs={12} />
                                                            <Grid item md={10} xs={12}>
                                                                {
                                                                    p.modificadores.map((m, mIndex) => {
                                                                        return (
                                                                            <Box
                                                                                key={mIndex}
                                                                                sx={{
                                                                                    px: 2,
                                                                                    opacity: 0.7
                                                                                }}
                                                                            >
                                                                                <Typography variant="body1">
                                                                                    {m?.Nombre}
                                                                                </Typography>
                                                                                {
                                                                                    m.opciones.map((o, oIndex) => {
                                                                                        return (
                                                                                            <Box
                                                                                                key={oIndex}
                                                                                                sx={{ px: 2 }}
                                                                                            >
                                                                                                <Typography variant="body2">
                                                                                                    {o?.Nombre} x {o?.CantidadSeleccionada}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Box>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </>
                                                    )
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                                <Divider sx={{ py: 1 }} />
                                <Grid container spacing={1}>
                                    <Grid item md={10} xs={8}>
                                        <Typography variant="h6" textAlign='end'>
                                            Subtotal:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <Typography variant="h6" textAlign='end'>
                                            {`${formatNumber({ num: totalPrice, currency: Moneda })}`}
                                        </Typography>
                                    </Grid>

                                    {
                                        (deliveryType === DELIVERY_TYPE_DELIVERY) && (
                                            <>
                                                <Grid item md={10} xs={8}>
                                                    <Typography variant="h6" textAlign='end'>
                                                        Costo de Envío:
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={4}>
                                                    <Typography variant="h6" textAlign='end'>
                                                        {`${formatNumber({ num: destiny?.shippingCost, currency: Moneda })} + IVA`}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )
                                    }

                                    <Grid item md={10} xs={8}>
                                        <Typography variant="h6" textAlign='end'>
                                            Propina:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select_tip">Propina</InputLabel>
                                            <Select
                                                id="select_tip"
                                                size='small'
                                                value={tipSelected?.perc}
                                                label="Propina"
                                                onChange={(ev) => changeTip(true, ev?.target?.value)}
                                            >
                                                <MenuItem value={0}>{`0% (${formatNumber({ num: Math.round(totalPrice * 0), currency: Moneda })})`}</MenuItem>
                                                <MenuItem value={5}>{`5% (${formatNumber({ num: Math.round(totalPrice * 0.05), currency: Moneda })})`}</MenuItem>
                                                <MenuItem value={10}>{`10% (${formatNumber({ num: Math.round(totalPrice * 0.1), currency: Moneda })})`}</MenuItem>
                                                <MenuItem value={15}>{`15% (${formatNumber({ num: Math.round(totalPrice * 0.15), currency: Moneda })})`}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={10} xs={8}>
                                        <Typography variant="h5" textAlign='end' fontWeight={600}>
                                            TOTAL:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <Typography variant="h5" textAlign='end' fontWeight={600}>
                                            {formatNumber({ num: getTotal(TYPE_ESPECIAL), currency: Moneda })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                    }

                </Grid>
            </Container>
        </>
    )
}

export default memo(Preview)