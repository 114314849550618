
/**
 * 
 * @param auth 
 * @param codigo 
 * @param tipo 
 * @returns {boolean}
 */
export function validatePrivilegioRecurso(Auth, codigo, tipo = 'acceso') {
    return Auth.payload.privilegiosPerfiles.some(privilegiosPerfil =>
        privilegiosPerfil.privilegios.some(privilegio => privilegio.codigo === codigo && privilegio[tipo])
    )
}