import React, { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import { ISucursalNotificacionesState } from '../../redux/sucursalNotificaciones/reducer'
import {
    TIPO_SUCURSAL_NOTIFICACION_ERROR,
    TIPO_SUCURSAL_NOTIFICACION_SUCCESS_RIDER,
    TIPO_SUCURSAL_NOTIFICACION_INFO_RIDER
} from '../../redux/sucursalNotificaciones/types.json'
import {
    Badge,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    FormControlLabel,
    FormGroup,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Switch,
    Typography
} from '@mui/material'
import { Info, Notifications } from '@mui/icons-material'
import { COLOR_YELLOW } from '../../constants/color'
import moment from 'moment'

const TopbarNotification = () => {
    /**
     * @type {typeof ISucursalNotificacionesState}
     */
    const SucursalNotificacionesAux = useSelector(state => state.SucursalNotificaciones)

    /**
     * @type {typeof SucursalNotificacionesAux.notificaciones[]}
     */
    const [notificaciones, setNotificaciones] = useState([])
    const [needRead, setNeedRead] = useState(0)
    const [open, setOpen] = useState(false)
    const [errorDetails, setErrorDetails] = useState(null)
    const [errorOnly, setErrorOnly] = useState(false)

    useEffect(() => {
        if (
            SucursalNotificacionesAux
            && SucursalNotificacionesAux.notificaciones
            && SucursalNotificacionesAux.notificaciones.length > 0
        ) {
            getNotifications()
        }
    }, [SucursalNotificacionesAux, errorOnly])

    useEffect(() => {
        if (open) {
            SucursalNotificacionesAux.notificaciones.forEach(n => { n.Leido = true })
            setErrorOnly(false)
            setNeedRead(0)
        }
    }, [open])

    const closeDrawer = () => {
        setOpen(false)
    }

    const closeDeatails = () => {
        setErrorDetails(null)
    }

    const showErrorDetails = (e) => {
        setErrorDetails(e)
    }

    const getNotifications = () => {
        if (errorOnly) {
            setNeedRead(SucursalNotificacionesAux.notificaciones.filter((e) => e.Tipo === TIPO_SUCURSAL_NOTIFICACION_ERROR && !e.Leido).length)
            setNotificaciones(SucursalNotificacionesAux.notificaciones.filter((e) => e.Tipo === TIPO_SUCURSAL_NOTIFICACION_ERROR))
        } else {
            setNeedRead(SucursalNotificacionesAux.notificaciones.filter(n => !n.Leido).length)
            setNotificaciones(SucursalNotificacionesAux.notificaciones)
        }
    }

    return (
        <>
            {
                (SucursalNotificacionesAux.notificaciones.length > 0) && (
                    <>
                        <IconButton onClick={() => setOpen(true)}>
                            <Badge
                                badgeContent={needRead}
                                color="warning"
                                sx={{
                                    '.MuiBadge-colorWarning': {
                                        bgcolor: COLOR_YELLOW
                                    }
                                }}
                            >
                                <Notifications color='primary' fontSize='large' />
                            </Badge>
                        </IconButton>

                        <Drawer
                            anchor='right'
                            open={open}
                            onClose={closeDrawer}
                        >
                            <List sx={{ width: 400 }}>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    justifyContent: 'start',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography variant='h5'>
                                                        Notificaciones
                                                    </Typography>
                                                    <Chip
                                                        size='small'
                                                        sx={{
                                                            bgcolor: COLOR_YELLOW,
                                                            ml: 1
                                                        }}
                                                        label={notificaciones.length}
                                                    />
                                                </Box>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        label="Mostrar sólo errores"
                                                        control={
                                                            <Switch
                                                                color='error'
                                                                onChange={() => setErrorOnly(!errorOnly)}
                                                                checked={errorOnly}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </Box>
                                        }
                                    />
                                </ListItem>

                                {
                                    notificaciones.map((e, i) => {
                                        return (
                                            <ListItemButton
                                                key={i}
                                                onClick={() => (e.Tipo === TIPO_SUCURSAL_NOTIFICACION_ERROR) && showErrorDetails(e)}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Chip
                                                                size='small'
                                                                color={(e.Tipo === TIPO_SUCURSAL_NOTIFICACION_SUCCESS_RIDER) ?
                                                                    'success'
                                                                    :
                                                                    (e.Tipo === TIPO_SUCURSAL_NOTIFICACION_INFO_RIDER) ?
                                                                        'info'
                                                                        :
                                                                        e.Tipo.toLocaleLowerCase()
                                                                }
                                                                icon={
                                                                    <Info fontSize='small' />
                                                                }
                                                                label={e.Titulo}
                                                            />
                                                            <Typography variant='caption' sx={{ opacity: 0.7 }}>
                                                                {moment(e.createdAtTZ).format('YYYY-MM-DD HH:mm')}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography variant='body2' sx={{ opacity: 0.7, my: 1 }}>
                                                                {e.Notificacion}
                                                            </Typography>
                                                            <Divider sx={{ mx: 4 }} />
                                                        </>
                                                    }
                                                />
                                            </ListItemButton>
                                        )
                                    })
                                }
                            </List>
                        </Drawer>

                        {
                            (errorDetails) && (
                                <Dialog
                                    open={(errorDetails) ? true : false}
                                    onClose={closeDeatails}
                                    fullWidth
                                >
                                    <DialogTitle>
                                        {errorDetails.Titulo}
                                    </DialogTitle>
                                    <DialogContent>
                                        {errorDetails.Descripcion.split('\n').map((e, i) => {
                                            return (
                                                <Box key={i}>
                                                    {
                                                        (e.endsWith(':')) ?
                                                            <Typography variant='body1' sx={{ fontWeight: 600 }}>
                                                                {e}
                                                            </Typography>
                                                            :
                                                            <Typography variant='body2'>
                                                                {e}
                                                            </Typography>
                                                    }
                                                </Box>
                                            )
                                        })}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={closeDeatails}
                                        >
                                            Cerrar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default memo(TopbarNotification)