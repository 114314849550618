import { all, takeEvery, fork, put, call } from 'redux-saga/effects';
import actions from './actions';
import { LAMBDA_POS } from "../../config"
import Axios from 'axios';


function* initOrders() {
    yield takeEvery(actions.INIT_ORDER, function* ({ payload, callback }) {
        const { orders, IdSucursal, Token } = payload

        const payloadNext = {
            orders: [],
            ordersActive: [],
            ordersReady: [],
            ordersEnd: [],
            ordersScheduled: [],
        }

        if (orders) {
            payloadNext.orders = orders.today
            payloadNext.ordersActive = orders.active
            payloadNext.ordersReady = orders.ready
            payloadNext.ordersEnd = orders.ended
            payloadNext.ordersRejected = orders.rejected
            payloadNext.ordersRefunded = orders.refunded
            payloadNext.ordersScheduled = orders.scheduled
        } else {
            const response = yield call(orderGet, IdSucursal)
            payloadNext.orders = response.data.orders.today
            payloadNext.ordersActive = response.data.orders.active
            payloadNext.ordersReady = response.data.orders.ready
            payloadNext.ordersEnd = response.data.orders.ended
            payloadNext.ordersRejected = response.data.orders.rejected
            payloadNext.ordersRefunded = response.data.orders.refunded
            payloadNext.ordersScheduled = response.data.orders.scheduled
        }

        yield put({
            type: actions.INIT_ORDER_SUCCESS,
            payload: payloadNext,
        })

        callback && setTimeout(callback, 1000);
    })
}


async function orderGet(IdSucursal) {
    return Axios.get(`${LAMBDA_POS}/getOrders/${IdSucursal}`)
        .catch(err => {
            console.error(err)
            return {
                data: {
                    orders: {
                        today: [],
                        active: [],
                        ready: [],
                        ended: [],
                        rejected: [],
                        scheduled: [],
                    }
                }
            }
        })
}


function* moveBeetweenList() {
    yield takeEvery(actions.MOVE_BEETWEEN_LIST, function* ({ payload }) {
        const { IdPedido, from, to, callback } = payload
        yield put({
            type: actions.MOVE_BEETWEEN_LIST_SUCCESS,
            payload: { IdPedido, from, to }
        })
        callback && setTimeout(() => callback(to), 1000);
    });
}

function* refreshDataOrder() {
    yield takeEvery(actions.REFRESH_DATA_ORDER, function* ({ payload }) {
        const { callback } = payload
        let { order } = payload

        if (!order) {
            const { IdPedido, IdSucursal } = payload
            order = yield call(callGetDataOrder, IdSucursal, IdPedido)
        }

        yield put({
            type: actions.REFRESH_DATA_ORDER_SUCCESS,
            payload: {
                order
            },
        })

        callback && setTimeout(callback, 1000);
    });
}

function* pushInList() {
    yield takeEvery(actions.PUSH_IN_LIST, function* ({ payload }) {
        const { order, list, callback } = payload

        yield put({
            type: actions.PUSH_IN_LIST_SUCCESS,
            payload: {
                order, list
            },
        })

        callback && setTimeout(callback, 1000);
    });
}

const callGetDataOrder = async (IdSucursal, IdPedido) => {
    return Axios.get(`${LAMBDA_POS}/getOrder/${IdSucursal}/${IdPedido}`)
        .then(response => response.data.order)
}



export default function* rootSaga() {
    yield all([
        fork(initOrders),
        fork(moveBeetweenList),
        fork(refreshDataOrder),
        fork(pushInList),
    ]);
}