const moment = require("moment")
/**
 * 
 * @param {date} date1 
 * @param {date} date2
 * @returns {int}
 */
module.exports.daysDiff = function (date1, date2 = new Date()) {
    let start = moment(date1).startOf('day');
    let current = moment(date2).startOf('day');
    let diff = moment.duration(start.diff(current)).asDays()
    if (diff < 0) {
        diff = diff * -1;
    }
    return diff;
}