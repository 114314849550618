import {
    ADD_ONE_MORE,
    ADD_PRODUCT,
    CLEAN_DATA,
    REMOVE_PRODUCT,
    SET_ACTIVE_PANEL,
    SET_CHANGE_QUANTITY_PRODUCT,
    SET_CHECKBOX_OPTION,
    SET_CLEAN_TEMP_PRODUCT,
    SET_CLOSE_MODAL_PEDIDO_ESPECIAL,
    SET_COMMENT,
    SET_TEMP_COMMENT,
    SET_CONTENT,
    SET_COUNTER_OPTION,
    SET_DATE_TYPE,
    SET_DELIVERY_DATETIME,
    SET_DELIVERY_TYPE,
    SET_DESTINY_CLIENT,
    SET_DESTINY_CLIENT_ADDITIONAL_INFORMATION,
    SET_DESTINY_CLIENT_DIRECTION,
    SET_DESTINY_CLIENT_EMAIL,
    SET_DESTINY_CLIENT_LASTNAME,
    SET_DESTINY_CLIENT_NAME,
    SET_DESTINY_CLIENT_PHONE,
    SET_DESTINY_CONFIRM,
    SET_DESTINY_NEW_CLIENT,
    SET_DESTINY_STORE,
    SET_DESTINY_STORE_ADMIN,
    SET_DESTINY_TYPE,
    SET_ORIGIN,
    SET_RADIO_OPTION,
    SET_REMOVE_PRODUCT,
    SET_SHOW_MODAL_PEDIDO_ESPECIAL,
    SET_STORE_IS_OPEN,
    SET_TEMP_PRODUCT,
    SET_TEMP_PRODUCT_QUANTITY,
    SET_TEMP_TO_CART,
    SET_TYPE,
    SET_USE_CAR,
    SUBTRACT_ONE,
    SET_TIP_PERC,
    SET_TIP
} from "./types"

export const setShowModalPedidoEspecial = () => {
    return {
        type: SET_SHOW_MODAL_PEDIDO_ESPECIAL,
    }
}

export const setCloseModalPedidoEspecial = () => {
    return {
        type: SET_CLOSE_MODAL_PEDIDO_ESPECIAL,
    }
}

export const setActivePanel = (payload) => {
    return {
        type: SET_ACTIVE_PANEL,
        payload
    }
}

export const setType = (payload) => {
    return {
        type: SET_TYPE,
        payload
    }
}

export const setDeliveryType = (payload) => {
    return {
        type: SET_DELIVERY_TYPE,
        payload
    }
}

export const setOrigin = (payload) => {
    return {
        type: SET_ORIGIN,
        payload
    }
}

export const setUseCar = (payload) => {
    return {
        type: SET_USE_CAR,
        payload
    }
}

export const setStoreIsOpen = (payload) => {
    return {
        type: SET_STORE_IS_OPEN,
        payload
    }
}

export const setDateType = (payload) => {
    return {
        type: SET_DATE_TYPE,
        payload
    }
}

export const setDeliveryDatetime = (payload) => {
    return {
        type: SET_DELIVERY_DATETIME,
        payload
    }
}

export const setDestinyType = (payload) => {
    return {
        type: SET_DESTINY_TYPE,
        payload
    }
}

export const setDestinyStore = (payload) => {
    return {
        type: SET_DESTINY_STORE,
        payload
    }
}

export const setDestinyStoreAdmin = (payload) => {
    return {
        type: SET_DESTINY_STORE_ADMIN,
        payload
    }
}

export const setDestinyClient = (payload) => {
    return {
        type: SET_DESTINY_CLIENT,
        payload
    }
}

export const setDestinyNewClient = () => {
    return {
        type: SET_DESTINY_NEW_CLIENT,
    }
}

export const setDestinyClientName = (payload) => {
    return {
        type: SET_DESTINY_CLIENT_NAME,
        payload
    }
}

export const setDestinyClientLastname = (payload) => {
    return {
        type: SET_DESTINY_CLIENT_LASTNAME,
        payload
    }
}

export const setDestinyClientEmail = (payload) => {
    return {
        type: SET_DESTINY_CLIENT_EMAIL,
        payload
    }
}

export const setDestinyClientPhone = (payload) => {
    return {
        type: SET_DESTINY_CLIENT_PHONE,
        payload
    }
}

export const setDestinyClientDirection = (payload) => {
    return {
        type: SET_DESTINY_CLIENT_DIRECTION,
        payload
    }
}

export const setDestinyClientAdditionalInformation = (payload) => {
    return {
        type: SET_DESTINY_CLIENT_ADDITIONAL_INFORMATION,
        payload
    }
}

export const setDestinyConfirm = () => {
    return {
        type: SET_DESTINY_CONFIRM,
    }
}

export const setContent = (payload) => {
    return {
        type: SET_CONTENT,
        payload
    }
}

export const setComment = (payload) => {
    return {
        type: SET_COMMENT,
        payload
    }
}

export const setTempProduct = (payload) => {
    return {
        type: SET_TEMP_PRODUCT,
        payload
    }
}

export const setCleanTempProduct = () => {
    return {
        type: SET_CLEAN_TEMP_PRODUCT,
    }
}

export const setRadioOption = (payload) => {
    return {
        type: SET_RADIO_OPTION,
        payload
    }
}

export const setCheckboxOption = (payload) => {
    return {
        type: SET_CHECKBOX_OPTION,
        payload
    }
}

export const setCounterOption = (payload) => {
    return {
        type: SET_COUNTER_OPTION,
        payload
    }
}

export const setTempProductQuantity = (payload) => {
    return {
        type: SET_TEMP_PRODUCT_QUANTITY,
        payload
    }
}

export const setTempToCart = () => {
    return {
        type: SET_TEMP_TO_CART,
    }
}

export const setChangeQuantityProduct = (payload) => {
    return {
        type: SET_CHANGE_QUANTITY_PRODUCT,
        payload
    }
}

export const setRemoveProduct = (payload) => {
    return {
        type: SET_REMOVE_PRODUCT,
        payload
    }
}

export const setTempComment = (payload) => {
    return {
        type: SET_TEMP_COMMENT,
        payload
    }
}

export const setTip = (payload) => {
    return {
        type: SET_TIP,
        payload
    }
}





export const addProduct = (data) => {
    return {
        type: ADD_PRODUCT,
        payload: { data },
    }
}

export const addOneMore = (data) => {
    return {
        type: ADD_ONE_MORE,
        payload: { data },
    }
}

export const subtractOne = (data) => {
    return {
        type: SUBTRACT_ONE,
        payload: { data },
    }
}

export const removeProduct = (data) => {
    return {
        type: REMOVE_PRODUCT,
        payload: { data },
    }
}

export const cleanData = () => {
    return {
        type: CLEAN_DATA,
    }
}
