import React, { useState, useEffect } from 'react'
import { Badge, Icon, Popover, Modal, Input, notification, Button } from 'antd'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import { LAMBDA_POS } from "../../config"
import GlobalNotification from '../GlobalNotification/GlobalNotification'

const Flag = ({ color = '', title = '', idPedido = 0 }) => {
    const [showPencil, setShowPencil] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [flags, setFlags] = useState([])
    const [flagCommercial, setFlagCommercial] = useState(false)
    const [flagText, setFlagText] = useState('')
    const [flagIdSelected, setFlagIdSelected] = useState(0)
    const [LoadingChangeFlag, setLoadingChangeFlag] = useState(false)
    const IdComercio = useSelector(state => state?.Auth?.payload?.usuarioPerfil?.sucursal?.comercio?.IdComercio)
    const IdSucursal = useSelector(state => state?.Auth?.payload?.usuarioPerfil?.sucursal?.IdSucursal)

    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState('')


    // const opacity = '4D'    //  30%
    const opacity = '33'    //  20%

    useEffect(() => {
        getDefaultFlags()
    }, [])

    const getDefaultFlags = async () => {
        const { data } = await Axios.get(`${LAMBDA_POS}/getDefaultEtiquetas/${IdComercio}`)
        if (data?.etiquetasComercio.length > 0) {
            setFlags(data?.etiquetasComercio)
            setFlagCommercial(true)
            setLoadingChangeFlag(false)
        } else {
            setFlags(data?.etiquetasDefault)
            setFlagCommercial(false)
            setLoadingChangeFlag(false)
        }
    }

    const cleanNotification = () => {
        setNotificationMessage('')
        setNotificationType('')
    }

    const sendNotification = (type, msg) => {
        setNotificationMessage(msg)
        setNotificationType(type)

        // const key = 'close'
        // const btn = (
        //     <>
        //         <Button type="default" size="small" onClick={() => notification.close(key)}>
        //             OK
        //         </Button>
        //     </>
        // )
        // notification[type]({
        //     message: msg,
        //     btn,
        //     key,
        //     onClose: key,
        // })
    }

    const changeColor = async (e) => {
        if (idPedido && (flagCommercial && e?.IdEtiquetaComercio || !flagCommercial && e?.IdEtiqueta)) {
            const { data } = await Axios.post(`${LAMBDA_POS}/assignFlagOrder/${IdComercio}`, {
                idPedido,
                idSucursal: IdSucursal,
                idEtiqueta: (flagCommercial) ? e?.IdEtiquetaComercio : e?.IdEtiqueta
            })
            sendNotification((data?.status) ? 'success' : 'warning', data?.message)
        }
        setShowOptions(false)
    }

    const iniciateEdit = async (idEtiqueta) => {
        setFlagIdSelected(idEtiqueta)
        setShowEdit(true)
    }

    const saveNameFlag = async () => {
        setLoadingChangeFlag(true)
        const { data } = await Axios.post(`${LAMBDA_POS}/renameCommercialFlag/${IdComercio}`, {
            nombre: flagText,
            idEtiqueta: flagIdSelected,
        })
        sendNotification((data?.status) ? 'success' : 'warning', data?.message)
        setFlagText('')
        getDefaultFlags()
        setShowEdit(false)
    }

    const closeModal = async () => {
        setFlagText('')
        setShowEdit(false)
    }

    return (
        <>
            <GlobalNotification
                message={notificationMessage}
                color={(notificationType === 'success') ? 'success' : 'info'}
                size='small'
                actionClose={cleanNotification}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alingItems: 'center',
                    marginBottom: 5
                }}
            >
                <Popover
                    placement="right"
                    content={
                        <>
                            {
                                flags.map((e, i) => {
                                    return (
                                        <div
                                            key={i}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alingItems: 'center',
                                                marginBottom: 5,
                                            }}>
                                            <Badge
                                                style={{
                                                    backgroundColor: (flagCommercial) ? `${e?.etiqueta?.Color}${opacity}` : `${e?.Color}${opacity}`,
                                                    borderRadius: 5,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    cursor: 'pointer',
                                                    width: 200,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden'
                                                }}
                                                onClick={() => changeColor(e)}
                                                status={((flagCommercial && e?.etiqueta?.ColorComponente === 'default') || (!flagCommercial && e?.ColorComponente === 'default')) ? 'default' : ''}
                                                color={((flagCommercial && e?.etiqueta?.ColorComponente === 'default') || (!flagCommercial && e?.ColorComponente === 'default')) ? '' : (flagCommercial) ? e?.etiqueta?.ColorComponente : e?.ColorComponente}
                                                text={
                                                    <>
                                                        {e?.Nombre}
                                                    </>
                                                }
                                            />
                                            {
                                                ((flagCommercial && e?.etiqueta?.ColorComponente !== 'default') || (!flagCommercial && e?.ColorComponente !== 'default')) && (
                                                    <Icon
                                                        type="edit"
                                                        style={{ marginLeft: 5 }}
                                                        onClick={() => iniciateEdit((flagCommercial) ? e?.IdEtiquetaComercio : e?.IdEtiqueta)}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }
                        </>
                    }
                    title="Etiquetas"
                    trigger="click"
                    visible={showOptions}
                    onVisibleChange={() => setShowOptions(!showOptions)}
                >
                    <Badge
                        style={{
                            backgroundColor: (flagCommercial) ? `${flags.find((e) => e?.etiqueta?.ColorComponente === color)?.etiqueta?.Color}${opacity}` : `${flags.find((e) => e?.ColorComponente === color)?.Color}${opacity}`,
                            borderRadius: 5,
                            paddingLeft: 5,
                            paddingRight: 5,
                            cursor: 'pointer',
                            width: 200,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}
                        status={(color === 'default') ? 'default' : ''}
                        color={(color === 'default') ? '' : (flagCommercial) ? flags.find((e) => e?.etiqueta?.ColorComponente === color)?.etiqueta?.ColorComponente : flags.find((e) => e?.ColorComponente === color)?.ColorComponente}
                        text={
                            <>
                                {
                                    (color === 'default') ?
                                        `Sin etiqueta`
                                        :
                                        title
                                }
                                {
                                    (showPencil) && (
                                        <Icon type="edit" style={{ marginLeft: 5 }} />
                                    )
                                }
                            </>
                        }
                        onClick={() => setShowOptions(true)}
                        onMouseEnter={() => setShowPencil(true)}
                        onMouseLeave={() => setShowPencil(false)}
                    />
                </Popover>
            </div>
            <Modal
                title="Editar etiqueta"
                visible={showEdit}
                onOk={() => saveNameFlag()}
                onCancel={() => closeModal()}
                cancelText="Cancelar"
                okText="Guardar"
                confirmLoading={LoadingChangeFlag}
            >
                <Input
                    placeholder="Ingresa la etiqueta"
                    onChange={(e) => setFlagText(e?.target?.value.substring(0, 30))}
                    value={flagText}
                />
            </Modal>
        </>
    )
}

export default Flag