import { ArrowForwardIos, AutoFixHigh, DeleteForever, EditCalendar, MoreTime, ProductionQuantityLimits, ReceiptLong } from '@mui/icons-material'
import { Box, DialogContent, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'


const ORDER_MENU = 0
const ORDER_PREP_TIME = 1
const ORDER_EDIT_SCHEDULE = 2
const ORDER_REJECT = 3
export const MenuOrder = ({
    setSelectedMenu,
    order
}) => {

    const [modMinutes, setModeMinutes] = useState(null)
    const [modEntrega, setModEntrega] = useState(null)

    useEffect(() => {
        if (order && order?.cambioEntregas) {
            const minData = order?.cambioEntregas?.find(ele => ele.Tipo === 'MINUTOS')
            const entregaData = order?.cambioEntregas?.find(ele => ele.Tipo === 'ENTREGA')

            if (minData) {
                setModeMinutes(minData)
            }

            if (entregaData) {
                setModEntrega(entregaData)
            }
        }
    }, [order])

    return (
        <DialogContent>
            <List>
                <ListItemButton disabled={[10, 11, 12, 9].includes(order.IdEstado) || modMinutes || order.IdTipoPedido === 5} onClick={() => setSelectedMenu(ORDER_PREP_TIME)}>
                    <ListItemIcon>
                        <MoreTime />
                    </ListItemIcon>
                    <ListItemText
                        primary={'Agregar Minutos'}
                        secondary={modMinutes ? <Box>
                            <Typography>{`Modificado por ${modMinutes?.usuario?.Nombre} ${modMinutes?.usuario?.Apellido}, solo puede ser modificado 1 vez. `}</Typography>
                        </Box> : 'Agrega minutos a la preparación y entrega al cliente.'}
                    />
                    <ListItemIcon>
                        <ArrowForwardIos />
                    </ListItemIcon>
                </ListItemButton >
                <Divider component="li" />
                <ListItemButton disabled={[10, 11, 12, 9].includes(order.IdEstado) || modEntrega || order.IdTipoPedido === 5} onClick={() => setSelectedMenu(ORDER_EDIT_SCHEDULE)}>
                    <ListItemIcon>
                        <EditCalendar />
                    </ListItemIcon>
                    <ListItemText
                        primary={'Editar Fecha de Entrega'}
                        secondary={modEntrega ? <Box>
                            <Typography>{`Modificado por ${modEntrega?.usuario?.Nombre} ${modEntrega?.usuario?.Apellido}, solo puede ser modificado 1 vez. `}</Typography>
                        </Box> : 'Modifica la fecha y hora de entrega del pedido al cliente.'}
                    />
                    <ListItemIcon>
                        <ArrowForwardIos />
                    </ListItemIcon>
                </ListItemButton>
                <Divider component="li" />
                <ListItemButton disabled={true} onClick={() => setSelectedMenu(ORDER_EDIT_SCHEDULE)}>
                    <ListItemIcon>
                        <AutoFixHigh />
                    </ListItemIcon>
                    <ListItemText
                        primary={'Editar Pedido'}
                        secondary={'Próximamente.'}
                    />
                    <ListItemIcon>
                        <ArrowForwardIos />
                    </ListItemIcon>
                </ListItemButton>
                <Divider component="li" />
                <ListItemButton disabled={true} onClick={() => setSelectedMenu(ORDER_EDIT_SCHEDULE)}>
                    <ListItemIcon>
                        <ReceiptLong />
                    </ListItemIcon>
                    <ListItemText
                        primary={'Imprimir ticket'}
                        secondary={'Próximamente.'}
                    />
                    <ListItemIcon>
                        <ArrowForwardIos />
                    </ListItemIcon>
                </ListItemButton>
                <Divider component="li" />
                <ListItemButton disabled={true} onClick={() => setSelectedMenu(ORDER_EDIT_SCHEDULE)}>
                    <ListItemIcon>
                        <ProductionQuantityLimits />
                    </ListItemIcon>
                    <ListItemText
                        primary={'Fuera de Stock'}
                        secondary={'Próximamente.'}
                    />
                    <ListItemIcon>
                        <ArrowForwardIos />
                    </ListItemIcon>
                </ListItemButton>
                <Divider component="li" />
                <ListItemButton disabled={[10, 11, 12, 9].includes(order.IdEstado) || (order.IdTipoPedido === 5 && order.extOrder.IdTipoIntegrador !== 5)} onClick={() => setSelectedMenu(ORDER_REJECT)}>
                    <ListItemIcon>
                        <DeleteForever sx={{
                            color: '#ef5350'
                        }} />
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            color: '#ef5350'
                        }}
                        primary={'Rechazar Pedidos'}
                        secondary={'Anula este pedido y reembolsa el 100% al cliente.'}
                    />
                    <ListItemIcon>
                        <ArrowForwardIos sx={{
                            color: '#ef5350'
                        }} />
                    </ListItemIcon>
                </ListItemButton>
            </List>
        </DialogContent>
    )
}