import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import { LAMBDA_POS } from '../../../config';
import Axios from 'axios';
import { Button } from 'antd';

export default function ({ }) {
    const Auth = useSelector(state => state.Auth)
    const IdSucursal = Auth.payload.usuarioPerfil.sucursal.IdSucursal
    const [downloading, setdownloading] = useState(false)

    const handleOnClick = useCallback(
        () => {
            setdownloading(true)
            Axios.get(`${LAMBDA_POS}/getDownloadLinkPOS/${IdSucursal}`)
                .then(({ data }) => {
                    window.location.replace(data.downloadLink)
                    setdownloading(false)
                }).catch((err) => {
                });
        },
        [IdSucursal],
    )

    return <Button type="primary" icon="download" size={'large'} onClick={handleOnClick} loading={downloading}>
        Descargar
    </Button>
}