import React, { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CircularProgress,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { AccessTime, Block, CalendarToday, ExpandMore } from "@mui/icons-material"
import { COLOR_BLUE } from "../../../constants/color"
import { DATE_TYPE_EXPRESS, DATE_TYPE_PROGRAMADO, DELIVERY_TYPE_RETIRO, PANEL_TIPO_FECHA_ENTREGA } from "../../../redux/pedidoEspecial/types"
import { setDateType, setStoreIsOpen } from "../../../redux/pedidoEspecial/actions"
import { LAMBDA_SHOP } from "../../../config"
import Axios from "axios"
import moment from "moment"
import ModalFechaProgramada from "./Modal/ModalFechaProgramada"

const TipoFechaEntrega = ({ changePanel }) => {
    const dispatch = useDispatch()
    const { activePanel, dateType, deliveryType, origin, storeIsOpen, deliveryDate, deliveryTime } = useSelector(state => state?.PedidoEspecial)
    const [loadingDates, setLoadingDates] = useState(false)
    const [expressText, setExpressText] = useState('')
    const [scheduleText, setScheduleText] = useState('')
    const [listAvailablesHours, setListAvailablesHours] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))

    useEffect(() => {
        if (deliveryType && origin && origin?.IdSucursal) {
            getSucursalHorarios()
        }
    }, [deliveryType, origin])

    const getSucursalHorarios = async () => {
        setLoadingDates(true)
        try {
            const res = await Axios.get(`${LAMBDA_SHOP}/calculateFechaEstimadaEntrega/${origin?.IdSucursal}`, {
                params: {
                    TipoEntrega: (deliveryType === DELIVERY_TYPE_RETIRO) ? 2 : 1, /* 1. DELIVERY, 2. RETIRO */
                    TipoFechaEntrega: (dateType === DATE_TYPE_PROGRAMADO) ? 3 : 1, /* 1. ANTES POSIBLE, 3. PROGRAMADO */
                    FechaEntrega: 1, /* 1 hoy, 2 mañana, 3 pasado mañana ... */
                    HoraEntrega: 1,
                },
            })
            setExpressText(formatEstimatedDelivery(res?.data?.fechaEstimadaEntrega?.FechaEstimadaEntregaMax))
        } catch (err) {
            console.error(err)
        }

        try {
            const res = await Axios.get(`${LAMBDA_SHOP}/getSucursalHorarios/${origin?.IdSucursal}`)

            if (!res?.data?.isOpen) {
                setExpressText(`Sucursal cerrada`)
            }

            await dispatch(setStoreIsOpen({ storeIsOpen: res?.data?.isOpen }))
            const availables = []
            res.data.availableScheduledHours.map((e) => {
                if (e?.hours.length > 0) {
                    availables.push(e)
                }
            })
            setListAvailablesHours(availables)

            if (availables && availables.length > 0) {
                setScheduleText(`Desde ${formatEstimatedDelivery(`${availables[0].date} ${formatTime(availables[0].hours[0].hour)}`, true)}`)
            } else {
                setScheduleText(`Sin horarios disponibles`)
            }
        } catch (err) {
            console.error(err)
        }
        setLoadingDates(false)
    }

    const formatEstimatedDelivery = (date, program = false) => {
        const today = moment().format("DD/MM/YYYY")
        const newDate = moment(date).format("DD/MM/YYYY")
        const nameDate = `${moment(date).format("dddd")} ${moment(date).format("DD")}`
        const time = moment(date).format("HH:mm")
        let text = ''

        if (today === newDate) {
            text = `${(program) ? `hoy` : `Hoy`} a las ${time}`
        } else {
            text = `${(program) ? `el` : `El`} ${nameDate} a las ${time}`
        }
        return text
    }

    const formatTime = (time, short = false) => {
        let formatTime = time.toString()
        if (formatTime.length < 4) {
            formatTime = `0${formatTime}`
        }
        formatTime = formatTime.match(/\d{2}/g).join(":")
        if (short) {
            formatTime = formatTime.substring(0, 5)
            return `${formatTime}`
        } else {
            return `${formatTime}:00`
        }
    }

    const changeDateType = async (dateType) => {
        await dispatch(setDateType({ dateType }))
        if (dateType === DATE_TYPE_PROGRAMADO) {
            setOpenModal(true)
        }
    }

    return (
        <>
            <Accordion
                expanded={(activePanel === PANEL_TIPO_FECHA_ENTREGA) ? true : false}
                onChange={() => changePanel(PANEL_TIPO_FECHA_ENTREGA)}
                sx={{ my: 1 }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack
                        direction={(Mediaquery) ? 'column' : 'row'}
                        justifyContent='space-between'
                        sx={{
                            width: {
                                lg: '50%',
                                md: '80%',
                                sm: '100%',
                            }
                        }}
                    >
                        <Typography variant="h6">
                            ¿Para cuando lo necesitas?
                        </Typography>
                        <Typography sx={{ opacity: 0.7 }}>
                            {
                                (dateType) && (
                                    <Typography sx={{ opacity: 0.7 }}>
                                        {
                                            (dateType === DATE_TYPE_EXPRESS) ?
                                                `Lo antes posible (${expressText})`
                                                :
                                                (deliveryDate && deliveryTime) ?
                                                    `Programado (${formatEstimatedDelivery(`${deliveryDate} ${formatTime(deliveryTime)}`)})`
                                                    :
                                                    `Programado`
                                        }
                                    </Typography>
                                )
                            }
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            px: {
                                md: 5,
                                xs: 0,
                            }
                        }}
                    >
                        <Stack direction={(Mediaquery) ? 'column' : 'row'}>
                            <Card
                                onClick={() => (storeIsOpen) ? changeDateType(DATE_TYPE_EXPRESS) : console.log('tienda cerrada')}
                                elevation={2}
                                sx={{
                                    mr: {
                                        md: 1,
                                        xs: 0,
                                    },
                                    mt: {
                                        md: 0,
                                        xs: 1,
                                    },
                                    width: {
                                        md: 350,
                                        xs: '100%'
                                    },
                                    p: 2,
                                    cursor: 'pointer',
                                    border: (dateType === DATE_TYPE_EXPRESS) ? 1 : 0,
                                    borderColor: (dateType === DATE_TYPE_EXPRESS) ? COLOR_BLUE : 'none',
                                    opacity: (storeIsOpen) ? 1 : 0.5
                                }}
                            >
                                <Stack direction='row' alignItems='center'>
                                    <AccessTime sx={{ mr: 1 }} />
                                    <Typography variant="h6">
                                        Lo antes posible
                                    </Typography>
                                </Stack>
                                {
                                    (loadingDates) ?
                                        <CircularProgress size={16} />
                                        :
                                        <Stack direction='row' alignItems='center'>
                                            {
                                                (!storeIsOpen) && (
                                                    <Block sx={{ mr: 1 }} />
                                                )
                                            }
                                            <Typography variant="body1">
                                                {expressText}
                                            </Typography>
                                        </Stack>
                                }
                            </Card>
                            <Card
                                onClick={() => (listAvailablesHours.length > 0) ? changeDateType(DATE_TYPE_PROGRAMADO) : console.log('sin horarios disponibles')}
                                elevation={2}
                                sx={{
                                    ml: {
                                        md: 1,
                                        xs: 0,
                                    },
                                    mt: {
                                        md: 0,
                                        xs: 1,
                                    },
                                    width: {
                                        md: 350,
                                        xs: '100%'
                                    },
                                    p: 2,
                                    cursor: 'pointer',
                                    border: (dateType === DATE_TYPE_PROGRAMADO) ? 1 : 0,
                                    borderColor: (dateType === DATE_TYPE_PROGRAMADO) ? COLOR_BLUE : 'none',
                                    opacity: (listAvailablesHours.length > 0) ? 1 : 0.5
                                }}
                            >
                                <Stack direction='row' alignItems='center'>
                                    <CalendarToday sx={{ mr: 1 }} />
                                    <Typography variant="h6">
                                        Programado
                                    </Typography>
                                </Stack>
                                {
                                    (loadingDates) ?
                                        <CircularProgress size={16} />
                                        :
                                        <Stack direction='row' alignItems='center'>
                                            {
                                                (listAvailablesHours.length === 0) && (
                                                    <Block sx={{ mr: 1 }} />
                                                )
                                            }
                                            <Typography variant="body1">
                                                {scheduleText}
                                            </Typography>
                                        </Stack>
                                }
                            </Card>
                        </Stack>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <ModalFechaProgramada
                open={openModal}
                setOpen={setOpenModal}
                listAvailablesHours={listAvailablesHours}
                formatEstimatedDelivery={formatEstimatedDelivery}
                formatTime={formatTime}
            />
        </>
    )
}

export default memo(TipoFechaEntrega)