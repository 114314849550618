import React, { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { DirectionsCar, ExpandMore } from "@mui/icons-material"
import { ORIGIN_TYPE_DIRECCION, ORIGIN_TYPE_SUCURSAL, PANEL_ORIGEN, TYPE_FLASH } from "../../../redux/pedidoEspecial/types"
import { setOrigin, setUseCar } from "../../../redux/pedidoEspecial/actions"
import { LAMBDA_ADMIN } from "../../../config"
import Axios from "axios"

const Origen = ({ changePanel }) => {
    const dispatch = useDispatch()
    const { activePanel, originType, origin, useCar, type } = useSelector(state => state?.PedidoEspecial)
    const { sucursal } = useSelector(state => state?.Auth?.payload?.usuarioPerfil)
    const { IdSucursal } = sucursal
    const { IdComercio } = sucursal?.comercio
    const [listSucursales, setListSucursales] = useState([])
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))
    const [loadingSucursales, setLoadingSucursales] = useState(false)

    useEffect(() => {
        getSucursales()
    }, [])

    const getSucursales = async () => {
        setLoadingSucursales(true)
        try {
            const res = await Axios.get(`${LAMBDA_ADMIN}/getAllSucursalComercio/${IdComercio}`)
            const data = res?.data?.sucursal.map((e) => {
                return {
                    ...e,
                    label: e?.Titulo
                }
            })
            setListSucursales(data)
            const found = res?.data?.sucursal.find((e) => e?.IdSucursal === IdSucursal)
            if (found) {
                await dispatch(setOrigin({
                    originType: ORIGIN_TYPE_SUCURSAL,
                    origin: { ...found },
                    initLoad: true
                }))
            }
        } catch (err) {
            console.error(err)
        }
        setLoadingSucursales(false)
    }

    const changeOrigin = async (origin) => {
        await dispatch(setOrigin({
            originType: ORIGIN_TYPE_SUCURSAL,
            origin: { ...origin },
            initLoad: false
        }))
    }

    const changeUseCar = async () => {
        await dispatch(setUseCar({ useCar: !useCar }))
    }

    return (
        <>
            <Accordion
                expanded={(activePanel === PANEL_ORIGEN) ? true : false}
                onChange={() => changePanel(PANEL_ORIGEN)}
                sx={{ my: 1 }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack
                        direction={(Mediaquery) ? 'column' : 'row'}
                        justifyContent='space-between'
                        sx={{
                            width: {
                                lg: '50%',
                                md: '80%',
                                sm: '100%',
                            }
                        }}
                    >
                        <Typography variant="h6">
                            {
                                (originType === ORIGIN_TYPE_DIRECCION) ?
                                    `Enviar desde la sucursal`
                                    :
                                    `Enviar desde`
                            }
                        </Typography>
                        <Typography sx={{ opacity: 0.7 }}>
                            {
                                (originType === ORIGIN_TYPE_SUCURSAL && origin?.Titulo) && (
                                    <Stack direction={(Mediaquery) ? 'column' : 'row'} alignItems='baseline'>
                                        <Typography sx={{ opacity: 0.7 }}>
                                            {origin?.Titulo}
                                        </Typography>
                                        <Typography variant='caption' sx={{ ml: 1, opacity: 0.7 }}>
                                            {`(${origin?.Direccion})`}
                                        </Typography>
                                    </Stack>
                                )
                            }
                        </Typography>
                    </Stack>
                </AccordionSummary >
                <AccordionDetails>
                    <Box
                        sx={{
                            px: {
                                md: 5,
                                xs: 0,
                            }
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>

                                {
                                    (loadingSucursales) ?
                                        <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
                                            <CircularProgress size={16} sx={{ mr: 1 }} />
                                            <Typography variant="body1">
                                                Cargando sucursales...
                                            </Typography>
                                        </Stack>
                                        :
                                        <>
                                            <Typography variant="body1">
                                                Selecciona una sucursal desde donde quieres enviar el pedido
                                            </Typography>
                                            <Autocomplete
                                                noOptionsText='...'
                                                size="small"
                                                onChange={(e, value) => changeOrigin(value)}
                                                disablePortal
                                                options={listSucursales}
                                                sx={{
                                                    width: 300,
                                                    mt: 2,
                                                }}
                                                renderInput={(params) => <TextField {...params} fullWidth label="Sucursal de Origen" />}
                                            />
                                        </>
                                }

                            </Grid>
                            {
                                (type === TYPE_FLASH) && (
                                    <Grid item md={6} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={useCar}
                                                        onChange={changeUseCar}
                                                    />
                                                }
                                                label={
                                                    <Stack direction='row' alignItems='center'>
                                                        <Typography variant="body1">
                                                            ¿Requieres un Auto?
                                                        </Typography>
                                                        <DirectionsCar sx={{ ml: 1, opacity: 0.7 }} />
                                                        {
                                                            (useCar) && (
                                                                <Typography variant="body1" sx={{ ml: 1, opacity: 0.7 }} >
                                                                    (costo adicional $1.000 + IVA)
                                                                </Typography>
                                                            )
                                                        }
                                                    </Stack>
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion >
        </>
    )
}

export default memo(Origen)