import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Icon, Row, Col, Table, Tag, Card, Pagination, Input } from "antd"
import Axios from "axios"
import { LAMBDA_POS } from "../../config"
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import currencyFormatter from 'currency-formatter'
import currencyFormatterOptions from '../../config/currencyFormatterOptions.json'
import { ContactCardWrapper } from "@iso/components/Orders/OrderCard.style"
import OrderHeadDetail from "../Orders/OrderDetails/Order-HeadDetail"
import TicketDrawerButton from "../Orders/Actions/TicketDrawer.Button"
import OrderPayment from "../Orders/OrderDetails/Order-Payment"
import OrderProducts from "../Orders/OrderDetails/Order-Products"
import OrderTotals from "../Orders/OrderDetails/Order-Totals"
import OrderClient from "../Orders/OrderDetails/Order-Client"
import OrderInfo from "../Orders/OrderDetails/Order-Info"
import { PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG } from "../../redux/ordenes/types"
import { isMobile } from "react-device-detect"
import moment from "moment"
import { Box, Button, Chip, CircularProgress } from "@mui/material"
import { COLOR_BG_AGIL, COLOR_BG_JUSTO, COLOR_BG_PEDIDOSYA, COLOR_BG_RAPPI, COLOR_BG_UBEREATS, COLOR_WHITE } from "../../constants/color"
import { Download } from "@mui/icons-material"
import useFilterSucursal from "../../hooks/useFilterSucursal/useFilterSucursal"
// import { DateRangePicker } from "react-dates"


export default function Orders() {

    const limit = 100
    const { IdSucursal, comercio } = useSelector(state => state.Auth.payload.usuarioPerfil.sucursal)
    const { Moneda } = comercio.nacionalidad
    const [orders, setOrders] = useState([])
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [dataOrder, setDataOrder] = useState(null)
    const [tiempoCocinaDiff, settiempoCocinaDiff] = useState(0)
    const [keyword, setKeyword] = useState('')
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days'))
    const [endDate, setEndDate] = useState(moment())
    const [loadingDownload, setLoadingDownload] = useState(false)
    const { FilterSucursalComponent, selectedSucursalIds, sucursalFiltered } = useFilterSucursal()

    const hrDetalle = <hr style={{ borderWidth: 1, borderColor: "rgba(233, 233, 233, 0.18)" }} />

    useEffect(() => {
        if (selectedSucursalIds.length > 0) {

            getOrders()
        }
    }, [currentPage, selectedSucursalIds])

    useEffect(() => {
        if (selectedOrder !== null && selectedOrder > 0) {
            setLoading(true)
            getDetailByOrder()
        }
    }, [selectedOrder])

    useEffect(() => {
        console.log("[📝] Orden Seleccionada:", dataOrder)
    }, [dataOrder])

    const backToList = () => {
        setSelectedOrder(null)
        setDataOrder(null)
    }

    const handleOnClicktiempoCocinaDiff = (value) => {
        settiempoCocinaDiff(prevtiempoCocinaDiff => Math.max(0, prevtiempoCocinaDiff + value))
    }

    const getOrders = async (searchByText = false) => {
        setLoading(true)
        let list = []
        Axios
            .get(`${LAMBDA_POS}/getAllOrderHistory/${comercio.IdComercio}`, { params: { limit, page: currentPage, searchText: (searchByText) ? keyword : '', suc: selectedSucursalIds.join(',') } })
            .then(response => {
                setTotal(response.data.pagination.totalOrder)
                response.data.orders.map((e, i) => {
                    list.push({
                        key: i + 1
                        , codigo: { codigo: e?.IdTipoPedido === 5 ? e?.CodigoExterno : (e?.Correlativo === 0) ? `#${e?.Codigo}` : `#${e?.CodigoCompuesto}`, idPedido: e?.IdPedido, tipoEntrega: e?.TipoEntrega }
                        , sucursal: e?.sucursal
                        , comercio: e?.comercio
                        , estado: e?.EstadoPedido
                        , fecha: e?.FechaEntrega
                        , total: e?.Total
                        , plataforma: {
                            IdPlataforma: e?.IdPlataforma
                            , Plataforma: e?.Plataforma
                            , CodigoPlataforma: e?.CodigoPlataforma

                        }
                        , cliente: { nombre: e?.IdTipoPedido === 5 ? `${e?.NombreClienteExterno} ${e?.ApellidoClienteExterno}` : `${e?.NombreCliente} ${e?.ApellidoCliente}`, email: e?.IdTipoPedido === 5 ? e?.EmailClienteExterno : e?.EmailCliente, telefono: e?.IdTipoPedido === 5 ? e?.TelefonoClienteExterno : e?.TelefonoCliente }
                        , direccion: e?.IdTipoPedido === 5 ? e?.DireccionExterna : (e?.DireccionCliente === '') ? '-' : `${e?.DireccionCliente} (${e?.distanciaClienteTexto})`
                        , rider: { nombre: (e?.NombreRider === 'N/A') ? '-' : e?.NombreRider, codigo: (e?.CodigoRider === 'N/A') ? '' : e?.CodigoRider }
                    })
                })
                setOrders(list)
                setLoading(false)
            }).catch(err => {
                setOrders(list)
                setLoading(false)
            })
    }

    const getDetailByOrder = async () => {
        Axios
            .get(`${LAMBDA_POS}/getOrder/${IdSucursal}/${selectedOrder}`)
            .then(response => {
                setDataOrder(response.data.order)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
    }

    const getColor = (estado) => {
        let color = ''
        switch (estado) {
            case 'Pendiente en POS':
                color = 'orange'
                break
            case 'Inactivo':
                color = 'red'
                break
            case 'Rechazado':
                color = 'red'
                break
            case 'Aceptado':
                color = 'geekblue'
                break
            case 'Buscando Delivery':
                color = 'cyan'
                break
            case 'En Camino':
                color = 'blue'
                break
            case 'En Despacho':
                color = 'lime'
                break
            case 'Entregado':
                color = 'green'
                break
            default:
                color = 'gray'
                break
        }
        return color
    }

    const columns = [
        {
            title: 'Código',
            dataIndex: 'codigo',
            key: 'codigo',
            responsive: ["sm"],
            align: 'center',
            render: codigo => (
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    {
                        (codigo?.tipoEntrega === 'Delivery') ?
                            <img src="https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_deliveryV2.svg" width={30} />
                            :
                            <img src="https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_retiro.svg" width={30} />
                    }
                    <a onClick={() => setSelectedOrder(codigo.idPedido)}>
                        <b>{codigo.codigo}</b>
                    </a>
                </div>
            )
        },
        {
            title: 'Sucursal',
            dataIndex: 'sucursal',
            key: 'sucursal',
            responsive: ["sm"],
            align: 'center',
            render: sucursal => (
                <div style={{ textAlign: 'start' }}>
                    {sucursal}
                </div>
            )
        },
        {
            title: 'Comercio',
            dataIndex: 'comercio',
            key: 'comercio',
            responsive: ["sm"],
            align: 'center',
            render: comercio => (
                <div style={{ textAlign: 'start' }}>
                    {comercio}
                </div>
            )
        },
        {
            title: 'Estado',
            key: 'estado',
            dataIndex: 'estado',
            responsive: ["sm"],
            align: 'center',
            render: estado => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tag color={getColor(estado)}>
                        {estado?.toUpperCase()}
                    </Tag>
                </div>
            ),
        },
        {
            title: 'Fecha Entrega',
            dataIndex: 'fecha',
            key: 'fecha',
            responsive: ["sm"],
            align: 'center',
            render: fecha => (
                <b>{moment(fecha).format('DD/MM/YYYY')}</b>
            )
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            responsive: ["sm"],
            align: 'total',
            render: Total => (
                <b>{currencyFormatter.format(Total, currencyFormatterOptions[Moneda])}</b>
            )
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            key: 'cliente',
            responsive: ["sm"],
            align: 'center',
            render: cliente => (
                <div style={{ textAlign: 'start' }}>
                    <b>{cliente?.nombre}</b>
                    <br />
                    {cliente?.email}
                    <br />
                    {cliente?.telefono}
                </div>
            )
        },
        {
            title: 'Dirección Cliente',
            dataIndex: 'direccion',
            key: 'direccion',
            responsive: ["sm"],
            align: 'center',
            render: direccion => (
                <div style={{ textAlign: 'start' }}>
                    {direccion}
                </div>
            )
        },
        // {
        //     title: 'Rider',
        //     dataIndex: 'rider',
        //     key: 'rider',
        //     responsive: ["sm"],
        //     align: 'center',
        //     render: rider => (
        //         <div style={{ textAlign: 'start' }}>
        //             {rider?.nombre}
        //             <br />
        //             <b>{(rider?.codigo === '') ? '' : `[${rider.codigo}]`}</b>
        //         </div>
        //     )
        // },
        {
            title: 'Plataforma',
            dataIndex: 'plataforma',
            key: 'plataforma',
            responsive: ["sm"],
            align: 'center',
            render: plataforma => (
                <Chip
                    label={plataforma?.Plataforma ? plataforma?.Plataforma : 'AGIL'}
                    size="small"
                    sx={{
                        ...getColorPlataform(plataforma?.CodigoPlataforma)
                    }}
                />
            )
        },

    ]

    const getColorPlataform = (plataformCode) => {
        switch (plataformCode) {
            case 'UBEREATS': {
                return {
                    background: COLOR_BG_UBEREATS,
                    color: COLOR_WHITE
                }
            }
            case 'PEYA': {
                return {
                    background: COLOR_BG_PEDIDOSYA,
                    color: COLOR_WHITE
                }
            }
            case 'RAPPI': {
                return {
                    background: COLOR_BG_RAPPI,
                    color: COLOR_WHITE
                }
            }
            case 'JUSTO': {
                return {
                    background: COLOR_BG_JUSTO,
                    color: COLOR_WHITE
                }
            }
            default: {
                return {
                    background: COLOR_BG_AGIL,
                    color: COLOR_WHITE
                }
            }
        }
    }

    const changePage = async (e) => {
        setLoading(true)
        setCurrentPage(e)
    }

    const CustomPagination = () => {
        return (
            <div style={{ margin: 10, textAlign: 'center' }}>
                <Pagination onChange={changePage} defaultCurrent={currentPage} total={total} pageSize={limit} />
            </div>
        )
    }

    const search = (e) => {
        if (e !== "") {
            setLoading(true)
            getOrders(true)
        }
    }

    const generateExcelFile = async () => {
        setLoadingDownload(true)
        try {
            const { data } = await Axios.get(`${LAMBDA_POS}/getAllOrderHistory/${comercio.IdComercio}`, { params: { limit, page: currentPage, searchText: keyword ? keyword : '', downloads: 1, dateInit: `${startDate.format('YYYY-MM-DD')} 00:00:00`, dateEnd: `${endDate.format('YYYY-MM-DD')} 23:59:59`, suc: selectedSucursalIds.join(',') } })


            const worksheet = [
                [
                    'Local',
                    'Comercio',
                    'ID pedido',
                    'Plataforma',
                    'Fecha de creación',
                    'Fecha de entrega',
                    'Estado',
                    'Cliente',
                    'Correo',
                    'Teléfono',
                    'Subtotal',
                    'Descuento',
                    'Subtotal con descuento',
                    'Costo de envío',
                    'Total con envío',
                    'Tipo de pedido'
                ],

                // Recorre los datos y crea filas
                ...data.orders.map(item => [item.sucursal,
                item?.IdTipoPedido === 5 ? item?.CodigoExterno : (item?.Correlativo === 0) ? `#${item?.Codigo}` : `#${item?.CodigoCompuesto}`,
                item.Plataforma ? item.Plataforma : 'AGIL',
                    '',
                item.FechaEntrega,
                item.EstadoPedido,
                `${item.NombreCliente || item.NombreClienteExterno} ${item.ApellidoCliente || item.ApellidoClienteExterno}`,
                item.EmailCliente ? item.EmailCliente : item.EmailClienteExterno,
                item.TelefonoCliente ? item.TelefonoCliente : item.TelefonoClienteExterno,
                    '',
                    '',
                    '',
                    '',
                    '',
                item.TipoEntrega
                ]),
            ];
            const row = data.orders.map(item => {
                return {
                    local: item.sucursal,
                    comercio: item.comercio,
                    IdOreder: item?.IdTipoPedido === 5 ? `${item?.CodigoExterno}` : `${item?.CodigoCompuesto}`,
                    plataforma: item.Plataforma ? item.Plataforma : 'AGIL',
                    dateOrder: moment(item.FechaCreacion).format('DD-MM-YYYY HH:mm'),
                    dateDelivery: moment(item.FechaEntrega).format('DD-MM-YYYY HH:mm'),
                    estado: item.EstadoPedido,
                    cliente: `${item.NombreCliente || item.NombreClienteExterno || ''} ${item.ApellidoCliente || item.ApellidoClienteExterno || ''}`,
                    email: item.EmailCliente ? item.EmailCliente : item.EmailClienteExterno,
                    telefono: item.TelefonoCliente ? item.TelefonoCliente : item.TelefonoClienteExterno,
                    Subtotal: Math.round(item.SubTotal),
                    Descuento: Math.round(item.Descuentos),
                    subDesc: Math.round(item.SubTotal - item.Descuentos),
                    envio: Math.round(item.GastosEnvio),
                    total: Math.round(item.Total),
                    tipoEntrega: item.TipoEntrega
                }
            })
            const csvRows = [];

            // Agregar las cabeceras
            const headers = [
                'Local',
                'Comercio',
                'ID pedido',
                'Plataforma',
                'Fecha de creacion',
                'Fecha de entrega',
                'Estado',
                'Cliente',
                'Correo',
                'Telefono',
                'Subtotal',
                'Descuento',
                'Subtotal con descuento',
                'Costo de envio',
                'Total con envio',
                'Tipo de pedido'
            ].join(';')
            csvRows.push(headers)
            // Agregar las filas
            row.forEach(item => {
                const values = Object.values(item);
                csvRows.push(values.join(';'));
            });
            const csvContent = csvRows.join('\n');
            const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);

            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${moment().format('YYYYMMDD')}_pedidos.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {

        } finally {
            setLoadingDownload(false)
        }
    };

    return (
        <Card
            style={{
                marginTop: 10
                , marginBottom: 10
                , marginLeft: (dataOrder !== null && !isMobile) ? 200 : 10
                , marginRight: (dataOrder !== null && !isMobile) ? 200 : 10
                , overflowX: 'auto'
            }}
        >
            {(dataOrder === null) &&
                <>
                    <FilterSucursalComponent variant='outlined' />
                    <Box
                        sx={{
                            width: '100',
                            display: 'flex',
                            justifyContent: 'end'
                        }}
                    >
                        <Button

                            onClick={generateExcelFile}
                            startIcon={<Download />}
                            disabled={loadingDownload}
                            endIcon={loadingDownload && <CircularProgress size={14} />}
                        >
                            Exportar
                        </Button>
                    </Box>
                </>
            }
            {
                (dataOrder === null) ?
                    <>
                        <Input.Search
                            placeholder="Busca por número de pedido, datos de clientes o datos del rider"
                            size="large"
                            onSearch={search}
                            enterButton
                            loading={loading}
                            onChange={(e) => setKeyword(e.target.value)}
                            value={keyword}
                            style={{ marginBottom: 10 }}
                            suffix={
                                <Icon
                                    onClick={() => (setKeyword(''), getOrders(false))}
                                    hidden={(keyword !== "") ? false : true}
                                    type="close"
                                    style={{
                                        backgroundColor: '#f1f3f6'
                                        , borderRadius: 100
                                        , padding: 5
                                        , cursor: 'pointer'
                                    }}
                                />
                            }
                        />
                        {/* <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                        /> */}
                        <CustomPagination />
                        <Table
                            loading={loading}
                            indentSize='5'
                            pagination={false}
                            size='small'
                            columns={columns}
                            dataSource={orders}
                            style={{ width: '100%' }}
                            mobileBreakPoint={768}
                        />
                        <CustomPagination />
                    </>
                    :
                    <>
                        <div style={{ textAlign: 'end' }}>
                            <h3>
                                <Icon
                                    type="close-circle"
                                    theme="outlined"
                                    style={{ fontSize: 20, cursor: 'pointer' }}
                                    onClick={() => backToList()}
                                />
                            </h3>
                        </div>
                        <ContactCardWrapper className="isoContactCard">
                            <div className="isoContactInfoWrapper">
                                <OrderHeadDetail order={dataOrder}>
                                    <Row>
                                        <Col span={12}>
                                            {
                                                dataOrder?.entrega?.TipoFechaEntrega === PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG &&
                                                <TicketDrawerButton orders={[dataOrder]} />
                                            }
                                        </Col>

                                        <Col span={8}>
                                            {dataOrder.Tiempo >= 0 && (
                                                <p style={{ fontSize: "14px", fontWeight: "400", color: "#979797", lineHeight: "1.5", margin: 0, textAlign: "left" }} >
                                                    {`El pedido estará listo en ${tiempoCocinaDiff} min`}
                                                    <span style={{ float: 'right' }}>
                                                        <Icon style={{ margin: '0 2px' }} type="minus-circle" theme="filled" onClick={() => handleOnClicktiempoCocinaDiff(-5)} />
                                                        <Icon style={{ margin: '0 2px' }} type="plus-circle" theme="filled" onClick={() => handleOnClicktiempoCocinaDiff(5)} />
                                                    </span>
                                                </p>
                                            )}
                                        </Col>
                                        <Col span={24}>
                                            <OrderPayment order={dataOrder} />
                                        </Col>
                                    </Row>
                                </OrderHeadDetail>
                            </div>
                        </ContactCardWrapper>

                        {hrDetalle}

                        {/* DETALLE PRODUCTOS */}
                        <ContactCardWrapper className="isoContactCard">
                            <div className="isoContactInfoWrapper">
                                <OrderProducts order={dataOrder} />
                            </div>
                        </ContactCardWrapper>

                        {hrDetalle}

                        {/* TOTALES */}

                        <ContactCardWrapper className="isoContactCard">
                            <div className="isoContactInfoWrapper">
                                <Row>
                                    <Col span={12}>
                                        <OrderTotals order={dataOrder} />
                                    </Col>
                                </Row>
                            </div>
                        </ContactCardWrapper>

                        {hrDetalle}

                        {/* DATOS CLIENTE */}
                        <ContactCardWrapper className="isoContactCard">
                            <div className="isoContactInfoWrapper">
                                <OrderClient order={dataOrder} />
                            </div>
                        </ContactCardWrapper>

                        {hrDetalle}

                        {/* DATOS PEDIDO */}

                        <ContactCardWrapper className="isoContactCard">
                            <div className="isoContactInfoWrapper">
                                <Row>
                                    <Col span={14}>
                                        <OrderInfo order={dataOrder} />
                                    </Col>
                                    <Col span={10}>

                                    </Col>
                                </Row>
                            </div>
                        </ContactCardWrapper>
                    </>
            }
        </Card>
    )
}
