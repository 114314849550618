import React from 'react'
import { Badge, Card, Col, Icon, Row, Tag } from 'antd'
import currencyFormatter from 'currency-formatter';

import currencyFormatterOptions from '../../../config/currencyFormatterOptions.json'
import {
    ID_PED_PAG_ESTA_PENDIENTE_PAGO,
    PED_ENTRE_TIPO_ENTRE_DELIVERY,
    PED_ENTRE_TIPO_ENTRE_MESA,
    PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA
} from '../../../redux/ordenes/types'
import { getDistanceText } from '../OrderDetails/Order-Riders'
import { useSelector } from 'react-redux';
import PedidoEstadoTag from '../Tags/PedidoEstado.Tag';
import PedidoHoraDespachoCocinaTag from '../Tags/PedidoHoraDespachoCocina.Tag';
import Flag from '../../../components/Orders/Flags'
import SucursalTitle from '../../../components/Orders/SucursalTitle';

const styleSelected = {
    border: '3px solid yellow'
}

export default ({ order, i, onSelected, active }) => {
    const Auth = useSelector(state => state.Auth)
    return (
        <div style={{
            ...(active ? styleSelected : {})
        }} onClick={() => onSelected(i)}>
            <Card
                type="inner"
                style={{ width: '100%' }}
                title={
                    <>
                        <SucursalTitle
                            sucursal={order?.IdSucursal}
                            isCard={true}
                            platformName={order?.extOrder?.plataforma?.Nombre || ''}
                            plataformCode={order?.extOrder?.plataforma?.Codigo}
                        />
                        <Flag
                            color={(order?.etiqueta !== undefined && order?.etiqueta !== null && order?.etiqueta.length > 0) ? order?.etiqueta[0]?.etiqueta_comercio?.etiqueta?.ColorComponente : 'default'}
                            title={(order?.etiqueta !== undefined && order?.etiqueta !== null && order?.etiqueta.length > 0) ? order?.etiqueta[0]?.etiqueta_comercio?.Nombre : ''}
                            idPedido={order?.IdPedido}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alingItems: 'center' }}>
                            <p style={{ textTransform: 'capitalize' }}>
                                {order.IdTipoPedido === 5 ? `#${order?.extOrder?.CodigoExterno}${order?.extOrder?.CorrelativoExterno ? `-${order?.extOrder?.CorrelativoExterno}` : ''}` : `#${order.Codigo}-${order.Corelativo}`}
                            </p>
                            <p style={{ textTransform: 'capitalize' }}>
                                <span>
                                    {order.IdTipoPedido === 5 ?
                                        `${order?.extOrder?.NombreCliente} ${order?.extOrder?.ApellidoCliente}`
                                        :
                                        `${order.cliente ? order.cliente.Nombre : order.sucursalEntrega ? order.sucursalEntrega.Titulo : ''} ${order.cliente ? order.cliente.Apellido : ''}`}
                                </span>
                            </p>
                        </div>
                    </>
                }
            >
                <Row gutter={[4, 4]}>
                    <Col span={12} >
                        {
                            order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY ?
                                <img style={{ width: '30px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_deliveryV2.svg' /> :
                                order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA ?
                                    <img style={{ width: '30px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_retiro.svg' /> :
                                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_MESA ?
                                        <Badge
                                            count={order.mesa?.Nro}
                                            style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                                            offset={[5, 30]}
                                        >
                                            <img style={{ width: '30px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_mesa.svg' />
                                        </Badge> :
                                        null
                        }
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <span>
                            {
                                order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY && (
                                    order.entrega.rider ?
                                        <PedidoEstadoTag pedido={order}>
                                            {
                                                order.mapsTracking?.coors?.riderUbicacion?.distances?.sucursal && <>
                                                    &nbsp;
                                                    {
                                                        getDistanceText(order.mapsTracking.coors.riderUbicacion.distances.sucursal.meters)
                                                    }
                                                </>
                                            }
                                        </PedidoEstadoTag> :
                                        <PedidoEstadoTag pedido={order} />
                                )
                            }
                        </span>
                    </Col>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <PedidoHoraDespachoCocinaTag pedido={order} />
                    </Col>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Tag>
                            <img style={{ width: '9px' }} src="https://agil.s3.us-east-2.amazonaws.com/Logos/Icon_Cubiertos.svg" />
                            &nbsp;
                            {order.productos.length + (order.extOrder ? order.extOrder.Productos.length : 0) || 0} items
                        </Tag>
                        <Tag>
                            <Icon type="dollar-circle" theme="filled" /> &nbsp;
                            {currencyFormatter.format(order.TotalOC - order.Propina - order.PropinaAgil - order.CuotaServicioAgil, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                        </Tag>
                    </Col>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        {
                            order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                            <Tag>
                                <img style={{ width: '10px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_deliveryV2.svg' />
                                &nbsp;
                                {`Agil Delivery ${order.entrega.rider ? 'Asignado' : 'Por Asignar'} (`}
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-key-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                </svg>
                                {`${order.entrega.LLAVE})`}
                            </Tag>
                        }
                        {order.clienteCompra && order.clienteCompra.pagos[0] && order.clienteCompra.pagos[0].IdEstado === ID_PED_PAG_ESTA_PENDIENTE_PAGO ?
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Tag color="volcano"  >
                                    <Icon type="credit-card" theme="filled" />
                                    &nbsp; Pendiente de pago
                                </Tag>
                            </Col>
                            :
                            <></>

                        }
                    </Col>
                </Row>



            </Card>
        </div >
    )
}