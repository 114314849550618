import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';

import IntercomChat from './containers/IntercomChat'
import IntercomProps from './config/Intercom'

import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from './config';



const App = () => {

    useEffect(() => {
        hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
    }, [])

    useEffect(() => {
        const url = new URL(window.location.href)

        if (url.searchParams) {
            const desktop = url.searchParams.get('desktop')
            desktop && localStorage.setItem('desktop', desktop)
        }
    }, [window.location.href])

    return <Provider store={store} >
        <AppProvider>
            <IntercomProvider {...IntercomProps}>
                <GlobalStyles />
                <Routes />
                <IntercomChat />
            </IntercomProvider>
        </AppProvider>
    </Provider>
}


Boot()

export default App;
