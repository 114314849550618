import React, { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Card,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { Add, Cancel, Check, Edit, ExpandMore, MonetizationOn, Person, Store, Warning } from "@mui/icons-material"
import { COLOR_BLUE } from "../../../constants/color"
import { DELIVERY_TYPE_DELIVERY, DESTINY_TYPE_CLIENTE, DESTINY_TYPE_SUCURSAL, PANEL_DESTINO, TYPE_ESPECIAL, TYPE_FLASH } from "../../../redux/pedidoEspecial/types"
import {
    setDestinyClient,
    setDestinyClientAdditionalInformation,
    setDestinyClientDirection,
    setDestinyClientEmail,
    setDestinyClientLastname,
    setDestinyClientName,
    setDestinyClientPhone,
    setDestinyConfirm,
    setDestinyNewClient,
    setDestinyStore,
    setDestinyStoreAdmin,
    setDestinyType,
} from "../../../redux/pedidoEspecial/actions"
import { LAMBDA_ADMIN, LAMBDA_POS, LAMBDA_SHOP } from "../../../config"
import Axios from "axios"
import InputMapGoogle from "../../../components/Map/InputMapGoogle"
import { formatNumber } from "../../../helpers/formatNumber"
const OpenStreetMapImage = React.lazy(() => import("../../../components/Map/OpenStreetMaps/OpenStreetMapImage"))

const Destino = ({ changePanel }) => {
    const dispatch = useDispatch()
    const { activePanel, destiny, origin, type, deliveryType } = useSelector(state => state?.PedidoEspecial)
    const IdNacionalidad = useSelector(state => state?.Auth?.auth?.nacionalidad?.Id)
    const { sucursal } = useSelector(state => state?.Auth?.payload?.usuarioPerfil)
    const { IdSucursal } = sucursal
    const { IdComercio } = sucursal?.comercio
    const [listSucursales, setListSucursales] = useState([])
    const [listClients, setListClients] = useState([])
    const [listAdmin, setListAdmin] = useState([])
    const [loadingSucursales, setLoadingSucursales] = useState(false)
    const [loadingClients, setLoadingClients] = useState(false)
    const [loadingSelectedClient, setLoadingSelectedClient] = useState(false)
    const [loadingCost, setLoadingCost] = useState(false)
    const [loadingListAdmin, setLoadingListAdmin] = useState(false)
    const [reloadingMap, setReloadingMap] = useState(false)
    const [editDirection, setEditDirection] = useState(false)
    const [googleDirection, setGoogleDirection] = useState(null)
    const [formNewClient, setFormNewClient] = useState(false)
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))
    const { Moneda } = useSelector(state => state?.Auth?.payload?.usuarioPerfil?.sucursal?.comercio?.nacionalidad)

    useEffect(() => {
        getSucursales()
        getUsers()
    }, [])

    useEffect(() => {
        if (googleDirection && !formNewClient) {
            changeClientDirection(googleDirection)
        }
    }, [googleDirection])

    const getSucursales = async () => {
        setLoadingSucursales(true)
        try {
            const res = await Axios.get(`${LAMBDA_ADMIN}/getAllSucursalComercio/${IdComercio}`)
            const data = res?.data?.sucursal.map((e) => {
                return {
                    ...e,
                    label: e?.Titulo
                }
            })
            setListSucursales(data)
        } catch (err) {
            console.error(err)
        }
        setLoadingSucursales(false)
    }

    const getUsers = async () => {
        setLoadingClients(true)
        try {
            const res = await Axios.get(`${LAMBDA_POS}/getUsersWithPurchases/${IdComercio}`)
            const data = res?.data?.Clientes.map((e) => {
                return {
                    ...e,
                    label: `${e?.Nombre} ${e?.Apellido}`
                }
            })
            setListClients(data)
        } catch (err) {
            console.error(err)
        }
        setLoadingClients(false)
    }

    const changeDestinyType = async (type) => {
        await dispatch(setDestinyType({ type }))
    }

    const changeDestinyStore = async (store) => {
        setLoadingCost(true)
        setLoadingListAdmin(true)
        setReloadingMap(true)

        const res = await getCost(true, store)
        await dispatch(setDestinyStore({ store, cost: res?.cost }))
        await getAdminBySucursal(store)

        setLoadingCost(false)
        setLoadingListAdmin(false)
        setReloadingMap(false)
    }

    const changeAdminSucursal = async (storeAdmin) => {
        await dispatch(setDestinyStoreAdmin({ storeAdmin }))
    }

    const changeDestinyClient = async (client) => {
        setLoadingCost(true)
        setLoadingSelectedClient(true)
        setFormNewClient(false)

        const res = await getCost(false, client)
        await dispatch(setDestinyClient({ client, ...res }))

        setLoadingSelectedClient(false)
        setLoadingCost(false)
    }

    const initNewClient = async () => {
        setFormNewClient(true)
        await dispatch(setDestinyNewClient())
    }

    const cancelInitNewClient = async () => {
        setFormNewClient(false)
        await dispatch(setDestinyNewClient())
    }

    const saveNewClient = async () => {
        setFormNewClient(false)
        setLoadingCost(true)

        let newIdUsuario = 0
        try {
            const res = await Axios.post(`${LAMBDA_SHOP}/createUsuarioInvitado`, {
                Nombre: `${destiny?.client?.Nombre} ${destiny?.client?.Apellido}`
                , Email: destiny?.client?.Email
                , Telefono: destiny?.client?.Telefono
                , IdNacionalidad
            })

            newIdUsuario = res?.data?.usuario?.IdUsuario
        } catch (err) {
            console.error(err)
        }

        if (newIdUsuario) {
            const data = {
                ...destiny?.client,
                IdUsuario: newIdUsuario,
                Direccion: googleDirection?.address,
                Latitude: googleDirection?.lat,
                Longitude: googleDirection?.lng,
            }

            console.log('data: ', data)
            const res = await getCost(false, data)
            await dispatch(setDestinyClient({ client: { ...data }, ...res }))
        }

        setLoadingCost(false)
    }

    const getCost = async (toStore, data) => {
        try {
            const res = await Axios.post(`${LAMBDA_SHOP}/address/validateOrCreate`, {
                esEnvioSucursal: toStore,
                direccion: data?.Direccion,
                IdDestino: (toStore) ? data?.IdSucursal : data?.IdUsuario,
                IdSucursalOrigen: (origin && origin?.IdSucursal) ? origin?.IdSucursal : IdSucursal,
                lat: (toStore) ? data?.lat : data?.Latitude,
                lng: (toStore) ? data?.lng : data?.Longitude,
                showPrivateData: false,
                Numeracion: ''
            })
            return {
                cost: res?.data?.address?.totalDelivery,
                inGeozone: res?.data?.address?.isInGeozone,
            }
        } catch (err) {
            console.error(err)
        }
        return {
            cost: 0,
            inGeozone: false,
        }
    }

    const getAdminBySucursal = async (store) => {
        try {
            const res = await Axios.get(`${LAMBDA_POS}/getAllSucursalUserAdmin/${store?.IdSucursal}`)
            const list = res?.data?.usuarios.map((e) => {
                return {
                    ...e,
                    label: `${e?.Nombre} ${e?.Apellido}`
                }
            })
            setListAdmin(list)
        } catch (err) {
            console.error(err)
        }
    }

    const changeClientName = async (name) => {
        await dispatch(setDestinyClientName({ name }))
    }

    const changeClientLastname = async (lastname) => {
        await dispatch(setDestinyClientLastname({ lastname }))
    }

    const changeClientEmail = async (email) => {
        await dispatch(setDestinyClientEmail({ email }))
    }

    const changeClientPhone = async (phone) => {
        await dispatch(setDestinyClientPhone({ phone }))
    }

    const changeClientDirection = async (direction) => {
        setReloadingMap(true)
        await dispatch(setDestinyClientDirection({ direction }))
        setEditDirection(false)
        setGoogleDirection(null)

        setLoadingCost(true)

        const data = {
            ...destiny?.client,
            Direccion: direction?.address,
            Latitude: direction?.lat,
            Longitude: direction?.lng,
        }
        const res = await getCost(false, data)
        await dispatch(setDestinyClient({ client: data, ...res }))
        setLoadingCost(false)
        setReloadingMap(false)
    }

    const changeClientAdditionalInformation = async (additionalInformation) => {
        await dispatch(setDestinyClientAdditionalInformation({ additionalInformation }))
    }

    const confirmDestiny = async () => {
        await dispatch(setDestinyConfirm())
    }

    return (
        <>
            <Accordion
                expanded={(activePanel === PANEL_DESTINO) ? true : false}
                onChange={() => changePanel(PANEL_DESTINO)}
                sx={{ my: 1 }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack
                        direction={(Mediaquery) ? 'column' : 'row'}
                        justifyContent='space-between'
                        sx={{
                            width: {
                                lg: '50%',
                                md: '80%',
                                sm: '100%',
                            }
                        }}
                    >
                        <Typography variant="h6">
                            Entregar en
                        </Typography>

                        {
                            (destiny?.type === DESTINY_TYPE_SUCURSAL && destiny?.store) && (
                                <Stack direction={(Mediaquery) ? 'column' : 'row'} alignItems='baseline'>
                                    <Typography sx={{ opacity: 0.7 }}>
                                        {destiny?.store?.Titulo}
                                    </Typography>
                                    <Typography variant='caption' sx={{ ml: 1, opacity: 0.7 }}>
                                        {`(${destiny?.store?.Direccion})`}
                                    </Typography>
                                </Stack>
                            )
                        }

                        {
                            (destiny?.type === DESTINY_TYPE_CLIENTE && destiny?.client) && (
                                <Typography sx={{ opacity: 0.7 }}>
                                    {destiny?.client?.Direccion}
                                </Typography>
                            )
                        }
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            px: {
                                md: 5,
                                xs: 0,
                            }
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>

                                {
                                    (type === TYPE_FLASH) && (
                                        <Stack direction={(Mediaquery) ? 'column' : 'row'} sx={{ mb: 2 }}>
                                            <Card
                                                onClick={() => changeDestinyType(DESTINY_TYPE_SUCURSAL)}
                                                elevation={2}
                                                sx={{
                                                    mr: {
                                                        md: 1,
                                                        xs: 0,
                                                    },
                                                    mt: {
                                                        md: 0,
                                                        xs: 1,
                                                    },
                                                    width: {
                                                        md: 350,
                                                        xs: '100%'
                                                    },
                                                    p: 2,
                                                    cursor: 'pointer',
                                                    border: (destiny?.type === DESTINY_TYPE_SUCURSAL) ? 1 : 0,
                                                    borderColor: (destiny?.type === DESTINY_TYPE_SUCURSAL) ? COLOR_BLUE : 'none',
                                                }}
                                            >
                                                <Stack direction='row' alignItems='center'>
                                                    <Store sx={{ mr: 1 }} />
                                                    <Typography variant="h6">
                                                        A otra sucursal
                                                    </Typography>
                                                </Stack>
                                            </Card>
                                            <Card
                                                onClick={() => changeDestinyType(DESTINY_TYPE_CLIENTE)}
                                                elevation={2}
                                                sx={{
                                                    ml: {
                                                        md: 1,
                                                        xs: 0,
                                                    },
                                                    mt: {
                                                        md: 0,
                                                        xs: 1,
                                                    },
                                                    width: {
                                                        md: 350,
                                                        xs: '100%'
                                                    },
                                                    p: 2,
                                                    cursor: 'pointer',
                                                    border: (destiny?.type === DESTINY_TYPE_CLIENTE) ? 1 : 0,
                                                    borderColor: (destiny?.type === DESTINY_TYPE_CLIENTE) ? COLOR_BLUE : 'none',
                                                }}
                                            >
                                                <Stack direction='row' alignItems='center'>
                                                    <Person sx={{ mr: 1 }} />
                                                    <Typography variant="h6">
                                                        A cliente
                                                    </Typography>
                                                </Stack>
                                            </Card>
                                        </Stack>
                                    )
                                }

                                {
                                    (destiny?.type === DESTINY_TYPE_SUCURSAL) ?
                                        <>
                                            {
                                                (loadingSucursales) ?
                                                    <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
                                                        <CircularProgress size={16} sx={{ mr: 1 }} />
                                                        <Typography variant="body1">
                                                            Cargando sucursales...
                                                        </Typography>
                                                    </Stack>
                                                    :
                                                    <>
                                                        <Typography variant="body1">
                                                            Selecciona una sucursal como destino
                                                        </Typography>
                                                        <Autocomplete
                                                            noOptionsText='...'
                                                            onChange={(e, value) => changeDestinyStore(value)}
                                                            disablePortal
                                                            size="small"
                                                            options={listSucursales}
                                                            sx={{
                                                                width: 300,
                                                                mt: 2,
                                                            }}
                                                            renderInput={(params) => <TextField {...params} fullWidth label="Sucursal de Destino" />}
                                                        />
                                                    </>
                                            }

                                            {
                                                (destiny?.store) && (
                                                    (loadingListAdmin) ?
                                                        <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
                                                            <CircularProgress size={16} sx={{ mr: 1 }} />
                                                            <Typography variant="body1">
                                                                Cargando responsables...
                                                            </Typography>
                                                        </Stack>
                                                        :
                                                        <>
                                                            <Typography variant="body1" sx={{ mt: 2 }}>
                                                                Selecciona un responsable de la sucursal
                                                            </Typography>
                                                            <Autocomplete
                                                                noOptionsText='...'
                                                                onChange={(e, value) => changeAdminSucursal(value)}
                                                                disablePortal
                                                                size="small"
                                                                options={listAdmin}
                                                                sx={{
                                                                    width: 300,
                                                                    mt: 2,
                                                                }}
                                                                renderInput={(params) => <TextField {...params} fullWidth label="Responsable" />}
                                                            />
                                                        </>
                                                )
                                            }
                                        </>
                                        :
                                        <>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1">
                                                        Selecciona un cliente de la lista o ingresa uno nuevo
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Autocomplete
                                                        noOptionsText={(loadingClients) ? <CircularProgress /> : 'No se encontraron clientes con compras todavía'}
                                                        onChange={(e, value) => changeDestinyClient(value)}
                                                        disablePortal
                                                        size="small"
                                                        options={listClients}
                                                        sx={{ width: '100%' }}
                                                        renderInput={(params) => <TextField {...params} fullWidth label="Seleccionar un cliente de la lista" />}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Button
                                                        variant="outlined"
                                                        color={(formNewClient) ? 'error' : 'primary'}
                                                        startIcon={(formNewClient) ? <Cancel /> : <Add />}
                                                        fullWidth
                                                        onClick={() => (formNewClient) ? cancelInitNewClient() : initNewClient()}
                                                    >
                                                        {
                                                            (formNewClient) ?
                                                                `Cancelar`
                                                                :
                                                                `Ingresar nuevo Cliente`
                                                        }
                                                    </Button>
                                                </Grid>
                                                {
                                                    (loadingSelectedClient) && (
                                                        <Grid item xs={12}>
                                                            <Stack direction='row' alignItems='center'>
                                                                <CircularProgress size={16} sx={{ mr: 1 }} />
                                                                <Typography variant="body1">
                                                                    Cargando datos...
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>

                                            {
                                                (destiny?.client && !loadingSelectedClient || formNewClient) && (
                                                    <Grid container spacing={3} sx={{ mt: 2 }}>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                label='Nombre'
                                                                fullWidth
                                                                size="small"
                                                                value={(destiny?.client?.Nombre) ? destiny?.client?.Nombre : ''}
                                                                onChange={(e) => changeClientName(e?.target?.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                label='Apellidos'
                                                                fullWidth
                                                                size="small"
                                                                value={(destiny?.client?.Apellido) ? destiny?.client?.Apellido : ''}
                                                                onChange={(e) => changeClientLastname(e?.target?.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                label='Email'
                                                                fullWidth
                                                                size="small"
                                                                value={(destiny?.client?.Email) ? destiny?.client?.Email : ''}
                                                                onChange={(e) => changeClientEmail(e?.target?.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <TextField
                                                                label='Teléfono'
                                                                fullWidth
                                                                size="small"
                                                                value={(destiny?.client?.Telefono) ? destiny?.client?.Telefono : ''}
                                                                onChange={(e) => changeClientPhone(e?.target?.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item md={(formNewClient) ? 12 : 10} xs={12}>
                                                            {
                                                                (editDirection || formNewClient) ?
                                                                    <InputMapGoogle
                                                                        variant='outlined'
                                                                        label='Dirección'
                                                                        setLocation={setGoogleDirection}
                                                                    />
                                                                    :
                                                                    <TextField
                                                                        label='Dirección'
                                                                        fullWidth
                                                                        size="small"
                                                                        value={(destiny?.address?.lat && destiny?.address?.lng && destiny?.address?.direction) ? destiny?.address?.direction : ''}
                                                                    />
                                                            }
                                                        </Grid>
                                                        {
                                                            (!formNewClient) && (
                                                                <Grid item md={2} xs={12}>
                                                                    <Button
                                                                        variant='outlined'
                                                                        color={(editDirection) ? 'error' : 'primary'}
                                                                        fullWidth
                                                                        onClick={() => setEditDirection(!editDirection)}
                                                                    >
                                                                        {
                                                                            (editDirection) ?
                                                                                <>
                                                                                    <Cancel
                                                                                        sx={{
                                                                                            mr: {
                                                                                                md: 0,
                                                                                                xs: 1
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        (Mediaquery) && (
                                                                                            `No Modificar dirección`
                                                                                        )
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Edit
                                                                                        sx={{
                                                                                            mr: {
                                                                                                md: 0,
                                                                                                xs: 1
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        (Mediaquery) && (
                                                                                            `Modificar dirección`
                                                                                        )
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </Button>
                                                                </Grid>
                                                            )
                                                        }
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                label='Dpto, Torre, Indicaciones'
                                                                fullWidth
                                                                size="small"
                                                                value={(destiny?.address?.additionalInformation) ? destiny?.address?.additionalInformation : ''}
                                                                onChange={(e) => changeClientAdditionalInformation(e?.target?.value)}
                                                            />
                                                        </Grid>
                                                        {
                                                            (formNewClient) && (
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color={'primary'}
                                                                        startIcon={<Check />}
                                                                        fullWidth
                                                                        onClick={saveNewClient}
                                                                    >
                                                                        Guardar nuevo cliente
                                                                    </Button>
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                )
                                            }
                                        </>
                                }

                            </Grid>
                            <Grid item md={6} xs={12}>
                                {
                                    (
                                        (destiny?.store || destiny?.client)
                                        && destiny?.address?.lat
                                        && destiny?.address?.lng
                                        && !loadingSelectedClient
                                        && !reloadingMap
                                    ) ?
                                        <>
                                            <OpenStreetMapImage
                                                width={'100%'}
                                                height={(Mediaquery) ? 200 : 300}
                                                zoomControl={false}
                                                zoom={16}
                                                locations={[{ positions: [destiny?.address?.lat, destiny?.address?.lng] }]}
                                            />

                                            {
                                                (deliveryType === DELIVERY_TYPE_DELIVERY) ?
                                                    <>
                                                        {
                                                            (loadingCost) ?
                                                                <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
                                                                    <CircularProgress size={16} sx={{ mr: 1 }} />
                                                                    <Typography variant="body1">
                                                                        Calculando envío...
                                                                    </Typography>
                                                                </Stack>
                                                                :
                                                                <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
                                                                    <MonetizationOn color='primary' sx={{ mr: 1 }} />
                                                                    <Typography variant="body1">
                                                                        Costo de envío (paga comercio): {`${formatNumber({ num: destiny?.shippingCost, currency: Moneda })} + IVA`}
                                                                    </Typography>
                                                                </Stack>
                                                        }
                                                    </>
                                                    :
                                                    <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
                                                        <Store color='primary' sx={{ mr: 1 }} />
                                                        <Typography variant="body1">
                                                            Retiro en tienda
                                                        </Typography>
                                                    </Stack>
                                            }

                                        </>
                                        :
                                        ''
                                }
                            </Grid>
                            {
                                (
                                    (destiny?.client || destiny?.store)
                                    && !formNewClient
                                    && !editDirection
                                    && destiny?.address?.lat
                                    && destiny?.address?.lng
                                    && destiny?.shippingCost > 0
                                ) ?
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color={(destiny?.confirm) ? 'success' : (!destiny?.inGeozone && type === TYPE_ESPECIAL) ? 'error' : 'primary'}
                                            startIcon={(destiny?.confirm) ? <Check /> : (!destiny?.inGeozone && type === TYPE_ESPECIAL) ? <Warning /> : ''}
                                            fullWidth
                                            onClick={() => (!destiny?.inGeozone && type === TYPE_ESPECIAL) ? console.log('fuera geozona') : confirmDestiny()}
                                        >
                                            {
                                                (destiny?.confirm) ?
                                                    `Destinado confirmado!`
                                                    :
                                                    (!destiny?.inGeozone && type === TYPE_ESPECIAL) ?
                                                        `Fuera de Geozona de entrega`
                                                        :
                                                        `Confirmar destino`
                                            }
                                        </Button>
                                    </Grid>
                                    :
                                    ''
                            }
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default memo(Destino)