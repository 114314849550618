import { all, takeEvery, fork, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import { LAMBDA_POS } from "../../config/"
import axios from 'axios'

export function* getSettingsScucursal() {
    yield takeEvery(actions.GET_SETTINGS, function* ({ payload }) {
        const { IdSucursal, callback } = payload
        const response = yield call(callGetSettingsScucursal, IdSucursal)

        yield put({
            type: actions.GET_SETTINGS_SUCCESS,
            payload: {
                sucursal: response.settings
            }
        })
        callback && setTimeout(callback, 1000);
    })
}

const callGetSettingsScucursal = async (IdSucursal) => {
    const response = await axios.get(`${LAMBDA_POS}/getSettingsSucursal/${IdSucursal}`)
    return response.data;
}


export function* refreshSettingsScucursal() {
    yield takeEvery(actions.REFRESH_SETTINGS, function* ({ payload }) {
        const { settings } = payload
        yield put({
            type: actions.GET_SETTINGS_SUCCESS,
            payload: {
                sucursal: settings.settings
            }
        })
    })
}

export default function* rootSaga() {
    yield all([
        fork(getSettingsScucursal),
        fork(refreshSettingsScucursal),
    ]);
}