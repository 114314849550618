import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Popover from '@iso/components/uielements/popover';
import userpic from '@iso/assets/images/nouser.png';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
import { useAgilAuth } from '../../AgilAuth/context/provider';
import { Icon, Spin } from 'antd';
import { Box } from '@mui/material';

export default function TopbarUser({ SelectSucursales, loadingSucursal }) {
    const Auth = useSelector(state => state.Auth)
    const { logoutUser } = useAgilAuth();


    const Picture = localStorage.getItem('imagen');
    var UserPicture = (Picture && Picture !== 'null') ? Picture : userpic

    const [visible, setVisibility] = React.useState(false);
    function handleVisibleChange() {
        setVisibility(visible => !visible);
    }

    const content = (
        <TopbarDropdownWrapper className="isoUserDropdown" style={{ width: 400 }}>
            <Link className="isoDropdownLink">
                {
                    (SelectSucursales) && (
                        (loadingSucursal) ?
                            <Spin indicator={<Icon type="loading" />} >
                                {SelectSucursales}
                            </Spin>
                            :
                            SelectSucursales
                    )
                }
            </Link>
            <Link
                className="isoDropdownLink"
                to={'/dashboard/user-profile'}
            >
                Perfil
            </Link>
            <Link
                className="isoDropdownLink"
                onClick={() => logoutUser(Auth.Token)}
            >
                Salir
            </Link>
        </TopbarDropdownWrapper >
    );

    return (
        <Popover
            content={content}
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
            arrowPointAtCenter={true}
            placement="bottomLeft"
        >
            <div className="isoImgWrapper">
                <img style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    // border: '4px solid #ffffff'
                }}
                    alt="user" src={Auth.auth.Imagen || UserPicture} />
                <span className="userActivity online img-circle" />
            </div>
        </Popover>
    );
}
