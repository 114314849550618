export const GET_SETTINGS = 'GET_SETTINGS'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const REFRESH_SETTINGS = 'REFRESH_SETTINGS'


export const getSettings = (IdSucursal, callback) => ({
    type: GET_SETTINGS,
    payload: {
        IdSucursal,
        callback
    }
})

export const refreshSettings = (payload) => ({
    type: REFRESH_SETTINGS,
    payload
})