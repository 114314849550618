import React, { useEffect, useState } from 'react'
import { Row, Col, Drawer } from 'antd'
// import { useSelector } from 'react-redux'
import { PED_ENTRE_TIPO_ENTRE_DELIVERY } from '../../../redux/ordenes/types'
import ClientHistory from './Client-History';
import { fixTelefono } from '../../../helpers/fixes';

const DRAWER_CONTENT_HISTORY_IN_POS = 'DRAWER_CONTENT_HISTORY_IN_POS';

export default ({ order }) => {

    const [drawerData, setdrawerData] = useState({
        title: '',
        visible: false,
        content: null,
        props: {}
    })

    useEffect(() => {
        setdrawerData({
            visible: false
        })
    }, [order.cliente])


    return (
        <>
            <Row>
                <Col span={6}> <b> Nombre </b> </Col>
                <Col span={18}>
                    <a
                        // onClick={() => order.client && setdrawerData({
                        onClick={() => order.IdTipoPedido !== 5 && setdrawerData({
                            title: `Historial Cliente ${order.cliente ? order.cliente.Nombre : order.sucursalEntrega ? order.sucursalEntrega.Titulo : ''}`,
                            visible: true,
                            content: DRAWER_CONTENT_HISTORY_IN_POS,
                            props: {
                                IdUsuario: order.cliente.IdUsuario
                            }
                        })}
                        style={{ textTransform: 'capitalize' }}
                    >
                        {`${order.IdTipoPedido === 5 ? order?.extOrder?.NombreCliente : order.cliente ? order.cliente.Nombre : order.sucursalEntrega ? order.sucursalEntrega.Titulo : ''} ${order.IdTipoPedido === 5 ? order?.extOrder?.ApellidoCliente : order.cliente ? order.cliente.Apellido : ''}`}
                    </a>
                    {/* <Popover
                        placement='right'
                        content={<div>
                            <a>Historia</a>
                        </div>}
                        // title="Title"
                        trigger="click"
                    >
                    </Popover> */}
                </Col>
            </Row>
            <Row>
                <Col span={6}> <b> Teléfono </b> </Col>
                <Col span={18}>
                    {
                        order.IdTipoPedido === 5 ?
                            order?.extOrder?.TelefonoCliente
                            :
                            order.cliente && order.cliente.Telefono ?
                                fixTelefono(order.cliente.nacionalidad, order.cliente.Telefono) :
                                order.sucursalEntrega && order.sucursalEntrega.Telefono ?
                                    fixTelefono(order.sucursalEntrega.comercio.nacionalidad, order.sucursalEntrega.Telefono)
                                    :
                                    null
                    }
                </Col>
            </Row>
            <Row>
                <Col span={6}> <b> Email </b> </Col>
                <Col span={18}> {order.IdTipoPedido === 5 ? order?.extOrder?.EmailCliente : order.cliente ? order.cliente.Email : order.sucursalEntrega ? order.sucursalEntrega.Email : ''} </Col>
            </Row>
            <Row>
                <Col span={6}> <b> Lugar Entrega </b> </Col>
                <Col span={18}>
                    {
                        order?.entrega?.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY ? <>
                            {order.IdTipoPedido === 5 ? order?.extOrder?.DireccionEntrega : order?.direccionCliente ? order?.direccionCliente?.Direccion : order?.sucursalEntrega ? order?.sucursalEntrega?.Direccion : ''}
                            <br></br>
                            {`Ref: ${order?.direccionCliente ? order?.direccionCliente?.Nro : ''}`}
                        </> : <>
                            {order?.sucursal?.Direccion}
                        </>
                    }
                </Col>
            </Row>
            <Row>
                <Col span={6}> <b> Nota </b> </Col>
                <Col span={18}>
                    <p style={{ wordBreak: 'break-all' }}>
                        <i>
                            {
                                order?.entrega?.tipoDelivery && <>
                                    <span>{order?.entrega?.tipoDelivery?.Tipo}</span>
                                    <br />
                                </>
                            }
                            {order?.Comentario}
                        </i>
                    </p>
                </Col>
            </Row>

            <Drawer
                title={drawerData.title}
                visible={drawerData.visible}
                onClose={() => setdrawerData({ visible: false })}
                closable
                destroyOnClose
                width={500}
            >
                {
                    drawerData.content === DRAWER_CONTENT_HISTORY_IN_POS ?
                        <ClientHistory
                            {...drawerData.props}
                        /> :
                        null
                }
            </Drawer>
        </>
    )
}