import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Layout, Row, Col, notification, Select, Typography } from "antd"

import { ContactListWrapper } from "@iso/components/Orders/OrderList.style"
import { ContactCardWrapper } from "@iso/components/Orders/OrderCard.style"

import Button from "@iso/components/uielements/button"
import { InputSearch } from "@iso/components/uielements/input"
import { ContactsWrapper } from "./Orders.styles"
import Scrollbar from "@iso/components/utility/customScrollBar"
import Pagination from "@iso/components/uielements/pagination"
import PreparacionCocina from './OrderDetails/PreparacionCocina'
import OrderProducts from "./OrderDetails/Order-Products"
import OrderTotals from "./OrderDetails/Order-Totals"
import OrderClient from "./OrderDetails/Order-Client"
import OrderInfo from "./OrderDetails/Order-Info"
import OrderHeadDetail from "./OrderDetails/Order-HeadDetail"
import { SocketContext } from "../Dashboard/Dashboard"
import OrderPayment from "./OrderDetails/Order-Payment"
import OrdersActiveCard from "./Cards/OrdersActive.Card"
import TicketDrawerButton from "./Actions/TicketDrawer.Button"
import TrackingOrderButton from "./Actions/TrackingOrderButton"
import SolicitarDeliveryPreferidoButton from "./Actions/SolicitarDeliveryPreferido.Button"
import {
    ID_PEDIDO_ESTADO_ACEPTADO,
    PED_ENTRE_TIPO_ENTRE_DELIVERY,
    PED_ENTRE_TIPO_ENTRE_MESA,
    PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA
} from "../../redux/ordenes/types"
import SplitOrderDeliveryButton from "./Actions/SplitOrderDelivery.Button"
import PedidoSeguimientoButton from "./Actions/PedidoSeguimiento.Button"
import RejectButton from "./Actions/Reject.button"
import PayLinkButton from "./Actions/PayLinkButton"
import { useLocation } from 'react-router-dom'
import moment from "moment"
import Axios from "axios"
import { LAMBDA_COMMON } from "../../config"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as ButtonMui } from "@mui/material"

const { Content } = Layout
const { Option } = Select;

const typeOrder = [
    'Correlativo menor a mayor',
    'Correlativo mayor a menor',
    'Hr de salida menor a mayor',
    'Hr de salida mayor a menor'
]

export default function OrdersActive() {

    const searchByUri = useLocation().search
    const socket = useContext(SocketContext)
    const Auth = useSelector(state => state.Auth)
    const { ordersActive } = useSelector(state => state.Ordenes)
    const [orders, setOrders] = useState(ordersActive)
    const [index, setIndex] = useState(0)
    const [search, setSearch] = useState("")
    const [loadingSolicitud, setLoadingSolicitud] = useState(false)
    const [openconfirmDelivery, setOpneConfirmDelivery] = useState(false)
    const [orderIndex, setOrderIndex] = useState(null)

    const [IdLista, setIdLista] = useState(1)
    const [ordenPedido, setOrdenPedido] = useState('Correlativo menor a mayor')

    useEffect(() => {

        if (ordersActive) {
            onChangeOrdenPedido(ordenPedido, true)
        }

    }, [ordersActive])

    useEffect(() => {
        setOrders(locateOnPage(applySearch()))
    }, [ordersActive, search, IdLista])

    // SOLO APLICA SI SE ENVIA EL CODIGO DEL PEDIDO POR GET
    const IdPedido = new URLSearchParams(searchByUri).get('IdPedido')
    useEffect(() => {
        setSearch((IdPedido !== null) ? IdPedido : "")
    }, [ordersActive, IdPedido])

    const applySearch = () => search ? ordersActive.filter(o => `${o.Codigo}-${o.Corelativo}`.includes(search)) : ordersActive
    const locateOnPage = (ordersToPaginate) => {
        const limitMax = IdLista * 10 - 1   // 09 , 19, 29
        const limitMin = limitMax - 9       // 00 , 10, 20

        return ordersToPaginate.filter((o, i) => (limitMin <= i && i <= limitMax))
    }
    const handleOnChangeSearch = (value) => {
        setIdLista(1)
        setSearch(value)
    }
    const handleOnChangePagination = (page) => {
        setIdLista(page)
    }


    const handleOnClickSolicitarDelivery = async (order) => {

        setOpneConfirmDelivery(false)
        const courier = Auth.payload.usuarioPerfil.sucursal.courier
        const defaultCourier = courier.find(ele => ele.DefaultCourier)
        const IdSucursal = Auth.payload.usuarioPerfil.sucursal.IdSucursal


        setLoadingSolicitud(true)

        // let isValid = true

        // if (defaultCourier && defaultCourier.IdTipoCourier && order.EsCourierExterno === true) {
        //     try {
        //         const { data } = await Axios.post(`${LAMBDA_COMMON}/createAndConfirm/courier/${order.IdSucursal}/${order.IdPedido}/${defaultCourier.IdTipoCourier}`)

        //     } catch (err) {
        //         console.error(err)

        //         isValid = false
        //     }

        // } else {
        //     isValid = false
        // }


        // if (!isValid) {

        socket.emit("SolicitarDelivery", {
            IdPedido: order.IdPedido,
            IdSucursal: order.IdSucursal,
            usuarioEmail: Auth.auth.Email,
            tipoSolicitud: 'MANUAL'
        }, ({ success }) => {
            if (success) {
                notification.success({
                    message: `Delivery Solicitado`,
                    description: `${order.Codigo}-${order.Corelativo}`
                })
                setLoadingSolicitud(false)
            } else {
                notification.error({
                    message: 'El Pedido no puede pasar a Delivery',
                    description: `${order.Codigo}-${order.Corelativo}`
                })
                setLoadingSolicitud(false)
            }
        })

        // }

    }

    const handleOnClickPedidoListo = (order) => {
        socket.emit("PedidoListo", {
            IdPedido: order.IdPedido,
            IdSucursal: order.IdSucursal,
            usuarioEmail: Auth.auth.Email,
            accion: "listo"
        }, (data) => {
        })
    }

    const hrDetalle = <hr style={{ borderWidth: 1, borderColor: "rgba(233, 233, 233, 0.18)" }}></hr>

    const onChangeOrdenPedido = (e, start) => {
        let value = e
        if (start) {
            let currentOrder = localStorage.getItem('currentOrden')
            if (currentOrder) {
                setOrdenPedido(currentOrder)
                value = currentOrder
            } else {
                localStorage.setItem('currentOrden', ordenPedido)
                value = ordenPedido
            }
        }

        setOrdenPedido(value)
        localStorage.setItem('currentOrden', value)
        let newOrden = ordersActive;
        let date = moment().format('YYYY-MM-DD')
        switch (value) {
            case 'Correlativo menor a mayor': {
                newOrden = ordersActive.sort((a, b) => a.Corelativo - b.Corelativo)
                break;
            }
            case 'Correlativo mayor a menor': {
                newOrden = ordersActive.sort((a, b) => a.Corelativo - b.Corelativo).reverse()
                break;
            }
            case 'Hr de salida menor a mayor': {
                newOrden = ordersActive.sort((a, b) => new Date(`${date} ${a.HoraDespachoCocina}`) - new Date(`${date} ${b.HoraDespachoCocina}`))
                break;
            }
            case 'Hr de salida mayor a menor': {
                newOrden = ordersActive.sort((a, b) => new Date(`${date} ${a.HoraDespachoCocina}`) - new Date(`${date} ${b.HoraDespachoCocina}`)).reverse()
                break;
            }
        }

        setOrders(newOrden)
    }

    return (
        <>
            <ContactsWrapper
                className="isomorphicContacts"
                style={{ background: "none" }}
            >
                {/* LEFT MENU */}
                <div className="isoContactListBar">
                    <ContactListWrapper className="isoContactListWrapper">
                        {/* <InputSearch
                            placeholder="Buscar Orden"
                            value={search}
                            onChange={e => handleOnChangeSearch(e.target.value)}
                            className="isoSearchBar"
                        /> */}
                        <Typography>Ordenar por:</Typography>
                        <Select
                            style={{ marginBottom: 10 }}
                            onChange={(e) => onChangeOrdenPedido(e, false)}
                            className="isoSearchBar"
                            value={ordenPedido}
                        >
                            {typeOrder.map((ele, i) => (
                                <Option
                                    value={ele}
                                    key={`ORDEN-${i}`}
                                >
                                    {ele}
                                </Option>

                            ))}
                        </Select>
                        {orders && orders.length > 0 ? (
                            <div className="isoContactList">
                                <Scrollbar
                                    className="contactListScrollbar"
                                    style={{ height: "calc(100vh - 200px)" }}
                                >
                                    {orders.map((order, i) =>
                                        <OrdersActiveCard key={i} order={order} i={i} active={index === i} onSelected={setIndex} onClickSolicitarDelivery={i => (setOpneConfirmDelivery(true), setOrderIndex(i))} loadingSolicitud={loadingSolicitud} ></OrdersActiveCard>
                                    )}
                                </Scrollbar>
                            </div>
                        ) : (
                            <span className="isoNoResultMsg">NO HAY DATOS</span>
                        )}
                    </ContactListWrapper>
                </div>
                {/* LEFT MENU */}

                {/* RIGTH INFO */}
                <Layout className="isoContactBoxWrapper">
                    {orders[index] && (
                        <Content className="isoContactBox">
                            <Scrollbar className="contactBoxScrollbar">
                                {/* INFO CABECERA */}
                                <ContactCardWrapper className="isoContactCard">


                                    <div className="isoContactInfoWrapper">

                                        <OrderHeadDetail order={orders[index]}>

                                            <Row>
                                                <Col span={12}>

                                                    {
                                                        orders[index].IdEstado === ID_PEDIDO_ESTADO_ACEPTADO && (
                                                            orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY && (orders[index].IdTipoPedido !== 5) ? (
                                                                !orders[index].entrega.rider && (
                                                                    <>
                                                                        <Button loading={loadingSolicitud} type="primary" onClick={() => (setOpneConfirmDelivery(true), setOrderIndex(index))}>
                                                                            Solicitar Delivery
                                                                        </Button>
                                                                        &nbsp;
                                                                        <SolicitarDeliveryPreferidoButton order={orders[index]}></SolicitarDeliveryPreferidoButton>
                                                                    </>
                                                                )
                                                            ) :
                                                                <Button type="primary" onClick={() => handleOnClickPedidoListo(orders[index])}>
                                                                    Pedido Listo
                                                                </Button>

                                                        )
                                                    }
                                                    {/* {orders[index].IdTipoPedido !== 5 &&
                                                        <>
                                                            <RejectButton pedido={orders[index]} />
                                                            <PayLinkButton pedido={orders[index]} tipo="SEGUIMIENTO" />
                                                        </>

                                                    } */}
                                                </Col>

                                                <Col span={12}>
                                                    <div style={{ display: 'flex' }}>
                                                        <TicketDrawerButton orders={[orders[index]]} />
                                                        {
                                                            orders[index].entrega.TipoEntrega !== PED_ENTRE_TIPO_ENTRE_MESA &&
                                                            <TrackingOrderButton order={orders[index]} />
                                                        }
                                                        {
                                                            orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                                                            orders[index].IdPedidoBase === null && (
                                                                <SplitOrderDeliveryButton order={orders[index]} />
                                                            )
                                                        }
                                                        <PedidoSeguimientoButton order={orders[index]} />
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <OrderPayment order={orders[index]} />
                                                </Col>
                                            </Row>
                                        </OrderHeadDetail>

                                    </div>
                                </ContactCardWrapper>

                                {
                                    orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                                    !orders[index].entrega.rider && <>
                                        {hrDetalle}
                                        <ContactCardWrapper className="isoContactCard">
                                            <div className="isoContactInfoWrapper">
                                                <Row>
                                                    <Col span={6}><b>{orders[index].entrega.LLAVE}</b></Col>
                                                    <Col span={6}>Sin Rider</Col>
                                                </Row>
                                            </div>
                                        </ContactCardWrapper>
                                    </>
                                }

                                {hrDetalle}

                                {/* DETALLE PRODUCTOS */}
                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <OrderProducts order={orders[index]} />
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* TOTALES */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <Row>
                                            <Col span={12}>
                                                <OrderTotals order={orders[index]}></OrderTotals>
                                            </Col>
                                        </Row>
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* DATOS CLIENTE */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <OrderClient order={orders[index]}></OrderClient>
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* DATOS PEDIDO */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <Row>
                                            <Col span={14}>
                                                <OrderInfo order={orders[index]} />
                                            </Col>
                                            <Col span={10}>
                                                <PreparacionCocina order={orders[index]}></PreparacionCocina>
                                            </Col>
                                        </Row>

                                    </div>
                                </ContactCardWrapper>

                                {/* FOOTER DETALLE */}
                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        {hrDetalle}
                                        <p className="isoInfoLabel" style={{ fontSize: '20px' }}>
                                            {`Pedido #${orders[index].IdTipoPedido === 5 ? orders[index]?.extOrder?.CodigoExterno || '' : orders[index].Codigo || 'N/A'}-${orders[index].IdTipoPedido === 5 ? orders[index]?.extOrder?.CorrelativoExterno || '' : orders[index].Corelativo} en ${Auth.payload.usuarioPerfil.sucursal.Titulo}`}
                                        </p>
                                    </Col>
                                </Row>
                                {/*  */}
                            </Scrollbar>
                        </Content>
                    )}
                </Layout>
                {/* RIGTH INFO */}
            </ContactsWrapper>

            <center>
                <Pagination
                    current={IdLista}
                    total={ordersActive.length}
                    onChange={handleOnChangePagination}
                />
            </center>

            <Dialog
                open={openconfirmDelivery}
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle>
                    <Typography>
                        ¿Solicitar delivery ahora?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Al solicitar manualmente el delivery llegará lo antes posible sin esperar la hora acordada de salida de cocina  (${orders[orderIndex]?.HoraDespachoCocina})`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonMui color="inherit" onClick={() => setOpneConfirmDelivery(false)}>Cancelar</ButtonMui>
                    <ButtonMui color="primary" variant="contained" onClick={() => handleOnClickSolicitarDelivery(orders[orderIndex])}>Solicitar Delivery AHORA</ButtonMui>
                </DialogActions>
            </Dialog>

        </>
    )
}
