import {
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    Card,
    IconButton,
    Button,
    useTheme,
    useMediaQuery
} from "@mui/material"
import React, { memo, useState, useEffect } from "react"
import { COLOR_BLUE, COLOR_LIGTH_BLUE, COLOR_WHITE } from "../../../../constants/color"
import moment from "moment"
import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import { setDeliveryDatetime } from "../../../../redux/pedidoEspecial/actions"

const ModalFechaProgramada = ({
    open,
    setOpen,
    listAvailablesHours,
    formatEstimatedDelivery,
    formatTime,
}) => {
    const dispatch = useDispatch()
    const [position, setPosition] = useState(0)
    const [selectedDate, setSelectedDate] = useState(null)
    const [listHours, setListHours] = useState([])
    const [selectedHour, setSelectedHour] = useState('')
    const [dateText, setDateText] = useState('')
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))

    useEffect(() => {
        if (listAvailablesHours.length > 0) {
            setSelectedDate(listAvailablesHours[0].date)
        }
    }, [listAvailablesHours])

    useEffect(() => {
        if (selectedDate && listAvailablesHours.length > 0) {
            let availableHours = listAvailablesHours.find(e => e?.date === selectedDate)
            if (availableHours) {
                setListHours(availableHours?.hours)
            }
        }
    }, [selectedDate, listAvailablesHours])

    useEffect(() => {
        if (selectedDate && selectedHour) {
            setDateText(formatEstimatedDelivery(`${selectedDate} ${formatTime(selectedHour, true)}:00`, true))
        }
    }, [selectedDate, selectedHour])

    const closeModal = () => {
        setOpen(false)
    }

    const confirmDate = async () => {
        await dispatch(setDeliveryDatetime({
            deliveryDate: selectedDate.replaceAll('-', '/'),
            deliveryTime: formatTime(selectedHour, true)
        }))

        closeModal()
    }

    return (
        <>
            <Dialog
                open={open}
                fullWidth
                fullScreen={Mediaquery}
                maxWidth='md'
            >
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={10}>
                            <Typography variant="h6">
                                Elige el día y hora de entrega
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton onClick={closeModal}>
                                <Close />
                            </IconButton>
                        </Grid>

                        {
                            (position > 0) && (
                                <Grid item md={1} xs={2}>
                                    <IconButton onClick={() => setPosition(position - 5)}>
                                        <ArrowBackIos fontSize="large" />
                                    </IconButton>
                                </Grid>
                            )
                        }

                        {
                            listAvailablesHours.slice(position, (position + 5)).map((e, i) => {
                                return (
                                    <Grid item
                                        md={2}
                                        sm={3}
                                        xs={3}
                                        key={i}
                                    >
                                        <Card
                                            elevation={2}
                                            sx={{
                                                bgcolor: (e?.date === selectedDate) ? COLOR_BLUE : '',
                                                color: (e?.date === selectedDate) ? COLOR_WHITE : '',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    bgcolor: (e?.date !== selectedDate) ? COLOR_LIGTH_BLUE : '',
                                                }
                                            }}
                                            onClick={() => setSelectedDate(e?.date)}
                                        >
                                            <Typography variant="body1" textAlign='center'>
                                                {e?.day}
                                            </Typography>
                                            <Typography variant="body1" textAlign='center'>
                                                {moment(e?.date).locale("es").format("DD/MM")}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }

                        {
                            (listAvailablesHours.slice(position + 5).length > (position + 5)) && (
                                <Grid item md={1} xs={2}>
                                    <IconButton onClick={() => setPosition(position + 5)}>
                                        <ArrowForwardIos fontSize="large" />
                                    </IconButton>
                                </Grid>
                            )
                        }

                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Elige el horario
                            </Typography>
                        </Grid>

                        {
                            listHours.map((e, i) => {
                                return (
                                    <Grid item
                                        md={2}
                                        sm={3}
                                        xs={3}
                                        key={i}
                                    >
                                        <Card
                                            elevation={2}
                                            sx={{
                                                bgcolor: (e?.hour === selectedHour) ? COLOR_BLUE : '',
                                                color: (e?.hour === selectedHour) ? COLOR_WHITE : '',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    bgcolor: (e?.hour !== selectedHour) ? COLOR_LIGTH_BLUE : '',
                                                }
                                            }}
                                            onClick={() => setSelectedHour(e?.hour)}
                                        >
                                            <Typography variant="body1" textAlign='center'>
                                                {formatTime(e.hour, true)}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }

                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        mx: 2
                    }}
                >
                    <Typography variant="body1">
                        {dateText}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={(selectedDate && selectedHour) ? false : true}
                        onClick={confirmDate}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default memo(ModalFechaProgramada)
