const actions = {
    ADD_IF_NOT_EXISTS_PRODUCT: 'ADD_IF_NOT_EXISTS_PRODUCT',
    CLEAN_DATA: 'CLEAN_DATA',
    addProduct: (data) => ({
        type: actions.ADD_IF_NOT_EXISTS_PRODUCT,
        payload: { data },
    }),
    cleanData: () => ({
        type: actions.CLEAN_DATA,
    }),
};

export default actions;
