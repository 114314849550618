import { Progress, Button, notification, Spin, Icon } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { settingContext, timeoutMStoSave } from '../'
import { ID_SUC_CANTIDAD_TICKETS_X_PEDIDO, DEFAULT_VALUE_CANTIDAD_TICKETS_X_PEDIDO } from "@iso/redux/settings/types"


const LIMIT_VALUE = 5
const MIN_VALUE = 1
const SUM = 1


export default () => {
    const { IdSucursal, initialValues, saveSettingSucursal } = useContext(settingContext)

    const [value, setValue] = useState(DEFAULT_VALUE_CANTIDAD_TICKETS_X_PEDIDO)
    const [showOK, setshowOK] = useState(false)
    const [showOKTimeOut, setshowOKTimeOut] = useState(null)
    const [, settimeoutsaveSettingSucursal] = useState()
    const [loading, setloading] = useState(false)

    useEffect(() => {
        setValue(Number(initialValues.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_CANTIDAD_TICKETS_X_PEDIDO)?.Valor || DEFAULT_VALUE_CANTIDAD_TICKETS_X_PEDIDO))
    }, [IdSucursal, initialValues])

    useEffect(() => {
        clearTimeout(showOKTimeOut)
        if (showOK) {
            setshowOKTimeOut(setTimeout(() => {
                setshowOK(false)
            }, 1500))
        }
    }, [showOK])


    function increase() {
        setValue(prevValue => {
            const nextValue = Math.min(LIMIT_VALUE, prevValue + SUM)

            if (nextValue !== prevValue) {
                handleOnClickGuardar(nextValue)
            }

            return nextValue
        })
    }

    function decline() {
        setValue(prevValue => {
            const nextValue = Math.max(MIN_VALUE, prevValue - SUM)

            if (nextValue !== prevValue) {
                handleOnClickGuardar(nextValue)
            }

            return nextValue
        })
    }

    async function handleOnClickGuardar(value) {

        settimeoutsaveSettingSucursal(prev => {
            clearTimeout(prev)

            return setTimeout(() => {
                setloading(true)
                saveSettingSucursal({
                    Valor: value,
                    IdTipoConfiguracion: ID_SUC_CANTIDAD_TICKETS_X_PEDIDO,
                }).then((result) => {
                    notification.success({
                        message: 'Configuración guardada',
                        key: ID_SUC_CANTIDAD_TICKETS_X_PEDIDO
                    })
                    setshowOK(true)
                }).finally(() => {
                    setloading(false)

                })
            }, timeoutMStoSave);
        })


    }

    return (
        <>
            <div style={{ textAlign: 'center' }}>
                {
                    showOK ?
                        <Progress type="circle" percent={100} /> :
                        loading ?
                            <Spin
                                tip={'Guardando...'}
                                indicator={<Icon type='loading' style={{ fontSize: 120 }} ></Icon>}
                            >
                            </Spin> :
                            <>
                                <Progress
                                    strokeColor={{
                                        '0%': 'blue',
                                        '100%': 'green',
                                    }}
                                    type="circle"
                                    percent={(value * 100) / LIMIT_VALUE}
                                    format={porc => `${value}`}
                                />
                                <br></br>
                                <Button.Group style={{ margin: '10px 0 0 0' }}>
                                    <Button onClick={decline} icon="minus" />
                                    <Button onClick={increase} icon="plus" />
                                </Button.Group>
                            </>

                }
            </div>
            {/* <Button type='primary' style={{ float: 'right' }} onClick={handleOnClickGuardar}>Guardar</Button> */}
        </>
    );

}