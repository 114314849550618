import { Button, CircularProgress, DialogActions, DialogContent, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LAMBDA_COMMON } from '../../config'
import { ID_PEDIDO_ESTADO_PENDIENTE } from '../../redux/ordenes/types'
import Axios from 'axios'
import { SocketContext } from '../../containers/Dashboard/Dashboard'
import { useSelector } from 'react-redux'

let motivos_init_state = []
const MIN_LENGTH_OBS = 20

export const RejectOrder = ({
    setSelectedMenu,
    order,
    setOpenActionOrder
}) => {
    const Auth = useSelector(state => state.Auth)
    const socket = useContext(SocketContext)
    const IdUsuario = Auth.auth.IdUsuario
    const [motivos, setmotivos] = useState(motivos_init_state)
    const [CodigoMotivo, setCodigoMotivo] = useState(null)
    const [MotivoTexto, setMotivoTexto] = useState('')
    const [submitting, setsubmitting] = useState(false)
    const [ObservacionComercio, setObservacionComercio] = useState('')
    const [ObservacionCliente, setObservacionCliente] = useState('')
    const [showConfirmation, setshowConfirmation] = useState(false)

    useEffect(() => {
        getAllReembolsoMotivo()
    }, [])

    const getAllReembolsoMotivo = useCallback(
        () => {
            Axios.get(`${LAMBDA_COMMON}/getAllReembolsoMotivo`)
                .then(response => {
                    setmotivos(response.data.motivos)
                    motivos_init_state = response.data.motivos
                }).catch(console.error);
        },
        []
    )

    const indexOfRiderGroup = motivos.findIndex(m => m.Codigo.startsWith('RIDER'))
    const indexOfClientGroup = motivos.findIndex(m => m.Codigo.startsWith('CLIENT'))
    const indexOfAgilGroup = motivos.findIndex(m => m.Codigo.startsWith('AGIL'))
    const indexOfOtherGroup = motivos.findIndex(m => m.Codigo.startsWith('OTHER'))
    const indexOfStoreGroup = motivos.findIndex(m => m.Codigo.startsWith('STORE'))

    const handleOnConfirm = useCallback(
        () => {
            setsubmitting(true)
            socket.emit("RefundOrder", {
                IdSucursal: order.IdSucursal,
                IdPedido: order.IdPedido,
                source: 'pos',
                CodigoMotivo,
                MotivoTexto,
                ObservacionComercio,
                ObservacionCliente,
                IdUsuario,
            }, (data) => {
                // setsubmitting(false)
                setshowConfirmation(false)
                setOpenActionOrder(false)
                setSelectedMenu(0)
            })
        },
        [
            socket,
            order,
            CodigoMotivo,
            MotivoTexto,
            ObservacionComercio,
            ObservacionCliente,
            IdUsuario,
        ],
    )


    return (
        <>
            <DialogContent>
                <p>
                    Al rechazar este pedido, se solicitará un reembolso a la plataforma <b>{order.clienteCompra ? order.clienteCompra.pagos[0].medioPago.MedioPago : ''}</b>.
                    <br />
                    <b>{order.clienteCompra ? order.clienteCompra.pagos[0].medioPago.MedioPago : ''}</b> gestionará los tiempos y procesos del reembolso.
                </p>

                <Typography variant='h6'
                    sx={{
                        marginBottom: 2,
                        marginTop: 3
                    }}
                >
                    Seleccione Motivo
                </Typography>

                <Select
                    // label='Motivo'
                    value={CodigoMotivo}
                    onChange={(e) => setCodigoMotivo(e.target.value)}
                    fullWidth
                    variant='outlined'
                >
                    {motivos.reduce((aux, motivo, i) => {
                        if (motivo.Codigo.startsWith('RIDER') && order.IdEstado === ID_PEDIDO_ESTADO_PENDIENTE) {
                            return aux
                        }

                        motivo.Codigo.startsWith('RIDER') && i === indexOfRiderGroup && aux.push(<MenuItem disabled value={i} >Rider</MenuItem >)
                        motivo.Codigo.startsWith('CLIENT') && i === indexOfClientGroup && aux.push(<MenuItem disabled value={i} >Cliente</MenuItem >)
                        motivo.Codigo.startsWith('AGIL') && i === indexOfAgilGroup && aux.push(<MenuItem disabled value={i} >Agil</MenuItem >)
                        motivo.Codigo.startsWith('OTHER') && i === indexOfOtherGroup && aux.push(<MenuItem disabled value={i} >Otro</MenuItem >)
                        motivo.Codigo.startsWith('STORE') && i === indexOfStoreGroup && aux.push(<MenuItem disabled value={i} >Sucursal</MenuItem >)
                        aux.push(<MenuItem value={motivo.Codigo}>{motivo.Nombre}</MenuItem >)
                        return aux
                    }, [])
                        .map(Option => Option)}
                </Select>
                {
                    CodigoMotivo === 'OTHER' && <>
                        <TextField
                            disabled={submitting}
                            value={MotivoTexto}
                            fullWidth
                            multiline
                            onChange={e => setMotivoTexto(e.target.value)}
                            placeholder='Motivo'
                            rows={3}
                            maxRows={3}
                            sx={{ marginTop: 4 }}
                        />
                        {
                            MotivoTexto.length < MIN_LENGTH_OBS &&
                            <span style={{ color: 'red' }}>{MIN_LENGTH_OBS} caracteres mínimos</span>
                        }
                    </>
                }


                <Typography variant='h6'
                    sx={{
                        marginTop: 3
                    }}
                >
                    Observación Comercio
                </Typography>
                <TextField
                    disabled={submitting}
                    value={ObservacionComercio}
                    fullWidth
                    multiline
                    onChange={e => setObservacionComercio(e.target.value)}
                    placeholder='Observación Comercio'
                    rows={3}
                    maxRows={3}
                // sx={{ marginTop: 5 }}
                />
                {
                    ObservacionComercio.length < MIN_LENGTH_OBS &&
                    <span style={{ color: 'red' }}>{MIN_LENGTH_OBS} caracteres mínimos</span>
                }
                <Typography variant='h6'
                    sx={{
                        marginTop: 3
                    }}
                >
                    Observación Cliente
                </Typography>
                <TextField
                    disabled={submitting}
                    value={ObservacionCliente}
                    onChange={e => setObservacionCliente(e.target.value)}
                    fullWidth
                    multiline
                    placeholder='Observación Cliente'
                    rows={3}
                    maxRows={3}
                // sx={{ marginTop: 5 }}
                />
                {
                    ObservacionCliente.length < MIN_LENGTH_OBS &&
                    <span style={{ color: 'red' }}>{MIN_LENGTH_OBS} caracteres mínimos</span>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedMenu(0)}>
                    Volver
                </Button>
                <Button
                    onClick={handleOnConfirm}
                    variant='contained'
                    endIcon={submitting && <CircularProgress size={14} />}
                    disabled={submitting || ObservacionComercio.length < MIN_LENGTH_OBS ||
                        ObservacionCliente.length < MIN_LENGTH_OBS ||
                        !CodigoMotivo ||
                        (CodigoMotivo === 'OTHER' && MotivoTexto.length < MIN_LENGTH_OBS)}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </>
    )
}