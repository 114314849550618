export const randomColor = () => {
    // Generar tres valores hexadecimales aleatorios para R, G y B
    const r = Math.floor(Math.random() * 256).toString(16)
    const g = Math.floor(Math.random() * 256).toString(16)
    const b = Math.floor(Math.random() * 256).toString(16)

    // Asegurarse de que cada componente tenga exactamente 2 caracteres
    const color = "#" + fillWithZeros(r) + fillWithZeros(g) + fillWithZeros(b)

    return color
}

const fillWithZeros = (val) => {
    return val.length === 1 ? "0" + val : val
}

export const disabledColor = '#ACACAC'