import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { Button, Modal, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { SocketContext } from '../../Dashboard/Dashboard'
import { useSelector } from 'react-redux'
import { validatePrivilegioRecurso } from '../../../library/helpers/validatePrivilegioRecurso'
import Axios from 'axios'
import { LAMBDA_COMMON } from '../../../config'
import { ID_PEDIDO_ESTADO_PENDIENTE } from '../../../redux/ordenes/types'

let motivos_init_state = []
const MIN_LENGTH_OBS = 20

const Component = ({ title = 'Rechazar', pedido }) => {
    const Auth = useSelector(state => state.Auth)


    // if (!Auth.auth.Email.endsWith('@getagil.com')) {
    //     return null
    // }

    const socket = useContext(SocketContext)
    const IdUsuario = Auth.auth.IdUsuario

    const [showConfirmation, setshowConfirmation] = useState(false)
    const [ObservacionComercio, setObservacionComercio] = useState('')
    const [ObservacionCliente, setObservacionCliente] = useState('')
    const [submitting, setsubmitting] = useState(false)

    const [motivos, setmotivos] = useState(motivos_init_state)
    const [CodigoMotivo, setCodigoMotivo] = useState()
    const [MotivoTexto, setMotivoTexto] = useState('')

    useEffect(() => {
        setshowConfirmation(false)
        setsubmitting(false)
    }, [pedido.IdPedido])

    useEffect(() => {
        if (CodigoMotivo === 'OTHER') {
            setMotivoTexto('')
        } else {
            setMotivoTexto(motivos.find(m => m.Codigo === CodigoMotivo)?.Nombre)
        }
    }, [CodigoMotivo])


    const handleOnClick = useCallback(
        () => {
            if (!motivos.length) {
                getAllReembolsoMotivo()
            }
            setshowConfirmation(true)
            setsubmitting(false)
            setObservacionComercio('')
            setObservacionCliente('')
            setCodigoMotivo()
            setMotivoTexto('')
        },
        [motivos],
    )

    const handleOnConfirm = useCallback(
        () => {
            setsubmitting(true)
            socket.emit("RefundOrder", {
                IdSucursal: pedido.IdSucursal,
                IdPedido: pedido.IdPedido,
                source: 'pos',
                CodigoMotivo,
                MotivoTexto,
                ObservacionComercio,
                ObservacionCliente,
                IdUsuario,
            }, (data) => {
                // setsubmitting(false)
                setshowConfirmation(false)
            })
        },
        [
            socket,
            pedido,
            CodigoMotivo,
            MotivoTexto,
            ObservacionComercio,
            ObservacionCliente,
            IdUsuario,
        ],
    )

    const getAllReembolsoMotivo = useCallback(
        () => {
            Axios.get(`${LAMBDA_COMMON}/getAllReembolsoMotivo`)
                .then(response => {
                    setmotivos(response.data.motivos)
                    motivos_init_state = response.data.motivos
                }).catch(console.error);
        },
        []
    )

    const indexOfRiderGroup = motivos.findIndex(m => m.Codigo.startsWith('RIDER'))
    const indexOfClientGroup = motivos.findIndex(m => m.Codigo.startsWith('CLIENT'))
    const indexOfAgilGroup = motivos.findIndex(m => m.Codigo.startsWith('AGIL'))
    const indexOfOtherGroup = motivos.findIndex(m => m.Codigo.startsWith('OTHER'))
    const indexOfStoreGroup = motivos.findIndex(m => m.Codigo.startsWith('STORE'))

    if (!validatePrivilegioRecurso(Auth, 'POS_REJECT_ORDER')) {
        return null
    }

    return <>
        <Button
            style={{ marginLeft: 10 }}
            type='danger'
            onClick={handleOnClick}
            loading={submitting}
        >
            {title}
        </Button>
        <Modal
            visible={showConfirmation}
            title={`Confirmación rechazo/reembolso pedido #${pedido.Codigo}${pedido.Corelativo > 0 ? `-${pedido.Corelativo}` : ''}`}
            cancelText='Cancelar'
            okText='Confirmar'
            onCancel={() => setshowConfirmation(false)}
            onOk={handleOnConfirm}
            confirmLoading={submitting}
            destroyOnClose
            okButtonProps={{
                loading: submitting,
                disabled:
                    ObservacionComercio.length < MIN_LENGTH_OBS ||
                    ObservacionCliente.length < MIN_LENGTH_OBS ||
                    !CodigoMotivo ||
                    (CodigoMotivo === 'OTHER' && MotivoTexto.length < MIN_LENGTH_OBS)

            }}
        >
            <p>
                Al rechazar este pedido, se solicitará un reembolso a la plataforma <b>{pedido.clienteCompra ? pedido.clienteCompra.pagos[0].medioPago.MedioPago : ''}</b>.
                <br />
                <b>{pedido.clienteCompra ? pedido.clienteCompra.pagos[0].medioPago.MedioPago : ''}</b> gestionará los tiempos y procesos del reembolso.
            </p>

            <Select
                disabled={submitting}
                placeholder='Seleccione Motivo'
                value={CodigoMotivo}
                onChange={setCodigoMotivo}
                style={{ marginTop: 10, width: '100%' }}
            >
                {
                    motivos
                        .reduce((aux, motivo, i) => {
                            if (motivo.Codigo.startsWith('RIDER') && pedido.IdEstado === ID_PEDIDO_ESTADO_PENDIENTE) {
                                return aux
                            }

                            motivo.Codigo.startsWith('RIDER') && i === indexOfRiderGroup && aux.push(<Select.Option disabled value={i} >Rider</Select.Option>)
                            motivo.Codigo.startsWith('CLIENT') && i === indexOfClientGroup && aux.push(<Select.Option disabled value={i} >Cliente</Select.Option>)
                            motivo.Codigo.startsWith('AGIL') && i === indexOfAgilGroup && aux.push(<Select.Option disabled value={i} >Agil</Select.Option>)
                            motivo.Codigo.startsWith('OTHER') && i === indexOfOtherGroup && aux.push(<Select.Option disabled value={i} >Otro</Select.Option>)
                            motivo.Codigo.startsWith('STORE') && i === indexOfStoreGroup && aux.push(<Select.Option disabled value={i} >Sucursal</Select.Option>)
                            aux.push(<Select.Option value={motivo.Codigo}>{motivo.Nombre}</Select.Option>)
                            return aux
                        }, [])
                        .map(Option => Option)
                }
            </Select>
            {
                CodigoMotivo === 'OTHER' && <>
                    <TextArea
                        disabled={submitting}
                        value={MotivoTexto}
                        onChange={e => setMotivoTexto(e.currentTarget.value)}
                        placeholder='Motivo'
                        rows={3}
                        style={{ marginTop: 10 }}
                    />
                    {
                        MotivoTexto.length < MIN_LENGTH_OBS &&
                        <span style={{ color: 'red' }}>{MIN_LENGTH_OBS} caracteres mínimos</span>
                    }
                </>
            }

            <TextArea
                disabled={submitting}
                value={ObservacionComercio}
                onChange={e => setObservacionComercio(e.currentTarget.value)}
                placeholder='Observación Comercio'
                rows={3}
                style={{ marginTop: 10 }}
            />
            {
                ObservacionComercio.length < MIN_LENGTH_OBS &&
                <span style={{ color: 'red' }}>{MIN_LENGTH_OBS} caracteres mínimos</span>
            }
            <TextArea
                disabled={submitting}
                value={ObservacionCliente}
                onChange={e => setObservacionCliente(e.currentTarget.value)}
                placeholder='Observación Cliente'
                rows={3}
                style={{ marginTop: 10 }}
            />
            {
                ObservacionCliente.length < MIN_LENGTH_OBS &&
                <span style={{ color: 'red' }}>{MIN_LENGTH_OBS} caracteres mínimos</span>
            }

        </Modal>
    </>
}

export default React.memo(Component)