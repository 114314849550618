import React from 'react';
import ImageCellView from './ImageCell';
import DeleteCell from './DeleteCell';
import EditableCell from './EditableCell';
import FilterDropdown from './FilterDropdown';

const DateCell = data => <p>{data.toLocaleString()}</p>;
const ImageCell = src => <ImageCellView src={src} />;
const ComponentCell = (src, txt1, txt2) => {
  return(
    <>
    <ImageCellView src={src} />
      <p>
        {txt1}
      </p>
      <p style={{color: '#6295eb'}}>{txt2}</p>
    </>
  )
};
const LinkCell = (link, href) => <a href={href ? href : '#'}>{link}</a>;
const TextCell = text =>{
  if(text!==undefined){
       var split = String(text).split("\n");

      if(split.length>1){
    
        return(
        
          split.map((e,i) =>{
           return(          
            renderText(e,i)
          )}
          )

          )

      }else{
        return(
          <p>{text}</p>
          )
      }

    }

  };

  const renderText = (text,i) => {
    var fecha = new Date();
    return(
      <p key={i+'-'+fecha.getMilliseconds()} style={  i>0 ? {color: '#6295eb'} : {}} >{text}</p>
      )
  }

export {
  DateCell,
  ImageCell,
  ComponentCell,
  LinkCell,
  TextCell,
  EditableCell,
  DeleteCell,
  FilterDropdown,
};
