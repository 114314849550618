import React, { memo, useEffect, useRef, useState } from "react"
import { MapContainer, TileLayer, Marker, Tooltip, Circle, Polygon, FeatureGroup } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import Leaflet from 'leaflet'
import { EditControl } from "react-leaflet-draw"
import {
    IMG_AGIL_MARKER_DEFAULT,
    IMG_AGIL_MARKER_HOME,
    IMG_AGIL_MARKER_RIDER,
    IMG_AGIL_MARKER_SUCURSAL,
    IMG_AGIL_MARKER_RIDER_BIKE,
    IMG_AGIL_MARKER_RIDER_BIKE_GREY,
} from "../../../constants/img/agil"
import { randomColor } from '../../../helpers/utils'
import { MAPBOX_TOKEN } from "../../../config"
import { Box } from "@mui/material"

const TILE_MAP_OSM = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const TILE_MAP_STREETS_V9 = `https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`
const TILE_MAP_STREETS_V12 = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`
const TILE_MAP_SATELLITE_V9 = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`
const TILE_MAP_DARK = `https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`
const TILE_MAP_NIGHT = `https://api.mapbox.com/styles/v1/mapbox/navigation-night-v1/tiles/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`

const OpenStreetMapImage = ({
    width,
    height,
    locations = [],
    area = [],
    zoom = 12,
    scrollWheelZoom = true,
    zoomControl = true,
    dragging = false,
    tileMap = 'STREETS_V9',
    available = true,
    sizeIcon = 35,
    adjustable = false,
    isEditable = false,
    setNewCoordinates = null,
}) => {
    const mapRef = useRef(null);
    const [isAjustable, setIsAjustable] = useState(false)

    const iconDefault = Leaflet.icon({
        iconUrl: IMG_AGIL_MARKER_DEFAULT,
        iconSize: [sizeIcon, sizeIcon],
    })

    const iconHome = Leaflet.icon({
        iconUrl: IMG_AGIL_MARKER_HOME,
        iconSize: [sizeIcon, sizeIcon],
    })

    const iconStore = Leaflet.icon({
        iconUrl: IMG_AGIL_MARKER_SUCURSAL,
        iconSize: [sizeIcon, sizeIcon],
    })

    const iconRider = Leaflet.icon({
        iconUrl: IMG_AGIL_MARKER_RIDER,
        iconSize: [sizeIcon, sizeIcon],
    })

    const iconRiderBike = Leaflet.icon({
        iconUrl: IMG_AGIL_MARKER_RIDER_BIKE,
        iconSize: [sizeIcon, sizeIcon],
    })

    const iconRiderBikeGrey = Leaflet.icon({
        iconUrl: IMG_AGIL_MARKER_RIDER_BIKE_GREY,
        iconSize: [sizeIcon, sizeIcon],
    })

    const getAutomaticValues = () => {
        let lat = -33.44195169598986
        let lng = -70.64352547726632

        if (locations.length === 1) {
            lat = parseFloat(locations[0]?.positions[0])
            lng = parseFloat(locations[0]?.positions[1])
        }

        if (locations.length === 2) {
            lat = ((parseFloat(locations[0]?.positions[0]) + parseFloat(locations[1]?.positions[0])) / 2)
            lng = ((parseFloat(locations[0]?.positions[1]) + parseFloat(locations[1]?.positions[1])) / 2)
        }

        if (locations.length >= 3) {
            const rider = locations.find((e) => e?.icon === 'RIDER')
            const home = locations.find((e) => e?.icon === 'HOME')

            if (rider && home) {
                lat = ((parseFloat(rider?.positions[0]) + parseFloat(home?.positions[0])) / 2)
                lng = ((parseFloat(rider?.positions[1]) + parseFloat(home?.positions[1])) / 2)
            }
        }

        return [lat, lng]
    }

    const getTileMap = () => {
        switch (tileMap) {
            case 'STREETS_V9':
                return TILE_MAP_STREETS_V9
                break
            case 'STREETS_V12':
                return TILE_MAP_STREETS_V12
                break
            case 'SATELLITE_V9':
                return TILE_MAP_SATELLITE_V9
                break
            case 'DARK':
                return TILE_MAP_DARK
                break
            case 'NIGHT':
                return TILE_MAP_NIGHT
                break
            default:
                return TILE_MAP_OSM
                break
        }
    }

    const getIcon = (e) => {
        switch (e?.icon) {
            case 'HOME':
                return iconHome
                break
            case 'STORE':
                return iconStore
                break
            case 'RIDER':
                return iconRider
                break
            case 'RIDER_BIKE':
                return iconRiderBike
                break
            case 'RIDER_BIKE_GREY':
                return iconRiderBikeGrey
                break
            default:
                return iconDefault
                break
        }
    }

    const convertToPixels = (value) => {
        try {
            const unitRegex = /(\d*\.?\d*)(\w+)/
            const [, quantity, unit] = value.match(unitRegex)

            const unitsInPixels = {
                'vw': window.innerWidth / 100,
                'vh': window.innerHeight / 100,
            }

            return Math.round(parseFloat(quantity) * unitsInPixels[unit])
        } catch (err) {
            return 0
        }
    }

    const getCoordinates = (type, e) => {
        let coordinates = []
        try {
            if (type === 'CREATED') {
                coordinates = e?.layer?._latlngs[0]
            } else {
                const key = Object.keys(e?.layers?._layers)
                coordinates = e?.layers?._layers[key[0]]?._latlngs[0]
            }

            if (setNewCoordinates) {
                const newCoordinates = coordinates.map((e, i) => [e?.lat, e?.lng])
                setNewCoordinates(newCoordinates)
            }
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <>
            <Box
                sx={{
                    position: 'relative'
                }}
            >
                <MapContainer
                    zoomControl={zoomControl}
                    keyboard={zoomControl}
                    touchZoom={zoomControl}
                    boxZoom={zoomControl}
                    trackResize={zoomControl}
                    doubleClickZoom={zoomControl}
                    attributionControl={zoomControl}
                    dragging={dragging}
                    scrollWheelZoom={scrollWheelZoom}
                    center={getAutomaticValues()}
                    zoom={15}
                    ref={mapRef}
                    style={{
                        height: (height.toString().indexOf('vh') >= 0) ? convertToPixels(height) : height,
                        width: (width.toString().indexOf('vw') >= 0) ? convertToPixels(width) : width,
                    }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url={getTileMap()}
                    />
                    <FeatureGroup>
                        {
                            (isEditable) && (
                                <EditControl
                                    position='topleft'
                                    onEdited={(e) => getCoordinates('EDITED', e)}
                                    onCreated={(e) => getCoordinates('CREATED', e)}
                                    draw={{
                                        rectangle: false,
                                        marker: false,
                                        circlemarker: false,
                                        polyline: false,
                                        circle: false,
                                    }}
                                />
                            )
                        }

                        {
                            area.map((e, i) => {
                                return (
                                    (e?.type === 'Circle') ?
                                        <Circle
                                            key={i}
                                            center={e?.positions[0]}
                                            pathOptions={{ fillColor: (e?.color) ? e?.color : randomColor() }}
                                            radius={e?.radius}
                                            eventHandlers={{
                                                click: (event) => {
                                                    if (e?.click && e?.data) {
                                                        e.click(e?.data)
                                                    }
                                                },
                                            }}
                                        />
                                        :
                                        <Polygon
                                            key={i}
                                            pathOptions={{ color: (e?.color) ? e?.color : randomColor() }}
                                            positions={e?.positions}
                                            eventHandlers={{
                                                click: (event) => {
                                                    if (e?.click && e?.data) {
                                                        e.click(e?.data)
                                                    }
                                                },
                                            }}
                                        />
                                )
                            })
                        }
                    </FeatureGroup>
                    {
                        locations.map((e, i) => {
                            return (
                                <Marker
                                    key={i}
                                    position={[parseFloat(e?.positions[0]), parseFloat(e?.positions[1])]}
                                    icon={getIcon(e)}
                                    draggable={(e?.isDraggable || isAjustable) ? true : false}
                                    eventHandlers={{
                                        dragend: (event) => {
                                        },
                                    }}
                                >
                                    {
                                        (e?.text) && (
                                            <Tooltip
                                                direction="bottom"
                                                offset={[0, 20]}
                                                opacity={1}
                                                permanent={e?.textPermanent}
                                            >
                                                {e?.text}
                                            </Tooltip>
                                        )
                                    }
                                </Marker>
                            )
                        })
                    }
                </MapContainer>
            </Box>
        </>
    )
}

export default memo(OpenStreetMapImage)