import React, { useEffect, useState } from 'react'
import { Divider, Alert, Icon, Tag } from 'antd'
import Text from 'antd/lib/typography/Text'
import {
    ID_PED_PAG_ESTA_ACTIVO,
    ID_PED_PAG_ESTA_REEMBOLSADO,
    ID_TIPO_PAGO_NO_ESPECIFICADO,
    ID_PEDIDO_ESTADO_RECHAZADO,
    ID_PED_PAG_ESTA_PENDIENTE_PAGO
} from '../../../redux/ordenes/types'

const height = 40

const Component = ({ order, onPaymentWaitingRefund }) => {

    useEffect(() => {
        if (onPaymentWaitingRefund) {
            if (order.IdEstado === ID_PEDIDO_ESTADO_RECHAZADO) {
                onPaymentWaitingRefund(order.clienteCompra?.pagos.some(pago => !pago.IdEstado === ID_PED_PAG_ESTA_REEMBOLSADO))
            } else {
                onPaymentWaitingRefund(false)
            }
        }
    }, [order])

    return (order.clienteCompra ? order.clienteCompra.pagos : []).map(pago =>
        <PaymentMemo key={pago.IdPublicoPago} order={order} pago={pago} />
    )
}


const Payment = ({ order, pago }) => {
    const [paid, setpaid] = useState(false)
    const [refunded, setrefunded] = useState(false)
    const [waitrefund, setwaitrefund] = useState(false)

    useEffect(() => {
        const refundedNext = pago.IdEstado === ID_PED_PAG_ESTA_REEMBOLSADO
        setwaitrefund(order.IdEstado === ID_PEDIDO_ESTADO_RECHAZADO && !refundedNext)
        setrefunded(refundedNext)
        setpaid(pago.IdEstado === ID_PED_PAG_ESTA_ACTIVO || refundedNext)
    }, [order])

    return <>
        <div
            style={{
                // zIndex: -1,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: '100%',
                height,
                opacity: '0.07',
                backgroundSize: '10% auto',
                // backgroundSize: '10vw auto',
                backgroundImage: `url(${pago.medioPago.Logo})`,
            }}
        />
        <div
            style={{
                height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

            }}
        >


            <div style={{ textAlign: 'center', height: '100%' }}>
                <img src={pago.medioPago.Logo} style={{ height: '100%' }} />
            </ div>
            <Divider type='vertical' />
            <div>
                {
                    paid ?
                        <>
                            <Text strong>
                                {
                                    pago.IdTipoPago !== ID_TIPO_PAGO_NO_ESPECIFICADO && <>
                                        {pago.tipoPago.TipoPago}
                                        <br />
                                    </>
                                }
                                {pago.Tarjeta}
                            </Text>
                        </>
                        :
                        (order.clienteCompra.pagos[0].IdEstado === ID_PED_PAG_ESTA_PENDIENTE_PAGO) ?
                            <>
                                <Tag color="volcano" style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, fontSize: 16 }}>
                                    <Icon type="credit-card" theme="filled" />
                                    &nbsp; Pendiente de pago
                                </Tag>
                            </>
                            :
                            <Alert
                                // style={{ width: '100%' }}
                                message={"Errores al procesar el pago"}
                                // description="Errores al procesar el pago"
                                type="error"
                                showIcon
                            />
                }
            </div>
            <Divider type='vertical' />

            {
                paid && waitrefund &&
                <Alert
                    // style={{ width: '100%' }}
                    message="Esperando reembolso"
                    // description="Errores al procesar el pago"
                    type="warning"
                    showIcon
                />
            }
            {
                paid && refunded &&
                <Alert
                    // style={{ width: '100%' }}
                    message="Reembolsado"
                    // description="Errores al procesar el pago"
                    type="success"
                    showIcon
                />
            }
        </div>
    </>
}
const PaymentMemo = React.memo(Payment)


export default React.memo(Component)