import React from 'react'
import { Col, Row, Tag } from 'antd'
import TrackingOrderButton from '../Actions/TrackingOrderButton'
import { ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY, ID_PEDIDO_ESTADO_ENTREGADO, ID_PEDIDO_ESTADO_EN_CAMINO, ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL } from '../../../redux/ordenes/types'
import { fixTelefono } from '../../../helpers/fixes'
import PedidoEstadoTag from '../Tags/PedidoEstado.Tag'

export function getDistanceText(meters) {
    meters = meters || 0
    return meters < 1000 ? `${meters}mts` : `${(meters / 1000).toFixed()}km`
}

export default ({ order }) => {
    return (<>
        {
            [order, ...order.pedidos].map((o, i) =>
                <RiderItem
                    key={i}
                    order={o}
                ></RiderItem>
            )
        }
    </>)
}

const RiderItem = ({ order }) => <>
    <Row>
        <Col span={6}><b>{order.entrega.LLAVE}</b></Col>
        <Col span={6}>
            {
                order.IdEstado === ID_PEDIDO_ESTADO_EN_CAMINO ?
                    <PedidoEstadoTag pedido={order}>
                        {
                            order.mapsTracking?.coors?.riderUbicacion?.distances?.sucursal && <>
                                &nbsp; {getDistanceText(order.mapsTracking.coors.riderUbicacion.distances.sucursal.meters)}
                            </>
                        }
                    </PedidoEstadoTag> :
                    order.IdEstado === ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL ?
                        <PedidoEstadoTag pedido={order}>
                            {
                                order.mapsTracking?.coors?.riderUbicacion?.distances?.cliente && <>
                                    &nbsp; {getDistanceText(order.mapsTracking.coors.riderUbicacion.distances.cliente.meters)}
                                </>
                            }
                        </PedidoEstadoTag> :
                        <PedidoEstadoTag pedido={order} />
            }


        </Col>
        <Col span={8}>
            {
                order.entrega.rider && <>
                    {order.entrega.rider.nombre} {order.entrega.rider.apellido}
                    <br />
                    {
                        order.entrega.rider.dispositivos[0].numTelefono ?
                            fixTelefono(order.entrega.rider.nacionalidad, order.entrega.rider.dispositivos[0].numTelefono) :
                            null
                    }
                </>
            }
        </Col>
        <Col span={4}>
            <div style={{ display: 'flex' }}>
                <TrackingOrderButton order={order} iconSize={1.6}></TrackingOrderButton>
            </div>
        </Col>
    </Row >

</>