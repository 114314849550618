
import React, { useState, useEffect, useContext } from 'react';
import { Select, notification } from 'antd';
import { settingContext } from '../'

import { ID_SUC_TIEMPO_COCINA, DEFAULT_VALUE_TIEMPO_COCINA } from "@iso/redux/settings/types"
import Axios from 'axios';
import { LAMBDA_AGIL } from '../../../config';

const { Option } = Select;

const options = [
    { value: '1-11', text: 'Hasta 10 minutos' },
    { value: '10-20', text: '10-20 minutos' },
    { value: '20-30', text: '20-30 minutos' },
    { value: '30-40', text: '30-40 minutos' },
    { value: '40-50', text: '40-50 minutos' },
    { value: '50-60', text: '50-60 minutos' },
    { value: '60-70', text: '60-70 minutos' },
    { value: '70-80', text: '70-80 minutos' }
]

export default () => {
    const { IdSucursal, initialValues, saveSettingSucursal } = useContext(settingContext)
    const [selectedOption, setselectedOption] = useState(DEFAULT_VALUE_TIEMPO_COCINA)
    const [loading, setloading] = useState(false)

    useEffect(() => {
        setselectedOption(initialValues.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_TIEMPO_COCINA)?.Valor || DEFAULT_VALUE_TIEMPO_COCINA)
    }, [IdSucursal, initialValues])

    const handleChange = (value) => {
        setloading(true)


        saveSettingSucursal({
            Valor: value,
            IdTipoConfiguracion: ID_SUC_TIEMPO_COCINA,
        }).then(async (result) => {
            setselectedOption(value)
            notification.success({
                message: 'Tiempo de Cocina guardado',
                key: ID_SUC_TIEMPO_COCINA
            })
            try {
                console.log(selectedOption)
                let cookingTime = value.split('-')
                cookingTime = parseInt(cookingTime[1]) * 60

                const { data } = await Axios.put(`${LAMBDA_AGIL}/api/oms/store/cookingTime`, {
                    IdSucursal,
                    cookingTime: cookingTime
                })

                console.log(data)
            } catch (err) {
                console.error(err)
            }
        }).finally(() => {
            setloading(false)
        })
    }

    return (
        <>
            <Select loading={loading} defaultValue={selectedOption} value={selectedOption} style={{ width: 200 }} onChange={handleChange} disabled>
                {
                    options.map((option, i) => <Option key={i} value={option.value}>{option.text}</Option>)
                }
            </Select>
        </>
    );

}