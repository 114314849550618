import actions from './actions';

export default function stockProductosReducer(state = { products: [] }, action) {
    switch (action.type) {
        case actions.ADD_IF_NOT_EXISTS_PRODUCT:
            var exists = state.products.findIndex(e => e?.Id === action.payload.data?.Id)
            if (exists !== undefined && exists !== -1) {
                state.products = state.products.filter(e => e?.Id !== action.payload.data?.Id)
            } else {
                state.products.push(action.payload.data)
            }
            return {
                ...state
            }
        case actions.CLEAN_DATA:
            state.products = []
            return {
                ...state
            }
        default:
            return {
                ...state
            }
    }
}

