import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Drawer, Icon, notification, Spin, Timeline } from 'antd'
import Axios from 'axios'
import { LAMBDA_POS } from '../../../config'
import { useDispatch, useSelector } from 'react-redux'


import orderActions from "../../../redux/ordenes/actions"

const {
    loadPedidoSeguimientos
} = orderActions

const iconColor = '#1890ff'
const iconSize = 2.5



const IconHistory = ({ size = iconSize, ...props }) =>
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={`${size}em`} height={`${size}em`} fill={iconColor} className="bi bi-clock-history" viewBox="0 0 16 16">
        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
    </svg>

export default function ({ order }) {
    const [DrawerData, setDrawerData] = useState({
        visible: false,
        title: `Historia Pedido`,
        Content: () => <></>
    })

    useEffect(() => {
        setDrawerData({
            visible: false,
            title: `Historia Pedido #${order.Codigo}-${order.Corelativo}`,
            Content: () => <></>
        })
    }, [order?.Codigo])

    return (
        <>
            <IconHistory
                onClick={() => setDrawerData(prev => ({
                    ...prev,
                    visible: true,
                    Content: () =>
                        <PedidoSeguimientosInfo
                            order={order}
                            onClose={() => setDrawerData(prev => ({ ...prev, visible: false }))}
                        />

                }))}
                style={{ cursor: 'pointer' }}
            />
            <Drawer
                title={DrawerData.title}
                placement="right"
                closable={true}
                onClose={() => setDrawerData(prev => ({ ...prev, visible: false }))}
                visible={DrawerData.visible}
                width={500}
                destroyOnClose
            // bodyStyle={{ padding: '0' }}
            // headerStyle={{ display: 'none' }}
            >
                <DrawerData.Content />
            </Drawer>
        </>
    )
}

const PedidoSeguimientosInfo = ({ order, onClose }) => {
    const Auth = useSelector(state => state.Auth)
    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)
    const [seguimientos, setseguimientos] = useState([])

    useEffect(() => {
        getPedidoSeguimientos()
    }, [])

    useEffect(() => {
        if (order.seguimientos) {
            setseguimientos(order.seguimientos)
        } else {
            setloading(true)
        }
    }, [order.seguimientos])

    function getPedidoSeguimientos() {
        Axios.get(`${LAMBDA_POS}/getPedidoSeguimientos/${order.IdSucursal}/${order.Codigo}`)
            .then(response => {
                dispatch(loadPedidoSeguimientos({
                    pedido: { Codigo: order.Codigo, IdSucursal: order.IdSucursal },
                    seguimientos: response.data.seguimientos
                }))

            }).catch((err) => {
                notification.warning({ message: err?.response?.data?.message || 'No es posible obtener esta información en estos momentos' })
                onClose()
            }).finally(() => {
                setloading(false)
            })
    }

    if (loading) {
        return <Spin indicator={<Icon type="loading" style={{ fontSize: '2.5em' }} />} />
    }
    return <>
        <Timeline mode='alternate'>
            {
                seguimientos.map(seguimiento => {
                    const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona
                    const Fecha = moment.utc(seguimiento.Fecha).tz(tz)

                    return (
                        <Timeline.Item key={seguimiento.Fecha} >
                            <p>
                                {Fecha.format('YYYY-MM-DD HH:mm:ss')}
                                <br />
                                <span style={{ fontSize: 13.5 }}>
                                    <b>

                                        {seguimiento.tipo.Descripcion}
                                    </b>
                                </span>
                                {
                                    seguimiento.usuario &&
                                    <>
                                        <br />
                                        <small>Cajero {seguimiento.usuario.Nombre} {seguimiento.usuario.Apellido} </small>
                                    </>
                                }
                                {
                                    seguimiento.rider &&
                                    <>
                                        <br />
                                        <small>Rider {seguimiento.rider.nombre} {seguimiento.rider.apellido} </small>
                                    </>
                                }
                            </p>
                        </Timeline.Item>
                    )
                })
            }
        </Timeline>
    </>
}