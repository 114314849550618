import { Button, Icon, Input, Modal, Tooltip, notification } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import NearRiders from '../../../components/Riders/NearRiders'
import { SocketContext } from '../../Dashboard/Dashboard'

export default ({ order }) => {
    const Auth = useSelector(state => state.Auth)
    const socket = useContext(SocketContext)

    const [modalVisible, setmodalVisible] = useState(false)
    const [modalOkText, setmodalOkText] = useState('Solicitar Delivery')
    const refIdPublicoRider = useRef()
    const [loading, setloading] = useState(false)
    const [IdPublicoRider, setIdPublicoRider] = useState('')

    useEffect(() => {
        setmodalVisible(false)
    }, [order.IdPedido])

    useEffect(() => {
        setloading(false)

        if (modalVisible) {
            setIdPublicoRider('')
            setTimeout(() => {
                refIdPublicoRider && refIdPublicoRider.current && refIdPublicoRider.current.focus()
            }, 500);
        }
    }, [modalVisible])

    useEffect(() => {
        setmodalOkText(loading ? 'Validando...' : 'Solicitar Delivery AHORA')

        setTimeout(() => {
            refIdPublicoRider && refIdPublicoRider.current && refIdPublicoRider.current.focus()
        }, 500);
    }, [loading])


    function handleSolicitarDelivery() {
        setloading(true)

        socket.emit('ValidarRiderEscuchando', {
            usuarioEmail: Auth.auth.Email,
            IdPublicoRider
        }, ({ message, success, IdRider: IdRiderPreasignado }) => {
            if (success) {
                notification.success({ message })
                // notification.success({ message: 'Solicitando Delivery...' })

                socket.emit("SolicitarDelivery", {
                    IdPedido: order.IdPedido,
                    IdSucursal: order.IdSucursal,
                    usuarioEmail: Auth.auth.Email,
                    IdRiderPreasignado,
                    tipoSolicitud: 'MANUAL'
                }, ({ success, message = '' }) => {
                    if (success) {
                        notification.success({ message: `Delivery Solicitado a Rider ${IdPublicoRider}` })
                        setmodalVisible(false)
                    } else {
                        notification.error({ message: message || 'El Pedido no puede pasar a Delivery' })
                        setloading(false)
                    }
                })
            } else {
                setloading(false)

                notification.warning({ message: `${IdPublicoRider} ${message}`, })
            }
        })
    }

    return (
        <>
            
            <Tooltip title={order?.courier?.IdTipoCourier ? 'Rider ya asignado en camino':'Priorizar Rider'}>

             <Button style={{ height: '36px' }} onClick={() => setmodalVisible(true)} disabled={order?.courier?.IdTipoCourier ? true: false}>
                 Priorizar Rider
             </Button>
            </Tooltip>
            
            <Modal
                title={`${order.Codigo}-${order.Corelativo} | ¿Priorizar rider ahora?`}
                visible={modalVisible}
                okText={modalOkText}
                cancelText='Cancelar'
                onOk={handleSolicitarDelivery}
                onCancel={() => setmodalVisible(false)}
            >
                <h4 style={{textAlign:'center'}}> {`Al priorizar rider manualmente el delivery llegará lo antes posible sin esperar la hora acordada de salida de cocina (${order.HoraDespachoCocina})`}</h4><br />
                <p></p>
                <NearRiders
                    onClickRider={rider => setIdPublicoRider(rider.idPublico)}
                    style={{ marginBottom: 20 }}
                />
                {/* <NearRiders /> */}
                <Input
                    ref={refIdPublicoRider}
                    placeholder='Ingrese Codigo del Rider'
                    disabled={loading}
                    prefix={loading && <Icon type="loading" />}
                    onPressEnter={handleSolicitarDelivery}
                    value={IdPublicoRider}
                    onChange={e => setIdPublicoRider(e.target.value)}
                />
                <p>
                    <br />
                    <br />
                    <small>
                        El Rider No debe tener pedidos pendientes en diferente sucursal.
                    </small>
                    <br />
                    <small>
                        Se esperará 60 segundos que el Rider acepte el pedido. de lo contrario se solicitará masivamente.
                    </small>
                </p>

            </Modal>
        </>
    )
}