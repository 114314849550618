const optionsMarket = [
    {
        key: 'orders',
        label: 'Nuevas',
        leftIcon: 'ion-android-menu',
    },
    {
        key: 'activeorders',
        label: 'Preparación',
        leftIcon: 'ion-android-menu',
    },
    {
        key: 'readyorders',
        label: 'Despachar',
        leftIcon: 'ion-android-menu',
    },
    {
        key: 'endorders',
        label: 'Entregadas',
        leftIcon: 'ion-android-menu',
    },
    {
        key: 'rejectedOrders',
        label: 'Rechazadas',
        leftIcon: 'ion-android-menu',
    },
    {
        key: 'scheduledOrders',
        label: 'Programadas',
        leftIcon: 'ion-android-menu',
        toBottom: true
    },
    {
        key: 'history',
        label: 'Histórico',
        leftIcon: 'ion-android-menu',
        toBottom: true
    },
    {
        key: 'time',
        label: 'Avance',
        leftIcon: 'ion-android-alarm-clock',
        toBottom: true
    },
    {
        key: 'products',
        label: 'Productos',
        leftIcon: 'ion-android-menu',
        toBottom: true,
        isNew: true
    },
    {
        key: 'settings',
        label: 'Configuración',
        leftIcon: 'ion-android-options',
        toBottom: true
    },

];
export default optionsMarket;