import { AccessTime, Close, EditCalendar, Event } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, Paper, TextField, Typography, useTheme } from '@mui/material'
import Axios from 'axios'
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { LAMBDA_SHOP } from '../../config'
import moment from 'moment'
import { capitalize } from 'lodash'
import { SocketContext } from '../../containers/Dashboard/Dashboard'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'

const MIN_LENGTH_OBS = 20
export const ChangeSchedule = ({ order, setSelectedMenu }) => {
    const Auth = useSelector(state => state.Auth)
    const socket = useContext(SocketContext)
    const IdUsuario = Auth.auth.IdUsuario
    const theme = useTheme()
    const [availableScheduledHours, setAvailableScheduledHours] = useState([])
    const [selectedTime, setSelectedTime] = useState([]);
    const [daySelected, setDatSelected] = useState(null)
    const [timeSelected, setTimeSelected] = useState(null)
    const [motivos, setMotivos] = useState('')
    const [submitting, setsubmitting] = useState(false)
    const [isOpen, setIsOpen] = useState(null)
    const [deliverySoon, setDeliverySoon] = useState(null)
    const [loadingTime, setLoadingTime] = useState(false)
    const [tipoEntrega, setTipoEntrega] = useState(null)

    useEffect(() => {
        getHorarioSucursal()
    }, [order])
    const getHorarioSucursal = async () => {
        setLoadingTime(true)
        try {
            let list = []
            const { data } = await Axios.get(`${LAMBDA_SHOP}/getSucursalHorarios/${order.IdSucursal}`)
            setIsOpen(data?.isOpen)
            if (!data?.isOpen) {
                setTipoEntrega(3)
            }
            if (data.availableScheduledHours.length > 0) {
                list = Array.from({ length: 5 }, (e, index) => {
                    let hora = data?.availableScheduledHours[index].hours.map((date) => {
                        let hourFormat = date.hour.toString().padStart(4, '0')
                        hourFormat = `${hourFormat.substring(0, 2)}:${hourFormat.substring(2, 4)}`
                        return { label: hourFormat, value: hourFormat, disabled: date.current >= date.limit }
                    })

                    return { value: index + 1, label: index === 0 ? 'Hoy' : capitalize(moment().add(index, "days").locale('es').format("ddd")), day: capitalize(moment().add(index, "days").locale('es').format("DD")), hora }

                })
            }

            await calculateFechaEstimadaEntrega()
            // setSelectedTime(list[0]?.hora)
            setAvailableScheduledHours(list)
        } catch (error) {
            console.error(error)
            setLoadingTime(false)
        }
    }

    const calculateFechaEstimadaEntrega = async () => {
        Axios.get(`${LAMBDA_SHOP}/calculateFechaEstimadaEntrega/${order.IdSucursal}`, {
            params: {
                TipoEntrega: order.entrega.TipoEntrega, /* 1. DELIVERY, 2. RETIRO */
                TipoFechaEntrega: 1, /* 1. ANTES POSIBLE, 3. PROGRAMADO */
                FechaEntrega: 1,    /* 1 hoy, 2 mañana, 3 pasado mañana ... */
                HoraEntrega: 1, /* 11:30*/
            },
        })
            .then(({ data }) => {

                setDeliverySoon({
                    date: moment(data.fechaEstimadaEntrega.FechaEstimadaEntregaMax).format("YYYY/MM/DD")
                    , hour: moment(data.fechaEstimadaEntrega.FechaEstimadaEntregaMax).format("HH:mm")
                })
                setLoadingTime(false)
            })
            .catch(err => {
                console.error(err)
                setLoadingTime(false)
            })
    }

    const submitOrderChangeTimes = useCallback(
        () => {

            setsubmitting(true)

            Swal.fire({
                title: 'Editar entrega',
                text: `¿Seguro quieres editar la entrega de este pedido para ${daySelected - 1 === 0 ? 'Hoy' : `el ${capitalize(moment().add(daySelected - 1, "days").locale('es').format("ddd"))} ${capitalize(moment().add(daySelected - 1, "days").locale('es').format("DD"))}`} a las ${timeSelected} ? `,
                icon: 'warning',
                customClass: {
                    container: 'my-swal'
                },
                showCancelButton: true,
                confirmButtonText: 'Confirmar y cambiar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    socket.emit("changeOrderTimes", {
                        IdSucursal: order.IdSucursal,
                        IdPedido: order.IdPedido,
                        IdUsuario,
                        tipo: 'UPDATE_DELIVERY_ORDER',
                        day: daySelected,
                        hour: timeSelected,
                        comentario: motivos,
                        tipoEntrega
                    }, (data) => {

                        setSelectedMenu(0)
                        setsubmitting(false)
                    })
                } else {
                    setsubmitting(false)
                }
            })
        }, [
        socket,
        order,
        IdUsuario,
        daySelected,
        timeSelected,
        motivos,
    ]
    )



    const onChangeDaySelected = (day) => {
        setDatSelected(day.value)
        setSelectedTime(day.hora)
        setTimeSelected(null)
    }

    return (
        <>
            <DialogContent>
                {loadingTime ?
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <>

                        <Typography variant='h6'
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            ¿Para cuándo lo necesitas?
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center'
                            }}
                        >
                            <Paper
                                sx={{
                                    borderRadius: 3,
                                    mb: 3,
                                    mt: 1,
                                    p: 0.5,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                elevation={0}
                                variant='outlined'
                                square
                            >
                                {
                                    <Button
                                        variant={tipoEntrega === 1 ? 'contained' : "text"}
                                        disabled={!isOpen}
                                        sx={{
                                            display: 'flex',
                                            borderRadius: 2,
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            textTransform: 'initial',
                                            width: 150,
                                            height: 85,

                                            fontWeight: 600,

                                        }}
                                        onClick={() => (setTipoEntrega(1), setDatSelected(1), setTimeSelected(deliverySoon.hour))}
                                    >
                                        <AccessTime />
                                        <Box>Lo antes Posible</Box>
                                        {deliverySoon && isOpen ? <Box>{deliverySoon.hour}</Box> : <Box sx={{ color: '#ef5350' }}>Cerrado</Box>}
                                    </Button>
                                }

                                {
                                    <Button
                                        variant={tipoEntrega === 3 ? 'contained' : "text"}
                                        sx={{
                                            display: 'flex',
                                            borderRadius: 2,
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            textTransform: 'initial',
                                            width: 150,
                                            height: 85,

                                            fontWeight: 600,

                                        }}
                                        onClick={() => (setTipoEntrega(3), setDatSelected(null), setTimeSelected(null))}
                                    >
                                        <Event />
                                        <>Programada</>
                                    </Button>
                                }
                            </Paper >

                        </Box>
                        {tipoEntrega === 3 &&
                            <>
                                <Typography variant='h6'
                                    sx={{
                                        marginBottom: 2
                                    }}
                                >
                                    Seleccione Dia
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    {availableScheduledHours.map((item) => (
                                        <Button
                                            variant={daySelected === item.value ? 'contained' : 'outlined'}
                                            onClick={() => !submitting && onChangeDaySelected(item)}
                                            key={`ButtonDaty-${item.value}`}
                                            disabled={item.hora < 1}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderRadius: '1000px !important',

                                                width: { sx: '15.5vw', md: 55 },
                                                '& .MuiButton-label': {
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                },
                                                '& svg': {
                                                    fontSize: '38px !important'
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    borderRadius: '100px !important',
                                                    border: 'none !important',

                                                    width: { sx: '15.5vw', md: 60 },
                                                    '& .MuiButton-label': {
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    },
                                                    '& svg': {
                                                        fontSize: '38px !important'
                                                    },
                                                    ...item.hora < 1 ? {
                                                        filter: 'opacity(0.5)'
                                                    } : {},
                                                }}
                                            >
                                                <Typography sx={{ fontWeight: 700 }}>{item.label.split('.', 1)[0]}</Typography>
                                                <Typography sx={{ fontWeight: 700 }}>{item.day}</Typography>
                                            </Box>
                                        </Button>
                                    ))}

                                </Box>
                                {daySelected &&
                                    <Typography variant='h6'
                                        sx={{
                                            marginBottom: 2,
                                            marginTop: 5
                                        }}
                                    >
                                        Seleccione Horario
                                    </Typography>
                                }
                                <Box
                                    sx={{
                                        marginTop: 3,
                                        marginBottom: 5
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        {selectedTime && selectedTime.map((item, index) => (

                                            <Fragment key={index}>
                                                {!item.disabled ?
                                                    <Grid item xs={2} justifyContent='center' style={{ display: 'flex' }} >
                                                        <Chip
                                                            label={item.value}
                                                            color={timeSelected === item.value ? 'primary' : 'default'}
                                                            sx={{
                                                                minWidth: 90,
                                                                p: 2,
                                                                fontSize: 14,
                                                                cursor: 'pointer',
                                                                border: 1,
                                                                fontWeight: 600,
                                                                '&:hover': {
                                                                    border: 1,
                                                                    // borderColor: theme?.palette?.primary?.main,
                                                                    background: '#AEAEAE',
                                                                }
                                                            }}
                                                            onClick={() => !submitting && setTimeSelected(item.value)}
                                                        />
                                                    </Grid>
                                                    : <></>
                                                }
                                            </Fragment>
                                        ))}
                                    </Grid>
                                </Box>
                            </>
                        }
                        <Typography variant='h6'
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            Motivos
                        </Typography>
                        <TextField
                            multiline
                            maxRows={3}
                            rows={2}
                            fullWidth
                            onChange={(e) => !submitting && setMotivos(e.target.value)}
                            value={motivos}
                        />
                        {
                            motivos.length < MIN_LENGTH_OBS &&
                            <span style={{ color: 'red' }}>{MIN_LENGTH_OBS - (motivos ? motivos.length : 0)} caracteres mínimos</span>
                        }
                    </>

                }
            </DialogContent>
            <DialogActions>
                <Button disabled={submitting} onClick={() => setSelectedMenu(0)}>
                    Volver
                </Button>
                <Button
                    disabled={!daySelected || !timeSelected || motivos.length < MIN_LENGTH_OBS || submitting}
                    variant='contained'
                    onClick={submitOrderChangeTimes}
                    endIcon={submitting && <CircularProgress size={14} />}
                >
                    {!daySelected || !timeSelected ? `Cambiar` : `Cambiar para ${daySelected - 1 === 0 ? 'Hoy' : `el ${capitalize(moment().add(daySelected - 1, "days").locale('es').format("ddd"))} ${capitalize(moment().add(daySelected - 1, "days").locale('es').format("DD"))}`} a las ${timeSelected}`}
                </Button>
            </DialogActions>
        </>

    )
}