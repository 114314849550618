import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Divider } from 'antd'
import currencyFormatter from 'currency-formatter';

import currencyFormatterOptions from "../../../config/currencyFormatterOptions.json"
import { useSelector } from 'react-redux';
import { Box, Icon, Tooltip, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';


export default ({ order, style = {} }) => {
    const Auth = useSelector(state => state.Auth)
    const [productos, setproductos] = useState([])
    const [groupProduct, setGroupProduct] = useState([])

    useEffect(() => {
        if (order?.productos !== undefined && order?.productos !== null) {
            setproductos(
                order.productos.filter(p => !p.pedido_detalle.IdMenu && !p.pedido_detalle.IdAgrupacion)
            )
            let productosGrupo = []

            order.productos.forEach(ele => {
                if (ele.pedido_detalle.IdAgrupacion) {
                    const findGroup = productosGrupo.find(p => ele.pedido_detalle.IdAgrupacion === p.IdAgrupacion && ele.pedido_detalle.Correlativo === p.Correlativo)
                    if (!findGroup) {
                        productosGrupo.push({
                            IdAgrupacion: ele.pedido_detalle.IdAgrupacion,
                            Correlativo: ele.pedido_detalle.Correlativo,
                            Nombre: ele.pedido_detalle.NombreAgrupacion,
                            Cantidad: ele.pedido_detalle.Cantidad / ele.pedido_detalle.CantidadMinima,
                            Comentario: ele.pedido_detalle.Comentario,
                            Total: ele.pedido_detalle.Total,
                            Monto: ele.pedido_detalle.Monto * ele.pedido_detalle.CantidadMinima,
                            productos: [ele]
                        })

                    } else {
                        productosGrupo.map(p => {
                            if (ele.pedido_detalle.IdAgrupacion === p.IdAgrupacion && ele.pedido_detalle.Correlativo === p.Correlativo) {
                                p.Total += ele.pedido_detalle.Total
                                p.Monto += (ele.pedido_detalle.Monto * ele.pedido_detalle.CantidadMinima)
                                p.productos = [...p.productos, ele]
                            }
                            return p
                        })
                    }
                }
            })

            setGroupProduct(productosGrupo)
        }
    }, [order])

    return (
        <div style={{ ...style }}>

            {
                (!!productos.length || !!groupProduct.length) ? <>
                    <Row
                        type="flex"
                        justify='start'
                        align="top"
                    >
                        <Col span={12}> <b> Producto </b> </Col>
                        <Col style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 600 }} span={4}> <b> Cantidad </b> </Col>
                        <Col style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 600 }} span={4}>Precio</Col>
                        <Col style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 600 }} span={4}>Total</Col>
                    </Row>
                    {groupProduct.map((producto, i) => {
                        const style = {
                            // bac0kgroundColor: i % 2 === 0 ? '#fafafa' : 'white',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }
                        const promoStyle = {
                            fontWeight: 'bold'
                        }
                        return (
                            <Fragment key={i}>
                                <Row>
                                    <Row>
                                        <Col style={promoStyle} span={12}>
                                            {producto.Nombre}
                                        </Col>
                                        <Col style={{ ...promoStyle, ...style }} span={4}>
                                            {producto.Cantidad}
                                        </Col>
                                        <Col style={{ ...promoStyle, ...style }} span={4}>
                                            {currencyFormatter.format(producto.Monto, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}

                                        </Col>
                                        <Col style={{ ...promoStyle, ...style }} span={4}>
                                            {currencyFormatter.format(producto.Total, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                        </Col>
                                    </Row>

                                    {producto.productos.map((ele, i) => {
                                        return (
                                            <>
                                                <Row>
                                                    <Col style={{ paddingLeft: 10 }} span={12}>
                                                        {ele.Nombre}
                                                    </Col>
                                                    <Col style={style} span={4}>
                                                        {ele.pedido_detalle.Cantidad}
                                                    </Col>
                                                    <Col style={style} span={4}>
                                                        {/* {currencyFormatter.format(producto.pedido_detalle.Monto, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])} */}
                                                    </Col>
                                                    <Col style={style} span={4}>
                                                        {/* {currencyFormatter.format(producto.pedido_detalle.Total, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])} */}
                                                    </Col>
                                                </Row>
                                                {
                                                    ele.pedido_detalle.Opciones &&
                                                    <Row>
                                                        <Col span={24}>
                                                            <p style={{ wordBreak: 'break-all', fontSize: 12, paddingLeft: 20 }}>
                                                                {

                                                                    ele.pedido_detalle.Opciones.split('\n').map(opcion =>
                                                                        <span key={opcion} style={{ fontSize: 12, textTransform: 'capitalize' }}>
                                                                            {opcion}
                                                                            <br />
                                                                        </span>
                                                                    )
                                                                }
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        )
                                    })

                                    }
                                </Row>
                                {
                                    producto.Comentario &&
                                    <Row>
                                        <Col style={promoStyle} span={24}>
                                            <p style={{ wordBreak: 'break-all', fontSize: 12 }}>
                                                <i>
                                                    "{producto.Comentario}"
                                                </i>
                                            </p>
                                        </Col>
                                    </Row>
                                }
                            </Fragment>
                        )
                    })}

                    {
                        productos.map((producto, i) => {
                            const style = {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'end',
                            }

                            return <Col span={24} key={i}>
                                <Row>

                                    <Col span={12}>
                                        {producto.Nombre}
                                        <br />
                                        {producto.productoMarkets && `SKU: ${producto.productoMarkets}`}
                                    </Col>
                                    <Col style={style} span={4}>
                                        {producto.pedido_detalle.Cantidad}
                                    </Col>
                                    {(producto.pedido_detalle.EsOferta) ?
                                        <Col style={{ ...style, gap: -1 }} span={4}>
                                            <Box>
                                                {currencyFormatter.format(producto.pedido_detalle.Monto, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                            </Box>
                                            <Box sx={{ fontSize: '.8rem', textDecoration: 'line-through' }}>
                                                {currencyFormatter.format(producto.pedido_detalle.MontoNormal, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                            </Box>
                                        </Col>
                                        :
                                        <Col style={style} span={4}>
                                            {currencyFormatter.format(producto.pedido_detalle.Monto, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                        </Col>
                                    }
                                    {
                                        (producto.pedido_detalle.EsOferta) ?
                                            <Col style={{ ...style, gap: -1 }} span={4}>
                                                <Box>
                                                    {currencyFormatter.format(producto.pedido_detalle.Total, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                                </Box>
                                                <Box sx={{ fontSize: '.8rem', textDecoration: 'line-through' }}>
                                                    {currencyFormatter.format(producto.pedido_detalle.TotalNormal, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                                </Box>
                                            </Col>
                                            :
                                            <Col style={style} span={4}>
                                                {currencyFormatter.format(producto.pedido_detalle.Total, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                            </Col>
                                    }
                                    {
                                        producto.pedido_detalle.Opciones &&
                                        <Col span={24}>
                                            <p style={{ wordBreak: 'break-all', fontSize: 12 }}>
                                                {

                                                    producto.pedido_detalle.Opciones.split('\n').map(opcion =>
                                                        <span key={opcion} style={{ fontSize: 12, textTransform: 'capitalize' }}>
                                                            {opcion}
                                                            <br />
                                                        </span>
                                                    )
                                                }
                                            </p>
                                        </Col>
                                    }
                                    {
                                        producto.pedido_detalle.Comentario &&
                                        <Col style={style} span={24}>
                                            <p style={{ wordBreak: 'break-all', fontSize: 12 }}>
                                                <i>
                                                    "{producto.pedido_detalle.Comentario}"
                                                </i>
                                            </p>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        })
                    }
                </>
                    : order?.IdTipoPedido === 5 && order?.extOrder?.Productos?.length > 0 ?
                        <>
                            <Row
                                type="flex"
                                justify='start'
                                align="top"
                            >
                                <Col span={8}> <b> Producto </b> </Col>
                                <Col span={3}> <b> Cantidad </b> </Col>
                                <Col span={3}> <b> Precio </b> </Col>
                                <Col span={3}> <b> Total </b> </Col>
                                {order?.extOrder?.Productos.map((prod, i) => (
                                    <>
                                        <Col style={style} span={8}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Typography>
                                                    {prod.IdProducto ? prod.producto.Nombre : prod.Nombre}
                                                </Typography>
                                                {!prod.IdProducto &&
                                                    <Tooltip title="Producto sin match">
                                                        <Warning
                                                            sx={{
                                                                color: '#ff9800',
                                                                fontSize: 17,
                                                                marginLeft: 1
                                                            }}
                                                        />

                                                    </Tooltip>

                                                }
                                            </Box>
                                        </Col>
                                        <Col style={style} span={3}>
                                            {prod.Cantidad}
                                        </Col>
                                        <Col style={style} span={3}>
                                            {currencyFormatter.format(prod.Precio, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                        </Col>
                                        <Col style={style} span={3}>
                                            {currencyFormatter.format(prod.Total, currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])}
                                        </Col>
                                        {
                                            prod?.opciones &&
                                            <Col style={style} span={24}>
                                                <p style={{ wordBreak: 'break-all', fontSize: 12 }}>
                                                    {

                                                        prod?.opciones?.map((opcion, index) =>
                                                            <span key={index} style={{ fontSize: 12, textTransform: 'capitalize' }}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <Typography>
                                                                        {opcion?.IdOpcion ? opcion.opcionExterno.Nombre : opcion?.Opcion}
                                                                    </Typography>
                                                                    {!opcion.IdOpcion &&
                                                                        <Tooltip title="Opción sin match">
                                                                            <Warning
                                                                                sx={{
                                                                                    color: '#ff9800',
                                                                                    fontSize: 17,
                                                                                    marginLeft: 1
                                                                                }}
                                                                            />

                                                                        </Tooltip>

                                                                    }
                                                                </Box>
                                                                <br />
                                                            </span>
                                                        )
                                                    }
                                                </p>
                                            </Col>
                                        }
                                        {
                                            prod?.comentario &&
                                            <Col style={style} span={24}>
                                                <p style={{ wordBreak: 'break-all', fontSize: 12 }}>
                                                    <i>
                                                        "{prod.comentario}"
                                                    </i>
                                                </p>
                                            </Col>
                                        }
                                    </>
                                ))}
                            </Row>
                        </>
                        :
                        order.ContenidoPedido ?
                            <>
                                <Row
                                    type="flex"
                                    justify='start'
                                    align="top"
                                >
                                    <Col span={8}> <b> Contenido </b> </Col>
                                    <Col style={{ ...style, width: '100%' }} span={8}><pre><Typography variant='body1'>{order.ContenidoPedido}</Typography></pre></Col>

                                </Row>
                            </>
                            :
                            <></>
            }
        </div>
    )
}