import * as actions from './actions'

const initState = {
    tz: "America/Santiago",
    OffsetUTC: "",
    schedule: []
}

export default (state = initState, action) => {
    switch (action.type) {
        case actions.GET_SCHEDULE_SUC_SUCCESS:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
