import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Progress } from 'antd';


export default function () {
    const allOrders = useSelector(state => state.Ordenes)

    const [lastCorelativo, setlastCorelativo] = useState(0)
    const [percent, setpercent] = useState(0)
    const [fullPercent, setfullPercent] = useState(false)


    useEffect(() => {
        const next = Math.max(
            ...allOrders.ordersActive.map(o => o.CorelativoPrivate),
            ...allOrders.ordersReady.map(o => o.CorelativoPrivate),
            ...allOrders.ordersEnd.map(o => o.CorelativoPrivate),
            0
        )

        setlastCorelativo(
            next
        )

        const allOrdersLength = allOrders.orders.length + allOrders.ordersActive.length + allOrders.ordersReady.length + allOrders.ordersEnd.length
        const allOrdersAcceptedLength = allOrders.ordersActive.length + allOrders.ordersReady.length + allOrders.ordersEnd.length


        const nextPercent = allOrdersAcceptedLength * 100 / allOrdersLength
        setpercent(Math.min(nextPercent, 100))

        if (nextPercent === 100) {
            setfullPercent(true)
            setTimeout(() => {
                setfullPercent(false)
            }, 2000);
        }
    }, [allOrders])

    return fullPercent ?
        <Progress type="circle" percent={100} width={80} /> :
        <Progress type="circle" percent={percent} width={80} format={() => <span style={{ color: 'white' }} >{lastCorelativo.toString().slice(-3)}</span>} />
}
