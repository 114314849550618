import actions from './actions';


const initState = {
	message: '',
	AppKey: '',
	Token: '',
	auth: {
		nacionalidad: {}
	},
	payload: {
		usuarioPerfiles: [],
		usuarioPerfil: {
			sucursal: {
				zonaHoraria: {
					Zona: 'America/Santiago'
				},
				comercio: {
					disenos: [],
					nacionalidad: {
						Moneda: 'CLP'
					},
					urls: []
				}
			}
		}
	}
};

export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_SUCCESS_DATA:
			return {
				...state,
				...action.payload
			}
		case actions.LOGOUT_SUCCESS:
			return initState;
		default:
			return state;
	}
}
