import React, { useState, useEffect, useContext, useCallback } from "react"
import { useSelector } from "react-redux"
import axios from 'axios'
import { Modal, Popover, Select, Radio, notification, Spin, Icon, Typography } from 'antd'
import { BsPlayFill, BsPauseFill, BsStopFill } from 'react-icons/bs'
import { LAMBDA_POS } from '../../../config'
import { SocketContext } from "../../Dashboard/Dashboard"
import moment from "moment"
import { AccessTime, PauseRounded, PlayArrowRounded, StopRounded } from "@mui/icons-material"
import { Box, CircularProgress } from "@mui/material"

const { Option } = Select

const RUNNING = 'RUNNING'
const INPAUSE = 'INPAUSE'
const STOP = 'STOP'
const FORCEPAUSE = 'FORCEPAUSE'

const BYTIMER = 'BYTIMER'
const FORDAY = 'FORDAY'

const MIN = 'MIN'
const HR = 'HR'

const notificationKey = 'PlayPause'
const iconSize = 50

export default () => {
    const socket = useContext(SocketContext)
    const Schedule = useSelector(state => state.Schedule)
    const Auth = useSelector(state => state.Auth)
    const [horario, sethorario] = useState(null)
    const [sucursalState, setsucursalState] = useState(STOP)
    const [showModalChangePlayPause, setShowModalChangePlayPause] = useState(false)
    const [typePause, settypePause] = useState(BYTIMER)
    const timerModal = {
        [MIN]: [10, 15, 30],
        [HR]: [1, 2, 3]
    }
    const [selectedTimer, setselectedTimer] = useState(MIN)
    const [selectedValueTimer, setselectedValueTimer] = useState(timerModal[selectedTimer][2])
    const [loading, setloading] = useState(false)

    const radioStyle = {
        display: 'block',
        minHeight: '30px',
        lineHeight: '30px',
    }

    const defineHorario = useCallback(
        () => {
            const { schedule } = Schedule
            const horario = schedule.find(h => h.iAmHere)
            sethorario(horario)

            if (horario) {
                setsucursalState(horario.state)
            } else {
                setsucursalState(STOP)
            }
        },
        [Schedule?.schedule, Schedule?.tz],
    )

    const refreshPOSSchedules = useCallback(
        () => {
            const IdComercio = Auth.payload.usuarioPerfil.sucursal.comercio.IdComercio
            const IdSucursal = Auth.payload.usuarioPerfil.sucursal.IdSucursal
            setloading(true)
            socket.emit("PlayPauseSucursal", { IdComercio, IdSucursal }, () => {
                setloading(false)
            })
        },
        [Auth, socket],
    )

    useEffect(() => {
        const debounce = setTimeout(() => {
            defineHorario()
            setShowModalChangePlayPause(false)
        }, 500)

        return () => {
            clearTimeout(debounce)
        }
    }, [defineHorario])

    useEffect(() => {
        setShowModalChangePlayPause(false)
        const timeoutRefreshPOSSchedules = setTimeout(refreshPOSSchedules, 15 * 60000)
        return () => {
            clearTimeout(timeoutRefreshPOSSchedules)
        }
    }, [refreshPOSSchedules])

    const handleOnClickIcon = () => {
        switch (sucursalState) {
            case RUNNING:
                setShowModalChangePlayPause(true)
                break
            case FORCEPAUSE:
            case INPAUSE:
                updatePlayPauseBySucursal()
                break
            default:
                break
        }
    }

    const handleModalChangePlayPauseSuccess = async () => {
        await updatePlayPauseBySucursal()
    }

    const updatePlayPauseBySucursal = async () => {
        setloading(true)

        const IdUsuario = Auth.auth.IdUsuario
        const IdComercio = Auth.payload.usuarioPerfil.sucursal.comercio.IdComercio
        const IdSucursal = Auth.payload.usuarioPerfil.sucursal.IdSucursal
        const alertMsg = {
            type: (sucursalState === RUNNING) ? 'warning' : 'success',
            msg: (sucursalState === RUNNING) ? 'Tienda pausada' : 'Tienda activa!',
        }

        return axios.patch(`${LAMBDA_POS}/updateScheduleState/${IdComercio}/${IdSucursal}`, {
            IdHorario: horario.Id,
            selectedTimer,
            selectedValueTimer,
            typePause: sucursalState === RUNNING ? typePause : null,
            IdUsuario
        }).then(response => {
            notification[alertMsg?.type]({ message: alertMsg?.msg, description: 'Los cambios se tardar hasta 1 minuto', key: notificationKey })
        }).catch((err) => {
            notification.warning({ message: 'No es posible esta acción en estos momentos', description: 'Refrescando...', key: notificationKey })
        }).finally(() => {
            setShowModalChangePlayPause(false)
            setloading(false)
        })
    }

    return (
        <>
            <Popover
                title={
                    <Typography textAlign="center">
                        {
                            sucursalState === RUNNING ?
                                'Sucursal Abierta' :
                                sucursalState === INPAUSE ?
                                    'Sucursal Pausada' :
                                    sucursalState === FORCEPAUSE ?
                                        'Sucursal Pausada desde POS' :
                                        sucursalState === STOP ?
                                            'Sucursal Cerrada' :
                                            null
                        }
                    </Typography>
                }
                content={
                    <Box
                        sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {
                            (sucursalState === RUNNING && horario) && (
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AccessTime sx={{ mr: 1 }} />
                                        <Typography variant="subtitle1">
                                            {formatHHmm(horario.HoraEntrada)} - {formatHHmm(horario.HoraSalida)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <a onClick={handleOnClickIcon}>Pausar</a>
                                    </Box>
                                </Box>
                            )
                        }
                        {
                            (sucursalState === INPAUSE && horario) && (
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AccessTime sx={{ mr: 1 }} />
                                        <Typography variant="subtitle1">
                                            {formatHHmm(horario.PausaDesde)} - {formatHHmm(horario.PausaHasta)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <a onClick={refreshPOSSchedules}>Refrescar</a>
                                    </Box>
                                </Box>
                            )
                        }
                        {
                            (sucursalState === FORCEPAUSE && horario) && (
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AccessTime sx={{ mr: 1 }} />
                                        <Typography variant="subtitle1">
                                            {formatHHmm((moment(horario?.PausaEspecialInicio).utc(false).format('HHmm')))} - {formatHHmm((moment(horario?.PausaEspecialFin).utc(false).format('HHmm')))}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <a onClick={handleOnClickIcon}>Reanudar</a>
                                    </Box>
                                </Box>
                            )
                        }
                        {
                            (sucursalState === STOP) && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <a onClick={refreshPOSSchedules}>Refrescar</a>
                                </Box>
                            )
                        }
                    </Box>
                }
                trigger="click"
            >
                {
                    (loading) ?
                        <CircularProgress size={18} />
                        :
                        <Box sx={{ cursor: 'pointer' }}>
                            {
                                sucursalState === RUNNING ?
                                    <PlayArrowRounded sx={{ color: '#4caf50', fontSize: iconSize }} />
                                    :
                                    sucursalState === INPAUSE ?
                                        <PauseRounded sx={{ color: '#ffcd38', fontSize: iconSize }} />
                                        :
                                        sucursalState === FORCEPAUSE ?
                                            <PauseRounded sx={{ color: '#1890ff', fontSize: iconSize }} />
                                            :
                                            sucursalState === STOP ?
                                                <StopRounded sx={{ color: '#dc3545', fontSize: iconSize }} />
                                                :
                                                null
                            }
                        </Box>
                }
            </Popover>
            <Modal
                title="Pausar la tienda sólo en caso de emergencia"
                visible={showModalChangePlayPause}
                onOk={handleModalChangePlayPauseSuccess}
                onCancel={() => setShowModalChangePlayPause(false)}
                okButtonProps={{
                    loading
                }}
                okText='Pausar Tienda'
                cancelText='Cancelar'
            >
                <Typography>
                    Al pausar la tienda dejarás de recibir pedidos de tus clientes.
                </Typography>
                <br />
                <Radio.Group onChange={e => settypePause(e.target.value)} value={typePause}>
                    <Radio style={radioStyle} value={BYTIMER}>
                        Pausar por tiempo limitado
                        {
                            typePause === BYTIMER && (
                                <Box sx={{ mt: 2, ml: 3 }}>
                                    <Select value={selectedTimer} style={{ width: 120, marginRight: '15px' }} onChange={value => {
                                        setselectedTimer(value)
                                        setselectedValueTimer(timerModal[value][0])
                                    }}>
                                        <Option value={`${MIN}`}>Minutos</Option>
                                        <Option value={`${HR}`}>Horas</Option>
                                    </Select>
                                    <Select value={selectedValueTimer} style={{ width: 120 }} onChange={setselectedValueTimer}  >
                                        {
                                            timerModal[selectedTimer].map((value, index) =>
                                                <Option key={`optkeyValTimer${index}`} value={`${value}`}>{value}</Option>
                                            )
                                        }
                                    </Select>
                                </Box>
                            )
                        }
                    </Radio>
                    <Radio style={radioStyle} value={FORDAY}>
                        Pausar por el resto del día (hasta las 23:59 hrs.)
                    </Radio>
                </Radio.Group>
            </Modal>
        </>
    )
}


function formatHHmm(hhmm) {
    let _hhmm = null
    if (hhmm !== null) {
        _hhmm = hhmm.toString().padStart(4, '0')
        _hhmm = `${_hhmm.substring(0, 2)}:${_hhmm.substring(2, 4)}`
    }
    return _hhmm
}