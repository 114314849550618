import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import { Col, Row, Timeline, Spin, Icon } from 'antd';
import moment from 'moment-timezone'

import MapContent from './MapContent';
import useWindowSize from '../../../../library/hooks/useWindowSize';
import { LAMBDA_POS } from '../../../../config';
import { ID_PEDIDO_ESTADO_EN_CAMINO, ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL } from '../../../../redux/ordenes/types';
import { useDispatch, useSelector } from 'react-redux';

import orderActions from "../../../../redux/ordenes/actions"
import { getDistanceText } from '../../OrderDetails/Order-Riders';

const { loadMapsTracking } = orderActions

export default ({ order }) => {
    const Auth = useSelector(state => state.Auth)
    const dispatch = useDispatch()
    const { height } = useWindowSize()
    const [traceData, setTraceData] = useState({
        timeline: [],
        coors: {
            sucursalUbicacion: null,
            riderUbicacion: null,
            clienteUbicacion: null,
        },
        IdEstado: 2
    })
    const [loading, setloading] = useState(true)
    const [lastUpdate, setlastUpdate] = useState(moment.tz(Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona || ''))

    useEffect(() => {
        getOrderMapsTracking()
    }, [])

    useEffect(() => {
        if (order.mapsTracking) {
            setTraceData(order.mapsTracking)
            setlastUpdate(moment.tz(Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona || ''))
            setloading(false)
        }
    }, [Auth, order, order.IdPedido, order.mapsTracking])

    async function getOrderMapsTracking() {
        await Axios.get(`${LAMBDA_POS}/getOrderMapsTracking/${order.IdPublicoPedido}`)
            .then(response => {
                dispatch(loadMapsTracking(response.data))
            })
            .catch(console.error)
    }

    return (
        <>
            {
                loading ? (
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
                ) : (
                    <Row>
                        <Col span={8}>
                            <div style={{ padding: '35px' }}>
                                <h2>{`${order.Codigo}-${order.Corelativo}`}</h2>
                                <br></br>
                                <Timeline>
                                    {
                                        traceData.timeline.map((step, i) => (
                                            <Timeline.Item key={i} color={step.color}>
                                                {
                                                    step.texts.map((text, i) => (
                                                        <p key={i} style={{
                                                            ...(i > 0 ? { fontSize: '12px' } : {})
                                                        }}>{text}</p>
                                                    ))
                                                }

                                            </Timeline.Item>
                                        ))
                                    }
                                </Timeline>
                                {
                                    !!traceData.coors.riderUbicacion &&
                                    <div style={{ padding: '30px 0 0 0' }}>
                                        <p>
                                            <b>Distancias:</b>
                                            <br></br>
                                            {
                                                traceData.IdEstado === ID_PEDIDO_ESTADO_EN_CAMINO && <>
                                                    Rider - Sucursal:  <span style={{ float: 'right' }}>
                                                        {
                                                            getDistanceText(traceData.coors?.riderUbicacion?.distances?.sucursal?.meters)
                                                        }
                                                    </span>
                                                    <br></br>
                                                </>
                                            }
                                            {
                                                traceData.IdEstado === ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL && <>
                                                    Rider - Cliente:  <span style={{ float: 'right' }}>
                                                        {
                                                            getDistanceText(traceData.coors?.riderUbicacion?.distances?.cliente?.meters)
                                                        }
                                                    </span>
                                                    <br></br>
                                                </>
                                            }
                                        </p>

                                    </div>
                                }
                                <div style={{ padding: '30px 0 0 0' }}>
                                    <p style={{ color: 'gray', fontSize: '11px' }}>
                                        Ultima Actualización {lastUpdate.format('HH:mm:ss')}
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div style={{
                                height
                            }}>
                                <MapContent
                                    coors={[
                                        traceData.coors.sucursalUbicacion,
                                        traceData.coors.riderUbicacion,
                                        traceData.coors.clienteUbicacion
                                    ]}
                                />
                            </div>
                        </Col>
                    </Row>

                )
            }
        </>
    )
}

