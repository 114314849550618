import React, { lazy, Suspense } from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import Loader from '@iso/components/utility/loader';


import OrdersPage from '../Orders/Orders'
import OrdersActivePage from '../Orders/OrdersActive'
import OrdersReadyPage from '../Orders/OrdersReady'
import OrdersEndPage from '../Orders/OrdersEnd'
import OrdersScheduledPage from '../Orders/OrdersScheduled'
import OrdersRejectedPage from '../Orders/OrdersRejected'
import OrdersAgilFavour from '../Orders/OrdersAgilFavour'
import ProductsPage from '../Products/'
import SettingsPage from '../Settings/'
import UserProfilePage from '../UserProfile'
import TimePage from '../Time'
import NotFoundPage from '../Pages/404/404'
import History from '../Orders/History'

const routes = [
  {
    path: '',
    component: lazy(() => import('@iso/containers/Widgets/Widgets')),
    exact: true,
  },
  {
    path: 'orders',
    component: OrdersPage
  },
  {
    path: 'activeorders',
    component: OrdersActivePage
  },
  {
    path: 'readyorders',
    component: OrdersReadyPage
  },
  {
    path: 'endorders',
    component: OrdersEndPage
  },
  {
    path: 'scheduledOrders',
    component: OrdersScheduledPage
  },
  {
    path: 'rejectedOrders',
    component: OrdersRejectedPage
  },
  {
    path: 'agilFavour',
    component: OrdersAgilFavour
  },
  {
    path: 'products',
    component: ProductsPage
  },
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'user-profile',
    component: UserProfilePage
  },
  {
    path: 'time',
    component: TimePage
  },
  {
    path: 'history',
    component: History
  },
];

export default function AppRouter(props) {
  const { url } = useRouteMatch();
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((route, idx) => (
          <Route exact={route.exact} key={idx} path={`${url}/${route.path}`}>
            <route.component ordersId={props.data ? props.data : []} />
          </Route>
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}
