import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history'

import actions from './actions';
import { LAMBDA_AUTH } from "../../config"
import Axios from 'axios';
import { notification } from 'antd';
const history = createBrowserHistory();



function* loginRequestToken() {
	yield takeEvery('LOGIN_REQUEST_TOKEN', function* ({ payload, callback }) {
		const { Token } = payload


		const response = yield call(callLoginWithToken, Token)

		if (response) {
			yield put({
				type: actions.LOGIN_SUCCESS_DATA,
				payload: response.data,
			})
		}
		callback && callback()
	});
}

function callLoginWithToken(Token) {
	return Axios.post(`${LAMBDA_AUTH}/loginWithToken`, {}, {
		headers: {
			Authorization: `Bearer ${Token}`
		}
	})
		.then(response => {

			const LastIdSucursal = Number(localStorage.getItem('LastIdSucursal'))

			const usuarioPerfil = response.data.payload.usuarioPerfiles.find(up => up.sucursal.IdSucursal === LastIdSucursal) || response.data.payload.usuarioPerfiles[0]

			document.title = `Agil POS | ${usuarioPerfil.sucursal.Titulo} | ${usuarioPerfil.sucursal.comercio.Nombre} `

			localStorage.setItem('LastIdSucursal', usuarioPerfil.sucursal.IdSucursal)

			response.data.payload = {
				...response.data.payload,
				usuarioPerfil,
			}
			response.data.Token = Token
			return response
		})
		.catch((err) => {
			notification.warning({ message: err.response.data.message })
			return null
		});
}


function* loginRequestData() {
	yield takeEvery('LOGIN_REQUEST_DATA', function* ({ payload, callback }) {
		const newAuth = payload

		document.title = `Agil POS | ${newAuth.payload.usuarioPerfil.sucursal.Titulo} | ${newAuth.payload.usuarioPerfil.sucursal.comercio.Nombre} `

		yield put({
			type: actions.LOGIN_SUCCESS_DATA,
			payload,
		})
		callback && callback()
	})
}



function* logout() {
	yield takeEvery(actions.LOGOUT, function* ({ payload, callback }) {
		const { Token } = payload
		document.title = 'Agil POS'

		yield call(callLogout, Token)


		yield put({
			type: actions.LOGOUT_SUCCESS,
			payload: payload
		})
	});
}


async function callLogout(Token) {
	Axios.post(`${LAMBDA_AUTH}/logout`, {

	}, {
		headers: {
			Authorization: `Bearer ${Token}`
		}
	})
		.then(console.log)
		.catch(console.error)
}






export default function* rootSaga() {
	yield all([
		fork(loginRequestToken),
		fork(loginRequestData),
		fork(logout),
	]);
}
