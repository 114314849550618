import React, { useEffect, useState } from 'react'
import { Popover, Tag } from 'antd';
import { useSelector } from 'react-redux';
import {
    ID_PEDIDO_ESTADO_ACEPTADO,
    ID_PEDIDO_ESTADO_EN_CAMINO,
    ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY
} from '../../../redux/ordenes/types'

const translateSeverity = {
    HARD: 'red',
    MEDIUM: 'gold',
    LOW: 'blue',
}

export default function ({ pedido, children }) {
    const Auth = useSelector(state => state.Auth)

    const settings = useSelector(state => state.Settings)
    const [horaEntregaCocina, sethoraEntregaCocina] = useState(null)
    const [color, setcolor] = useState(null)
    const [helpText, sethelpText] = useState(null)
    //
    useEffect(() => {
        sethoraEntregaCocina(pedido.HoraDespachoCocina)
    }, [Auth, settings, pedido.preparacion?.FechaEntregaMinima])

    useEffect(() => {
        console.log('DELAYDESPACHO::', pedido?.delayedDespachoCocina?.delayedMins)
        if (pedido.IdEstado === ID_PEDIDO_ESTADO_ACEPTADO && pedido.delayedDespachoCocina) {
            sethelpText(pedido.delayedDespachoCocina.delayedMins > 0 ? `${pedido.delayedDespachoCocina.delayedMins} mins retrasado.` : `Faltan ${pedido.delayedDespachoCocina.delayedMins * -1} mins.`)
            setcolor(translateSeverity[pedido.delayedDespachoCocina.severity])
        } else if ((pedido.IdEstado === ID_PEDIDO_ESTADO_EN_CAMINO || pedido.IdEstado === ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY) && pedido.delayedWaitingRider) {
            sethelpText(pedido.delayedWaitingRider.delayedMins > 0 ? `${pedido.delayedWaitingRider.delayedMins} mins retrasado.` : `Faltan ${pedido.delayedWaitingRider.delayedMins * -1} mins.`)
            setcolor(translateSeverity[pedido.delayedWaitingRider.severity])

        }
    }, [pedido.IdEstado, pedido.delayedDespachoCocina, pedido.delayedWaitingRider])


    if (!horaEntregaCocina) {
        return null
    }

    const TagHoraEntregaCocina = <Tag color={color}>Despachar {horaEntregaCocina} {children}</Tag>

    return helpText ?
        <Popover
            content={helpText}
            trigger="click"
            placement='right'
        >
            {TagHoraEntregaCocina}
        </Popover> :
        TagHoraEntregaCocina
}