const getCurrencyType = (type) => {
    switch (type.toUpperCase()) {
        case 'CLP':
            return 'es-CL'
        case 'USD':
            return 'en-US'
        case 'PEN':
            return 'es-CL'
        default:
            return type
    }
}

export const formatNumber = ({ num, currency }) => {
    let formattedNumber = new Intl.NumberFormat(getCurrencyType(currency), { style: 'currency', currency }).format(num)
    return formattedNumber
}
