import React, { useEffect, useState } from 'react'
import { Row, Col, Icon, Badge } from 'antd'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import 'moment/locale/es'
import {
    PED_ENTRE_TIPO_ENTRE_DELIVERY,
    PED_ENTRE_TIPO_ENTRE_MESA,
    PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA
} from '../../../redux/ordenes/types'
import Flag from '../../../components/Orders/Flags'
import SucursalTitle from '../../../components/Orders/SucursalTitle'
import useFilterSucursal from '../../../hooks/useFilterSucursal/useFilterSucursal'
import { Button, Chip, Typography } from '@mui/material'
import { COLOR_BG_AGIL, COLOR_BG_JUSTO, COLOR_BG_PEDIDOSYA, COLOR_BG_RAPPI, COLOR_BG_UBEREATS, COLOR_WHITE } from '../../../constants/color'
import { EditCalendar } from '@mui/icons-material'
import { ChangeSchedule } from '../../../components/ActionOrder/ChangeSchedule'
import { ProblemaPedido } from '../../../components/ActionOrder/Index'
moment.locale('es')

export default ({ order, children }) => {
    const Auth = useSelector(state => state.Auth)
    const { selectedSucursalIds } = useFilterSucursal()
    const { usuarioPerfiles } = useSelector(state => state?.Auth?.payload)
    const [fechaEstimadaEntregaFormat, setfechaEstimadaEntregaFormat] = useState('')
    const [openActionOrder, setOpenActionOrder] = useState(false)

    useEffect(() => {
        const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona
        const fechaEstimadaEntregaMaxFormat = order?.entrega?.FechaEstimadaEntregaMax && moment.utc(order?.entrega?.FechaEstimadaEntregaMax).tz(tz).format('HH:mm')
        setfechaEstimadaEntregaFormat(moment.utc(order?.entrega?.FechaEstimadaEntrega).tz(tz).format(`dddd DD [de] MMMM, HH:mm ${order?.entrega?.FechaEstimadaEntregaMax ? `- [${fechaEstimadaEntregaMaxFormat}]` : ''}`))
    }, [Auth, order])

    const getNombre = () => {
        const filtered = usuarioPerfiles.find((e) => e?.sucursal?.IdSucursal === order?.IdSucursal)
        if (filtered) {
            return filtered?.sucursal?.Titulo
        }
        return ''
    }
    const getColorPlataform = (code) => {
        switch (code) {
            case 'UBEREATS': {
                return {
                    background: COLOR_BG_UBEREATS,
                    color: COLOR_WHITE
                }
            }
            case 'PEYA': {
                return {
                    background: COLOR_BG_PEDIDOSYA,
                    color: COLOR_WHITE
                }
            }
            case 'RAPPI': {
                return {
                    background: COLOR_BG_RAPPI,
                    color: COLOR_WHITE
                }
            }
            case 'JUSTO': {
                return {
                    background: COLOR_BG_JUSTO,
                    color: COLOR_WHITE
                }
            }
            default: {
                return {
                    background: COLOR_BG_AGIL,
                    color: COLOR_WHITE
                }
            }
        }
    }
    return (
        <>
            <Row>
                <Col span={17} style={{ zIndex: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ color: 'black', fontSize: '20px' }}>
                            {`Pedido #${order.IdTipoPedido === 5 ? order?.extOrder?.CodigoExterno || '' : order.Codigo || 'N/A'}${order.IdTipoPedido === 5 ? order?.extOrder?.CorrelativoExterno || '' : order.IdEstado === 1 ? '' : `-${order.Corelativo}`} en ${selectedSucursalIds.length > 0 ? getNombre() : Auth.payload.usuarioPerfil.sucursal.Titulo}`}

                        </p>
                        <div style={{ marginRight: 30 }}>
                            <Flag
                                color={(order?.etiqueta !== undefined && order?.etiqueta !== null && order?.etiqueta.length > 0) ? order?.etiqueta[0]?.etiqueta_comercio?.etiqueta?.ColorComponente : 'default'}
                                title={(order?.etiqueta !== undefined && order?.etiqueta !== null && order?.etiqueta.length > 0) ? order?.etiqueta[0]?.etiqueta_comercio?.Nombre : ''}
                                idPedido={order?.IdPedido}
                            />
                        </div>
                    </div>
                    <p className="isoInfoLabel" style={{ fontSize: '19px', fontWeight: 'bold' }}>
                        <span style={{ fontSize: '14px' }}>
                            <Icon type="clock-circle" />
                            &nbsp;
                            El Cliente necesita este pedido para: {fechaEstimadaEntregaFormat}
                        </span>
                    </p>
                </Col>
                <Col>
                    {order?.entrega?.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY && <img style={{ width: '65px', height: '50px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_deliveryV2.svg'></img>}
                    {order?.entrega?.TipoEntrega === PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA && <img style={{ width: '65px', height: '50px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_retiro.svg'></img>}
                    {
                        order?.entrega?.TipoEntrega === PED_ENTRE_TIPO_ENTRE_MESA &&
                        <>
                            <Badge
                                count={order.mesa?.Nro}
                                style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                                offset={[5, 50]}
                            >
                                <img style={{ width: '65px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_mesa.svg' />
                            </Badge>
                        </>
                    }
                    <Chip
                        label={order?.extOrder?.plataforma?.Nombre || 'AGIL'}
                        // size="small"
                        sx={{
                            ...getColorPlataform(order?.extOrder?.plataforma?.Codigo)
                        }}
                    />
                    {(order.IdTipoPedido !== 5 || (order.IdTipoPedido === 5 && order.extOrder.IdTipoIntegrador === 5)) &&
                        <Button
                            variant='outlined'
                            startIcon={<EditCalendar />}
                            onClick={() => setOpenActionOrder(true)}
                            sx={{
                                borderRadius: 10
                            }}
                        >
                            Acciones del pedido
                        </Button>

                    }
                    {(order.IdTipoPedido !== 5 || (order.IdTipoPedido === 5 && order.extOrder.IdTipoIntegrador === 5)) &&
                        <ProblemaPedido order={order} setOpenActionOrder={setOpenActionOrder} openActionOrder={openActionOrder} />
                    }
                </Col>
            </Row>
            <div style={{ margin: '23px 0 10px 0' }}>
                {children}
            </div>

        </>
    )
}