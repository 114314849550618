import React, { useState, useEffect, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Layout, Icon, Row, Col, notification, Button } from "antd"
import moment from 'moment-timezone'
import { ID_SUC_TIEMPO_COCINA, DEFAULT_VALUE_TIEMPO_COCINA } from "@iso/redux/settings/types"
import { ContactListWrapper } from "@iso/components/Orders/OrderList.style"
import { ContactCardWrapper } from "@iso/components/Orders/OrderCard.style"
import { ContactsWrapper } from "./Orders.styles"
import Scrollbar from "@iso/components/utility/customScrollBar"
import OrderProducts from "./OrderDetails/Order-Products"
import OrderTotals from "./OrderDetails/Order-Totals"
import OrderClient from "./OrderDetails/Order-Client"
import OrderInfo from "./OrderDetails/Order-Info"
import OrderHeadDetail from "./OrderDetails/Order-HeadDetail"
import { SocketContext } from '../Dashboard/Dashboard'
import OrderCard from "./Cards/Order.Card"
import OrderPayment from "./OrderDetails/Order-Payment"
import { PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG, ID_PED_PAG_ESTA_PENDIENTE_PAGO } from "../../redux/ordenes/types"
import TicketDrawerButton from "./Actions/TicketDrawer.Button"
import RejectButton from "./Actions/Reject.button"
import PayLinkButton from "./Actions/PayLinkButton"
import { validatePrivilegioRecurso } from "../../library/helpers/validatePrivilegioRecurso"
import { useLocation } from 'react-router-dom'
import { setShowModalPedidoEspecial } from "../../redux/pedidoEspecial/actions"
import PedidoEspecial from "../PedidoEspecial"
const { Content } = Layout

export default function Orders() {
    const searchByUri = useLocation().search
    const socket = useContext(SocketContext)
    const Auth = useSelector(state => state.Auth)
    const { PuedeVenderAgilFavor, PuedeVenderPedidoEspecial } = Auth.payload.usuarioPerfil.sucursal
    const { orders: items } = useSelector(state => state.Ordenes)
    const [orders, setOrders] = useState(items)
    const [search, setSearch] = useState("")
    const [index, setIndex] = useState(0)
    const [submittingAcceptOrder, setsubmittingAcceptOrder] = useState(false)
    const settings = useSelector(state => state.Settings)
    const [tiempoCocinaDiff, settiempoCocinaDiff] = useState(0)
    const [showForm, setShowForm] = useState(false)
    const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona
    const hrDetalle = <hr style={{ borderWidth: 1, borderColor: "rgba(233, 233, 233, 0.18)" }}></hr>
    const dispatch = useDispatch()

    useEffect(() => {
        setOrders(items)
    }, [items])

    useEffect(() => {
        const tiempoCocina = settings.sucursal.find(set => set.IdTipoConfiguracion === ID_SUC_TIEMPO_COCINA)?.Valor || DEFAULT_VALUE_TIEMPO_COCINA
        const [auxtiempoCocinaMin, auxtiempoCocinaMax] = tiempoCocina.split('-').map(t => Number(t))
        settiempoCocinaDiff(auxtiempoCocinaMax - auxtiempoCocinaMin)
    }, [settings, index])

    useEffect(() => {
        setsubmittingAcceptOrder(false)

        return () => {
            if (orders[index]) {
                const key = `NOTIFY${orders[index].Codigo}`
                notification.close(key)
            }
        }
    }, [index, orders[index]?.Codigo])

    useEffect(() => {
        setOrders(search ? items.filter(o => o.Codigo.includes(search)) : items)
    }, [search, items])

    // SOLO APLICA SI SE ENVIA EL CODIGO DEL PEDIDO POR GET
    const IdPedido = new URLSearchParams(searchByUri).get('IdPedido')
    useEffect(() => {
        setSearch((IdPedido !== null) ? IdPedido : "")
    }, [items, IdPedido])

    function handleOnClicktiempoCocinaDiff(value) {
        settiempoCocinaDiff(prevtiempoCocinaDiff => Math.max(0, prevtiempoCocinaDiff + value))
    }

    function handleAceptar(order) {
        setsubmittingAcceptOrder(true)
        // una hora antes de la preparacion de cocina
        if (order.entrega.TipoFechaEntrega === PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG) {
            const currentMoment = moment.tz(tz)

            const momentEntregaEnCocina = moment.tz(currentMoment.format(`YYYY-MM-DD [${order.HoraDespachoCocina}]`), tz)

            const hoursDiff = Math.max(moment.duration(momentEntregaEnCocina.diff(currentMoment)).asHours(), 0)

            if (hoursDiff > 2) {
                return openOrderNotification({
                    order,
                    message: 'Aceptación antes de tiempo',
                    description: `#${order.Codigo} Aún hay bastante tiempo de diferencia en los tiempos de este pedido\n¿Desea confirmar ? `,
                    onConfirm: handleOnConfirm,
                })
            }
        }

        handleOnConfirm(order)
    }

    function handleOnConfirm(order) {
        socket.emit("PedidoAceptado", {
            IdPedido: order.IdPedido,
            IdSucursal: order.IdSucursal,
            Token: Auth.Token,
            usuarioEmail: Auth.auth.Email,
            tiempoCocinaDiff,
            tipoAceptacion: 'MANUAL'
        }, (data) => {
        })
    }

    function openOrderNotification({ order, message, description, onConfirm }) {
        const key = `NOTIFY${order.Codigo}`
        notification.warning({
            message,
            description,
            btn: <div>
                <Button size="small" onClick={() => {
                    notification.close(key)
                    setsubmittingAcceptOrder(false)
                }}>
                    Cerrar
                </Button>
                <Button
                    style={{ marginLeft: 10 }} type="primary" size="small"
                    onClick={() => {
                        notification.close(key)
                        onConfirm(order)
                        setsubmittingAcceptOrder(false)
                    }}
                >
                    Confirmar
                </Button>
            </div>,
            key,
            duration: null,
            onClose: () => {
                setsubmittingAcceptOrder(false)
            },
            style: {
                width: 600,
                marginLeft: 335 - 600,
            },
        })
    }

    function handleOnClickAcceptAll() {
        const key = 'handleOnClickAcceptAll'

        setsubmittingAcceptOrder(true)

        notification.warning({
            message: 'Confirmar',
            description: 'Se aceptarán todas las ordenes',
            btn: <div>
                <Button size="small" onClick={() => {
                    notification.close(key)
                    setsubmittingAcceptOrder(false)
                }}>
                    Cerrar
                </Button>
                <Button style={{ marginLeft: 10 }} type="primary" size="small" onClick={async () => {
                    notification.close(key)

                    for (const order of orders) {
                        handleOnConfirm(order)
                        await sleep(1)
                    }
                    setsubmittingAcceptOrder(false)

                }}>
                    Confirmar
                </Button>
            </div>,
            key,
            duration: null,
            onClose: () => {
                setsubmittingAcceptOrder(false)
            },
        })
    }

    const showModalPedidoEspecial = async () => {
        await dispatch(setShowModalPedidoEspecial())
    }

    return (
        <ContactsWrapper
            className="isomorphicContacts"
            style={{ background: "none" }}
        >
            {/* LEFT MENU */}
            <div className="isoContactListBar">
                <ContactListWrapper className="isoContactListWrapper">
                    <div style={{ display: 'flex', margin: 5 }}>
                        <TicketDrawerButton
                            orders={orders}
                        />
                        <Icon
                            style={{ marginLeft: 10, fontSize: `${2.5}em` }}
                            type="check-circle"
                            theme="twoTone"
                            onClick={handleOnClickAcceptAll}
                        />
                        {
                            (PuedeVenderAgilFavor || PuedeVenderPedidoEspecial || validatePrivilegioRecurso(Auth, "AGIL_FAVOR", "escritura") || validatePrivilegioRecurso(Auth, "AGIL_ESPECIAL", "escritura")) && (
                                <Button
                                    type="primary"
                                    onClick={showModalPedidoEspecial}
                                    style={{
                                        float: 'right'
                                        , marginLeft: 'auto'
                                    }}
                                >
                                    <Icon type="plus" theme="outlined" />
                                    Crear pedido
                                </Button>
                            )
                        }
                    </div>
                    <div style={{ height: 20 }} />
                    {orders && orders.length > 0 ? (
                        <div className="isoContactList">
                            <Scrollbar
                                className="contactListScrollbar"
                                style={{ height: "calc(100vh - 200px)" }}
                            >
                                {orders.map((order, i) =>
                                    <OrderCard key={i} order={order} i={i} active={index === i} onSelected={setIndex} />
                                )}
                            </Scrollbar>
                        </div>
                    ) : (
                        <span className="isoNoResultMsg">NO HAY DATOS</span>
                    )}
                </ContactListWrapper>
            </div>
            {/* LEFT MENU */}

            {/* RIGTH INFO */}

            <Layout className="isoContactBoxWrapper">
                {orders[index] && (
                    <Content className="isoContactBox">
                        <Scrollbar className="contactBoxScrollbar">

                            {/* INFO CABECERA */}
                            <ContactCardWrapper className="isoContactCard">
                                <div className="isoContactInfoWrapper">
                                    <OrderHeadDetail order={orders[index]}>
                                        <Row>
                                            <Col span={12}>
                                                <Button
                                                    loading={submittingAcceptOrder}
                                                    type="primary"
                                                    onClick={() => handleAceptar(orders[index])}
                                                >
                                                    Aceptar
                                                </Button>
                                                {/* {orders[index].IdTipoPedido !== 5 &&
                                                    <>
                                                        <RejectButton pedido={orders[index]} />
                                                    </>
                                                } */}

                                                {
                                                    (orders[index]?.tipoPedido?.Codigo === 'PEDESPECIAL' && orders[index]?.clienteCompra?.pagos[0]?.IdEstado === ID_PED_PAG_ESTA_PENDIENTE_PAGO) && (
                                                        <PayLinkButton pedido={orders[index]} tipo="PAGO" />
                                                    )
                                                }


                                                {
                                                    (orders[index]?.clienteCompra?.pagos[0]?.IdEstado !== ID_PED_PAG_ESTA_PENDIENTE_PAGO) && orders[index].IdTipoPedido !== 5 && (
                                                        <PayLinkButton pedido={orders[index]} tipo="SEGUIMIENTO" />
                                                    )
                                                }

                                            </Col>
                                            <Col span={12}>
                                                {
                                                    orders[index].entrega.TipoFechaEntrega === PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG &&
                                                    <TicketDrawerButton orders={[orders[index]]} />
                                                }
                                            </Col>


                                            <Col span={8}>
                                                {orders[index].Tiempo >= 0 && (
                                                    <p style={{ fontSize: "14px", fontWeight: "400", color: "#979797", lineHeight: "1.5", margin: 0, textAlign: "left" }} >
                                                        {`El pedido estará listo en ${tiempoCocinaDiff} min`}
                                                        <span style={{ float: 'right' }}>
                                                            <Icon style={{ margin: '0 2px' }} type="minus-circle" theme="filled" onClick={() => handleOnClicktiempoCocinaDiff(-5)} />
                                                            <Icon style={{ margin: '0 2px' }} type="plus-circle" theme="filled" onClick={() => handleOnClicktiempoCocinaDiff(5)} />
                                                        </span>
                                                    </p>
                                                )}
                                            </Col>
                                            <Col span={24}>
                                                <OrderPayment order={orders[index]} />
                                            </Col>
                                        </Row>
                                    </OrderHeadDetail>
                                </div>
                            </ContactCardWrapper>

                            {hrDetalle}

                            {/* DETALLE PRODUCTOS */}
                            <ContactCardWrapper className="isoContactCard">
                                <div className="isoContactInfoWrapper">
                                    <OrderProducts order={orders[index]} />
                                </div>
                            </ContactCardWrapper>

                            {hrDetalle}

                            {/* TOTALES */}

                            <ContactCardWrapper className="isoContactCard">
                                <div className="isoContactInfoWrapper">
                                    <Row>
                                        <Col span={12}>
                                            <OrderTotals order={orders[index]}></OrderTotals>
                                        </Col>
                                    </Row>
                                </div>
                            </ContactCardWrapper>

                            {hrDetalle}

                            {/* DATOS CLIENTE */}

                            <ContactCardWrapper className="isoContactCard">
                                <div className="isoContactInfoWrapper">
                                    <OrderClient order={orders[index]}></OrderClient>
                                </div>
                            </ContactCardWrapper>

                            {hrDetalle}

                            {/* DATOS PEDIDO */}

                            <ContactCardWrapper className="isoContactCard">
                                <div className="isoContactInfoWrapper">
                                    <Row>
                                        <Col span={14}>
                                            <OrderInfo order={orders[index]}></OrderInfo>
                                        </Col>
                                        <Col span={10}>

                                        </Col>
                                    </Row>
                                </div>
                            </ContactCardWrapper>
                        </Scrollbar>
                    </Content>
                )}
            </Layout>
            {/* RIGTH INFO */}

            {/* FORMULARIO PARA AGIL FAVOR Y PEDIDOS */}
            <PedidoEspecial />

        </ContactsWrapper >
    )
}



async function sleep(seg) {
    await new Promise((resolve, reject) => { setTimeout(resolve, seg * 1000) })
}
