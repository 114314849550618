import React, { memo, useEffect, useState } from 'react'
import OpenStreetMapImage from '../../../../components/Map/OpenStreetMaps/OpenStreetMapImage'
import { Box, Typography } from '@mui/material'

const MapContent = ({ coors }) => {
    const [locations, setLocations] = useState([])

    useEffect(() => {
        if (coors && coors.length > 0) {
            getLocations()
        }
    }, [coors])

    const getLocations = () => {
        let listLocations = []

        coors.map((e) => {
            if (e?.lat && e?.lng) {
                listLocations.push({
                    positions: [e?.lat, e?.lng],
                    icon: (e?.address) ? 'HOME' : (e?.distances) ? 'RIDER' : 'STORE',
                    isDraggable: false,
                    text: <Box>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {e?.title}
                        </Typography>
                        {
                            (e?.address) && (
                                <Typography variant='body2'>
                                    {e?.address}
                                </Typography>
                            )
                        }
                    </Box>,
                    textPermanent: false,
                })
            }
        })

        setLocations(listLocations)
    }

    return (
        <>
            {
                (locations.length > 0) && (
                    <OpenStreetMapImage
                        width='100%'
                        height='100vh'
                        locations={locations}
                        zoomControl={false}
                        dragging={false}
                        scrollWheelZoom={false}
                    />
                )
            }
        </>
    )
}

export default memo(MapContent)