import Axios from "axios";
import printJS from "print-js";
import { BACKEND_UTILS } from "../config";
import { DEFAULT_VALUE_CANTIDAD_TICKETS_X_PEDIDO, ID_SUC_CANTIDAD_TICKETS_X_PEDIDO } from "../redux/settings/types";
import { store } from "../redux/store";
import { PRINT_CONTENT_FULL, PRINT_FORMAT_THERMAL } from "../redux/ordenes/types";
import { print, socketPrint, getPrinters, socketLocalPOSData } from "./printer-socket";


let LIMIT_REPEAT_TICKET_PER_ORDER = 1
store.subscribe(() => {
    const { Settings } = store.getState()
    LIMIT_REPEAT_TICKET_PER_ORDER = Math.max(1, Number(
        Settings.sucursal.find(s => s.IdTipoConfiguracion === ID_SUC_CANTIDAD_TICKETS_X_PEDIDO)?.Valor || DEFAULT_VALUE_CANTIDAD_TICKETS_X_PEDIDO
    ))
})
let cacheApiGetOrderPdf = []


let service;
let isProcessingStack = false


/**
 * @type {{
 *    IdStackItem: string;
 *    IdSucursal: number;
 *    IdPedido: number;
 *    IdPublicoPedido: string;
 *    Codigo: string;
 *    Corelativo: number;
 *    processed: boolean;
 *    Corelativo: number;
 *    content: string;
 *    format: string;
 *    url: string;
 *    printers: {
 *        copies: number;
 *        machineId: string;
 *        machineIdOriginal: string;
 *        name: string;
 *    }[];
 * }[]}
 */
const stackTitcketsToPrint = []
initService()

let socket = null


/**
 * @type {{
 *      name: string;
 *      copies: number;
 *      machineId: string;
 *      machineIdOriginal: string;
 * }}
 */
const IPrinter = null
/**
 * @type {{
 *     format: string;
 *     content: string;
 *     url: string;
 *     printers: IPrinter[];
 * }}
 */
const ITicket = null
/**
 * @type {{
 *     IdStackItem: string;
 *     IdSucursal: number;
 *     IdPedido: number;
 *     Codigo: string;
 *     Corelativo: number;
 *     IdUsuario: number;
 *     Token: string;
 *     tickets: ITicket[];
 * }}
 */
const IData = null


/**
 * 
 * @param {IData} data 
 * @param {any} _socket 
 */
export async function stackUpTicketToPrint(data, _socket) {
    try {
        socket = _socket
        let { tickets } = data

        const { machineId, machineIdOriginal } = socketLocalPOSData
        console.log('[PRINT] machineId', machineId, machineIdOriginal);
        tickets =
            tickets
                .map(ticket => {
                    ticket.printers = ticket.printers.filter(printer =>
                        machineId && machineIdOriginal ?
                            (printer.machineId === machineId && printer.machineIdOriginal === machineIdOriginal) :
                            (!printer.machineId && !printer.machineIdOriginal)
                    )
                    return ticket
                })
                .filter(ticket => ticket.printers.length)

        emitOnReportPOSFeedbackTicket({
            IdStackItem: data.IdStackItem,
            IdSucursal: data.IdSucursal,
            IdPedido: data.IdPedido,
            status: 'RECEIVED_SUCCESS',
            receivedBy: {
                machineId,
                machineIdOriginal
            },
        })

        for (const ticket of tickets) {
            const pushData = {
                ...ticket,
                IdStackItem: data.IdStackItem,
                IdSucursal: data.IdSucursal,
                IdPedido: data.IdPedido,
                IdPublicoPedido: data.IdPublicoPedido,
                Codigo: data.Codigo,
                Corelativo: data.Corelativo,
                processed: false,
                Corelativo: data.Corelativo
            }
            const indexTicketRepeated = stackTitcketsToPrint.findIndex(t =>
                t.IdPedido === pushData.IdPedido &&
                t.content === pushData.content &&
                t.format === pushData.format &&
                !t.processed
            )

            if (indexTicketRepeated >= 0) {
                console.log(`[PRINT] el ticket ${data.Corelativo} ya existe en la pila`)
            } else {
                console.log(`[PRINT] agregando ticket ${data.Corelativo} a la pila`)
                stackTitcketsToPrint.push(pushData)
            }
            // setTimeout(stackTitcketsToPrint.shift, 1 * 60 * 60 * 1000) // 1h
        }
    } catch (error) {
        console.error(error)
    }
}


function initService() {
    stopService()
    service = setInterval(processStackTicketsToPrint, 2000);
}

function stopService() {
    service && clearInterval(service)
}

async function processStackTicketsToPrint() {
    try {
        if (isProcessingStack) {
            console.log('[PRINT] ...')
            return
        }

        isProcessingStack = true
        console.log('[PRINT] pila de impresion analizando')

        const ticketToPrint =
            stackTitcketsToPrint
                .sort((a, b) => a.Corelativo - b.Corelativo)
                .find(t => !t.processed)

        if (ticketToPrint) {
            console.log(`[PRINT] procesando ticket ${ticketToPrint.Corelativo}...`, ticketToPrint)

            const socketPrintResults = []

            const printersLive = await getPrinters()
            console.log('[PRINT] socketPrint.connected', socketPrint.connected)
            console.log('[PRINT] printersLive', JSON.stringify(printersLive))

            // const { machineId, machineIdOriginal } = socketLocalPOSData
            // const printers = ticketToPrint.printers
            //     .filter(printer =>
            //         machineId && machineIdOriginal ?
            //             (printer.machineId === machineId && printer.machineIdOriginal === machineIdOriginal) :
            //             (!printer.machineId && !printer.machineIdOriginal)
            //     )

            if (socketPrint.connected && !!printersLive.length) {
                let beforePrinter = ''
                for (const printer of ticketToPrint.printers) {

                    let countCopies = 1

                    while (countCopies <= printer.copies) {
                        console.log(`[PRINT] ticket ${ticketToPrint.Corelativo} mandando a imprimir copia ${countCopies} a ${printer.name}...`)

                        const socketPrintResult = await print({ url: ticketToPrint.url, printer: printer.name, })
                        console.log('[PRINT] result', socketPrintResult);
                        socketPrintResults.push(socketPrintResult)

                        emitOnReportPOSFeedbackTicket({
                            IdStackItem: ticketToPrint.IdStackItem,
                            IdSucursal: ticketToPrint.IdSucursal,
                            status: socketPrintResult ? 'SENDED_SUCCESS' : 'SENDED_FAIL',
                            IdPedido: ticketToPrint.IdPedido,
                            sendedSuccessRecord:
                                socketPrintResult ?
                                    {
                                        name: printer.name,
                                        countCopies,
                                        totalCopies: printer.copies
                                    } :
                                    undefined,
                            sendedFailRecord:
                                socketPrintResult ?
                                    undefined :
                                    {
                                        name: printer.name,
                                    }
                        })

                        socketPrintResult && (!beforePrinter || printer.name === beforePrinter) && countCopies < printer.copies && await sleep(1)

                        countCopies++
                        beforePrinter = printer.name
                    }
                }
            } else {
                socketPrintResults.push(false)
            }

            if (socketPrintResults.includes(false)) {
                const { data: { base64 } } = await getOrderPdf({ IdPublicoPedido: ticketToPrint.IdPublicoPedido, format: PRINT_FORMAT_THERMAL, content: PRINT_CONTENT_FULL, getBase64: true, })
                let countCopies = 1
                while (countCopies <= LIMIT_REPEAT_TICKET_PER_ORDER) {
                    console.log('[PRINT] No se pudo imprimir en socket, se solicita a browser!')

                    await new Promise((resolve, reject) => {
                        printJS({
                            printable: base64,
                            type: 'pdf',
                            base64: true,
                            // showModal: true,
                            onPrintDialogClose: resolve
                        })
                    })
                    await sleep(1)
                    countCopies++
                }

            }

            console.log(`[PRINT] ticket ${ticketToPrint.Corelativo} procesado!`)

            console.log('[PRINT] ........................................')

            await sleep(1)

            ticketToPrint.processed = true
            isProcessingStack = false
        } else {
            isProcessingStack = false
        }
    } catch (error) {
        isProcessingStack = false
        console.error('[PRINT]', error)
    }
}

/**
 * @param {{
 *  IdStackItem: string
 *  IdSucursal: number
 *  IdPedido: number
 *  status: 'RECEIVED_SUCCESS'| 'RECEIVED_FAIL' | 'WAITING_SEND_TO_PRINTER' | 'SENDED_SUCCESS' | 'SENDED_FAIL'
 *  receivedBy?: {
 *      machineId: string
 *      machineIdOriginal: string
 *  }
 *  sendedSuccessRecord?: {
 *      name: string
 *      countCopies: number
 *      totalCopies: number
 *  }
 *  sendedFailRecord?: {
 *      name: string,
 *  }
 * }} data 
 */
async function emitOnReportPOSFeedbackTicket(data) {
    try {
        socket && socket.emit('OnReportPOSFeedbackTicket', data)
    } catch (error) {
        console.error(error)
    }
}

export async function getOrderPdf({
    IdPublicoPedido,
    format = PRINT_FORMAT_THERMAL,
    content = PRINT_CONTENT_FULL,
    getBase64 = false,
}) {
    const request = {
        IdPublicoPedido,
        format,
        content,
        getBase64,
    }

    const requetStrinfify = JSON.stringify(request)
    let cache = cacheApiGetOrderPdf.find(c => c.request === requetStrinfify)

    if (!cache) {
        const response = await Axios.get(`${BACKEND_UTILS}/pos-ticket/${request.IdPublicoPedido}`, {
            params: {
                getBase64: request.getBase64,
                ticketFormat: request.format,
                content: request.content
            }
        })

        cache = {
            request: requetStrinfify,
            response
        }
        cacheApiGetOrderPdf.unshift(cache)

        setTimeout(cacheApiGetOrderPdf.pop, 60 * 60 * 1000) //10min
    }
    return cache.response
}


async function sleep(seg) {
    console.log('[PRINT] sleep', seg)
    await new Promise((resolve, reject) => { setTimeout(resolve, seg * 1000) })
}

