import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { APP_ENV, LAMBDA_POS } from '../../../config'
import TicketDrawerButton from '../../Orders/Actions/TicketDrawer.Button'
import { settingContext } from '../'
import Axios from 'axios'
import { ID_SUC_IMPRESORA } from '../../../redux/settings/types';
import { Icon, Spin } from 'antd'

const IdSucursalTest = 15
const IdsPedidosTest =
    APP_ENV === 'prod' ?
        [
            179892, 179892
        ] :
        [
            1414, 1528, 1820, 3093, 3094, 3095, 3096, 3097, 3098, 3099, 3100, 3101, 3102, 3103, 3104, 3105, 3106, 3108, 3109, 3110, 3111, 3113, 3114, 3115, 3116, 3117, 3118, 3119, 3120, 3121, 3122, 3124, 3127, 3128, 3129, 3130, 3133, 3143, 3150,
        ]


export default function ({ }) {
    const {
        initialValues,
        machineId,
        machineIdOriginal,
    } = useContext(settingContext)
    const Auth = useSelector(state => state.Auth)
    const [orderTest, setorderTest] = useState(null)
    const [loading, setloading] = useState(true)
    const { IdUsuario } = Auth.auth


    const getTestOrder = useCallback(
        () => {
            setloading(true)
            let sucursalPrinters = initialValues.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_IMPRESORA)?.Valor || '[]'
            sucursalPrinters = JSON.parse(sucursalPrinters)

            if (machineId && machineIdOriginal) {
                sucursalPrinters = sucursalPrinters.filter(sucursalPrinter => sucursalPrinter.machineId === machineId && sucursalPrinter.machineIdOriginal === machineIdOriginal)
            } else {
                sucursalPrinters = sucursalPrinters.filter(sucursalPrinter => !sucursalPrinter.machineId && !sucursalPrinter.machineIdOriginal)
            }
            sucursalPrinters = sucursalPrinters.filter(sucursalPrinter => !sucursalPrinter.disabled)

            Axios
                .post(`${LAMBDA_POS}/updateSucursalConfiguracion/${IdSucursalTest}`, {
                    Valor: JSON.stringify(sucursalPrinters),
                    IdTipoConfiguracion: ID_SUC_IMPRESORA,
                    IdUsuario,
                    machineId,
                    machineIdOriginal,
                }, {
                    headers: { "Content-Type": "application/json" }
                })
                .then(() =>
                    Axios.get(`${LAMBDA_POS}/getOrder/${IdSucursalTest}/${IdsPedidosTest[randomInteger(0, IdsPedidosTest.length - 1)]}`)
                )
                .then(response => setorderTest(response.data.order))
                .finally(() => {
                    setloading(false)
                })
        },
        [initialValues, initialValues, machineId, machineIdOriginal],
    )

    useEffect(() => {
        const debounce = setTimeout(getTestOrder, 1000)
        return () => {
            clearTimeout(debounce)
        }
    }, [getTestOrder])

    if (loading) {
        return <Spin indicator={<Icon type="loading" style={{ fontSize: `2.5em` }} />} />
    }

    return orderTest && <TicketDrawerButton orders={[orderTest]} icon={false} text='Probar configuración de Tickets' />
}


function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}