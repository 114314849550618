import { Switch } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { ID_SUC_POS_LIDER_IMPRESION } from '../../../redux/settings/types'
import { settingContext } from '../'



export default function ({ style, printers }) {
    const {
        IdSucursal,
        initialValues,
        saveSettingSucursal,
        machineId,
        machineIdOriginal
    } = useContext(settingContext)
    const [canBeLeader, setcanBeLeader] = useState(true)
    const [checked, setchecked] = useState(false)
    const [loading, setloading] = useState(false)


    useEffect(() => {
        if (machineId && machineIdOriginal && printers.some(p => p.checked)) {
            setcanBeLeader(true)
        } else {
            setcanBeLeader(false)
        }
    }, [IdSucursal, machineId, machineIdOriginal, printers])

    useEffect(() => {
        if (canBeLeader) {
            let configLeader = initialValues.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_POS_LIDER_IMPRESION)?.Valor || '{}'
            configLeader = JSON.parse(configLeader)

            setchecked(configLeader.machineId === machineId && configLeader.machineIdOriginal === machineIdOriginal)

            setloading(false)

        }
    }, [canBeLeader, initialValues, machineId, machineIdOriginal])


    function handleOnChange(nextChecked) {
        setloading(true)
        saveSettingSucursal({
            Valor: nextChecked ?
                JSON.stringify({
                    machineId,
                    machineIdOriginal
                }) :
                null,
            IdTipoConfiguracion: ID_SUC_POS_LIDER_IMPRESION
        }).finally(() => {
            setchecked(nextChecked)
            setloading(false)
        })
    }


    if (!canBeLeader) {
        return null
    }

    return <div style={{ ...style }}>
        <p>
            <span style={{ float: 'left', color: 'black' }}><b>POS Lider de Impresión</b></span>
        </p>
        <br />
        <Switch loading={loading} checked={checked} onChange={handleOnChange} />
    </div>
}