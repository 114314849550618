const orderActions = {

    INIT_ORDER: 'INIT_ORDER',
    INIT_ORDER_SUCCESS: 'INIT_ORDER_SUCCESS',

    ERROR: 'ERROR',

    MOVE_BEETWEEN_LIST: 'MOVE_BEETWEEN_LIST',
    MOVE_BEETWEEN_LIST_SUCCESS: 'MOVE_BEETWEEN_LIST_SUCCESS',

    REFRESH_DATA_ORDER: 'REFRESH_DATA_ORDER',
    REFRESH_DATA_ORDER_SUCCESS: 'REFRESH_DATA_ORDER_SUCCESS',

    PUSH_IN_LIST: 'PUSH_IN_LIST',
    PUSH_IN_LIST_SUCCESS: 'PUSH_IN_LIST_SUCCESS',

    LOAD_MAPS_TRACKING: 'LOAD_MAPS_TRACKING',
    LOAD_PEDIDO_SEGUIMIENTOS: 'LOAD_PEDIDO_SEGUIMIENTOS',

    RECEIVE_SOCKET_LIVE_INFO: 'RECEIVE_SOCKET_LIVE_INFO',

    initOrders: (payload, callback) => ({
        type: orderActions.INIT_ORDER,
        payload,
        callback
    }),
    moveBeetweenList: (payload) => ({
        type: orderActions.MOVE_BEETWEEN_LIST,
        payload
    }),
    /**
     * 
     * @param {{
     *  IdPedido?: number
     *  IdSucursal?: number
     *  order?: number
     *  callback?: () => void
     * }} payload 
     * @returns
     */
    refreshDataOrder: (payload) => ({
        type: orderActions.REFRESH_DATA_ORDER,
        payload
    }),
    loadMapsTracking: (payload) => ({
        type: orderActions.LOAD_MAPS_TRACKING,
        payload
    }),
    loadPedidoSeguimientos: (payload) => ({
        type: orderActions.LOAD_PEDIDO_SEGUIMIENTOS,
        payload
    }),
    pushInList: (payload) => ({
        type: orderActions.PUSH_IN_LIST,
        payload
    }),

    receiveSocketLiveInfo: (payload) => ({
        type: orderActions.RECEIVE_SOCKET_LIVE_INFO,
        payload
    })
}
export default orderActions;