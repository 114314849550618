import React, { useState } from 'react';
import { Row, Col, Divider, } from 'antd';
import SettingCantidadTicketPorOrden from './SettingCantidadTicketPorOrden';
import SettingSelectPrinters from './SettingSelectPrinters';
import SettingPosPrinterLeader from './SettingPosPrinterLeader';
import Test from './Test';
import DownloadDesktopPOS from '../SettingAuto/DownloadDesktopPOS';



export default () => {
    const [printers, setprinters] = useState([])

    return <>
        <Row type="flex" justify="center">
            <Col span={18}>
                <h1 style={{ float: 'left' }}><b>Cantidad de Tickets por Orden</b></h1>
                <br />
                <SettingCantidadTicketPorOrden></SettingCantidadTicketPorOrden>
            </Col>
        </Row>
        <Divider />
        <Row type="flex" justify="center">
            <Col span={18}>
                <p>
                    <span style={{ float: 'left', color: 'black' }}><b>Seleccione Impresoras del Ticket</b></span>
                </p>
                <br />
                <br />
                <SettingSelectPrinters onChange={setprinters} />
            </Col>
        </Row>

        <Row type="flex" justify="center">
            <Col span={18}>
                <SettingPosPrinterLeader
                    style={{ marginTop: 15 }}
                    printers={printers}
                />
            </Col>
        </Row>

        <Row type="flex" justify="center">
            <Col span={18}>
                <p>
                    <h1 style={{ float: 'left' }}><b>Descargar versión de escritorio</b></h1>
                </p>
                <br />
                <div style={{ textAlign: 'right' }}>
                    <DownloadDesktopPOS />
                </div>
            </Col>
        </Row>

        <Divider />

        <Test />

    </>
}