import { Card, Col, Drawer, Icon, notification, Row, Spin, Typography } from 'antd'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { LAMBDA_POS } from '../../../config'
import useWindowSize from '../../../library/hooks/useWindowSize'

const iconColor = '#1890ff'
const iconSize = '2.5em'
const IconBox = <svg width={iconSize} height={iconSize} viewBox="0 0 16 16" className="bi bi-box-seam" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
</svg>

export default function ({ order }) {
    const { height, width } = useWindowSize()
    const [visibleEntrega, setVisibleEntrega] = useState(false)
    const [pedidoSeguimientos, setpedidoSeguimientos] = useState([])
    const [loading, setloading] = useState(false)

    useEffect(() => {
        setpedidoSeguimientos([])
        setloading(false)
        setVisibleEntrega(false)
    }, [order])

    async function handleOnClickVerEntrega() {
        getOrderTracking()
    }

    function getOrderTracking() {
        setloading(true)
        setpedidoSeguimientos([])

        Axios.get(`${LAMBDA_POS}/getPedidoSeguimientoByIdPublico/${order.IdPublicoPedido}`)
            .then(response => {
                setpedidoSeguimientos(response.data.pedidoSeguimientos)
                setVisibleEntrega(true)
            })
            .catch(err => {
                notification.warning({ message: 'No es posible obtener las evidencias en estos momentos' })
            })
            .finally(() => {
                setloading(false)
            })
    }


    return (
        <>
            <div style={{ margin: '0 5px' }}>
                {
                    loading ?
                        <Spin indicator={<Icon type="loading" style={{ fontSize: iconSize }} />} /> :
                        <a onClick={handleOnClickVerEntrega}>
                            {IconBox}
                        </a>

                }
            </div>

            <Drawer
                title={<span style={{ color: 'white' }}>
                    <Typography.Text type="secondary">
                        {order.Codigo}-{order.Corelativo}
                    </Typography.Text>
                </span>}
                visible={visibleEntrega}
                onClose={() => setVisibleEntrega(false)}
                // width={width / 3}
                width={'500px'}
                headerStyle={{ borderBottom: 0 }}
                bodyStyle={{}}
            >

                <Row gutter={[8, 16]}>
                    {
                        order.Comentario && <Col span={24}>
                            <b>Nota: </b> {order.Comentario}
                        </Col>
                    }
                    {
                        pedidoSeguimientos.map((pedidoSeguimiento, i) =>
                            <Col key={i} span={12}>
                                <Card
                                    // hoverable
                                    style={{ width: '100%' }}
                                    cover={<img width={'200px'} alt={pedidoSeguimiento.tipo.Descripcion} src={pedidoSeguimiento.Evidencia}></img>}
                                >
                                    <Card.Meta title={''} description={pedidoSeguimiento.tipo.Descripcion} />
                                </Card>
                            </Col>
                        )
                    }
                </Row>


            </Drawer>
        </>
    )
}