import { all, takeEvery, fork, put, call } from 'redux-saga/effects';
import axios from 'axios'

import { LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_RIDERS_WAITING, MARK_AS_READ, UPDATE_NOTIFICATION_SUCCESS } from './actions';
import { LAMBDA_POS } from "../../config/"

function* loadAll() {
    yield takeEvery(LOAD_ALL, function* ({ payload }) {

        const { IdSucursal, createdAt, callback } = payload

        const data = yield call(callGetAll, IdSucursal, createdAt)

        yield put({
            type: LOAD_ALL_SUCCESS,
            payload: data
        })

        const riders = yield call(getRidersWaiting, IdSucursal)

        yield put({
            type: LOAD_RIDERS_WAITING,
            payload: riders.riders
        })

        callback && setTimeout(callback, 1000);
    })
}

async function callGetAll(IdSucursal, createdAt) {
    return axios.get(`${LAMBDA_POS}/getAllSucursalNotificaciones/${IdSucursal}`, {
        params: {
            createdAt
        }
    })
        .then((response) => response.data)
        .catch((err) => {
            console.warn('[getAllSucursalNotificaciones] catch', err)
            return err.response
        });
}

async function getRidersWaiting(IdSucursal) {
    return axios.get(`${LAMBDA_POS}/getRiderWaitingSucursal`, {
        params: {
            IdSucursal
        }
    })
        .then((response) => response.data)
        .catch((err) => {
            return err.response
        })
}

function* markAsRead() {
    yield takeEvery(MARK_AS_READ, function* ({ payload }) {

        const { Id, callback } = payload

        const notificacion = yield call(callUpdateSucursalNotificacionLeido, Id)

        yield put({
            type: UPDATE_NOTIFICATION_SUCCESS,
            payload: notificacion
        })

        callback && setTimeout(callback, 1000);
    })
}


async function callUpdateSucursalNotificacionLeido(Id) {
    const { data } = await axios.patch(`${LAMBDA_POS}/updateSucursalNotificacionLeido/${Id}`)
    return data.notificacion;
}


export default function* rootSaga() {
    yield all([
        fork(loadAll),
        fork(markAsRead),
    ]);
}