import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, notification, Select, Spin, Icon } from 'antd';
import { useHistory } from 'react-router';

import AuthActions from '../../redux/auth/actions'

import appActions from '@iso/redux/app/actions';
import TopbarTimerNotification from './TopbarTimerNotification';
import TopbarNotification from './TopbarNotification';
// import TopbarMessage from './TopbarMessage';
// import TopbarSearch from './TopbarSearch';
// import TopbarAddtoCart from './TopbarAddToCart';
import TopbarUser from './TopbarUser';
import TopbarWrapper from './Topbar.styles';
import PlayPause from "../schedule/PlayPause/PlayPause";
import TopbarSearchInput from './TopbarSearchInput';
import useFilterSucursal from '../../hooks/useFilterSucursal/useFilterSucursal'
import { Box, Button, Typography } from '@mui/material';
import TopbarModalSucursales from './TopbarModalSucursales';
import TopbarRiderWaiting from './TopbarRiderWaiting';

const { Header } = Layout;
const { toggleCollapsed } = appActions;

export default function Topbar() {
    const Auth = useSelector(state => state.Auth)
    const customizedTheme = useSelector(state => state.ThemeSwitcher.topbarTheme);
    const { collapsed, openDrawer } = useSelector(state => state.App);
    const dispatch = useDispatch();
    const { FilterSucursalLabel } = useFilterSucursal()
    const history = useHistory()
    const [selectedItem, setSelectedItem] = useState('');
    const [showModal, setShowModal] = useState(false);
    const handleToggle = React.useCallback(() => dispatch(toggleCollapsed()), [
        dispatch,
    ]);
    const isCollapsed = collapsed && !openDrawer;
    const styling = {
        background: customizedTheme.backgroundColor,
        position: 'fixed',
        width: '100%',
        height: 70,
    };

    const [loadingSucursal, setloadingSucursal] = useState(false)

    function handleOnChangeSucursal(Id) {
        setloadingSucursal(true)

        Auth.payload.usuarioPerfil = Auth.payload.usuarioPerfiles.find(up => up.Id === Id)

        localStorage.removeItem("SelectedSucursales")
        localStorage.setItem('LastIdSucursal', Auth.payload.usuarioPerfil.sucursal.IdSucursal.toString())

        history.push("/dashboard")

        return window.location.reload()


        dispatch(AuthActions.loginWithData(Auth))
        dispatch(appActions.clearMenu())
        setloadingSucursal(false)
    }

    const SelectSucursales = Auth.payload.usuarioPerfiles && Auth.payload.usuarioPerfiles.length > 1 && <Select
        loading={loadingSucursal}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        style={{ width: 400 }}
        value={Auth.payload.usuarioPerfil.Id}
        onChange={handleOnChangeSucursal}
    >
        {
            Auth.payload.usuarioPerfiles.map((usuarioPerfil, k) => {
                return (
                    <Select.Option key={usuarioPerfil.Id} value={usuarioPerfil.Id}>
                        {`${usuarioPerfil.sucursal.Titulo} | ${usuarioPerfil.sucursal.comercio.Nombre}`}
                    </Select.Option>
                )
            })
        }
    </Select>

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <TopbarWrapper>
            <Header
                style={styling}
                className={
                    isCollapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
                }
            >
                <div className="isoLeft">
                    <button
                        className={
                            isCollapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
                        }
                        style={{ color: customizedTheme.textColor }}
                        onClick={handleToggle}
                    />
                </div>

                <div>
                    <TopbarSearchInput />
                </div>

                <ul className="isoRight">
                    <li>
                        <TopbarRiderWaiting />
                    </li>
                    <li>
                        <TopbarNotification />
                    </li>

                    <li>
                        <TopbarTimerNotification />
                    </li>

                    <Box>
                        <Button
                            variant='text'
                            color='inherit'
                            size='small'
                            sx={{ textTransform: 'initial' }}
                        >
                            <FilterSucursalLabel onclick={openModal} />
                        </Button>
                        <TopbarModalSucursales
                            showModal={showModal}
                            closeModal={closeModal}
                        />
                    </Box>
                    <li>
                        <PlayPause />
                    </li>

                    <li onClick={() => setSelectedItem('user')} className="isoUser">
                        <TopbarUser
                            SelectSucursales={SelectSucursales}
                            loadingSucursal={loadingSucursal}
                        />
                    </li>
                </ul>
            </Header>
        </TopbarWrapper >
    );
}
