import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { PANEL_CONTENIDO_ADICIONAL, TYPE_FLASH } from "../../../redux/pedidoEspecial/types"
import { setContent, setComment } from "../../../redux/pedidoEspecial/actions"

const ContenidoAdicional = ({ changePanel }) => {
    const dispatch = useDispatch()
    const { activePanel, type, content, comment } = useSelector(state => state?.PedidoEspecial)
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))

    const changeContent = async (content) => {
        await dispatch(setContent({ content }))
    }

    const changeComment = async (comment) => {
        await dispatch(setComment({ comment }))
    }

    return (
        <>
            <Accordion
                expanded={(activePanel === PANEL_CONTENIDO_ADICIONAL) ? true : false}
                onChange={() => changePanel(PANEL_CONTENIDO_ADICIONAL)}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack
                        direction={(Mediaquery) ? 'column' : 'row'}
                        justifyContent='space-between'
                        sx={{
                            width: {
                                lg: '50%',
                                md: '80%',
                                sm: '100%',
                            }
                        }}
                    >
                        <Typography variant="h6">
                            ¿Qué necesitas enviar?
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            px: {
                                md: 5,
                                xs: 0,
                            }
                        }}
                    >
                        {
                            (type === TYPE_FLASH) && (
                                <TextField
                                    label='Describe el contenido del envío'
                                    placeholder="Detalla bien todo el contenido para el envío."
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={(content) ? content : ''}
                                    onChange={(e) => changeContent(e?.target?.value)}
                                />
                            )
                        }

                        <TextField
                            label='Indicaciones adicionales para el Rider'
                            placeholder="Indica si es frágil, si debe mantener temperatura, si puede derramarse, etc."
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ mt: 2 }}
                            value={(comment) ? comment : ''}
                            onChange={(e) => changeComment(e?.target?.value)}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default memo(ContenidoAdicional)