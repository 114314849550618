import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import Menu from '@iso/components/uielements/menu';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Icon } from 'antd';
import { latLng } from 'leaflet';
import { Chip } from '@mui/material';
import { palette } from 'styled-theme';
const SubMenu = Menu.SubMenu;

const IconCocina = () => <i className='anticon'>
  <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 512 512" width="1.2em" height="1.2em" fill='#28a745'>
    <path d="M392,48a104.462,104.462,0,0,0-42.239,8.947,127.885,127.885,0,0,0-187.579,0A104.006,104.006,0,1,0,93.621,252.623L120,408.672V488a8,8,0,0,0,8,8H384a8,8,0,0,0,8-8V408.672l26.379-156.049A104.014,104.014,0,0,0,392,48ZM376,480H136V416H376Zm33.771-241.794a8,8,0,0,0-6.281,6.5L377.239,400H328.592l7.387-103.43h-.008c.014-.189.029-.377.029-.569a8,8,0,0,0-15.971-.569h-.008L312.551,400H264V312a8,8,0,0,0-16,0v88H199.449l-7.47-104.57h-.008A8,8,0,0,0,176,296c0,.192.015.38.029.569h-.008L183.408,400H134.761L108.51,244.709a8,8,0,0,0-6.281-6.5A88.009,88.009,0,1,1,208,152a8,8,0,0,0,16,0,103.994,103.994,0,0,0-47.262-87.116,112.016,112.016,0,0,1,187.237,49.244,8,8,0,1,0,15.422-4.256,126.93,126.93,0,0,0-19.08-39.994A87.115,87.115,0,0,1,392,64a88.01,88.01,0,0,1,17.771,174.206Z" />
  </svg>
  <b></b>
</i>
const IconProductos = () => <i className='anticon'>
  <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-egg" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M8 15a5 5 0 0 0 5-5c0-1.956-.69-4.286-1.742-6.12-.524-.913-1.112-1.658-1.704-2.164C8.956 1.206 8.428 1 8 1c-.428 0-.956.206-1.554.716-.592.506-1.18 1.251-1.704 2.164C3.69 5.714 3 8.044 3 10a5 5 0 0 0 5 5zm0 1a6 6 0 0 0 6-6c0-4.314-3-10-6-10S2 5.686 2 10a6 6 0 0 0 6 6z" />
  </svg>
</i>

const IconPlatoOk = () => <i className='anticon'>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101" width="1.2em" height="1.2em" fill='#17a2b8'>
    <title>Artboard 2 copy</title>
    <path d="M87.56,64,67.66,75.79,60.08,71h9.53a5.5,5.5,0,0,0,0-11H53.24a15.19,15.19,0,0,0-5.51,1L35,66.78V65.5A1.5,1.5,0,0,0,33.5,64h-11A1.5,1.5,0,0,0,21,65.5v25A1.5,1.5,0,0,0,22.5,92h11A1.5,1.5,0,0,0,35,90.5V89H66.09a9.32,9.32,0,0,0,5.48-1.77L92.83,72A4.83,4.83,0,0,0,87.56,64ZM32,89H24V67h8ZM91.09,69.61,69.82,84.8A6.35,6.35,0,0,1,66.09,86H35V70.07l13.88-6.27A12.13,12.13,0,0,1,53.24,63H69.61a2.5,2.5,0,0,1,0,5H54.9a1.5,1.5,0,0,0-.8,2.77l12.73,8a1.5,1.5,0,0,0,1.57,0l20.7-12.29a1.83,1.83,0,0,1,2,3.06Z" /><path d="M94.5,52H91A38.55,38.55,0,0,0,57.35,14.82a6.5,6.5,0,1,0-9.69,0A38.54,38.54,0,0,0,14,52H9.5a1.5,1.5,0,0,0,0,3h85a1.5,1.5,0,0,0,0-3ZM52.5,7A3.5,3.5,0,1,1,49,10.5,3.5,3.5,0,0,1,52.5,7Zm0,10.5A35.53,35.53,0,0,1,88,52H17A35.53,35.53,0,0,1,52.5,17.5Z" /><path d="M44.44,24.61a29.46,29.46,0,0,0-18.8,16.17A1.5,1.5,0,1,0,28.37,42,26.47,26.47,0,0,1,45.26,27.5a1.5,1.5,0,1,0-.82-2.89Z" />
  </svg>
</i>

const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};
export default React.memo(function SidebarMenu({
  singleOption,
  submenuStyle,
  submenuColor,
  data,
  ...rest
}) {
  const allOrders = useSelector(state => state.Ordenes)
  const Auth = useSelector(state => state.Auth)
  const isMarket = Auth.payload.usuarioPerfil.sucursal.market && Auth.payload.usuarioPerfil.sucursal.market.length > 0

  let match = useRouteMatch();
  const { key, label, leftIcon, children, isNew } = singleOption;

  const url = stripTrailingSlash(match.url);
  const [icon, seticon] = useState()
  const [count, setcount] = useState(0)
  const location = useLocation()

  let searchUrl = location.search
  if (searchUrl !== null && searchUrl !== "") {
    let realPath = location.pathname.split('/')
    rest.selectedKeys[0] = (realPath?.[2]) ? realPath?.[2] : rest.selectedKeys[0]
  }

  useEffect(() => {
    setcount({
      orders: allOrders.orders.length,
      activeorders: allOrders.ordersActive.length,
      readyorders: allOrders.ordersReady.length,
      endorders: allOrders.ordersEnd.length,
      rejectedOrders: allOrders.ordersRejected.length + allOrders.ordersRefunded.length,
      scheduledOrders10: allOrders.ordersScheduled.length,
      agilFavour: allOrders.ordersScheduled.length,
    }[key])

    seticon({
      orders: <Icon type="thunderbolt" theme="twoTone" twoToneColor='#ffc107' />,
      // activeorders: <Icon type="right-circle" theme="twoTone" twoToneColor='#28a745' />,
      activeorders: isMarket ? <Icon type="shopping-cart" twoToneColor='#ffc107' /> : <IconCocina />,
      // readyorders: <Icon type="flag" theme="twoTone" twoToneColor='#17a2b8' />,
      readyorders: isMarket ? <Icon type="like" theme="twoTone" twoToneColor='#17a2b8' /> : <IconPlatoOk />,
      endorders: <Icon type="snippets" theme="twoTone" twoToneColor='#6c757d' />,
      rejectedOrders: <Icon type="close-circle" theme="twoTone" twoToneColor='#dc3545' />,
      scheduledOrders: <Icon type="calendar" theme="twoTone" twoToneColor='#dc3545' />,
      products: <IconProductos />,
      settings: <Icon type="setting" theme="twoTone" twoToneColor='#6c757d' />,
      agilFavour: <Icon type="tags-o" theme="outlined" style={{ color: '#fe3814' }} />,
      history: <Icon type="container" theme="twoTone" twoToneColor='#0097a7' />,
    }[key])
  }, [allOrders, key])

  if (key === 'rejectedOrders' && !count) {
    return null
  }


  if (children) {
    return (
      <SubMenu
        key={key}
        title={
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        }
        {...rest}
      >
        {children.map(child => {
          const linkTo = child.withoutDashboard
            ? `/${child.key}`
            : `${url}/${child.key}`;
          return (
            <Menu.Item style={submenuStyle} key={child.key}>
              <Link style={submenuColor} to={linkTo}>
                <IntlMessages id={child.label} />
              </Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  }

  return (
    <Menu.Item key={key} {...rest}>
      <Link to={`${url}/${key}`}>
        <span className="isoMenuHolder" style={submenuColor}>
          {icon || <i className={leftIcon} />}

          <b>
            {count}
          </b>
          <span className="nav-text" style={{ margin: '0 0 0 5px' }}>
            {label}
            {isNew &&
              <Chip
                sx={{
                  bgcolor: palette('primary', 2),
                  color: 'white',
                  ml: 1
                }}
                label={'new'}
              />
            }
          </span>
        </span>
      </Link>
    </Menu.Item>
  );
});
