import React from 'react'
import { Badge, Card, Icon, Tag } from 'antd'
import { useSelector } from 'react-redux'
import currencyFormatter from 'currency-formatter';
import currencyFormatterOptions from '../../../config/currencyFormatterOptions.json'
import { ID_PED_PAG_ESTA_PENDIENTE_PAGO, PED_ENTRE_TIPO_ENTRE_DELIVERY, PED_ENTRE_TIPO_ENTRE_MESA, PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA, PED_ENTRE_TIPO_FECHA_ENTRE_ANTES_POSIBLE, PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG } from '../../../redux/ordenes/types'
import Flag from '../../../components/Orders/Flags'
import SucursalTitle from '../../../components/Orders/SucursalTitle';
export default ({ order, i, onSelected, active }) => {
    const Auth = useSelector(state => state.Auth)

    const styleP = {
        margin: '5px 0',
        textAlign: 'right'
    }
    const styleSelected = {
        border: '3px solid yellow'
    }
    return (
        <div style={{
            ...(active ? styleSelected : {})
        }} onClick={() => onSelected(i)}>
            <Card
                type="inner"
                style={{ width: '100%' }}
                title={
                    <>
                        <SucursalTitle
                            sucursal={order?.IdSucursal}
                            isCard={true}
                            platformName={order?.extOrder?.plataforma?.Nombre || ''}
                            plataformCode={order?.extOrder?.plataforma?.Codigo}
                        />
                        <Flag
                            color={(order?.etiqueta !== undefined && order?.etiqueta !== null && order?.etiqueta.length > 0) ? order?.etiqueta[0]?.etiqueta_comercio?.etiqueta?.ColorComponente : 'default'}
                            title={(order?.etiqueta !== undefined && order?.etiqueta !== null && order?.etiqueta.length > 0) ? order?.etiqueta[0]?.etiqueta_comercio?.Nombre : ''}
                            idPedido={order?.IdPedido}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alingItems: 'center' }}>
                            <p style={{ textTransform: 'capitalize' }}>
                                {order.IdTipoPedido === 5 ? `#${order?.extOrder?.CodigoExterno}${order?.extOrder?.CorrelativoExterno ? `-${order?.extOrder?.CorrelativoExterno}` : ''}` : `#${order.Codigo}`}
                            </p>
                            <p style={{ textTransform: 'capitalize' }}>
                                <span>
                                    {order.IdTipoPedido === 5 ?
                                        `${order?.extOrder?.NombreCliente} ${order?.extOrder?.ApellidoCliente}`
                                        :
                                        `${order.cliente ? order.cliente.Nombre : order.sucursalEntrega ? order.sucursalEntrega.Titulo : ''} ${order.cliente ? order.cliente.Apellido : ''}`}
                                </span>
                            </p>
                        </div>
                    </>
                }
            >
                <p style={styleP}>
                    <span style={{ float: "left" }}>
                        {order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY && <img style={{ width: '30px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_deliveryV2.svg'></img>}
                        {order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA && <img style={{ width: '30px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_retiro.svg'></img>}
                        {
                            order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_MESA &&
                            <Badge
                                count={order.mesa?.Nro}
                                style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                                offset={[5, 30]}
                            >
                                <img style={{ width: '30px' }} src='https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_mesa.svg' />
                            </Badge>
                        }
                    </span>
                    <Tag>
                        <img style={{ width: '9px' }} src="https://agil.s3.us-east-2.amazonaws.com/Logos/Icon_Cubiertos.svg"></img> &nbsp;
                        {order.productos.length + (order.extOrder ? order.extOrder.Productos.length : 0) || 0} items
                    </Tag>
                    <Tag>
                        <Icon type="dollar-circle" theme="filled" /> &nbsp;
                        {currencyFormatter.format(order.TotalOC - order.Propina - order.PropinaAgil - order.CuotaServicioAgil, { ...currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda], symbol: '' })}
                    </Tag>

                </p>
                <p style={styleP}>
                    <Tag  >
                        {
                            [PED_ENTRE_TIPO_FECHA_ENTRE_ANTES_POSIBLE].includes(order.entrega.TipoFechaEntrega) && <Icon type="thunderbolt" theme="filled" />
                        }
                        {
                            // 2 ????
                            [2, PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG].includes(order.entrega.TipoFechaEntrega) && <Icon type="clock-circle" theme="filled" />
                        }
                        &nbsp; {order.entrega.tipoFechaEntrega.Tipo}
                    </Tag>
                </p>
                {order.clienteCompra && order.clienteCompra.pagos[0] && order.clienteCompra.pagos[0].IdEstado === ID_PED_PAG_ESTA_PENDIENTE_PAGO ?
                    <p style={styleP}>
                        <Tag color="volcano"  >
                            <Icon type="credit-card" theme="filled" />
                            &nbsp; Pendiente de pago
                        </Tag>
                    </p>
                    :
                    <></>

                }

            </Card>
        </div>
    )
}