
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Select, notification, Icon, Button, Modal, Switch, TimePicker, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import moment from 'moment-timezone'

import { settingContext } from '../'
import { LAMBDA_POS } from '../../../config';


export default () => {
    const { IdSucursal, initialValues } = useContext(settingContext)
    const [loading, setloading] = useState(false)
    const [prepaCocinafechaEntregaMax, setprepaCocinafechaEntregaMax] = useState('Cargando...')
    const Auth = useSelector(state => state.Auth)
    const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona
    const [now, setnow] = useState(true)
    const [custom, setcustom] = useState(moment.tz(tz))
    const [modalvisible, setmodalvisible] = useState(false)
    const [submitting, setsubmitting] = useState(false)


    useEffect(() => {
        getTiemposEntregaCocina()
        let timeout = setTimeout(() => {
            getTiemposEntregaCocina()
        }, 60000)
        return () => {
            clearTimeout(timeout)
        }
    }, [IdSucursal, initialValues])

    useEffect(() => {
        !now && setcustom(moment.tz(tz))
    }, [now])

    useEffect(() => {
        setnow(true)
    }, [modalvisible])

    const getTiemposEntregaCocina = useCallback(
        () => {
            Axios
                .get(`${LAMBDA_POS}/calculateTiemposEntregaCocina/${IdSucursal}`)
                .then(response => {
                    setprepaCocinafechaEntregaMax(response.data.tiemposEntregaCocina.fechaEntregaMax.slice(-8, -3))
                })
                .catch(console.error);
        },
        [IdSucursal],
    )

    const handleOnReset = useCallback(
        () => {
            setmodalvisible(true)
        },
        [],
    )

    const handleOnResetConfirm = useCallback(
        () => {
            setsubmitting(true)
            return Axios
                .patch(`${LAMBDA_POS}/resetPreparacionCocina/${IdSucursal}`, {
                    tz,
                    ...now ?
                        {} :
                        {
                            hour: custom.format('HH'),
                            minute: custom.format('mm')
                        }
                })
                .then(response => {
                    notification.success({ message: 'Hora reseteada' })
                    setmodalvisible(false)
                })
                .catch((err) => {
                    notification.error({ message: err.response?.data?.message || 'No es posible realizar esta acción en estos momentos' })
                })
                .finally(() => {
                    setsubmitting(false)
                    getTiemposEntregaCocina()
                })
        },
        [IdSucursal, tz, now, custom, getTiemposEntregaCocina],
    )

    return <>
        <p>
            <Icon type="reload" onClick={handleOnReset} />
            &nbsp;
            {prepaCocinafechaEntregaMax}

        </p>
        <Modal
            visible={modalvisible}
            title='¿Resetear?'
            okText='Confirmar'
            cancelText='Cancelar'
            onOk={handleOnResetConfirm}
            onCancel={() => setmodalvisible(false)}
            confirmLoading={submitting}
        >
            <div style={{ display: 'flex' }}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        Las proximas horas de preparacion de cocina se calcularán desde la hora especificada
                    </Col>

                    <Col span={8}>
                        ¿Utilizar hora actual?
                    </Col>
                    <Col span={16}>
                        <Switch checked={now} onChange={setnow} />
                    </Col>
                    {
                        !now &&
                        <>
                            <Col span={8}>
                                Hora personalizada
                            </Col>
                            <Col span={16}>
                                <TimePicker
                                    {...submitting ? {
                                        suffixIcon: <Icon type='loading' />
                                    } : {}}
                                    disabled={submitting}
                                    style={{ width: '200px' }}
                                    format={'HH:mm'}
                                    value={custom}
                                    onChange={(value, formattedValue) => {
                                        setcustom(value.tz(tz))
                                    }}
                                />
                            </Col>
                        </>
                    }
                </Row>

            </div>



        </Modal>

    </>

}