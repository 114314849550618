export const COLOR_BG_AGIL = '#fe3814'
export const COLOR_BG_RAPPI = '#ff441f'
export const COLOR_BG_PEDIDOSYA = '#fa0050'
export const COLOR_BG_UBEREATS = '#06c167'
export const COLOR_BG_JUSTO = '#111827'
export const COLOR_WHITE = '#f9f9f9'
export const COLOR_ABSOLUTE_WHITE = '#ffffff'
export const COLOR_BLACK = '#363738'
export const COLOR_LIGHT_GRAY = '#E5E5E5'
export const COLOR_GREEN = '#4caf50'
export const COLOR_YELLOW = '#ffcd38'
export const COLOR_BLUE = '#1890ff'
export const COLOR_LIGTH_BLUE = '#C2E1FE'
export const COLOR_RED = '#dc3545'
export const COLOR_ORANGE = '#ff9800'