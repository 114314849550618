import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Col, Input, Row, Table, Button, Layout, Typography, Card, Divider, Drawer, Form, Badge } from 'antd'
import { Icon } from 'antd';
import { InputSearch } from "@iso/components/uielements/input"
import { ContactListWrapper } from "@iso/components/Orders/OrderList.style"
import { ContactsWrapper } from "./Orders.styles"




import {
    ID_PEDIDO_ESTADO_ENTREGADO,
    ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL,
    PED_ENTRE_TIPO_ENTRE_DELIVERY,
    PED_ENTRE_TIPO_ENTRE_MESA,
    PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA
} from '../../redux/ordenes/types';
import OrderRiders, { getDistanceText } from './OrderDetails/Order-Riders';
import { fixTelefono } from '../../helpers/fixes';
import currencyFormatter from 'currency-formatter';
import currencyFormatterOptions from '../../config/currencyFormatterOptions.json'
import RateRidersButtons from './Actions/RateRidersButtons';
// import Text from 'antd/lib/typography/Text';
import TicketDrawerButton from './Actions/TicketDrawer.Button';
import TrackingOrderButton from './Actions/TrackingOrderButton';
import SplitOrderDeliveryButton from './Actions/SplitOrderDelivery.Button';
import EvidenceButton from './Actions/EvidenceButton';
import PedidoSeguimientoButton from './Actions/PedidoSeguimiento.Button';
import OrderPayment from './OrderDetails/Order-Payment';
import OrderProducts from './OrderDetails/Order-Products';
import OrderTotals from './OrderDetails/Order-Totals';
import ClientHistory from './OrderDetails/Client-History';
import PedidoEstadoTag from './Tags/PedidoEstado.Tag';

const TEXT_RETIRO_TIENDA = 'RETIRO EN TIENDA'
const DRAWER_CONTENT_HISTORY_IN_POS = 'DRAWER_CONTENT_HISTORY_IN_POS'
const TEXT_MESA = 'ENTREGA EN MESA'

export default function () {

    const { Content } = Layout;
    const { Text, Title, Paragraph } = Typography;

    const Auth = useSelector(state => state.Auth)
    const Ordenes = useSelector(state => state.Ordenes)

    const [data, setdata] = useState([])
    const [search, setsearch] = useState('')
    const [showForm, setShowForm] = useState(false)

    const [drawerData, setdrawerData] = useState({
        title: '',
        visible: false,
        content: null,
        props: {}
    })


    useEffect(() => {
        const debounce = setTimeout(() => {
            if (search) {
                setdata(Ordenes.ordersEnd.filter(order =>
                    order.estado.Nombre.toLowerCase().includes(search) ||
                    order.Codigo.toLowerCase().includes(search) ||
                    `-${order.Corelativo}`.toString().includes(search) ||
                    order.cliente.Nombre.toLowerCase().includes(search) ||
                    // (order.TotalOC).toString().toLowerCase().includes(search) ||
                    (order.TotalOC - order.Propina - order.PropinaAgil - order.CuotaServicioAgil).toString().toLowerCase().includes(search) ||
                    (order.entrega.rider?.nombre || TEXT_RETIRO_TIENDA).toLowerCase().includes(search)
                ))
            } else {
                setdata(Ordenes.ordersEnd)
            }
        }, 1500);

        return () => {
            clearTimeout(debounce)
        }
    }, [Ordenes, search])







    const columns = [

        // Codigo
        // Correlativo
        // IdSucursal
        // NombreClient
        // TelefonoCliente
        // IdDireccion
        // Comentario
        // FechaCreacion
        // IdEstado
        // CodigoCentroNegocio
        // UrlStatus
        // EsEntreSucursal
        // IdSucursalEntrega
        // IdUsuarioSucursal
        // IdTipoEntrega
        // FechaEntregaProgramada

        {
            title: 'Estado',
            render: (text, order, index) => <span>
            </span>,
        },
        {
            title: 'Código',
            defaultSortOrder: 'descend',
            render: (text, order, index) => <span>
            </span>,
        },
        {
            title: 'Correlativo',
            render: (text, order, index) => <span>
            </span>,
        },
        {
            title: 'Cliente/EsEntreSucursal',
            render: (text, order, index) => <span>
            </span>,
        },
        {
            title: 'Cliente',
            render: (text, order, index) => <p>
            </p>,
        },
        {
            title: 'Entrega Programada (si/no)',
            render: (text, order, index) => <span>
            </span>,
        },
    ]

    return <>
        <Layout>
            <Row>
                <Col span={6}>
                    <Card style={{ marginBottom: 5 }}>
                        <Row>
                            <Col span={12}>
                                <Title level={4}>Agil Flash</Title>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    onClick={() => setShowForm(!showForm)}
                                    style={{
                                        float: 'right'
                                        , marginLeft: 'auto'
                                    }}
                                >
                                    <Icon type="plus" theme="outlined" />
                                    Crear nuevo
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <ContactListWrapper className="isoContactListWrapper">
                                    {/* <InputSearch
                                        placeholder="Buscar Orden"
                                        value={search}
                                        // onChange={e => setSearch(e.target.value)}
                                        className="isoSearchBar"
                                    /> */}
                                </ContactListWrapper>
                            </Col>
                        </Row>
                    </Card>

                    {/* LISTADO DE PEDIDOS */}

                    <Card style={{ cursor: "pointer", border: "2px solid #1890ff" }}>
                        <Row>
                            <div style={{ backgroundColor: "#b1b9bd" }}>
                                <Col span={6}>
                                    <Paragraph style={{ fontSize: 18, marginTop: 0, marginBottom: 0, fontWeight: 500, color: "#1890ff" }}>#44124</Paragraph>
                                </Col>
                                <Col span={18}>
                                    <Paragraph style={{ fontSize: 18, marginTop: 0, textAlign: 'right', fontWeight: 500, color: "#1890ff" }}>Nombre cliente 1</Paragraph>
                                </Col>
                            </div>
                            <Divider />
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Badge count="Nuevo" style={{ backgroundColor: '#17a2b8', fontSize: 16 }} />
                                <Paragraph style={{ fontSize: 16, marginTop: 0, marginBottom: 0 }}>
                                    <Icon type="environment" theme="filled" style={{ marginRight: 10 }} />
                                    Seminario 1700, dpto. 508, Ñuñoa
                                </Paragraph>
                                <Paragraph style={{ fontSize: 16, marginTop: 0, marginBottom: 0 }}>
                                    <Icon type="thunderbolt" theme="filled" style={{ marginRight: 10 }} />
                                    Lo antes posible
                                </Paragraph>
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ cursor: "pointer" }}>
                        <Row>
                            <div style={{ backgroundColor: "#b1b9bd" }}>
                                <Col span={6}>
                                    <Paragraph style={{ fontSize: 18, marginTop: 0, marginBottom: 0, fontWeight: 500 }}>#44132</Paragraph>
                                </Col>
                                <Col span={18}>
                                    <Paragraph style={{ fontSize: 18, marginTop: 0, textAlign: 'right', fontWeight: 500 }}>Nombre cliente 2</Paragraph>
                                </Col>
                            </div>
                            <Divider />
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Badge count="Pendiente" style={{ backgroundColor: '#ffc107', fontSize: 16 }} />
                                <Paragraph style={{ fontSize: 16, marginTop: 0, marginBottom: 0 }}>
                                    <Icon type="shop" theme="filled" style={{ marginRight: 10 }} />
                                    Sucursal 4
                                </Paragraph>
                                <Paragraph style={{ fontSize: 16, marginTop: 0, marginBottom: 0 }}>
                                    <Icon type="calendar" theme="filled" style={{ marginRight: 10 }} />
                                    Programado 15/08/2022
                                </Paragraph>
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ cursor: "pointer" }}>
                        <Row>
                            <div style={{ backgroundColor: "#b1b9bd" }}>
                                <Col span={6}>
                                    <Paragraph style={{ fontSize: 18, marginTop: 0, marginBottom: 0, fontWeight: 500 }}>#45100</Paragraph>
                                </Col>
                                <Col span={18}>
                                    <Paragraph style={{ fontSize: 18, marginTop: 0, textAlign: 'right', fontWeight: 500 }}>Nombre cliente 3</Paragraph>
                                </Col>
                            </div>
                            <Divider />
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Badge count="Retrasado" style={{ backgroundColor: '#dc3545', fontSize: 16 }} />
                                <Paragraph style={{ fontSize: 16, marginTop: 0, marginBottom: 0 }}>
                                    <Icon type="environment" theme="filled" style={{ marginRight: 10 }} />
                                    Seminario 1700, dpto. 508, Ñuñoa
                                </Paragraph>
                                <Paragraph style={{ fontSize: 16, marginTop: 0, marginBottom: 0 }}>
                                    <Icon type="calendar" theme="filled" style={{ marginRight: 10 }} />
                                    Programado 15/08/2022
                                </Paragraph>
                            </Col>
                        </Row>
                    </Card>

                    {/* LISTADO DE PEDIDOS */}



                </Col>
                <Col span={18}>
                    <div style={{ margin: 10 }}>
                        <Table
                            columns={columns}
                            expandedRowRender={record => <ExpandedRow order={record} />}
                            dataSource={data}
                        />
                    </div>
                </Col>
            </Row>



            <Drawer
                title="Create a new account"
                width={720}
                // onClose={onClose}
                visible={showForm}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <div>
                        <Button>Cancel</Button>
                        <Button type="primary">
                            Submit
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please enter user name' }]}
                            >
                                <Input placeholder="Please enter user name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="url"
                                label="Url"
                                rules={[{ required: true, message: 'Please enter url' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    addonBefore="http://"
                                    addonAfter=".com"
                                    placeholder="Please enter url"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>



        </Layout>

        {/* <Drawer
            title={drawerData.title}
            visible={drawerData.visible}
            onClose={() => setdrawerData({ visible: false })}
            closable
            destroyOnClose
            width={500}
        >
            {
                drawerData.content === DRAWER_CONTENT_HISTORY_IN_POS ?
                    <ClientHistory
                        {...drawerData.props}
                    /> :
                    null
            }
        </Drawer> */}
    </>


}

function ExpandedRow({ order }) {


    return <Row gutter={[40, 8]}>
        <Col span={12}>
            <div style={{ width: '100%', display: 'flex' }}>
                <TicketDrawerButton orders={[order]} />
                {
                    order.entrega.TipoEntrega !== PED_ENTRE_TIPO_ENTRE_MESA &&
                    <TrackingOrderButton order={order} />
                }

                {
                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                    order.IdPedidoBase === null && (
                        <SplitOrderDeliveryButton order={order} />
                    )
                }
                {
                    order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                    <EvidenceButton order={order} />
                }
                <PedidoSeguimientoButton order={order} />
            </div>
            {
                order.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                <div style={{
                    marginTop: 20,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <OrderRiders order={order} />
                </div>
            }
            <OrderProducts order={order} style={{ marginTop: 20 }} />

        </Col>
        <Col span={12}>
            <OrderPayment order={order} />
            <OrderTotals order={order} style={{ marginTop: 20 }} />
        </Col>
    </Row>
}