import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { COLOR_BLUE } from "../../../constants/color"
import { IMG_AGIL_BAG } from "../../../constants/img/agil"
import { PANEL_TIPO_PEDIDO, TYPE_ESPECIAL, TYPE_FLASH } from "../../../redux/pedidoEspecial/types"
import { setType } from "../../../redux/pedidoEspecial/actions"

const TipoPedido = ({ changePanel }) => {
    const dispatch = useDispatch()
    const { activePanel, type } = useSelector(state => state?.PedidoEspecial)
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))

    const changeType = async (type) => {
        await dispatch(setType({ type }))
    }

    return (
        <>
            <Accordion
                expanded={(activePanel === PANEL_TIPO_PEDIDO) ? true : false}
                onChange={() => changePanel(PANEL_TIPO_PEDIDO)}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack
                        direction={(Mediaquery) ? 'column' : 'row'}
                        justifyContent='space-between'
                        sx={{
                            width: {
                                lg: '50%',
                                md: '80%',
                                sm: '100%',
                            }
                        }}
                    >
                        <Typography variant="h6">
                            ¿Qué tipo de pedido quieres realizar?
                        </Typography>
                        {
                            (type) && (
                                <Typography sx={{ opacity: 0.7 }}>
                                    {
                                        (type === TYPE_FLASH) ?
                                            `Agil Flash`
                                            :
                                            `Agil Especial`
                                    }
                                </Typography>
                            )
                        }
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            px: {
                                md: 5,
                                xs: 0,
                            }
                        }}
                    >
                        <Stack direction={(Mediaquery) ? 'column' : 'row'}>
                            <Card
                                onClick={() => changeType(TYPE_FLASH)}
                                elevation={2}
                                sx={{
                                    mr: {
                                        md: 1,
                                        xs: 0,
                                    },
                                    mt: {
                                        md: 0,
                                        xs: 1,
                                    },
                                    width: {
                                        md: 350,
                                        xs: '100%'
                                    },
                                    p: 2,
                                    cursor: 'pointer',
                                    border: (type === TYPE_FLASH) ? 1 : 0,
                                    borderColor: (type === TYPE_FLASH) ? COLOR_BLUE : 'none',
                                }}
                            >
                                <Stack direction='row' alignItems='center'>
                                    <Box
                                        component='img'
                                        src={IMG_AGIL_BAG}
                                        sx={{
                                            height: 30,
                                            objectFit: 'cover',
                                            mr: 1
                                        }}
                                    />
                                    <Typography variant="h6">
                                        Agil Flash
                                    </Typography>
                                </Stack>
                                <Typography variant="body1">
                                    Envía cualquier artículo que necesites
                                </Typography>
                            </Card>
                            <Card
                                onClick={() => changeType(TYPE_ESPECIAL)}
                                elevation={2}
                                sx={{
                                    ml: {
                                        md: 1,
                                        xs: 0,
                                    },
                                    mt: {
                                        md: 0,
                                        xs: 1,
                                    },
                                    width: {
                                        md: 350,
                                        xs: '100%'
                                    },
                                    p: 2,
                                    cursor: 'pointer',
                                    border: (type === TYPE_ESPECIAL) ? 1 : 0,
                                    borderColor: (type === TYPE_ESPECIAL) ? COLOR_BLUE : 'none',
                                }}
                            >
                                <Stack direction='row' alignItems='center'>
                                    <Box
                                        component='img'
                                        src={IMG_AGIL_BAG}
                                        sx={{
                                            height: 30,
                                            objectFit: 'cover',
                                            mr: 1
                                        }}
                                    />
                                    <Typography variant="h6">
                                        Agil Especial
                                    </Typography>
                                </Stack>
                                <Typography variant="body1">
                                    Elige productos y realiza una venta remota
                                </Typography>
                            </Card>
                        </Stack>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default memo(TipoPedido)