import { ArrowForwardIos, Close, DeleteForever, EditCalendar, MoreTime } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React, { useState } from 'react'
import { MenuOrder } from './MenuOrder'
import { RejectOrder } from './RejectOrder'
import { ChangeSchedule } from './ChangeSchedule'
import { PrepOrder } from './PrepOrder'


const ORDER_MENU = 0
const ORDER_PREP_TIME = 1
const ORDER_EDIT_SCHEDULE = 2
const ORDER_REJECT = 3
export const ProblemaPedido = ({
    order,
    setOpenActionOrder,
    openActionOrder
}) => {
    const [selectedMenu, setSelectedMenu] = useState(ORDER_MENU)

    const titleModal = () => {
        let orderCode = `${order.Codigo}${order.IdEstado === 1 ? '' : `-${order.Corelativo}`}`
        switch (selectedMenu) {
            case ORDER_MENU: {
                return `Acciones del pedido #${orderCode}`
            }
            case ORDER_PREP_TIME: {
                return `Agrega minutos a la entrega #${orderCode}`
            }
            case ORDER_EDIT_SCHEDULE: {
                return `Editar entrega #${orderCode}`
            }
            case ORDER_REJECT: {
                return `Confirmación rechazo/reembolso pedido #${orderCode}`
            }
            default: {
                return ''
            }
        }
    }

    const Menu = () => {
        switch (selectedMenu) {
            case ORDER_MENU: {
                return <MenuOrder order={order} setSelectedMenu={setSelectedMenu} />
            }
            case ORDER_PREP_TIME: {
                return <PrepOrder order={order} setSelectedMenu={setSelectedMenu} />
            }
            case ORDER_EDIT_SCHEDULE: {
                return <ChangeSchedule order={order} setSelectedMenu={setSelectedMenu} />
            }
            case ORDER_REJECT: {
                return <RejectOrder setSelectedMenu={setSelectedMenu} order={order} setOpenActionOrder={setOpenActionOrder} />
            }
            default: {
                return <></>
            }
        }
    }
    return (
        <Dialog
            open={openActionOrder}
            maxWidth={'md'}
            fullWidth
            sx={{ zIndex: 1000 }}
        >

            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant='subtitle2' sx={{ fontSize: 16 }}>
                        {titleModal()}
                    </Typography>

                </Box>
                <IconButton onClick={() => setOpenActionOrder(false)}>
                    <Close />
                </IconButton>
            </DialogTitle>
            {Menu()}

        </Dialog >
    )
}