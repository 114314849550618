import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Row, Col } from 'antd';

import { ContactListWrapper } from '@iso/components/Orders/OrderList.style';
import { ContactCardWrapper } from '@iso/components/Orders/OrderCard.style';

import Button from '@iso/components/uielements/button';
import { InputSearch } from '@iso/components/uielements/input';
import { ContactsWrapper } from './Orders.styles';
import Scrollbar from '@iso/components/utility/customScrollBar';
import Pagination from '@iso/components/uielements/pagination';

import PreparacionCocina from './OrderDetails/PreparacionCocina'

import OrderProducts from './OrderDetails/Order-Products';
import OrderTotals from './OrderDetails/Order-Totals';
import OrderClient from './OrderDetails/Order-Client';
import OrderInfo from './OrderDetails/Order-Info';
import OrderHeadDetail from './OrderDetails/Order-HeadDetail';
import { SocketContext } from '../Dashboard/Dashboard';
import OrderPayment from './OrderDetails/Order-Payment';
import OrdersReadyCard from './Cards/OrdersReady.Card';

import TicketDrawerButton from './Actions/TicketDrawer.Button';
import TrackingOrderButton from './Actions/TrackingOrderButton';
import {
    PED_ENTRE_TIPO_ENTRE_DELIVERY,
    PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA,
    ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY,
    ID_PEDIDO_ESTADO_EN_CAMINO,
    PED_ENTRE_TIPO_ENTRE_MESA,
    ID_PED_PAG_ESTA_PENDIENTE_PAGO
} from '../../redux/ordenes/types';
import SolicitarDeliveryPreferidoButton from './Actions/SolicitarDeliveryPreferido.Button';
import SplitOrderDeliveryButton from './Actions/SplitOrderDelivery.Button';
import OrderRiders from './OrderDetails/Order-Riders';
import PedidoSeguimientoButton from './Actions/PedidoSeguimiento.Button';
import { useLocation } from 'react-router-dom'
import PayLinkButton from "./Actions/PayLinkButton"

const { Content } = Layout;

export default function OrdersReady() {

    const searchByUri = useLocation().search
    const socket = useContext(SocketContext)
    const Auth = useSelector(state => state.Auth)
    const { ordersReady } = useSelector(state => state.Ordenes);
    const [orders, setOrders] = useState(ordersReady)
    const [search, setSearch] = useState('')
    const [index, setIndex] = useState(0)

    const [IdLista, setIdLista] = useState(1)

    useEffect(() => {
        setOrders(locateOnPage(applySearch()))
    }, [ordersReady, search, IdLista])

    // SOLO APLICA SI SE ENVIA EL CODIGO DEL PEDIDO POR GET
    const IdPedido = new URLSearchParams(searchByUri).get('IdPedido')
    useEffect(() => {
        setSearch((IdPedido !== null) ? IdPedido : "")
    }, [ordersReady, IdPedido])

    const applySearch = () => search ? ordersReady.filter(o => `${o.Codigo}-${o.Corelativo}`.includes(search)) : ordersReady
    const locateOnPage = (ordersToPaginate) => {
        const limitMax = IdLista * 10 - 1   // 09 , 19, 29
        const limitMin = limitMax - 9       // 00 , 10, 20

        return ordersToPaginate.filter((o, i) => (limitMin <= i && i <= limitMax))
    }
    const handleOnChangeSearch = (value) => {
        setIdLista(1)
        setSearch(value)
    }
    const handleOnChangePagination = (page) => {
        setIdLista(page)
    }




    const handleOnClickEntregado = (order) => {
        socket.emit("PedidoEntregado", {
            IdPedido: order.IdPedido,
            IdSucursal: order.IdSucursal,
            usuarioEmail: Auth.auth.Email,
        }, data => {
        })
    }


    const hrDetalle = <hr style={{ borderWidth: 1, borderColor: "rgba(233, 233, 233, 0.18)" }}></hr>


    return (
        <>
            <ContactsWrapper
                className="isomorphicContacts"
                style={{ background: 'none' }}
            >
                {/* LEFT MENU */}
                <div className="isoContactListBar">

                    <ContactListWrapper className="isoContactListWrapper">
                        {/* <InputSearch
                            placeholder='Buscar Orden'
                            value={search}
                            onChange={e => handleOnChangeSearch(e.target.value)}
                            className="isoSearchBar"
                        /> */}
                        <div style={{ height: 20 }} />
                        {orders && orders.length > 0 ? (
                            <div className="isoContactList">
                                <Scrollbar
                                    className="contactListScrollbar"
                                    style={{ height: 'calc(100vh - 200px)' }}
                                >
                                    {orders.map((order, i) =>
                                        <OrdersReadyCard key={i} order={order} i={i} active={index === i} onSelected={setIndex} ></OrdersReadyCard>
                                    )}
                                </Scrollbar>
                            </div>
                        ) : (
                            <span className="isoNoResultMsg">
                                NO HAY DATOS
                            </span>
                        )}
                    </ContactListWrapper>

                </div>
                {/* LEFT MENU */}

                {/* RIGTH INFO */}

                <Layout className="isoContactBoxWrapper">
                    {orders[index] && (
                        <Content className="isoContactBox">
                            <Scrollbar className="contactBoxScrollbar">

                                <ContactCardWrapper className="isoContactCard" >


                                    <div className="isoContactInfoWrapper">

                                        <OrderHeadDetail order={orders[index]}>
                                            <Row>
                                                {

                                                    orders[index].IdEstado === ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY &&
                                                    orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY && <Col span={6}>
                                                        <SolicitarDeliveryPreferidoButton order={orders[index]}></SolicitarDeliveryPreferidoButton>
                                                    </Col>
                                                }
                                                {

                                                    orders[index].IdEstado === ID_PEDIDO_ESTADO_EN_CAMINO &&
                                                    (orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA || orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_MESA || orders[index].IdTipoPedido === 5) &&
                                                    <Col span={6}>
                                                        <Button type="primary" onClick={() => handleOnClickEntregado(orders[index])}>
                                                            Pedido Entregado
                                                        </Button>
                                                    </Col>
                                                }
                                                <Col span={18}>
                                                    <div style={{ display: 'flex' }}>

                                                        {
                                                            (orders[index]?.tipoPedido?.Codigo === 'PEDESPECIAL') && (
                                                                <div style={{ marginRight: 10 }}>
                                                                    <PayLinkButton pedido={orders[index]} tipo={(orders[index]?.clienteCompra?.pagos[0]?.IdEstado === ID_PED_PAG_ESTA_PENDIENTE_PAGO) ? "PAGO" : "SEGUIMIENTO"} />
                                                                </div>
                                                            )
                                                        }

                                                        <TicketDrawerButton orders={[orders[index]]} />
                                                        {
                                                            orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA &&
                                                            <TrackingOrderButton order={orders[index]} />
                                                        }
                                                        {
                                                            orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                                                            orders[index].IdPedidoBase === null && (
                                                                <SplitOrderDeliveryButton order={orders[index]} />
                                                            )
                                                        }
                                                        <PedidoSeguimientoButton order={orders[index]} />
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <OrderPayment order={orders[index]} />
                                                </Col>
                                            </Row>

                                        </OrderHeadDetail>

                                    </div>
                                </ContactCardWrapper>


                                {/* DATOS RIDER */}

                                {
                                    orders[index].entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY && <>
                                        {hrDetalle}
                                        <ContactCardWrapper className="isoContactCard">
                                            <div className="isoContactInfoWrapper">
                                                <OrderRiders order={orders[index]}></OrderRiders>
                                            </div>
                                        </ContactCardWrapper>
                                    </>
                                }

                                {hrDetalle}

                                {/* DETALLE PRODUCTOS */}
                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <OrderProducts order={orders[index]} />
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* TOTALES */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <Row>
                                            <Col span={12}>
                                                <OrderTotals order={orders[index]}></OrderTotals>
                                            </Col>
                                        </Row>
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* DATOS CLIENTE */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <OrderClient order={orders[index]}></OrderClient>
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* DATOS PEDIDO */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <Row>
                                            <Col span={14}>
                                                <OrderInfo order={orders[index]} />
                                            </Col>
                                            <Col span={10}>
                                                <PreparacionCocina order={orders[index]}></PreparacionCocina>
                                            </Col>
                                        </Row>

                                    </div>
                                </ContactCardWrapper>

                                {/* FOOTER DETALLE */}
                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        {hrDetalle}
                                        <p className="isoInfoLabel" style={{ fontSize: '20px' }}>
                                            {`Pedido #${orders[index].IdTipoPedido === 5 ? orders[index]?.extOrder?.CodigoExterno || '' : orders[index].Codigo || 'N/A'}-${orders[index].IdTipoPedido === 5 ? orders[index]?.extOrder?.CorrelativoExterno || '' : orders[index].Corelativo} en ${Auth.payload.usuarioPerfil.sucursal.Titulo}`}
                                        </p>
                                    </Col>
                                </Row>

                            </Scrollbar>
                        </Content>
                    )}
                </Layout>
                {/* RIGTH INFO */}

            </ContactsWrapper>

            <center>
                <Pagination current={IdLista} total={ordersReady.length} onChange={handleOnChangePagination} />
            </center>

        </>
    );
}
