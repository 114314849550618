import React, { useEffect, useState } from 'react'
import { Upload, Icon, message, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { LAMBDA_AUTH } from '../../config';
import authAction from '../../redux/auth/actions';

const { loginWithData } = authAction;


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('El Archivo debe ser de extension JPG o PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('El Archivos supera en peso permitido (2MB)');
    }
    return isJpgOrPng && isLt2M;
}

export default ({
    onSuccess = () => { }
}) => {
    const dispatch = useDispatch()
    const Auth = useSelector(state => state.Auth)

    const [loading, setloading] = useState(false)
    const [imageUrl, setimageUrl] = useState('')
    useEffect(() => {
        setimageUrl(Auth.auth.Imagen)
    }, [Auth])

    function handleChange(info) {
        if (info.file.status === 'uploading') {
            setloading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setimageUrl(imageUrl)
                uploadUserImage(imageUrl)
                setloading(false)
            })
        }
    }

    function uploadUserImage(imageUrl) {
        setloading(true)

        Axios.put(`${LAMBDA_AUTH}/uploadUserImage/${Auth.auth.IdUsuario}`, {
            Imagen: imageUrl
        }, {
            headers: {
                'content-type': 'application/json'
            },
        }).then(response => {
            notification.success({ message: response?.data?.message || 'Imagen Actualizado 😉' })
            Auth.auth.Imagen = imageUrl
            dispatch(loginWithData(Auth))
            onSuccess()
        }).catch((err) => {
            notification.warning({ message: err.response?.data?.message || 'No es posible actualizar la Imagen en estos momentos' })
        }).finally(() => {
            setloading(false)
        })
    }

    return (
        <div
            style={{
                cursor: 'pointer',

            }}
        >
            <Upload
                name="avatar"
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                    setTimeout(() => { onSuccess() }, 1000);
                }}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {
                    imageUrl ?
                        <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '128px',
                                height: '128px',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                // border: '5px solid #ffffff'
                                border: '5px solid rgb(0, 197, 141)'
                            }}
                        /> :
                        <div>
                            <Icon type={loading ? 'loading' : 'plus'} />
                            <div className="ant-upload-text">Subir</div>
                        </div>
                }
            </Upload>
        </div>
    )
}