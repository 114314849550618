import React, { CSSProperties } from 'react'
import { List, Avatar, Button, Skeleton, notification, Progress } from 'antd';
import { useEffect, useState } from 'react';
import { getDistanceText } from '../../containers/Orders/OrderDetails/Order-Riders';
import { useContext } from 'react';
import { SocketContext } from '../../containers/Dashboard/Dashboard';
import { useSelector } from 'react-redux';

const MAX_RADIO = 150

/**
 * 
 * @param {{style: CSSProperties}}  
 * @returns 
 */
export default function ({
    defaultMetters = 50,
    onClickRider = rider => { },
    style = {}
}) {
    const socket = useContext(SocketContext)
    const Auth = useSelector(state => state.Auth)

    const [Radio, setRadio] = useState(Math.min(MAX_RADIO, defaultMetters))
    const [fetchingRiders, setfetchingRiders] = useState(false)
    const [ridersAux, setridersAux] = useState([])
    const [riders, setriders] = useState([])
    const [limitIndex, setlimitIndex] = useState(2)

    useEffect(() => {
        getRiders()
    }, [Radio])

    useEffect(() => {
        setriders(
            ridersAux
                .sort((a, b) => a.distancia - b.distancia)
                .filter((r, i) => i <= limitIndex)
        )
    }, [limitIndex, ridersAux])

    function getRiders() {
        setfetchingRiders(true)
        socket.emit('ObtenerRidersCercanos', {
            IdSucursal: Auth.payload.usuarioPerfil.sucursal.IdSucursal,
            Radio
        }, function ({ message, success, riders: socketRiders }) {
            if (success) {
                // setridersAux([...socketRiders, ...socketRiders, ...socketRiders, ...socketRiders, ...socketRiders, ...socketRiders, ...socketRiders, ...socketRiders, ...socketRiders, ...socketRiders,])
                setridersAux(socketRiders)
            } else {
                notification.warning({ message })
            }
            setfetchingRiders(false)

        })
    }

    function handleOnLoadMore() {
        setlimitIndex(prev => prev + 3)
    }


    const loadMore =
        !fetchingRiders &&
            (riders.length - 1) <= limitIndex &&
            riders.length !== ridersAux.length ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={handleOnLoadMore}>Ver mas...</Button>
            </div>
        ) : null;

    return <div style={{ ...style }}>
        <div style={{ display: 'flex' }}>
            <div style={{ width: '80%' }}>
                <Progress percent={Math.ceil(Radio * 100 / MAX_RADIO)} format={porc => getDistanceText(Radio)} />
            </div>
            <div style={{ width: '20%' }}>
                <Button disabled={fetchingRiders} shape="circle" style={{ float: 'right' }} onClick={() => setRadio(prev => Math.min(MAX_RADIO, prev + 10))} icon="plus" />
                <Button disabled={fetchingRiders} shape="circle" style={{ float: 'right' }} onClick={() => setRadio(prev => Math.max(10, prev - 10))} icon="minus" />
            </div>
        </div>
        {/* <p>Riders dentro de los {getDistanceText(Radio)}: {ridersAux.length}</p> */}
        <List
            locale={{
                emptyText: `No hay Riders disponibles dentro de ${getDistanceText(Radio)}`
            }}
            className="demo-loadmore-list"
            loading={fetchingRiders}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={riders}
            renderItem={rider => (
                <List.Item
                    actions={[
                        // <a key="list-loadmore-edit">edit</a>
                    ]}
                >
                    <Skeleton avatar title={false} loading={false} active>
                        <List.Item.Meta
                            avatar={<Avatar src={rider.avatarImg} />}
                            title={<a onClick={() => onClickRider(rider)}>{rider.idPublico} {rider.nombre} {rider.apellido}</a>}
                            description=""
                        />
                        <div>{getDistanceText(rider.distancia)}</div>
                    </Skeleton>
                </List.Item>
            )}
        />
    </div>


}
