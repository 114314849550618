module.exports = {
    BACKEND_UTILS: "https://utils.server.getagil.com",
    socketUrl: "https://qa-socket.getagil.com",
    LAMBDA_POS: 'https://lambda.getagil.com/prod-pos',
    LAMBDA_AUTH: 'https://lambda.getagil.com/prod-auth',
    LAMBDA_SHOP: 'https://lambda.getagil.com/prod-shop',
    LAMBDA_COMMON: 'https://lambda.getagil.com/prod-common',
    LAMBDA_ADMIN: 'https://lambda.getagil.com/prod-admin',
    LAMBDA_AGIL: "https://lambda.getagil.com/prod-agil",
    AppKey: 'e939be0a35afb7d219b034d740c9f803',
    API_KEY_MAPS: 'AIzaSyCETkB78xH2peosxbPxCW7fiHxg0jxAXoM',
    SENTRY_DSN: 'https://cfab122337db492381bf4cfcfdd553e3@o454113.ingest.sentry.io/5443650',
    APP_ENV: 'prod',
    URL_DOWNLOAD_AGIL_POS_EXE: 'https://agil.s3.us-east-2.amazonaws.com/pos-local-socket/AGIL-POS.exe',
    AUTH_URL: "https://account.getagil.com",
    INTERCOM_APP_ID: 'vydyfedc',
    HOTJAR_HJID: 2450342,
    HOTJAR_HJSV: 6,
    MAPBOX_TOKEN: 'pk.eyJ1IjoiYWdpbG1hcCIsImEiOiJjbG1rbzY2amkwM3NoMmttb3dtMHRteTRkIn0.6gAtqofNy3MsffYN6ml4SQ',
}