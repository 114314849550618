import { all, takeEvery, fork, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import { LAMBDA_POS } from "../../../config/"
import axios from 'axios'

export function* getScheduleSucursal() {
    yield takeEvery(actions.GET_SCHEDULE_SUC, function* ({ payload }) {

        const { IdSucursal, IdComercio, callback } = payload
        const data = yield call(callGetScheduleSucursal, IdComercio, IdSucursal)
        yield put({
            type: actions.GET_SCHEDULE_SUC_SUCCESS,
            payload: data
        })

        callback && setTimeout(callback, 1000);
    })
}

const callGetScheduleSucursal = async (IdComercio, IdSucursal) => {
    const { data } = await axios.get(`${LAMBDA_POS}/getSchedule/${IdComercio}/${IdSucursal}`)
    return data;
}

export function* refreshScheduleSucursal() {
    yield takeEvery(actions.REFRESH_SCHEDULE_SUC, function* ({ payload }) {
        const { schedule } = payload
        yield put({
            type: actions.GET_SCHEDULE_SUC_SUCCESS,
            payload: schedule
        })
    })
}

export default function* rootSaga() {
    yield all([
        fork(getScheduleSucursal),
        fork(refreshScheduleSucursal),
    ]);
}