import { Tag } from 'antd';
import React from 'react'
import {
    ID_PEDIDO_ESTADO_INACTIVO,
    ID_PEDIDO_ESTADO_PENDIENTE,
    ID_PEDIDO_ESTADO_RECHAZADO,
    ID_PEDIDO_ESTADO_ACEPTADO,
    ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY,
    ID_PEDIDO_ESTADO_EN_CAMINO,
    ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL,
    ID_PEDIDO_ESTADO_ENTREGADO,
    PED_ENTRE_TIPO_ENTRE_DELIVERY,
    PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA,
    PED_ENTRE_TIPO_ENTRE_MESA
} from '../../../redux/ordenes/types';

const TagColorByIdEstado = {
    [ID_PEDIDO_ESTADO_PENDIENTE]: 'yellow',
    [ID_PEDIDO_ESTADO_ACEPTADO]: 'blue',
    [ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY]: 'red',
    [ID_PEDIDO_ESTADO_EN_CAMINO]: 'blue',
    [ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL]: 'blue',
    [ID_PEDIDO_ESTADO_ENTREGADO]: 'green',
    [ID_PEDIDO_ESTADO_RECHAZADO]: 'red',
}

export default function ({ pedido, children }) {
    return <Tag color={ pedido.estado.Id === ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY && pedido.courier?.IdTipoCourier? TagColorByIdEstado[ID_PEDIDO_ESTADO_EN_CAMINO] :TagColorByIdEstado[pedido.estado.Id]}>
        {
            pedido.estado.Id === ID_PEDIDO_ESTADO_EN_CAMINO && pedido.entrega.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY ?
                'En Camino' :
                pedido.estado.Id === ID_PEDIDO_ESTADO_EN_CAMINO && [PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA, PED_ENTRE_TIPO_ENTRE_MESA].includes(pedido.entrega.TipoEntrega) ?
                    'Pedido Listo' :
                    pedido.estado.Id === ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY && pedido.courier?.IdTipoCourier ?
                    'Rider asignado': 
                    pedido.estado.Nombre
        }
        {children}
    </Tag>
}