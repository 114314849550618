import { Button, Checkbox, Col, Drawer, Icon, message, notification, Row, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LAMBDA_POS, LAMBDA_COMMON } from '../../../config'
import { fixTelefono } from '../../../helpers/fixes'
import useWindowSize from '../../../library/hooks/useWindowSize'
import {
    ID_PEDIDO_TIPO_CALIFICACION_RIDER_DISLIKE,
    ID_PEDIDO_TIPO_CALIFICACION_RIDER_LIKE,
    PEDIDO_POS_MOTIVO_CALIFICACION_CONNOTACION_POSITIVA,
    PEDIDO_POS_MOTIVO_CALIFICACION_CONNOTACION_NEGATIVA,
    PEDIDO_POS_MOTIVO_CALIFICACION_OBJETIVO_RIDER,
} from '../../../redux/ordenes/types'

const handStyle = {
    fontSize: '20px',
    cursor: 'pointer',
}

let reasonsBadAux = []
let reasonsGoodAux = []
Axios.get(`${LAMBDA_COMMON}/getPOSMotivosCalificacion`, {
    params: {
        Objetivo: PEDIDO_POS_MOTIVO_CALIFICACION_OBJETIVO_RIDER
    }
})
    .then(({ data }) => {
        reasonsGoodAux = data.reasons.filter(reason => reason.Connotacion === PEDIDO_POS_MOTIVO_CALIFICACION_CONNOTACION_POSITIVA).map(reason => reason.Motivo)
        reasonsBadAux = data.reasons.filter(reason => reason.Connotacion === PEDIDO_POS_MOTIVO_CALIFICACION_CONNOTACION_NEGATIVA).map(reason => reason.Motivo)
    }).catch(console.error)





export default ({ order }) => {
    const Auth = useSelector(state => state.Auth)
    const [drawerVisible, setdrawerVisible] = useState(false)
    const { height } = useWindowSize()
    const [handTypeSelected, sethandTypeSelected] = useState(null)
    const [reasonsSelected, setreasonsSelected] = useState([])
    const [hasSavedRate, sethasSavedRate] = useState(false)
    const [reasonExtra, setreasonExtra] = useState('')
    const [reasonsBad, setreasonsBad] = useState(reasonsBadAux)
    const [reasonsGood, setreasonsGood] = useState(reasonsGoodAux)

    useEffect(() => {
        const ratesSaved = order.posCalificaciones.filter(c => [ID_PEDIDO_TIPO_CALIFICACION_RIDER_DISLIKE, ID_PEDIDO_TIPO_CALIFICACION_RIDER_LIKE].includes(c.IdTipo) && c.IdUsuario === Auth.auth.IdUsuario)
        if (ratesSaved.length) {
            sethasSavedRate(true)
            const [rateFirstSaved] = ratesSaved
            sethandTypeSelected(rateFirstSaved.IdTipo)
            setreasonsSelected(ratesSaved.map(r => r.Comentario))
        }
    }, [order, order.posCalificaciones])

    useEffect(() => {
        setreasonsBad(reasonsBadAux)
        setreasonsGood(reasonsGoodAux)
    }, [reasonsBadAux, reasonsGoodAux])

    function handleOnClickHand(handType) {
        setdrawerVisible(true)
        sethandTypeSelected(handType)
        if (!hasSavedRate) {
            setreasonsSelected([])
            setreasonExtra('')
        }
    }


    function handleOnClickButtonCalificar() {
        const IdTipo = handTypeSelected
        const IdUsuario = Auth.auth.IdUsuario

        Axios.post(`${LAMBDA_POS}/rate/save/${PEDIDO_POS_MOTIVO_CALIFICACION_OBJETIVO_RIDER}`, {
            IdPedido: order.IdPedido,
            IdTipo,
            IdUsuario,
            reasons: [
                ...reasonsSelected,
                ...reasonExtra ? [reasonExtra] : []
            ]
        }, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        })
            .then(response => {
                const { data: { message } } = response

                const reasonsSelectedAux = reasonsSelected
                !reasonsSelectedAux.length && reasonsSelectedAux.push(null)


                const rate = { IdTipo, IdUsuario, Comentario: null, }

                order.posCalificaciones = [
                    ...reasonsSelectedAux.reduce((aux, r) => [...aux, {
                        ...rate,
                        Comentario: r
                    }], order.posCalificaciones),
                    ...reasonExtra ? [{
                        ...rate,
                        Comentario: reasonExtra
                    }] : []
                ]

                notification.success({ message })
            })
            .catch(err => {
                console.error(err)
                notification.error({ message: err.response?.data?.message || 'ha ocurrido un error inesperado' })
            })
            .finally(() => {
                setdrawerVisible(false)
            })

    }

    return (
        <>
            {
                hasSavedRate ? <>
                    {
                        handTypeSelected === ID_PEDIDO_TIPO_CALIFICACION_RIDER_LIKE ? <Icon style={{ ...handStyle }} type="like" onClick={() => handleOnClickHand(ID_PEDIDO_TIPO_CALIFICACION_RIDER_LIKE)} theme="twoTone" twoToneColor="#52c41a" /> :
                            handTypeSelected === ID_PEDIDO_TIPO_CALIFICACION_RIDER_DISLIKE ? <Icon style={{ ...handStyle }} type="dislike" onClick={() => handleOnClickHand(ID_PEDIDO_TIPO_CALIFICACION_RIDER_DISLIKE)} theme="twoTone" twoToneColor="red" /> :
                                null
                    }
                </> : <>
                    <Icon style={{ ...handStyle }} type="like" onClick={() => handleOnClickHand(ID_PEDIDO_TIPO_CALIFICACION_RIDER_LIKE)} />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Icon style={{ ...handStyle }} type="dislike" onClick={() => handleOnClickHand(ID_PEDIDO_TIPO_CALIFICACION_RIDER_DISLIKE)} />
                </>
            }

            <Drawer
                title={<p>
                    <span>
                        {
                            (handTypeSelected === ID_PEDIDO_TIPO_CALIFICACION_RIDER_LIKE ? <Icon style={{ fontSize: '40px' }} type="like" theme="twoTone" twoToneColor="#52c41a" /> :
                                handTypeSelected === ID_PEDIDO_TIPO_CALIFICACION_RIDER_DISLIKE ? <Icon style={{ fontSize: '40px' }} type="dislike" theme="twoTone" twoToneColor="red" />
                                    :
                                    [])
                        }
                    </span>
                </p>}
                placement="right"
                closable={true}
                onClose={() => setdrawerVisible(false)}
                visible={drawerVisible}
                width='500px'
            // height={height}
            >
                <div style={{ minHeight: (height * 0.65) }}>
                    <center>
                        <img style={{
                            borderRadius: '50%',
                            width: '150px',
                            height: '150px',
                        }} src={order.entrega.rider.avatarImg} alt={''} />
                        <br></br>
                        {`${order.entrega.rider.nombre} ${order.entrega.rider.apellido}`}
                        <br></br>
                        {
                            order.entrega.rider.dispositivos[0].numTelefono ?
                                fixTelefono(order.entrega.rider.nacionalidad, order.entrega.rider.dispositivos[0].numTelefono) :
                                null
                        }
                    </center>

                    <div style={{ margin: '20px 0 0 0' }}>
                        {
                            hasSavedRate ? <Row>
                                {reasonsSelected.map((r, i) => <Col key={i} span={24}>
                                    {r}
                                </Col>)}
                            </Row> : <>
                                <Checkbox.Group
                                    value={reasonsSelected}
                                    onChange={setreasonsSelected}
                                >
                                    <Row>
                                        {
                                            (handTypeSelected === ID_PEDIDO_TIPO_CALIFICACION_RIDER_LIKE ? reasonsGood :
                                                handTypeSelected === ID_PEDIDO_TIPO_CALIFICACION_RIDER_DISLIKE ? reasonsBad :
                                                    []).map((reason, i) => <Col key={i} span={24}>
                                                        <Checkbox value={reason}>{reason}</Checkbox>
                                                    </Col>)
                                        }
                                    </Row>
                                </Checkbox.Group>
                                <Row>
                                    <Col span={24}>
                                        <TextArea placeholder="Otro..." autoSize maxLength={100} value={reasonExtra} onChange={e => setreasonExtra(e.target.value)}></TextArea>
                                    </Col>
                                </Row>
                            </>


                        }
                    </div>



                </div>
                <div style={{ alignItems: 'center', verticalAlign: 'bottom' }}>
                    {
                        !hasSavedRate && <>
                            <small>* Tranquil@, la Calificación no será visible para el Rider.</small>
                            <Tooltip title={<small>La Calificación es <b> irreversible</b></small>}>
                                <Button type='primary' style={{ width: '100%' }} onClick={handleOnClickButtonCalificar}>CALIFICAR</Button>
                            </Tooltip>
                        </>
                    }
                </div>
            </Drawer>
        </>
    )
}