export const ID_SUC_TIEMPO_PREPA = 1;
export const ID_SUC_TIEMPO_COCINA = 2;
export const ID_SUC_TIEMPO_DELIVERY = 3;
export const ID_SUC_TIEMPO_ACEPTACION_PEDIDO_AUTO = 4;
export const ID_SUC_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO = 5;
export const ID_SUC_CANTIDAD_TICKETS_X_PEDIDO = 6;
export const ID_SUC_IMPRESORA = 7;
export const ID_SUC_POS_LIDER_IMPRESION = 11;


export const DEFAULT_VALUE_TIEMPO_PREPARACION = '05:00';
export const DEFAULT_VALUE_TIEMPO_COCINA = '10-20';
export const DEFAULT_VALUE_TIEMPO_DELIVERY = '10-20';
export const DEFAULT_VALUE_TIEMPO_ACEPTACION_PEDIDO_AUTO = 'manual';
export const DEFAULT_VALUE_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO = 'manual';
export const DEFAULT_VALUE_CANTIDAD_TICKETS_X_PEDIDO = 1;