const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_REQUEST_DATA: 'LOGIN_REQUEST_DATA',
  LOGIN_REQUEST_TOKEN: 'LOGIN_REQUEST_TOKEN',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_SUCCESS_DATA: 'LOGIN_SUCCESS_DATA',

  /**
   * @deprecated se usaba en la plantilla
   * @param {*} token 
   */
  login: (token = false) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token },
  }),
  //
  loginWithData: (data, callback) => ({
    type: actions.LOGIN_REQUEST_DATA,
    payload: data,
    callback
  }),
  loginWithToken: (token, callback) => ({
    type: actions.LOGIN_REQUEST_TOKEN,
    payload: token,
    callback
  }),
  logout: (payload, callback) => ({
    type: actions.LOGOUT,
    payload,
    callback
  }),
};
export default actions;
