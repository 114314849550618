import * as actions from './actions';


const initState = {
    sucursal: [
        /*
            Id
            IdSucursal
            IdTipoConfiguracion
            Valor
            tipo
        */
    ]
}
export default (state = initState, action) => {

    switch (action.type) {
        case actions.GET_SETTINGS_SUCCESS:
            return action.payload
        default:
            return state
    }
}
