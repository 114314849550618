import React, { memo, useState } from 'react';
import useFilterSucursal from '../../hooks/useFilterSucursal/useFilterSucursal'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const TopbarModalSucursales = ({ showModal, closeModal }) => {
    const { FilterSucursalComponent, selectedSucursalIds } = useFilterSucursal()
    const [loading, setLoading] = useState(false)

    const saveFilteredSucursales = async () => {
        setLoading(true)
        localStorage.setItem('SelectedSucursales', selectedSucursalIds)
        window.location.reload()
    }

    return (
        <>
            <Dialog
                open={showModal}
                onClose={closeModal}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>Selecciona las sucursales que quieres visualizar</DialogTitle>
                <DialogContent>
                    <FilterSucursalComponent />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="inherit"
                        variant="text"
                        onClick={closeModal}
                    >
                        Cerrar
                    </Button>
                    <Button
                        disabled={(selectedSucursalIds.length > 0 || !loading) ? false : true}
                        color="primary"
                        variant="contained"
                        onClick={saveFilteredSucursales}
                    >
                        {
                            (loading) ?
                                <CircularProgress size={24} />
                                :
                                `Ver sucursales`
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default memo(TopbarModalSucursales)