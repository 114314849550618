import React, { useContext, useEffect, useState } from 'react';
import { AgilAuthProvider, useAgilAuth } from './context/provider';
import jwtService from './services/jwtService'



const withAuthProvider = (Component) => {
    return (props) => {
        return (
            <AgilAuthProvider>
                <Component {...props} />
            </AgilAuthProvider>
        )
    }
}


const AgilAuth = (props) => {
    const {
        children
    } = props;

    const [waitingForService, setWaitingForService] = useState(true);
    const { validateToken, loginWithToken } = useAgilAuth();

    useEffect(() => {
        checkJWT().then();
    }, [])

    const checkJWT = async () => {
        jwtService.on('onAutoLogin', (token) => {
            // exite token y esta listo para loguearse automaticamente
            loginWithToken(token).then(() => {
                setWaitingForService(false);
            }).catch(() => {
                setWaitingForService(false);
            })
        });

        jwtService.on('onAutoLogout', message => {
            // Se autodesloguea
            setWaitingForService(false);
        });

        jwtService.on('onNoAccessToken', () => {
            // No se encontro token
            setWaitingForService(false);
        });

        jwtService.init();
    }

    return waitingForService ? <p>Recuperando login...</p> : children

    // return (
    //     <>
    //         {!waitingForService && children}
    //     </>
    // )
}

export default withAuthProvider(AgilAuth);
