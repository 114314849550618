import {
    ADD_ONE_MORE,
    ADD_PRODUCT,
    CLEAN_DATA,
    DATE_TYPE_EXPRESS,
    DELIVERY_TYPE_DELIVERY,
    DESTINY_TYPE_CLIENTE,
    DESTINY_TYPE_SUCURSAL,
    ORIGIN_TYPE_SUCURSAL,
    PANEL_DESTINO,
    PANEL_ORIGEN,
    PANEL_TIPO_ENTREGA,
    PANEL_TIPO_FECHA_ENTREGA,
    PANEL_TIPO_PEDIDO,
    PRODUCT_ADD_OPTION,
    REMOVE_PRODUCT,
    SET_ACTIVE_PANEL,
    SET_CHECKBOX_OPTION,
    SET_CLEAN_TEMP_PRODUCT,
    SET_COMMENT,
    SET_CONTENT,
    SET_COUNTER_OPTION,
    SET_DATE_TYPE,
    SET_DELIVERY_DATETIME,
    SET_DELIVERY_TYPE,
    SET_DESTINY_CLIENT,
    SET_DESTINY_CLIENT_ADDITIONAL_INFORMATION,
    SET_DESTINY_CLIENT_DIRECTION,
    SET_DESTINY_CLIENT_EMAIL,
    SET_DESTINY_CLIENT_LASTNAME,
    SET_DESTINY_CLIENT_NAME,
    SET_DESTINY_CLIENT_PHONE,
    SET_DESTINY_CONFIRM,
    SET_DESTINY_NEW_CLIENT,
    SET_DESTINY_STORE,
    SET_DESTINY_STORE_ADMIN,
    SET_DESTINY_TYPE,
    SET_ORIGIN,
    SET_RADIO_OPTION,
    SET_SHOW_MODAL_PEDIDO_ESPECIAL,
    SET_STORE_IS_OPEN,
    SET_TEMP_PRODUCT,
    SET_TYPE,
    SET_USE_CAR,
    SUBTRACT_ONE,
    TYPE_FLASH,
    SET_TEMP_PRODUCT_QUANTITY,
    TEMP_PRODUCT_ADD_QUATITY,
    SET_TEMP_TO_CART,
    SET_CHANGE_QUANTITY_PRODUCT,
    PRODUCT_ADD_QUATITY,
    SET_REMOVE_PRODUCT,
    SET_CLOSE_MODAL_PEDIDO_ESPECIAL,
    PANEL_PRODUCTOS,
    PANEL_CONTENIDO_ADICIONAL,
    TYPE_ESPECIAL,
    SET_TEMP_COMMENT,
    SET_TIP_PERC,
    SET_TIP
} from "./types"

const INIT_STATE = {
    isOpen: false,
    activePanel: null,
    type: null,
    deliveryType: null,
    originType: null,
    origin: null,
    useCar: false,
    storeIsOpen: true,
    dateType: null,
    deliveryDate: null,
    deliveryTime: null,
    destiny: {
        type: null,
        client: null,
        store: null,
        storeAdmin: null,
        address: {
            direction: null,
            lat: 0,
            lng: 0,
            additionalInformation: null,
        },
        inGeozone: false,
        shippingCost: 0,
        confirm: false,
    },
    content: null,
    tempProduct: null,
    products: [],
    totalPrice: 0,
    comment: null,
    tip: 0,
}

export default function pedidoEspecialReducer(state = INIT_STATE, action) {
    switch (action.type) {

        case SET_SHOW_MODAL_PEDIDO_ESPECIAL: {
            state = {
                ...INIT_STATE,
                isOpen: true,
                activePanel: PANEL_TIPO_PEDIDO,
            }

            return {
                ...state
            }
        }

        case SET_CLOSE_MODAL_PEDIDO_ESPECIAL: {
            state = {
                ...INIT_STATE,
                isOpen: false,
                activePanel: PANEL_TIPO_PEDIDO,
            }

            return {
                ...state
            }
        }

        case SET_ACTIVE_PANEL: {
            state = {
                ...state,
                activePanel: (action?.payload?.panel === state?.activePanel) ? null : action?.payload?.panel,
            }

            return {
                ...state
            }
        }

        case SET_TYPE: {
            state = {
                ...state,
                type: action?.payload?.type,
                activePanel: (action?.payload?.type === TYPE_FLASH) ? PANEL_ORIGEN : PANEL_TIPO_ENTREGA,
                deliveryType: (action?.payload?.type === TYPE_FLASH) ? DELIVERY_TYPE_DELIVERY : state?.deliveryType,
                originType: ORIGIN_TYPE_SUCURSAL,
                destiny: {
                    ...state?.destiny,
                    type: DESTINY_TYPE_CLIENTE
                }
            }

            return {
                ...state
            }
        }

        case SET_DELIVERY_TYPE: {
            state = {
                ...state,
                activePanel: PANEL_ORIGEN,
                deliveryType: (state?.type === TYPE_FLASH) ? DELIVERY_TYPE_DELIVERY : action?.payload?.deliveryType
            }

            return {
                ...state
            }
        }

        case SET_ORIGIN: {
            state = {
                ...state,
                originType: action?.payload?.originType,
                origin: action?.payload?.origin,
                activePanel: (action?.payload?.initLoad) ? state?.activePanel : PANEL_TIPO_FECHA_ENTREGA,
            }

            return {
                ...state
            }
        }

        case SET_USE_CAR: {
            state = {
                ...state,
                useCar: action?.payload?.useCar,
            }

            return {
                ...state
            }
        }

        case SET_STORE_IS_OPEN: {
            state = {
                ...state,
                storeIsOpen: action?.payload?.storeIsOpen
            }

            return {
                ...state
            }
        }

        case SET_DATE_TYPE: {
            state = {
                ...state,
                dateType: action?.payload?.dateType,
                activePanel: (action?.payload?.dateType === DATE_TYPE_EXPRESS) ? PANEL_DESTINO : state?.activePanel,
            }

            return {
                ...state
            }
        }

        case SET_DELIVERY_DATETIME: {
            state = {
                ...state,
                deliveryDate: action?.payload?.deliveryDate,
                deliveryTime: action?.payload?.deliveryTime,
                activePanel: PANEL_DESTINO,
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_TYPE: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    type: action?.payload?.type,
                    confirm: false,
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_STORE: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    type: DESTINY_TYPE_SUCURSAL,
                    client: null,
                    store: action?.payload?.store,
                    address: {
                        direction: action?.payload?.store?.Direccion,
                        lat: action?.payload?.store?.lat,
                        lng: action?.payload?.store?.lng,
                        additionalInformation: null,
                    },
                    shippingCost: action?.payload?.cost,
                    inGeozone: true,
                },
                confirm: false
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_STORE_ADMIN: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    storeAdmin: action?.payload?.storeAdmin,
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_NEW_CLIENT: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    store: null,
                    storeAdmin: null,
                    client: null,
                    address: {
                        direction: null,
                        lat: 0,
                        lng: 0,
                        additionalInformation: null,
                    },
                    inGeozone: false,
                    shippingCost: 0,
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_CLIENT: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    type: DESTINY_TYPE_CLIENTE,
                    store: null,
                    storeAdmin: null,
                    client: action?.payload?.client,
                    address: {
                        direction: action?.payload?.client?.Direccion,
                        lat: action?.payload?.client?.Latitude,
                        lng: action?.payload?.client?.Longitude,
                        additionalInformation: null,
                    },
                    inGeozone: action?.payload?.inGeozone,
                    shippingCost: action?.payload?.cost,
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_CLIENT_NAME: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    client: {
                        ...state?.destiny?.client,
                        Nombre: action?.payload?.name,
                    }
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_CLIENT_LASTNAME: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    client: {
                        ...state?.destiny?.client,
                        Apellido: action?.payload?.lastname,
                    }
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_CLIENT_EMAIL: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    client: {
                        ...state?.destiny?.client,
                        Email: action?.payload?.email,
                    }
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_CLIENT_PHONE: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    client: {
                        ...state?.destiny?.client,
                        Telefono: action?.payload?.phone,
                    }
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_CLIENT_DIRECTION: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    client: {
                        ...state?.destiny?.client,
                        Direccion: action?.payload?.direction?.address,
                        Latitude: action?.payload?.direction?.lat,
                        Longitude: action?.payload?.direction?.lng,
                    },
                    address: {
                        ...state?.destiny?.address,
                        direction: action?.payload?.direction?.address,
                        lat: action?.payload?.direction?.lat,
                        lng: action?.payload?.direction?.lng,
                    }
                },
                confirm: false
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_CLIENT_ADDITIONAL_INFORMATION: {
            state = {
                ...state,
                destiny: {
                    ...state?.destiny,
                    address: {
                        ...state?.destiny?.address,
                        additionalInformation: action?.payload?.additionalInformation,
                    }
                }
            }

            return {
                ...state
            }
        }

        case SET_DESTINY_CONFIRM: {
            state = {
                ...state,
                activePanel: (state?.type === TYPE_ESPECIAL) ? PANEL_PRODUCTOS : PANEL_CONTENIDO_ADICIONAL,
                destiny: {
                    ...state?.destiny,
                    confirm: true
                }
            }

            return {
                ...state
            }
        }

        case SET_CONTENT: {
            state = {
                ...state,
                content: action?.payload?.content,
            }

            return {
                ...state
            }
        }

        case SET_COMMENT: {
            state = {
                ...state,
                comment: action?.payload?.comment,
            }

            return {
                ...state
            }
        }

        case SET_TEMP_PRODUCT: {
            const tempProduct = {
                ...action?.payload?.product,
                CantidadSeleccionada: 1,
            }

            state = {
                ...state,
                tempProduct,
            }

            state.tempProduct.CostoTotal = getTotalCostByProduct(tempProduct)

            return {
                ...state
            }
        }

        case SET_CLEAN_TEMP_PRODUCT: {
            state = {
                ...state,
                tempProduct: null,
            }

            return {
                ...state
            }
        }

        case SET_RADIO_OPTION: {
            const modPosition = state?.tempProduct.modificadores.findIndex((e) => e?.Id === action?.payload?.mod?.Id)
            const optPosition = state.tempProduct.modificadores[modPosition].opciones.findIndex((e) => e?.Id === action?.payload?.option)

            const cleanOp = state.tempProduct.modificadores[modPosition].opciones.map((e) => {
                return {
                    ...e,
                    CantidadSeleccionada: 0
                }
            })

            state.tempProduct.modificadores[modPosition].opciones = cleanOp

            const res = getMaxPerModifier(state.tempProduct.modificadores[modPosition])
            if ((res?.current + 1) <= res?.max) {
                state.tempProduct.modificadores[modPosition].opciones[optPosition] = {
                    ...state.tempProduct.modificadores[modPosition].opciones[optPosition],
                    CantidadSeleccionada: 1
                }

                state.tempProduct.modificadores[modPosition].CantidadSeleccionada = (res?.current + 1)
            } else {
                state.tempProduct.modificadores[modPosition].CantidadSeleccionada = res?.current
            }

            state.tempProduct.CostoTotal = getTotalCostByProduct(state.tempProduct)

            state = {
                ...state,
            }

            return {
                ...state
            }
        }

        case SET_CHECKBOX_OPTION: {
            const modPosition = state?.tempProduct.modificadores.findIndex((e) => e?.Id === action?.payload?.mod?.Id)
            const optPosition = state.tempProduct.modificadores[modPosition].opciones.findIndex((e) => e?.Id === action?.payload?.option)

            const res = getMaxPerModifier(state.tempProduct.modificadores[modPosition])

            if (state?.tempProduct?.modificadores[modPosition]?.opciones[optPosition]?.CantidadSeleccionada) {
                if ((res?.current - 1) <= res?.max) {
                    state.tempProduct.modificadores[modPosition].opciones[optPosition] = {
                        ...state.tempProduct.modificadores[modPosition].opciones[optPosition],
                        CantidadSeleccionada: 0
                    }

                    state.tempProduct.modificadores[modPosition].CantidadSeleccionada = (res?.current - 1)
                } else {
                    state.tempProduct.modificadores[modPosition].CantidadSeleccionada = res?.current
                }
            } else {
                if ((res?.current + 1) <= res?.max) {
                    state.tempProduct.modificadores[modPosition].opciones[optPosition] = {
                        ...state.tempProduct.modificadores[modPosition].opciones[optPosition],
                        CantidadSeleccionada: 1
                    }

                    state.tempProduct.modificadores[modPosition].CantidadSeleccionada = (res?.current + 1)
                } else {
                    state.tempProduct.modificadores[modPosition].CantidadSeleccionada = res?.current
                }
            }

            state.tempProduct.CostoTotal = getTotalCostByProduct(state.tempProduct)

            return {
                ...state
            }
        }

        case SET_COUNTER_OPTION: {
            const modPosition = state?.tempProduct.modificadores.findIndex((e) => e?.Id === action?.payload?.mod?.Id)
            const optPosition = state.tempProduct.modificadores[modPosition].opciones.findIndex((e) => e?.Id === action?.payload?.option)

            const current = (state.tempProduct.modificadores[modPosition].opciones[optPosition]?.CantidadSeleccionada) ?
                state.tempProduct.modificadores[modPosition].opciones[optPosition]?.CantidadSeleccionada
                :
                0

            const res = getMaxPerModifier(state.tempProduct.modificadores[modPosition])

            if (action?.payload?.type === PRODUCT_ADD_OPTION) {
                if ((res?.current + 1) <= res?.max) {
                    state.tempProduct.modificadores[modPosition].opciones[optPosition] = {
                        ...state.tempProduct.modificadores[modPosition].opciones[optPosition],
                        CantidadSeleccionada: (current + 1)
                    }

                    state.tempProduct.modificadores[modPosition].CantidadSeleccionada = (res?.current + 1)
                } else {
                    state.tempProduct.modificadores[modPosition].CantidadSeleccionada = res?.current
                }
            } else {
                if ((res?.current - 1) <= res?.max) {
                    state.tempProduct.modificadores[modPosition].opciones[optPosition] = {
                        ...state.tempProduct.modificadores[modPosition].opciones[optPosition],
                        CantidadSeleccionada: (current > 0) ? (current - 1) : 0
                    }

                    state.tempProduct.modificadores[modPosition].CantidadSeleccionada = (res?.current - 1)
                } else {
                    state.tempProduct.modificadores[modPosition].CantidadSeleccionada = res?.current
                }
            }

            state.tempProduct.CostoTotal = getTotalCostByProduct(state.tempProduct)

            return {
                ...state
            }
        }

        case SET_TEMP_PRODUCT_QUANTITY: {
            state.tempProduct = {
                ...state.tempProduct,
                CantidadSeleccionada: (action?.payload?.type === TEMP_PRODUCT_ADD_QUATITY) ?
                    (state?.tempProduct?.CantidadSeleccionada + 1)
                    :
                    ((state?.tempProduct?.CantidadSeleccionada - 1) > 0) ?
                        (state?.tempProduct?.CantidadSeleccionada - 1)
                        :
                        0
            }

            state.tempProduct.CostoTotal = getTotalCostByProduct(state.tempProduct)

            return {
                ...state
            }
        }

        case SET_TEMP_TO_CART: {
            let mod = []

            state.tempProduct.modificadores.map((e) => {
                const opt = []
                e.opciones.map((x) => {
                    if (x?.CantidadSeleccionada) {
                        opt.push(x)
                    }
                })

                if (opt.length > 0) {
                    mod.push({
                        ...e,
                        opciones: opt
                    })
                }
            })

            state.products.push({
                ...state.tempProduct,
                modificadores: mod
            })

            state.tempProduct = null
            state.totalPrice = getCartTotalCost(state.products)

            return {
                ...state
            }
        }

        case SET_CHANGE_QUANTITY_PRODUCT: {
            if (state.products[action?.payload?.position]) {
                if (action?.payload?.type === PRODUCT_ADD_QUATITY) {
                    state.products[action?.payload?.position].CantidadSeleccionada = (state.products[action?.payload?.position]?.CantidadSeleccionada + 1)
                    state.products[action?.payload?.position].CostoTotal = getTotalCostByProduct(state.products[action?.payload?.position])
                } else {
                    if ((state.products[action?.payload?.position]?.CantidadSeleccionada - 1) >= 1) {
                        state.products[action?.payload?.position].CantidadSeleccionada = (state.products[action?.payload?.position]?.CantidadSeleccionada - 1)
                        state.products[action?.payload?.position].CostoTotal = getTotalCostByProduct(state.products[action?.payload?.position])
                    }
                }
            }

            state.totalPrice = getCartTotalCost(state.products)

            return {
                ...state
            }
        }

        case SET_REMOVE_PRODUCT: {
            if (state.products[action?.payload?.position]) {
                state.products = state.products.filter((e, i) => i !== action?.payload?.position)
                state.totalPrice = getCartTotalCost(state.products)
            }

            return {
                ...state
            }
        }

        case SET_TEMP_COMMENT: {
            state.tempProduct.Comentario = action?.payload?.comment

            return {
                ...state
            }
        }

        case SET_TIP: {
            state.tip = action?.payload?.tip

            return {
                ...state
            }
        }







        case ADD_PRODUCT: {
            state.products.push({ Index: Math.floor(Math.random() * 99999), ...action.payload.data })
            state.subtotalFinal = getSubtotal(state.products)
            state.subtotalNormal = getSubtotal(state.products, true)

            return {
                ...state
            }
        }

        case ADD_ONE_MORE: {
            let prod = state.products.find(e => e?.Id === action.payload.data)

            if (prod !== undefined) {
                let cantidad = prod?.Cantidad + 1
                let precioUnitarioFinal = (prod?.SubtotalFinal / prod?.Cantidad)
                let precioUnitarioNormal = (prod?.SubtotalNormal / prod?.Cantidad)
                prod.Cantidad = cantidad
                prod.SubtotalFinal = (precioUnitarioFinal * cantidad)
                prod.SubtotalNormal = (precioUnitarioNormal * cantidad)
            }

            state.subtotalFinal = getSubtotal(state.products)
            state.subtotalNormal = getSubtotal(state.products, true)

            return {
                ...state
            }
        }

        case SUBTRACT_ONE: {
            let prod = state.products.find(e => e?.Id === action.payload.data)

            if (prod !== undefined) {
                let cantidad = prod?.Cantidad - 1
                let precioUnitarioFinal = (prod?.SubtotalFinal / prod?.Cantidad)
                let precioUnitarioNormal = (prod?.SubtotalNormal / prod?.Cantidad)
                prod.Cantidad = cantidad
                prod.SubtotalFinal = (precioUnitarioFinal * cantidad)
                prod.SubtotalNormal = (precioUnitarioNormal * cantidad)
            }

            state.subtotalFinal = getSubtotal(state.products)
            state.subtotalNormal = getSubtotal(state.products, true)

            return {
                ...state
            }
        }

        case REMOVE_PRODUCT: {
            state.products = state.products.filter(e => e.Index !== action.payload.data)
            state.subtotalFinal = getSubtotal(state.products)
            state.subtotalNormal = getSubtotal(state.products, true)

            return {
                ...state
            }
        }

        case CLEAN_DATA: {
            state.isOpen = false
            state.products = []
            state.subtotalFinal = 0
            state.subtotalNormal = 0

            return {
                ...state
            }
        }

        default:
            return {
                ...state
            }
    }
}

const getMaxPerModifier = (mod) => {
    const current = mod.opciones.filter((e) => e?.CantidadSeleccionada && e?.CantidadSeleccionada > 0).reduce((result, item) => {
        return result + item?.CantidadSeleccionada
    }, 0)

    return {
        max: mod?.Maximo,
        current
    }
}

const getTotalCostByProduct = (product) => {
    const base = (product?.PrecioFinal * product?.CantidadSeleccionada)
    let mod = 0

    product.modificadores.map((e) => {
        const opc = e.opciones.reduce((result, item) => {
            if (item?.CantidadSeleccionada) {
                return (result + (item?.PrecioFinal * item?.CantidadSeleccionada))
            } else {
                return result
            }
        }, 0)

        mod = mod + opc
    })

    mod = (mod * product?.CantidadSeleccionada)

    return (base + mod)
}

const getCartTotalCost = (products) => {
    let total = 0

    products.map((p) => {
        const base = (p?.PrecioFinal * p?.CantidadSeleccionada)
        let mod = 0

        p.modificadores.map((m) => {
            const opc = m.opciones.reduce((result, item) => {
                if (item?.CantidadSeleccionada) {
                    return (result + (item?.PrecioFinal * item?.CantidadSeleccionada))
                } else {
                    return result
                }
            }, 0)

            mod = mod + opc
        })

        mod = (mod * p?.CantidadSeleccionada)

        total = (total + (base + mod))
    })

    return total
}


const getSubtotal = (products, normal = false) => {
    let subtotalCalculado = 0

    products.map((prod) => {
        subtotalCalculado += (normal) ? prod?.SubtotalNormal : prod?.SubtotalFinal
    })
    return subtotalCalculado
}


