import { INTERCOM_APP_ID } from ".";

const svgPathAgil = `
    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
    <path d="M2.165 15.803l.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
`

export default {
    appId: INTERCOM_APP_ID,
    // "appId   ": "fyrafkh2" // mio
    onShow: e => { modifyDOMIframeIntercomMessenger(); modifyDOMIframeIntercomLauncher() },
    // onHide={e => {  }}
    onUnreadCountChange: e => { overwriteIntercomLauncher() }
    // autoBoot
}

function overwriteIntercomLauncher() {
    setTimeout(() => {
        try {
            const [intercomLauncher] = document.getElementsByClassName('intercom-launcher')

            const [svgOpen, svgClose] = intercomLauncher.getElementsByTagName('svg')
            svgOpen.setAttribute('viewBox', '0 0 16 16')
            svgOpen.innerHTML = svgPathAgil
        } catch (error) {
        }
    }, 1000);
}


function modifyDOMIframeIntercomMessenger() {
    setTimeout(() => {
        try {
            const [iframe] = document.getElementsByName('intercom-messenger-frame')
            const iframeDocument = iframe.contentDocument
            const as = iframeDocument.getElementsByTagName('a')

            for (const a of as) {

                if (a.innerText.includes('Usamos Intercom') || a.innerText.includes('We run on Intercom')) {
                    a.innerText = 'Somos Agil'
                    a.setAttribute('href', 'http://getagil.com/')
                }
            }
        } catch (error) {
        }
    }, 1000)
}

function modifyDOMIframeIntercomLauncher() {
    setTimeout(() => {
        try {
            const [iframe] = document.getElementsByName('intercom-launcher-frame')
            const iframeDocument = iframe.contentDocument
            const intercomContainer = iframeDocument.getElementById('intercom-container')
            const [svgOpen, svgClose] = intercomContainer.getElementsByTagName('svg')

            svgOpen.setAttribute('viewBox', '0 0 16 16')
            // svgOpen.setAttribute('viewBox', '0 0 28 32')
            svgOpen.innerHTML = svgPathAgil
        } catch (error) {
        }
    }, 1000);
}


