import React, { useState, useEffect } from "react"
import moment from 'moment-timezone'
import { Row, Col, Popover, Spin, Icon } from 'antd'
import { useSelector } from "react-redux"

const format = 'HH:mm'

export default ({ order }) => {
    const Auth = useSelector(state => state.Auth)
    const settings = useSelector(state => state.Settings)

    const [isFetching, setisFetching] = useState(true)
    const [hasError, sethasError] = useState(false)
    const [data, setdata] = useState({})


    useEffect(() => {
        const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona
    }, [order?.Codigo, settings])

    useEffect(() => {
        const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona

        if (order.preparacion && order.preparacion.FechaEntrada && order.preparacion.FechaEntregaMaxima && order.preparacion.FechaEntregaMinima) {
            setdata({
                FechaEntrada: moment.utc(order.preparacion.FechaEntrada).tz(tz).format(format),
                FechaEntregaMinima: moment.utc(order.preparacion.FechaEntregaMinima).tz(tz).format(format),
                FechaEntregaMaxima: moment.utc(order.preparacion.FechaEntregaMaxima).tz(tz).format(format),
            })
            setisFetching(false)
        }
    }, [Auth, order])


    return (
        <Popover content='Tiempos de Cocina' trigger="hover">
            {
                isFetching && (
                    <div style={{
                        textAlign: 'center'
                    }}>
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}></Spin>
                    </div>
                )
            }
            {
                !isFetching && !hasError && (
                    <div>
                        <Row>
                            <Col span={12} style={{ fontWeight: 'bold' }}>
                                Aceptado en Caja
                            </Col>
                            <Col span={12}>
                                <p>{data.FechaEntrada}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{ fontWeight: 'bold' }}>
                                Entrega Cocina
                            </Col>
                            <Col span={12}>
                                <p>{order.HoraDespachoCocina}</p>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col span={12} style={{ fontWeight: 'bold' }}>
                                Fecha Entrega (max)
                            </Col>
                            <Col span={12}>
                                <p>{data.FechaEntregaMaxima}</p>
                            </Col>
                        </Row> */}
                    </div>
                )
            }
        </Popover>
    )
}