import React, { useState } from 'react'
import { Button, Menu, Dropdown, Icon, message } from 'antd'

export default function PayLinkButton({ pedido, tipo }) {
    const greenColor = { baseColor: '#28a745', hoverColor: '#4CC162' }
    const [thirdButtonColor, setThirdButtonColor] = useState(greenColor?.baseColor)
    const data = {
        url: (tipo === "SEGUIMIENTO" && pedido?.URLStatus !== undefined && pedido?.URLStatus !== null) ? pedido?.URLStatus : pedido?.UrlPago
        , codigo: pedido?.Codigo
        , comercio: (pedido?.sucursal?.comercio?.Nombre !== undefined && pedido?.sucursal?.comercio?.Nombre !== null) ? pedido?.sucursal?.comercio?.Nombre : ""
        , cliente: (pedido?.cliente?.Nombre !== undefined && pedido?.cliente?.Nombre !== null) ? pedido?.cliente?.Nombre : ""
        , telefono: (pedido?.cliente?.Telefono !== undefined && pedido?.cliente?.Telefono !== null) ? pedido?.cliente?.Telefono : ""
        , email: (pedido?.cliente?.Email !== undefined && pedido?.cliente?.Email !== null) ? pedido?.cliente?.Email : ""
    }
    const subject = (tipo === "SEGUIMIENTO") ?
        `Tu%20pedido%20${data?.codigo}%20está%20en%20camino.`
        :
        `Finaliza%20tu%20compra%20en%20${data?.cliente}.`

    const body = (tipo === "SEGUIMIENTO") ?
        `Hola%20${data?.cliente},%0ARevisa%20el%20estado%20de%20tu%20pedido%20de%20${data?.comercio}%20en%20el%20siguiente%20link%0A%0A${data?.url}`
        :
        `Hola%20${data?.cliente},%0AFinaliza%20tu%20compra%20en%20${data?.comercio}%20pagando%20en%20el%20siguiente%20link:%0A%0A${data?.url}`

    const copyPayUrl = () => {
        navigator.clipboard.writeText(data?.url).then(() => {
            message.success('Link copiado en portapapeles');
        });
    }

    const formatPhone = () => {
        let phone = data?.telefono.replace('+', '').replaceAll(' ', '')
        switch (phone.length) {
            case 8:
                phone = `569${phone}`
                break
            case 9:
                phone = `56${phone}`
                break
            case 10:
                phone = `5${phone}`
                break
            default:
                phone = `${phone}`
                break
        }
        return phone
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" href={`mailto:${data?.email}?Subject=${subject}&body=${body}`}>
                    Enviar por correo
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" href={`https://wa.me/+${formatPhone()}?text=${body}`}>
                    Enviar por WhatsApp
                </a>
            </Menu.Item>
            <Menu.Item onClick={copyPayUrl}>Copiar enlace</Menu.Item>
        </Menu >
    );

    return (
        <Dropdown overlay={menu}>
            <Button
                type="default"
                style={{
                    marginLeft: 10
                    , backgroundColor: thirdButtonColor
                    , color: '#fff'
                    , border: 'none'
                }}
                onMouseEnter={() => setThirdButtonColor(greenColor.hoverColor)}
                onMouseLeave={() => setThirdButtonColor(greenColor.baseColor)}
            >
                {(tipo === "SEGUIMIENTO") ? "Link de seguimiento" : "Link de Pago"}
            </Button>
        </Dropdown>
    )
}