import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { Block, ExpandMore } from "@mui/icons-material"
import { COLOR_BLUE } from "../../../constants/color"
import { IMG_AGIL_DELIVERY, IMG_AGIL_RETIRO } from "../../../constants/img/agil"
import { DELIVERY_TYPE_DELIVERY, DELIVERY_TYPE_RETIRO, PANEL_TIPO_ENTREGA, TYPE_FLASH } from "../../../redux/pedidoEspecial/types"
import { setDeliveryType } from "../../../redux/pedidoEspecial/actions"

const TipoEntrega = ({ changePanel }) => {
    const dispatch = useDispatch()
    const { activePanel, type, deliveryType } = useSelector(state => state?.PedidoEspecial)
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))

    const changeDeliveryType = async (deliveryType) => {
        await dispatch(setDeliveryType({ deliveryType }))
    }

    return (
        <>
            <Accordion
                expanded={(activePanel === PANEL_TIPO_ENTREGA) ? true : false}
                onChange={() => changePanel(PANEL_TIPO_ENTREGA)}
                sx={{ my: 1 }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack
                        direction={(Mediaquery) ? 'column' : 'row'}
                        justifyContent='space-between'
                        sx={{
                            width: {
                                lg: '50%',
                                md: '80%',
                                sm: '100%',
                            }
                        }}
                    >
                        <Typography variant="h6">
                            ¿Tipo de Entrega?
                        </Typography>
                        <Typography sx={{ opacity: 0.7 }}>
                            {
                                (deliveryType) && (
                                    <Typography sx={{ opacity: 0.7 }}>
                                        {
                                            (deliveryType === DELIVERY_TYPE_DELIVERY) ?
                                                `Delivery`
                                                :
                                                `Retiro en tienda`
                                        }
                                    </Typography>
                                )
                            }
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            px: {
                                md: 5,
                                xs: 0,
                            }
                        }}
                    >
                        <Stack direction={(Mediaquery) ? 'column' : 'row'}>
                            <Card
                                onClick={() => changeDeliveryType(DELIVERY_TYPE_DELIVERY)}
                                elevation={2}
                                sx={{
                                    mr: {
                                        md: 1,
                                        xs: 0,
                                    },
                                    mt: {
                                        md: 0,
                                        xs: 1,
                                    },
                                    width: {
                                        md: 350,
                                        xs: '100%'
                                    },
                                    p: 2,
                                    cursor: 'pointer',
                                    border: (deliveryType === DELIVERY_TYPE_DELIVERY) ? 1 : 0,
                                    borderColor: (deliveryType === DELIVERY_TYPE_DELIVERY) ? COLOR_BLUE : 'none',
                                }}
                            >
                                <Stack direction='row' alignItems='center'>
                                    <Box
                                        component='img'
                                        src={IMG_AGIL_DELIVERY}
                                        sx={{
                                            height: 30,
                                            objectFit: 'cover',
                                            mr: 1
                                        }}
                                    />
                                    <Typography variant="h6">
                                        Delivery
                                    </Typography>
                                </Stack>
                                <Typography variant="body1">
                                    Envía a una dirección que especifiques
                                </Typography>
                            </Card>
                            <Card
                                onClick={() => changeDeliveryType(DELIVERY_TYPE_RETIRO)}
                                elevation={2}
                                sx={{
                                    ml: {
                                        md: 1,
                                        xs: 0,
                                    },
                                    mt: {
                                        md: 0,
                                        xs: 1,
                                    },
                                    width: {
                                        md: 350,
                                        xs: '100%'
                                    },
                                    p: 2,
                                    cursor: 'pointer',
                                    border: (deliveryType === DELIVERY_TYPE_RETIRO) ? 1 : 0,
                                    borderColor: (deliveryType === DELIVERY_TYPE_RETIRO) ? COLOR_BLUE : 'none',
                                    opacity: (type === TYPE_FLASH) ? 0.5 : 1
                                }}
                            >
                                <Stack direction='row' alignItems='center'>
                                    {
                                        (type === TYPE_FLASH) ?
                                            <Block sx={{ mr: 1 }} />
                                            :
                                            <Box
                                                component='img'
                                                src={IMG_AGIL_RETIRO}
                                                sx={{
                                                    height: 30,
                                                    objectFit: 'cover',
                                                    mr: 1
                                                }}
                                            />
                                    }
                                    <Typography variant="h6">
                                        Retiro en Tienda
                                    </Typography>
                                </Stack>
                                <Typography variant="body1">
                                    {
                                        (type === TYPE_FLASH) ?
                                            `Agil Flash es solo para Delivery`
                                            :
                                            `Retiro en la sucursal que especifiques`
                                    }
                                </Typography>
                            </Card>
                        </Stack>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default memo(TipoEntrega)