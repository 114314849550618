import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useIntercom } from "react-use-intercom"
import { Icon } from 'antd';
import { fixTelefono } from '../../helpers/fixes'

import { APP_ENV } from '../../config';

const AgilColor = '#FE3814'
const baseSession = {
    languageOverride: 'es',
    actionColor: AgilColor,
    backgroundColor: AgilColor,
}


export default function () {
    const Auth = useSelector(state => state.Auth)
    const { boot, shutdown, hide, show, update, showMessages, startTour, showNewMessages } = useIntercom();

    useEffect(() => {

        if (Auth.auth.Email) {
            update({
                ...baseSession,
                name: `${Auth.auth.Nombre} ${Auth.auth.Apellido}`,
                email: Auth.auth.Email,
                phone: fixTelefono(Auth.auth.nacionalidad, Auth.auth.Telefono),
                avatar: Auth.auth.Imagen,
                customAttributes: {
                    APP_ENV,
                    SucursalTitulo: Auth.payload.usuarioPerfil.sucursal.Titulo,
                    ComercioNombre: Auth.payload.usuarioPerfil.sucursal.comercio.Nombre
                },
                // company: {
                //     companyId
                //     name: `${Auth.payload.usuarioPerfil.sucursal.Titulo} | ${Auth.payload.usuarioPerfil.sucursal.comercio.Nombre}`,
                // },
            })
        } else {
            boot({ ...baseSession })
        }
    }, [Auth])


    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    right: 30,
                    bottom: 20,
                    cursor: 'pointer',
                    // zIndex: 2147483004
                    // zIndex: 2147483004
                }}
                onClick={() => {
                    try {
                        // showMessages('content')
                        showNewMessages()
                    } catch (error) {
                        console.error(error);
                    }
                }}
            >
                {/* <Icon type="message" style={{ fontSize: '5vh', color: "#fff", background: AgilColor, borderRadius: 50, padding: 15 }} /> */}
            </div>
        </>
    )
}