import React, { useState, useEffect, createContext, useContext } from 'react';
import { useSelector } from "react-redux"
import { notification, Tabs } from 'antd';
import axios from 'axios'
import { LAMBDA_POS } from '../../config'
import { SocketContext } from '../Dashboard/Dashboard';
import SettingTiempo from './SettingTiempo';
import SettingAuto from './SettingAuto';
import SettingPrint from './SettingPrint';
import { socketLocalPOSData, socketPrint } from '../../library/printer-socket';

export const timeoutMStoSave = 2000
export const settingContext = createContext()


const settingsOptions = [
  { settingName: 'Tiempo' },
  { settingName: 'Automatización' },
  { settingName: 'Impresión' },
]

export default ({ }) => {
  const socket = useContext(SocketContext)
  const Auth = useSelector(state => state.Auth)
  const settings = useSelector(state => state.Settings)
  const [selectedSetting, setselectedSetting] = useState(0)
  const [initialValues, setinitialValues] = useState([])

  const [machineId, setmachineId] = useState(null)
  const [machineIdOriginal, setmachineIdOriginal] = useState(null)

  useEffect(() => {
    setinitialValues(settings.sucursal)
  }, [settings])

  useEffect(() => {
    getMachineId()
  }, [socketPrint?.connected])

  useEffect(() => {
    let intervalGetMachineId

    if (!machineId || !machineIdOriginal) {
      intervalGetMachineId = setInterval(() => {
        getMachineId()
      }, 2000)
    }

    return () => {
      clearInterval(intervalGetMachineId)
    }
  }, [machineId, machineIdOriginal])

  function getMachineId() {
    setmachineId(socketLocalPOSData.machineId)
    setmachineIdOriginal(socketLocalPOSData.machineIdOriginal)
  }

  const saveSettingSucursal = async ({ Valor, IdTipoConfiguracion }) => {
    const { IdSucursal } = Auth.payload.usuarioPerfil.sucursal
    const { IdUsuario } = Auth.auth

    return axios.post(`${LAMBDA_POS}/updateSucursalConfiguracion/${IdSucursal}`, {
      Valor,
      IdTipoConfiguracion,
      IdUsuario,
      machineId,
      machineIdOriginal,
    }, {
      headers: { "Content-Type": "application/json" }
    }).catch((err) => {
      console.error(err)
      notification.error({
        message: 'Ocurrio un error inesperado, intente mas tarde'
      })
    }).finally(() => {
      socket.emit('SucursalSettingsModificado', { IdSucursal }, data => { })
    })
  }


  return (
    <settingContext.Provider value={{
      initialValues,
      IdSucursal: Auth.payload.usuarioPerfil.sucursal.IdSucursal,
      machineId,
      machineIdOriginal,
      saveSettingSucursal
    }}>
      <div style={{ margin: 10, marginRight: 50 }}>

        <Tabs defaultActiveKey="1" tabPosition='left'>
          <Tabs.TabPane tab="Tiempo" key="1">
            <SettingTiempo />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Automatización" key="2">
            <SettingAuto />
          </Tabs.TabPane> */}
          <Tabs.TabPane tab="Impresoras" key="3">
            <SettingPrint />
          </Tabs.TabPane>
        </Tabs>
      </div>

    </settingContext.Provider>
  );
}
