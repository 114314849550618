import React, { useState } from 'react'
import { Drawer, Row, Col, Icon, Input, Divider, message } from 'antd'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { Link, useLocation } from "react-router-dom"
import currencyFormatter from 'currency-formatter'
import currencyFormatterOptions from '../../config/currencyFormatterOptions.json'
import moment from 'moment'

export default function TopbarSearchInput() {
    const location = useLocation()
    const { orders, ordersActive, ordersReady, ordersEnd, ordersScheduled, ordersRejected, ordersRefunded } = useSelector(state => state.Ordenes)
    const [keyword, setKeyword] = useState('')
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [hover, setHover] = useState(0)
    const [ordenesNuevas, setOrdenesNuevas] = useState([])
    const [ordenesPreparacion, setOrdenesPreparacion] = useState([])
    const [ordenesDespachar, setOrdenesDespachar] = useState([])
    const [ordenesEntregadas, setOrdenesEntregadas] = useState([])
    const [ordenesProgramadas, setOrdenesProgramadas] = useState([])
    const [ordenesRechazadas, setOrdenesRechazadas] = useState([])
    const [ordenesReembolsadas, setOrdenesReembolsadas] = useState([])
    const Auth = useSelector(state => state.Auth)
    const { Moneda } = useSelector(state => state.Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad)
    const tz = useSelector(state => Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona)

    const fullscreen = global.window.innerWidth
    const customStyle = {
        color: {
            blue: '#1A73E8'
            , lightBlue: '#D1E1FF'
            , white: '#fff'
            , red: '#dc3545'
            , orange: '#ff9800'
            , green: '#28a745'
            , black: '#595959'
            , gray: '#f1f3f6'
        }
        , font: {
            description: '18px'
            , title: '20px'
            , icon: '24px'
        }, images: {
            delivery: "https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_deliveryV2.svg"
            , retiro: "https://s3.us-east-2.amazonaws.com/agil.public/Logos/pos_icon_retiro.svg"
        }
    }

    const IconCocina = () => <i className='anticon'>
        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 512 512" width="1.2em" height="1.2em" fill='#28a745'>
            <path d="M392,48a104.462,104.462,0,0,0-42.239,8.947,127.885,127.885,0,0,0-187.579,0A104.006,104.006,0,1,0,93.621,252.623L120,408.672V488a8,8,0,0,0,8,8H384a8,8,0,0,0,8-8V408.672l26.379-156.049A104.014,104.014,0,0,0,392,48ZM376,480H136V416H376Zm33.771-241.794a8,8,0,0,0-6.281,6.5L377.239,400H328.592l7.387-103.43h-.008c.014-.189.029-.377.029-.569a8,8,0,0,0-15.971-.569h-.008L312.551,400H264V312a8,8,0,0,0-16,0v88H199.449l-7.47-104.57h-.008A8,8,0,0,0,176,296c0,.192.015.38.029.569h-.008L183.408,400H134.761L108.51,244.709a8,8,0,0,0-6.281-6.5A88.009,88.009,0,1,1,208,152a8,8,0,0,0,16,0,103.994,103.994,0,0,0-47.262-87.116,112.016,112.016,0,0,1,187.237,49.244,8,8,0,1,0,15.422-4.256,126.93,126.93,0,0,0-19.08-39.994A87.115,87.115,0,0,1,392,64a88.01,88.01,0,0,1,17.771,174.206Z" />
        </svg>
        <b></b>
    </i>

    const IconPlatoOk = () => <i className='anticon'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101" width="1.2em" height="1.2em" fill='#17a2b8'>
            <title>Artboard 2 copy</title>
            <path d="M87.56,64,67.66,75.79,60.08,71h9.53a5.5,5.5,0,0,0,0-11H53.24a15.19,15.19,0,0,0-5.51,1L35,66.78V65.5A1.5,1.5,0,0,0,33.5,64h-11A1.5,1.5,0,0,0,21,65.5v25A1.5,1.5,0,0,0,22.5,92h11A1.5,1.5,0,0,0,35,90.5V89H66.09a9.32,9.32,0,0,0,5.48-1.77L92.83,72A4.83,4.83,0,0,0,87.56,64ZM32,89H24V67h8ZM91.09,69.61,69.82,84.8A6.35,6.35,0,0,1,66.09,86H35V70.07l13.88-6.27A12.13,12.13,0,0,1,53.24,63H69.61a2.5,2.5,0,0,1,0,5H54.9a1.5,1.5,0,0,0-.8,2.77l12.73,8a1.5,1.5,0,0,0,1.57,0l20.7-12.29a1.83,1.83,0,0,1,2,3.06Z" /><path d="M94.5,52H91A38.55,38.55,0,0,0,57.35,14.82a6.5,6.5,0,1,0-9.69,0A38.54,38.54,0,0,0,14,52H9.5a1.5,1.5,0,0,0,0,3h85a1.5,1.5,0,0,0,0-3ZM52.5,7A3.5,3.5,0,1,1,49,10.5,3.5,3.5,0,0,1,52.5,7Zm0,10.5A35.53,35.53,0,0,1,88,52H17A35.53,35.53,0,0,1,52.5,17.5Z" /><path d="M44.44,24.61a29.46,29.46,0,0,0-18.8,16.17A1.5,1.5,0,1,0,28.37,42,26.47,26.47,0,0,1,45.26,27.5a1.5,1.5,0,1,0-.82-2.89Z" />
        </svg>
    </i>


    const closeSetup = () => {
        setShowModal(false)
        setShowModal(false)
        setHover(0)
        setOrdenesNuevas([])
        setOrdenesPreparacion([])
        setOrdenesDespachar([])
        setOrdenesEntregadas([])
        setOrdenesProgramadas([])
    }

    const search = (e) => {
        if (e !== "") {
            setLoading(true)
            searchInOrders()
        }
    }

    const searchInOrders = () => {
        if (keyword !== "") {
            const nuevas = orders.filter(e =>
                `${e?.Codigo.toLowerCase()}-${e?.Corelativo.toString().toLowerCase()}`.includes(keyword.toLowerCase())
                || e?.cliente?.Nombre.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Apellido.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Telefono.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Email.toLowerCase().includes(keyword.toLowerCase())
            )

            const preparacion = ordersActive.filter(e =>
                `${e?.Codigo.toLowerCase()}-${e?.Corelativo.toString().toLowerCase()}`.includes(keyword.toLowerCase())
                || e?.cliente?.Nombre.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Apellido.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Telefono.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Email.toLowerCase().includes(keyword.toLowerCase())
            )

            const despachar = ordersReady.filter(e =>
                `${e?.Codigo.toLowerCase()}-${e?.Corelativo.toString().toLowerCase()}`.includes(keyword.toLowerCase())
                || e?.cliente?.Nombre.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Apellido.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Telefono.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Email.toLowerCase().includes(keyword.toLowerCase())
            )

            const entregadas = ordersEnd.filter(e =>
                `${e?.Codigo.toLowerCase()}-${e?.Corelativo.toString().toLowerCase()}`.includes(keyword.toLowerCase())
                || e?.cliente?.Nombre.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Apellido.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Telefono.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Email.toLowerCase().includes(keyword.toLowerCase())
            )

            const programadas = ordersScheduled.filter(e =>
                `${e?.Codigo.toLowerCase()}-${e?.Corelativo.toString().toLowerCase()}`.includes(keyword.toLowerCase())
                || e?.cliente?.Nombre.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Apellido.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Telefono.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Email.toLowerCase().includes(keyword.toLowerCase())
            )

            const rechazadas = ordersRejected.filter(e =>
                `${e?.Codigo.toLowerCase()}-${e?.Corelativo.toString().toLowerCase()}`.includes(keyword.toLowerCase())
                || e?.cliente?.Nombre.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Apellido.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Telefono.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Email.toLowerCase().includes(keyword.toLowerCase())
            )

            const reembolsadas = ordersRefunded.filter(e =>
                `${e?.Codigo.toLowerCase()}-${e?.Corelativo.toString().toLowerCase()}`.includes(keyword.toLowerCase())
                || e?.cliente?.Nombre.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Apellido.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Telefono.toLowerCase().includes(keyword.toLowerCase())
                || e?.cliente?.Email.toLowerCase().includes(keyword.toLowerCase())
            )

            setOrdenesNuevas(nuevas)
            setOrdenesPreparacion(preparacion)
            setOrdenesDespachar(despachar)
            setOrdenesEntregadas(entregadas)
            setOrdenesProgramadas(programadas)
            setOrdenesRechazadas(rechazadas)
            setOrdenesReembolsadas(reembolsadas)

            if ((nuevas.length + preparacion.length + despachar.length + entregadas.length + programadas.length + rechazadas.length + reembolsadas.length) > 0) {
                setShowModal(true)
            } else {
                message.info('No hay resultados para la búsqueda');
            }
            setLoading(false)
        }
    }

    const GetBadge = ({ etapa }) => {
        let res
        switch (etapa) {
            case "NUEVAS":
                res =
                    <div style={{ border: '2px #ffc107 solid', borderRadius: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Icon type="thunderbolt" theme="twoTone" twoToneColor='#ffc107' style={{ fontSize: customStyle.font.icon }} />
                        <p style={{ fontSize: customStyle.font.description, marginLeft: 5 }}>
                            Nuevos
                        </p>
                    </div>
                break
            case "PREPARACION":
                res =
                    <div style={{ border: '2px #52b869 solid', borderRadius: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconCocina />
                        <p style={{ fontSize: customStyle.font.description, marginLeft: 5 }}>
                            Cocina
                        </p>
                    </div>
                break
            case "DESPACHAR":
                res =
                    <div style={{ border: '2px #17a2b8 solid', borderRadius: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconPlatoOk />
                        <p style={{ fontSize: customStyle.font.description, marginLeft: 5 }}>
                            Despachar
                        </p>
                    </div>
                break
            case "ENTREGADAS":
                res =
                    <div style={{ border: '2px #6c757d solid', borderRadius: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Icon type="snippets" theme="twoTone" twoToneColor='#6c757d' style={{ fontSize: customStyle.font.icon }} />
                        <p style={{ fontSize: customStyle.font.description, marginLeft: 5 }}>
                            Entregadas
                        </p>
                    </div>
                break
            case "PROGRAMADAS":
                res =
                    <div style={{ border: '2px #742C86 solid', borderRadius: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Icon type="calendar" theme="twoTone" twoToneColor='#742C86' style={{ fontSize: customStyle.font.icon }} />
                        <p style={{ fontSize: customStyle.font.description, marginLeft: 5 }}>
                            Programadas
                        </p>
                    </div>
                break
            case "RECHAZADAS":
                res =
                    <div style={{ border: '2px #fe3814 solid', borderRadius: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Icon type="close-circle" theme="twoTone" twoToneColor='#fe3814' style={{ fontSize: customStyle.font.icon }} />
                        <p style={{ fontSize: customStyle.font.description, marginLeft: 5 }}>
                            Rechazadas
                        </p>
                    </div>
                break
        }
        return res
    }

    return (
        <>
            <div style={{ marginTop: 15, display: 'flex' }}>
                <Input.Search
                    placeholder="#Pedido o cliente"
                    size="large"
                    onSearch={search}
                    enterButton
                    loading={loading}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    suffix={
                        <Link
                            onClick={() => setKeyword('')}
                            to={`${location.pathname}`}
                            hidden={(keyword !== "") ? false : true}
                        >
                            <Icon
                                type="close"
                                style={{
                                    backgroundColor: customStyle.color.gray
                                    , borderRadius: 100
                                    , padding: 5
                                    , cursor: 'pointer'
                                }}
                            />
                        </Link>
                    }
                />
            </div>
            <Drawer
                title={
                    <Row style={{ paddingBottom: 15 }}>
                        <Col xs={12} style={{ textAlign: "start" }}>
                            <h4 style={{ color: customStyle.color.white }}>
                                <Icon type="search" theme="outlined" style={{ marginRight: 6 }} />
                                Búsqueda {(keyword !== "") ? `"${keyword}"` : ``}
                            </h4>
                        </Col>
                        <Col xs={12} style={{ textAlign: "end" }}>
                            <h4 style={{ color: customStyle.color.white }}>
                                <Icon
                                    type="close-circle"
                                    theme="outlined"
                                    style={{ fontSize: 20, cursor: 'pointer' }}
                                    onClick={() => (closeSetup(), setKeyword(''))}
                                />
                            </h4>
                        </Col>
                    </Row>
                }
                width={fullscreen}
                visible={showModal}
                closable={false}
                headerStyle={{ backgroundColor: customStyle.color.blue }}
            >
                <Row>
                    <Col span={23} offset={1}>
                        <p style={{ fontSize: customStyle.font.title, fontWeight: 600, marginBottom: 20 }}>
                            {
                                ordenesNuevas.length
                                + ordenesPreparacion.length
                                + ordenesDespachar.length
                                + ordenesEntregadas.length
                                + ordenesProgramadas.length
                                + ordenesRechazadas.length
                                + ordenesReembolsadas.length
                            }
                            <span style={{ marginLeft: 10 }}>Resultados:</span>
                        </p>
                    </Col>
                </Row>
                {
                    (isMobile) && (
                        <>
                            <br />
                            <br />
                        </>
                    )
                }
                <Row>
                    {/* ORDENES NUEVAS */}
                    {
                        ordenesNuevas.map((e, i) => {
                            return (
                                <Link onClick={() => (setKeyword(`${e?.Codigo}`), closeSetup())} key={i} to={`/dashboard/orders?IdPedido=${e?.Codigo}`} style={{ color: customStyle.color.black }}>
                                    <Col
                                        lg={{ span: 6, offset: 1 }}
                                        md={{ span: 11, offset: 1 }}
                                        sm={{ span: 22, offset: 1 }}
                                        style={{
                                            border: `2px solid ${(e?.Codigo === hover) ? customStyle.color.blue : customStyle.color.lightBlue}`
                                            , borderRadius: 10
                                            , padding: (isMobile) ? 5 : 20
                                            , cursor: 'pointer'
                                            , marginBottom: 10
                                            , minHeight: (isMobile) ? 300 : 230
                                        }}
                                        onMouseEnter={() => setHover(e?.Codigo)}
                                        onMouseLeave={() => setHover(0)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: customStyle.font.title, fontWeight: 600 }}>
                                                {e?.Codigo}
                                            </p>
                                            <GetBadge etapa={"NUEVAS"} />
                                        </div>
                                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                        <p style={{ fontSize: customStyle.font.description }}>
                                            <Icon type="user" style={{ marginRight: 10 }} />
                                            {e?.cliente?.Nombre} {e?.cliente?.Apellido}
                                        </p>
                                        {
                                            (e?.entrega?.tipoEntrega?.Tipo === "Delivery") && (
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="pushpin" style={{ marginRight: 10 }} />
                                                    {e?.direccionCliente?.Direccion}
                                                </p>
                                            )
                                        }
                                        <div style={{ display: (isMobile) ? 'grid' : 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            {
                                                (e?.entrega?.tipoEntrega?.Tipo === "Delivery") ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            style={{ width: '40px', opacity: '0.7', marginRight: 10 }}
                                                            src={customStyle.images.delivery}
                                                        />
                                                        <p>Delivery</p>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <Icon
                                                            type="shop"
                                                            theme="filled"
                                                            style={{ fontSize: '34px' }}
                                                        />
                                                        <p>Retiro</p>
                                                    </div>
                                            }
                                            <div>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="shop" style={{ marginRight: 10 }} />
                                                    {e?.sucursal?.Titulo}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="calendar" style={{ marginRight: 10 }} />
                                                    {moment.utc(e?.entrega?.FechaEstimadaEntregaMax).tz(tz).format(`dddd DD [de] MMMM, HH:mm`)}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="dollar" style={{ marginRight: 10 }} />
                                                    {`${currencyFormatter.format(e?.TotalPago, currencyFormatterOptions[Moneda])}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Link>
                            )
                        })
                    }

                    {/* ORDENES EN PREPARACION */}
                    {
                        ordenesPreparacion.map((e, i) => {
                            return (
                                <Link onClick={() => (setKeyword(`${e?.Codigo}-${e?.Corelativo}`), closeSetup())} key={i} to={`/dashboard/activeorders?IdPedido=${e?.Codigo}-${e?.Corelativo}`} style={{ color: customStyle.color.black }}>
                                    <Col
                                        lg={{ span: 6, offset: 1 }}
                                        md={{ span: 11, offset: 1 }}
                                        sm={{ span: 22, offset: 1 }}
                                        style={{
                                            border: `2px solid ${(e?.Codigo === hover) ? customStyle.color.blue : customStyle.color.lightBlue}`
                                            , borderRadius: 10
                                            , padding: (isMobile) ? 5 : 20
                                            , cursor: 'pointer'
                                            , marginBottom: 10
                                            , minHeight: (isMobile) ? 300 : 230
                                        }}
                                        onMouseEnter={() => setHover(e?.Codigo)}
                                        onMouseLeave={() => setHover(0)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: customStyle.font.title, fontWeight: 600 }}>
                                                {e?.Codigo}-{e?.Corelativo}
                                            </p>
                                            <GetBadge etapa={"PREPARACION"} />
                                        </div>
                                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                        <p style={{ fontSize: customStyle.font.description }}>
                                            <Icon type="user" style={{ marginRight: 10 }} />
                                            {e?.cliente?.Nombre} {e?.cliente?.Apellido}
                                        </p>
                                        {
                                            (e?.entrega?.tipoEntrega?.Tipo === "Delivery") && (
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="pushpin" style={{ marginRight: 10 }} />
                                                    {e?.direccionCliente?.Direccion}
                                                </p>
                                            )
                                        }
                                        <div style={{ display: (isMobile) ? 'grid' : 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            {
                                                (e?.entrega?.tipoEntrega?.Tipo === "Delivery") ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            style={{ width: '40px', opacity: '0.7', marginRight: 10 }}
                                                            src={customStyle.images.delivery}
                                                        />
                                                        <p>Delivery</p>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <Icon
                                                            type="shop"
                                                            theme="filled"
                                                            style={{ fontSize: '34px' }}
                                                        />
                                                        <p>Retiro</p>
                                                    </div>
                                            }
                                            <div>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="shop" style={{ marginRight: 10 }} />
                                                    {e?.sucursal?.Titulo}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="calendar" style={{ marginRight: 10 }} />
                                                    {moment.utc(e?.entrega?.FechaEstimadaEntregaMax).tz(tz).format(`dddd DD [de] MMMM, HH:mm`)}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="dollar" style={{ marginRight: 10 }} />
                                                    {`${currencyFormatter.format(e?.TotalPago, currencyFormatterOptions[Moneda])}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Link>
                            )
                        })
                    }

                    {/* ORDENES EN DESPACHAR */}
                    {
                        ordenesDespachar.map((e, i) => {
                            return (
                                <Link onClick={() => (setKeyword(`${e?.Codigo}-${e?.Corelativo}`), closeSetup())} key={i} to={`/dashboard/readyorders?IdPedido=${e?.Codigo}-${e?.Corelativo}`} style={{ color: customStyle.color.black }}>
                                    <Col
                                        lg={{ span: 6, offset: 1 }}
                                        md={{ span: 11, offset: 1 }}
                                        sm={{ span: 22, offset: 1 }}
                                        style={{
                                            border: `2px solid ${(e?.Codigo === hover) ? customStyle.color.blue : customStyle.color.lightBlue}`
                                            , borderRadius: 10
                                            , padding: (isMobile) ? 5 : 20
                                            , cursor: 'pointer'
                                            , marginBottom: 10
                                            , minHeight: (isMobile) ? 300 : 230
                                        }}
                                        onMouseEnter={() => setHover(e?.Codigo)}
                                        onMouseLeave={() => setHover(0)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: customStyle.font.title, fontWeight: 600 }}>
                                                {e?.Codigo}-{e?.Corelativo}
                                            </p>
                                            <GetBadge etapa={"DESPACHAR"} />
                                        </div>
                                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                        <p style={{ fontSize: customStyle.font.description }}>
                                            <Icon type="user" style={{ marginRight: 10 }} />
                                            {e?.cliente?.Nombre} {e?.cliente?.Apellido}
                                        </p>
                                        {
                                            (e?.entrega?.tipoEntrega?.Tipo === "Delivery") && (
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="pushpin" style={{ marginRight: 10 }} />
                                                    {e?.direccionCliente?.Direccion}
                                                </p>
                                            )
                                        }
                                        <div style={{ display: (isMobile) ? 'grid' : 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            {
                                                (e?.entrega?.tipoEntrega?.Tipo === "Delivery") ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            style={{ width: '40px', opacity: '0.7', marginRight: 10 }}
                                                            src={customStyle.images.delivery}
                                                        />
                                                        <p>Delivery</p>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <Icon
                                                            type="shop"
                                                            theme="filled"
                                                            style={{ fontSize: '34px' }}
                                                        />
                                                        <p>Retiro</p>
                                                    </div>
                                            }
                                            <div>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="shop" style={{ marginRight: 10 }} />
                                                    {e?.sucursal?.Titulo}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="calendar" style={{ marginRight: 10 }} />
                                                    {moment.utc(e?.entrega?.FechaEstimadaEntregaMax).tz(tz).format(`dddd DD [de] MMMM, HH:mm`)}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="dollar" style={{ marginRight: 10 }} />
                                                    {`${currencyFormatter.format(e?.TotalPago, currencyFormatterOptions[Moneda])}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Link>
                            )
                        })
                    }

                    {/* ORDENES ENTREGADAS */}
                    {
                        ordenesEntregadas.map((e, i) => {
                            return (
                                <Link onClick={() => (setKeyword(`${e?.Codigo}-${e?.Corelativo}`), closeSetup())} key={i} to={`/dashboard/endorders?IdPedido=${e?.Codigo}-${e?.Corelativo}`} style={{ color: customStyle.color.black }}>
                                    <Col
                                        lg={{ span: 6, offset: 1 }}
                                        md={{ span: 11, offset: 1 }}
                                        sm={{ span: 22, offset: 1 }}
                                        style={{
                                            border: `2px solid ${(e?.Codigo === hover) ? customStyle.color.blue : customStyle.color.lightBlue}`
                                            , borderRadius: 10
                                            , padding: (isMobile) ? 5 : 20
                                            , cursor: 'pointer'
                                            , marginBottom: 10
                                            , minHeight: (isMobile) ? 300 : 230
                                        }}
                                        onMouseEnter={() => setHover(e?.Codigo)}
                                        onMouseLeave={() => setHover(0)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: customStyle.font.title, fontWeight: 600 }}>
                                                {e?.Codigo}-{e?.Corelativo}
                                            </p>
                                            <GetBadge etapa={"ENTREGADAS"} />
                                        </div>
                                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                        <p style={{ fontSize: customStyle.font.description }}>
                                            <Icon type="user" style={{ marginRight: 10 }} />
                                            {e?.cliente?.Nombre} {e?.cliente?.Apellido}
                                        </p>
                                        {
                                            (e?.entrega?.tipoEntrega?.Tipo === "Delivery") && (
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="pushpin" style={{ marginRight: 10 }} />
                                                    {e?.direccionCliente?.Direccion}
                                                </p>
                                            )
                                        }
                                        <div style={{ display: (isMobile) ? 'grid' : 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            {
                                                (e?.entrega?.tipoEntrega?.Tipo === "Delivery") ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            style={{ width: '40px', opacity: '0.7', marginRight: 10 }}
                                                            src={customStyle.images.delivery}
                                                        />
                                                        <p>Delivery</p>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <Icon
                                                            type="shop"
                                                            theme="filled"
                                                            style={{ fontSize: '34px' }}
                                                        />
                                                        <p>Retiro</p>
                                                    </div>
                                            }
                                            <div>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="shop" style={{ marginRight: 10 }} />
                                                    {e?.sucursal?.Titulo}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="calendar" style={{ marginRight: 10 }} />
                                                    {moment.utc(e?.entrega?.FechaEstimadaEntregaMax).tz(tz).format(`dddd DD [de] MMMM, HH:mm`)}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="dollar" style={{ marginRight: 10 }} />
                                                    {`${currencyFormatter.format(e?.TotalPago, currencyFormatterOptions[Moneda])}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Link>
                            )
                        })
                    }

                    {/* ORDENES PROGRAMADAS */}
                    {
                        ordenesProgramadas.map((e, i) => {
                            return (
                                <Link onClick={() => (setKeyword(`${e?.Codigo}`), closeSetup())} key={i} to={`/dashboard/scheduledOrders?IdPedido=${e?.Codigo}`} style={{ color: customStyle.color.black }}>
                                    <Col
                                        lg={{ span: 6, offset: 1 }}
                                        md={{ span: 11, offset: 1 }}
                                        sm={{ span: 22, offset: 1 }}
                                        style={{
                                            border: `2px solid ${(e?.Codigo === hover) ? customStyle.color.blue : customStyle.color.lightBlue}`
                                            , borderRadius: 10
                                            , padding: (isMobile) ? 5 : 20
                                            , cursor: 'pointer'
                                            , marginBottom: 10
                                            , minHeight: (isMobile) ? 300 : 230
                                        }}
                                        onMouseEnter={() => setHover(e?.Codigo)}
                                        onMouseLeave={() => setHover(0)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: customStyle.font.title, fontWeight: 600 }}>
                                                {e?.Codigo}
                                            </p>
                                            <GetBadge etapa={"PROGRAMADAS"} />
                                        </div>
                                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                        <p style={{ fontSize: customStyle.font.description }}>
                                            <Icon type="user" style={{ marginRight: 10 }} />
                                            {e?.cliente?.Nombre} {e?.cliente?.Apellido}
                                        </p>
                                        {
                                            (e?.entrega?.tipoEntrega?.Tipo === "Delivery") && (
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="pushpin" style={{ marginRight: 10 }} />
                                                    {e?.direccionCliente?.Direccion}
                                                </p>
                                            )
                                        }
                                        <div style={{ display: (isMobile) ? 'grid' : 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            {
                                                (e?.entrega?.tipoEntrega?.Tipo === "Delivery") ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            style={{ width: '40px', opacity: '0.7', marginRight: 10 }}
                                                            src={customStyle.images.delivery}
                                                        />
                                                        <p>Delivery</p>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <Icon
                                                            type="shop"
                                                            theme="filled"
                                                            style={{ fontSize: '34px' }}
                                                        />
                                                        <p>Retiro</p>
                                                    </div>
                                            }
                                            <div>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="shop" style={{ marginRight: 10 }} />
                                                    {e?.sucursal?.Titulo}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="calendar" style={{ marginRight: 10 }} />
                                                    {moment.utc(e?.entrega?.FechaEstimadaEntregaMax).tz(tz).format(`dddd DD [de] MMMM, HH:mm`)}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="dollar" style={{ marginRight: 10 }} />
                                                    {`${currencyFormatter.format(e?.TotalPago, currencyFormatterOptions[Moneda])}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Link>
                            )
                        })
                    }

                    {/* ORDENES RECHAZADAS */}
                    {
                        ordenesRechazadas.map((e, i) => {
                            return (
                                <Link onClick={() => (setKeyword(`${e?.Codigo}`), closeSetup())} key={i} to={`/dashboard/rejectedOrders?IdPedido=${e?.Codigo}`} style={{ color: customStyle.color.black }}>
                                    <Col
                                        lg={{ span: 6, offset: 1 }}
                                        md={{ span: 11, offset: 1 }}
                                        sm={{ span: 22, offset: 1 }}
                                        style={{
                                            border: `2px solid ${(e?.Codigo === hover) ? customStyle.color.blue : customStyle.color.lightBlue}`
                                            , borderRadius: 10
                                            , padding: (isMobile) ? 5 : 20
                                            , cursor: 'pointer'
                                            , marginBottom: 10
                                            , minHeight: (isMobile) ? 300 : 230
                                        }}
                                        onMouseEnter={() => setHover(e?.Codigo)}
                                        onMouseLeave={() => setHover(0)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: customStyle.font.title, fontWeight: 600 }}>
                                                {e?.Codigo}
                                            </p>
                                            <GetBadge etapa={"RECHAZADAS"} />
                                        </div>
                                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                        <p style={{ fontSize: customStyle.font.description }}>
                                            <Icon type="user" style={{ marginRight: 10 }} />
                                            {e?.cliente?.Nombre} {e?.cliente?.Apellido}
                                        </p>
                                        {
                                            (e?.entrega?.tipoEntrega?.Tipo === "Delivery") && (
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="pushpin" style={{ marginRight: 10 }} />
                                                    {e?.direccionCliente?.Direccion}
                                                </p>
                                            )
                                        }
                                        <div style={{ display: (isMobile) ? 'grid' : 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            {
                                                (e?.entrega?.tipoEntrega?.Tipo === "Delivery") ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            style={{ width: '40px', opacity: '0.7', marginRight: 10 }}
                                                            src={customStyle.images.delivery}
                                                        />
                                                        <p>Delivery</p>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <Icon
                                                            type="shop"
                                                            theme="filled"
                                                            style={{ fontSize: '34px' }}
                                                        />
                                                        <p>Retiro</p>
                                                    </div>
                                            }
                                            <div>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="shop" style={{ marginRight: 10 }} />
                                                    {e?.sucursal?.Titulo}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="calendar" style={{ marginRight: 10 }} />
                                                    {moment.utc(e?.entrega?.FechaEstimadaEntregaMax).tz(tz).format(`dddd DD [de] MMMM, HH:mm`)}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="dollar" style={{ marginRight: 10 }} />
                                                    {`${currencyFormatter.format(e?.TotalPago, currencyFormatterOptions[Moneda])}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Link>
                            )
                        })
                    }

                    {/* ORDENES REEMBOLSADAS */}
                    {
                        ordenesReembolsadas.map((e, i) => {
                            return (
                                <Link onClick={() => (setKeyword(`${e?.Codigo}`), closeSetup())} key={i} to={`/dashboard/rejectedOrders?IdPedido=${e?.Codigo}`} style={{ color: customStyle.color.black }}>
                                    <Col
                                        lg={{ span: 6, offset: 1 }}
                                        md={{ span: 11, offset: 1 }}
                                        sm={{ span: 22, offset: 1 }}
                                        style={{
                                            border: `2px solid ${(e?.Codigo === hover) ? customStyle.color.blue : customStyle.color.lightBlue}`
                                            , borderRadius: 10
                                            , padding: (isMobile) ? 5 : 20
                                            , cursor: 'pointer'
                                            , marginBottom: 10
                                            , minHeight: (isMobile) ? 300 : 230
                                        }}
                                        onMouseEnter={() => setHover(e?.Codigo)}
                                        onMouseLeave={() => setHover(0)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: customStyle.font.title, fontWeight: 600 }}>
                                                {e?.Codigo}
                                            </p>
                                            <GetBadge etapa={"RECHAZADAS"} />
                                        </div>
                                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                        <p style={{ fontSize: customStyle.font.description }}>
                                            <Icon type="user" style={{ marginRight: 10 }} />
                                            {e?.cliente?.Nombre} {e?.cliente?.Apellido}
                                        </p>
                                        {
                                            (e?.entrega?.tipoEntrega?.Tipo === "Delivery") && (
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="pushpin" style={{ marginRight: 10 }} />
                                                    {e?.direccionCliente?.Direccion}
                                                </p>
                                            )
                                        }
                                        <div style={{ display: (isMobile) ? 'grid' : 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            {
                                                (e?.entrega?.tipoEntrega?.Tipo === "Delivery") ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            style={{ width: '40px', opacity: '0.7', marginRight: 10 }}
                                                            src={customStyle.images.delivery}
                                                        />
                                                        <p>Delivery</p>
                                                    </div>
                                                    :
                                                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <Icon
                                                            type="shop"
                                                            theme="filled"
                                                            style={{ fontSize: '34px' }}
                                                        />
                                                        <p>Retiro</p>
                                                    </div>
                                            }
                                            <div>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="shop" style={{ marginRight: 10 }} />
                                                    {e?.sucursal?.Titulo}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="calendar" style={{ marginRight: 10 }} />
                                                    {moment.utc(e?.entrega?.FechaEstimadaEntregaMax).tz(tz).format(`dddd DD [de] MMMM, HH:mm`)}
                                                </p>
                                                <p style={{ fontSize: customStyle.font.description }}>
                                                    <Icon type="dollar" style={{ marginRight: 10 }} />
                                                    {`${currencyFormatter.format(e?.TotalPago, currencyFormatterOptions[Moneda])}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Link>
                            )
                        })
                    }
                </Row>
            </Drawer>
        </>
    )
}
