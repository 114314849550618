import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAgilAuth } from '../../AgilAuth/context/provider'

export default function () {
    const history = useHistory()
    const { goToLogin, validateToken, loginWithToken } = useAgilAuth()

    useEffect(() => {
        try {
            if (history.location.search.includes('token')) {
                // es un retorno desde auth
                const urlSearchParams = new URLSearchParams(history.location.search)

                const token = urlSearchParams.get('token')
                const continueUrl = urlSearchParams.get('continueUrl')
                if (token) {
                    validateToken(token)
                        .then(() => {
                            return loginWithToken(token)
                        })
                        .then(() => {
                            window.location.href = continueUrl || '/dashboard';
                        })
                } else {
                    goToLogin(true)
                }
            } else {
                goToLogin(true)
            }
        } catch (error) {
            console.error(error)
            localStorage.clear()
            goToLogin(true)
        }
    }, [history])




    if (history.location.search.includes('token')) {
        return (
            <div style={{ width: "100%", height: '100vh', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <div style={{ borderRadius: 20, overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center", maxWidth: "400px", width: "80%" }}>
                    <img style={{ width: "100%" }} src={"https://s3.us-east-2.amazonaws.com/agil.public/iconografia/21652-delivery-guy-waiting.gif"} />
                </div>
                <div style={{ marginTop: 30, textAlign: "center", padding: 20 }}>
                    <h3>Iniciando Sesión</h3>
                    <p>Redirigiendo al sitio, por favor espera</p>
                </div>
            </div>
        )
    }
    return <p>Redirigiendo a login...</p>
}