import React, { useEffect, useState } from "react"
import { usePlacesWidget } from "react-google-autocomplete"
import { API_KEY_MAPS } from '../../config'
import { TextField, InputAdornment, CircularProgress, Typography, useTheme } from "@mui/material"
import { Place } from "@mui/icons-material"

const InputMapGoogle = ({
    variant = 'standard',
    label = 'Dirección',
    setLocation,
}) => {
    const theme = useTheme()
    const [googleLocation, setGoogleLocation] = useState({ address: '', lat: 0, lng: 0 })

    const { ref: materialRef } = usePlacesWidget({
        apiKey: API_KEY_MAPS,
        onPlaceSelected: (place) => setGoogleLocation({
            address: (place?.formatted_address !== undefined) ? place?.formatted_address : '',
            lat: (place?.geometry?.location?.lat()) ? place?.geometry?.location?.lat() : 0,
            lng: (place?.geometry?.location?.lng()) ? place?.geometry?.location?.lng() : 0,
        }),
        inputAutocompleteValue: "country",
        options: {
            types: ["address"],
            fields: ['formatted_address', 'geometry'],
            componentRestrictions: { country: 'CL' },
        },
    })

    useEffect(() => {
        if (googleLocation?.address) {
            setLocation({
                address: googleLocation?.address,
                lat: googleLocation?.lat,
                lng: googleLocation?.lng,
            })
        }
    }, [googleLocation])

    return (
        <>
            <TextField
                label={label}
                fullWidth
                size="small"
                variant={variant}
                inputRef={materialRef}
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start">
                            <Place fontSize="small" />
                        </InputAdornment>,
                }}
                // InputLabelProps={{
                //     style: {
                //         color: COLOR_DARK_GRAY,
                //     },
                // }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: theme?.palette?.primary?.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme?.palette?.primary?.main,
                        },
                    },
                }}
            />
        </>
    )
}

export default InputMapGoogle