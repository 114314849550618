import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Drawer, notification, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import { APP_ENV, LAMBDA_COMMON } from '../../../config'
import { SocketContext } from '../../Dashboard/Dashboard'
import { ID_PEDIDO_ESTADO_ACEPTADO } from '../../../redux/ordenes/types'
import { useSelector } from 'react-redux'
import Axios from 'axios'

const iconColor = '#1890ff'
const iconSize = 2.5
const Icon = ({ size = iconSize }) => <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" className="bi bi-signpost-split" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
    <path d="M7 16h2V6H8V2h1v-.586a1 1 0 0 0-2 0V7h1v4H7v5z" />
    <path fillRule="evenodd" d="M14 3H8v2h6l.75-1L14 3zM8 2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 .8-.4l.975-1.3a.5.5 0 0 0 0-.6L14.8 2.4A1 1 0 0 0 14 2H8zM2 8h6v2H2l-.75-1L2 8zm6-1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-.8-.4L.225 9.3a.5.5 0 0 1 0-.6L1.2 7.4A1 1 0 0 1 2 7h6z" />
</svg>

export default ({ order }) => {
    const Auth = useSelector(state => state.Auth)
    const socket = useContext(SocketContext)
    const [drawerVisible, setdrawerVisible] = useState(false)
    const [Comentario, setComentario] = useState('')
    const [loading, setloading] = useState(false)


    useEffect(() => {
        setdrawerVisible(false)
    }, [order])

    useEffect(() => {
        if (drawerVisible) {
            setloading(false)
            setComentario(order.Comentario)
        }
    }, [drawerVisible])

    async function handleOnClick() {
        setloading(true)
        socket.emit('SplitOrderDelivery', {
            IdPedido: order.IdPedido,
            usuarioEmail: Auth.auth.Email,
            Comentario
        }, ({ IdPedidoBase, IdPedido, success, message }) => {

            if (success) {
                notification.success({
                    message,
                    description: `${order.Codigo}-${order.Corelativo}`
                })
                setTimeout(async () => {


                    const courier = Auth.payload.usuarioPerfil.sucursal.courier
                    const defaultCourier = courier.find(ele => ele.DefaultCourier)
                    const IdSucursal = Auth.payload.usuarioPerfil.sucursal.IdSucursal
                    let isValidPedidoHijo = true

                    // if (defaultCourier && defaultCourier.IdTipoCourier && order.EsCourierExterno === true) {

                    //     try {
                    //         const { data } = await Axios.post(`${LAMBDA_COMMON}/createAndConfirm/courier/${order.IdSucursal}/${IdPedido}/${defaultCourier.IdTipoCourier}`)


                    //     } catch (err) {
                    //         console.error(err)

                    //         isValidPedidoHijo = false
                    //     }

                    // } else {
                    //     isValidPedidoHijo = false
                    // }

                    // if (!isValidPedidoHijo) {
                    socket.emit("SolicitarDelivery", {
                        IdPedido: IdPedido,
                        IdSucursal: order.IdSucursal,
                        usuarioEmail: Auth.auth.Email,
                        tipoSolicitud: 'MANUAL'
                    }, ({ success }) => {
                        setdrawerVisible(false)
                        if (success) {
                            notification.success({
                                message: `Delivery Solicitado`,
                                description: `${order.Codigo}-${order.Corelativo}`
                            })
                        }
                    })
                    // }

                    // si esta en estado aceptado solicita para ambos pedidos ! 
                    if ([ID_PEDIDO_ESTADO_ACEPTADO].includes(order.IdEstado)) {

                        // let isValidOrder = true

                        // if (defaultCourier && defaultCourier.IdTipoCourier && order.EsCourierExterno === true) {

                        //     try {
                        //         const { data } = await Axios.post(`${LAMBDA_COMMON}/createAndConfirm/courier/${order.IdSucursal}/${IdPedidoBase}/${defaultCourier.IdTipoCourier}`)

                        //     } catch (err) {
                        //         console.error(err)

                        //         isValidOrder = false
                        //     }

                        // } else {
                        //     isValidOrder = false
                        // }

                        // if (!isValidOrder) {
                        socket.emit("SolicitarDelivery", {
                            IdPedido: IdPedidoBase,
                            IdSucursal: order.IdSucursal,
                            usuarioEmail: Auth.auth.Email,
                            tipoSolicitud: 'MANUAL'
                        }, ({ success }) => {
                            setdrawerVisible(false)
                            if (success) {
                                // notification.success({
                                //     message: `Delivery Solicitado`,
                                //     description: `${order.Codigo}-${order.Corelativo}`
                                // })
                            }
                        })
                        // }
                    }
                }, 500)
            } else {
                notification.warning({
                    message,
                    description: `${order.Codigo}-${order.Corelativo}`
                })
                setdrawerVisible(false)
            }
        })
    }

    // if (APP_ENV === 'prod') {
    //     if (Auth.payload.usuarioPerfil.sucursal.IdSucursal !== 121) {
    //         return null
    //     }
    // }

    return (<>
        <div style={{ margin: '0 5px' }}>
            <a onClick={() => setdrawerVisible(true)}>
                {<Icon></Icon>}
            </a>
        </div>

        <Drawer
            title={<p style={{ color: iconColor }}>
                <Icon size={iconSize - 1} ></Icon> {`${order.Codigo}-${order.Corelativo}`}
            </p>}
            placement="right"
            closable={true}
            onClose={() => setdrawerVisible(false)}
            visible={drawerVisible}
            width='500px'
        // bodyStyle={{ padding: '0' }}
        // headerStyle={{ display: 'none' }}
        >

            <Row>
                <Col>
                    <TextArea placeholder="Motivo" rows={10} value={Comentario} onChange={e => setComentario(e.target.value)}></TextArea>
                    <span>* El Motivo será visible para el Rider y el Cliente</span>
                </Col>
            </Row>

            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={() => setdrawerVisible(false)} style={{ marginRight: 8 }}>
                    Cancelar
                </Button>
                <Button disabled={!Comentario || loading} onClick={handleOnClick} type="primary">
                    Solicitar
                </Button>
            </div>

        </Drawer>
    </>)
}