import React, { useState, useEffect, createContext, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Layout, message, Modal, notification } from "antd"
import moment from 'moment-timezone'
import socketIOClient from "socket.io-client"
import ReactNotifications from 'react-browser-notifications';
import { TIPO_SUCURSAL_NOTIFICACION_SUCCESS_RIDER, TIPO_SUCURSAL_NOTIFICACION_INFO_RIDER } from '../../redux/sucursalNotificaciones/types.json'

import useWindowSize from "@iso/lib/hooks/useWindowSize"
import appActions from "@iso/redux/app/actions"
import orderActions from "../../redux/ordenes/actions"
import * as notificationsActions from '../../redux/sucursalNotificaciones/actions'


import {
    PED_ENTRE_TIPO_FECHA_ENTRE_ANTES_POSIBLE,
    PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG,
    PED_ENTRE_TIPO_ENTRE_DELIVERY,
    ID_PEDIDO_ESTADO_PENDIENTE,
    ID_PEDIDO_ESTADO_ACEPTADO
} from "../../redux/ordenes/types"
import {
    ID_SUC_TIEMPO_ACEPTACION_PEDIDO_AUTO,
    ID_SUC_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO,
    DEFAULT_VALUE_TIEMPO_ACEPTACION_PEDIDO_AUTO,
    DEFAULT_VALUE_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO,
    ID_SUC_TIEMPO_COCINA,
    DEFAULT_VALUE_TIEMPO_COCINA,
} from "../../redux/settings/types"
import { getScheduleSucursal, refreshScheduleSucursal } from "../../redux/schedule/sucursal/actions"
import { getSettings, refreshSettings } from "../../redux/settings/actions"
import ThemeSwitcher from "@iso/containers/ThemeSwitcher/ThemeSwitcher"
import siteConfig from "@iso/config/site.config"
import Sidebar from "../Sidebar/Sidebar"
import Topbar from "../Topbar/Topbar"
import DashboardRoutes from "./DashboardRoutes"
import alertAudio from "@iso/assets/audio/new-ticket.mp3"
import { DashboardContainer, DashboardGlobalStyles } from "./Dashboard.styles"
import { stackUpTicketToPrint } from "../../library/print"
import { syncSocketLocalvsCloud } from "../../library/printer-socket"
import Axios from "axios"
import { useHistory } from "react-router-dom"
import GlobalNotification from "../../components/GlobalNotification/GlobalNotification"


const { socketUrl, LAMBDA_POS } = require("../../config")

export const SocketContext = createContext()


const { Content, Footer } = Layout
const { toggleAll } = appActions
const styles = {
    layout: { flexDirection: "row", overflowX: "hidden" },
    content: {
        padding: "70px 0 0",
        flexShrink: "0",
        background: "#f1f3f6",
        position: "relative"
    },
    footer: {
        background: "#ffffff",
        textAlign: "center",
        borderTop: "1px solid #ededed"
    }
}

const audio = new Audio(alertAudio)

const AgilIcon = 'https://agil.s3.us-east-2.amazonaws.com/Logos/Logo_Agil_Horizontal.png'


export default function DashboardView() {
    const history = useHistory()
    const [socket, setsocket] = useState()
    const dispatch = useDispatch()
    const appHeight = useSelector(state => state.App.height)
    const Auth = useSelector(state => state.Auth)
    const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona
    const allOrders = useSelector(state => state.Ordenes)
    const settings = useSelector(state => state.Settings)
    const settingAutoAcceptValue = settings.sucursal.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_TIEMPO_ACEPTACION_PEDIDO_AUTO)?.Valor || DEFAULT_VALUE_TIEMPO_ACEPTACION_PEDIDO_AUTO
    const settingAutoReqDelivery = settings.sucursal.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO)?.Valor || DEFAULT_VALUE_TIEMPO_SOLICITAR_DELIVERY_PEDIDO_AUTO
    const settingTiempoCocina = settings.sucursal.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_TIEMPO_COCINA)?.Valor || DEFAULT_VALUE_TIEMPO_COCINA

    const { width, height } = useWindowSize()
    const [play, setPlay] = useState(false)



    const refReactNotifications = useRef()
    const [queqeOrderForCheckShowNotify, setQueqeOrderForCheckShowNotify] = useState()
    const [sessionSucursalUsuarioIndex, setsessionSucursalUsuarioIndex] = useState(0)

    const [messageOpen, setMessageOpen] = useState(false);
    const [visibleModalNoSocketConexion, setvisibleModalNoSocketConexion] = useState(false)
    const [notificationTitle, setNotificationTitle] = useState('')
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationPedido, setNotificationPedido] = useState(null)
    const [notificationCodigoPedido, setNotificationCodigoPedido] = useState(null)
    const [notificationAction, setNotificationAction] = useState('')
    const [notificationType, setNotificationType] = useState('')


    // useEffect(() => {
    //     window.addEventListener('beforeunload', handleOnBeforeUnload)

    //     return () => {
    //         window.removeEventListener('beforeunload', handleOnBeforeUnload)
    //     }
    // }, [])


    //
    useEffect(() => {
        loadAllRedux()
        const socketAux = socketIOClient(socketUrl)
        setsocket(socketAux)

        const intervalCheckDayChange = setInterval(() => {
            const currentMoment = moment.tz(tz)
            const currentLocalDatePeriod = currentMoment.format('A')
            const preLocalDatePeriod = currentMoment.clone().subtract(1, 'hours').format('A')
            if (currentLocalDatePeriod === 'AM' && preLocalDatePeriod === 'PM') {
                window.location.reload()
            }
        }, 1 * 60 * 60 * 1000)

        return () => {
            clearInterval(intervalCheckDayChange)
            socketAux.close()
        }
    }, [Auth, dispatch])

    useEffect(() => {
        dispatch(toggleAll(width, height))
    }, [width, height, dispatch])


    useEffect(() => {
        if (allOrders?.orders?.length) {
            if (!play) {
                iniciarAudio()

                if (window.location.host.includes('localhost') || window.location.host.includes('devagil.com')) {
                    setTimeout(detenerAudio, 2000)
                }
            }
        } else {
            if (play) {
                detenerAudio()
            }
        }

    }, [allOrders])




    useEffect(() => {
        socket && RunSocket()
    }, [socket])


    useEffect(() => {
        if (queqeOrderForCheckShowNotify) {
            checkNewOrderShowNotify(queqeOrderForCheckShowNotify)
        }
    }, [queqeOrderForCheckShowNotify])

    //

    const loadAllRedux = () => {
        const IdComercio = Auth.payload.usuarioPerfil.sucursal.comercio.IdComercio
        const IdSucursal = Auth.payload.usuarioPerfil.sucursal.IdSucursal

        Promise.all([
            new Promise((resolve, reject) => {
                dispatch(getSettings(IdSucursal, resolve))
            }),
            new Promise((resolve, reject) => {
                dispatch(getScheduleSucursal(IdComercio, IdSucursal, resolve))
            }),
        ])
            .finally(() => {
                checkNewsScheduledOrdersForToday()
            })
    }

    const checkNewsScheduledOrdersForToday = async () => {

        const currentLocalDateFormatted = moment.tz(tz).format('YYYY-MM-DD')

        const newsScheduledOrdersForToday = allOrders.orders.filter(order =>
            order.entrega.TipoFechaEntrega === PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG &&
            order.FechaCreacion < `${currentLocalDateFormatted} 00:00:00` &&
            order.entrega.FechaEstimadaEntrega.startsWith(currentLocalDateFormatted)
        )

        if (newsScheduledOrdersForToday.length) {
            const key = `Hay ${newsScheduledOrdersForToday.length} Ordenes previamente programadas para hoy`
            setNotificationTitle('')
            setNotificationMessage(key)
            setNotificationPedido(null)
            setNotificationCodigoPedido(null)
            setNotificationAction('Programadas')
            setNotificationType('info')

            refReactNotifications.current.props = {
                ...refReactNotifications.current.props,
                title: '',
                body: key,
                icon: AgilIcon,
                tag: key,
            }
            refReactNotifications.current.supported() && refReactNotifications.current.show()

            // notification.success({
            //     message: `Hay ${newsScheduledOrdersForToday.length} Ordenes previamente programadas para hoy`,
            // })
        }
    }



    function iniciarAudio() {
        if (!play) {
            setPlay(true)
            audio.loop = true;
            audio.play()
        }
    }
    function detenerAudio() {
        setPlay(false)
        audio.pause();
        audio.currentTime = 0;
    }

    const cleanNotification = () => {
        setNotificationTitle('')
        setNotificationMessage('')
        setNotificationPedido(null)
        setNotificationCodigoPedido(null)
        setNotificationAction('')
        setNotificationType('')
    }

    const emitirPedidoAceptado = (data) => {
        cleanNotification()
        if (data) {
            socket.emit("PedidoAceptado", {
                IdPedido: data.IdPedido,
                IdSucursal: data.IdSucursal,
                Token: Auth.Token,
                usuarioEmail: Auth.auth.Email,
                tipoAceptacion: 'MANUAL'
            }, (data) => {
                // console.log('PedidoAceptado: ', data)
            })
            refReactNotifications.current.supported() && refReactNotifications.current.close(data.key)
        }
    }

    async function checkNewOrderShowNotify(data) {
        const settingValue = settingAutoAcceptValue
        if (settingValue === 'manual') {
            const key = `Ha entrado un nuevo Pedido #${data.Codigo}`
            setNotificationTitle('')
            setNotificationMessage(key)
            setNotificationPedido({ ...data, key: key })
            setNotificationCodigoPedido(data.Codigo)
            setNotificationAction('PedidoAceptado')
            setNotificationType('info')

            refReactNotifications.current.props = {
                ...refReactNotifications.current.props,
                title: '',
                body: key,
                icon: AgilIcon,
                tag: key,
                // onClick: emitirPedidoAceptado({ ...data, key: key })
            }
            refReactNotifications.current.supported() && refReactNotifications.current.show()
        }
    }

    const getLocalStorage = () => {
        let LsSelectedSucursales = localStorage.getItem('SelectedSucursales')
        let data = []
        if (LsSelectedSucursales) {
            LsSelectedSucursales = LsSelectedSucursales.split(',')
            LsSelectedSucursales.map((e) => {
                data.push(parseInt(e))
            })
        }
        return data
    }

    const RunSocket = () => {

        const messageUseHere = () => {
            if (!messageOpen) {
                Modal.info({
                    title: 'Ya tiene una sesión abierta.',
                    content: (
                        <div>
                            <h1>¿Usar aquí?</h1>
                        </div>
                    ),
                    onOk() {
                        socket.emit("LimpiarSesionesUsuario", {
                            IdSucursal: Auth.payload.usuarioPerfil.sucursal.IdSucursal,
                            IdUsuario: Auth.auth.IdUsuario,
                        }, () => {
                            loadAllRedux()
                            socket.emit('subscribeAsPOS', {
                                IdSucursal: Auth.payload.usuarioPerfil.sucursal.IdSucursal,
                                IdUsuario: Auth.auth.IdUsuario,
                                Token: Auth.Token,
                                desktop: JSON.parse(localStorage.getItem('desktop') || '{}')
                            })
                        })
                        setMessageOpen(false);
                    },
                });
                setMessageOpen(true);
            }
        }

        socket
            .off('subscribedAsPOS')
            .on('subscribedAsPOS', ({ sessionSucursalUsuarioIndex: _sessionSucursalUsuarioIndex, orders }) => {

                console.log("-subscribedAsPOS::", orders)
                const { IdSucursal } = Auth.payload.usuarioPerfil.sucursal
                const { Token } = Auth
                dispatch(orderActions.initOrders({
                    orders,
                    IdSucursal,
                    Token
                }, function () {

                }))

                dispatch(notificationsActions.loadAll({
                    IdSucursal
                }))

                message.success({ content: `¡Conectado ${Auth.payload.usuarioPerfil.sucursal.Titulo}!`, key: 'message_socket' })
                console.log(`[SOCKET] EL SOCKET SE HA CONECTADO AL ROOM SUC ${Auth.payload.usuarioPerfil.sucursal.IdSucursal} USU ${Auth.auth.IdUsuario}`)
                setsessionSucursalUsuarioIndex(_sessionSucursalUsuarioIndex)

                socket
                    .off('NuevoPedido')
                    .on("NuevoPedido", (data) => {
                        console.log("-NuevoPedido-", data)
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal))
                            || (data.IdSucursal == IdSucursal)
                        ) {
                            const currentLocalDateFormatted = moment.tz(tz).format('YYYY-MM-DD')
                            const isForToday = moment.utc(data.order.entrega.FechaEstimadaEntrega).tz(tz).format('YYYY-MM-DD').startsWith(currentLocalDateFormatted)

                            dispatch(
                                orderActions.pushInList({
                                    order: data.order,
                                    list: isForToday ? 'orders' : 'ordersScheduled',
                                    callback: function () {
                                        isForToday && setQueqeOrderForCheckShowNotify(data)
                                    }
                                })
                            )
                        }
                    })
                    .off('PedidoAceptado')
                    .on("PedidoAceptado", async data => {
                        console.log("-PedidoAceptado-", data)
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal))
                            || (data.IdSucursal == IdSucursal)
                        ) {
                            dispatch(orderActions.moveBeetweenList({
                                IdPedido: data.IdPedido, to: 'ordersActive',
                                callback: list => {
                                    dispatch(orderActions.refreshDataOrder({
                                        IdSucursal,
                                        IdPedido: data.IdPedido,
                                        order: data.order,
                                        callback: () => { }
                                    }))
                                }
                            }))

                            socket.emit('saveAppLog', {
                                evento: 'POSFeedbackPedidoAceptado',
                                IdSucursal,
                                IdPedido: data.order.IdPedido,
                                mensaje: 'El pedido ha llegado al POS',
                            })
                        }
                    })
                    .off('SendToPrint')
                    .on("SendToPrint", async data => {
                        console.log('[PRINT] SendToPrint', data)
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal) && data.IdUsuario === Auth.auth.IdUsuario && data.Token === Token)
                            || (data.IdSucursal == IdSucursal && data.IdUsuario === Auth.auth.IdUsuario && data.Token === Token)
                        ) {
                            stackUpTicketToPrint(data, socket)
                        }
                    })
                    .off('PedidoListo')
                    .on("PedidoListo", (data) => {
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal))
                            || (data.IdSucursal == IdSucursal)
                        ) {
                            console.log("-PedidoListo-", data)
                            dispatch(orderActions.moveBeetweenList({
                                IdPedido: data.IdPedido, to: 'ordersReady',
                                callback: list => dispatch(orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.IdPedido,
                                    order: data.order,
                                }))
                            }))
                        }
                    })
                    .off('NuevoPedidoDelivery')
                    .on("NuevoPedidoDelivery", (data) => {
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.order.IdSucursal))
                            || (data.order.IdSucursal == IdSucursal)
                        ) {
                            console.log("-NuevoPedidoDelivery-", data)
                            dispatch(orderActions.moveBeetweenList({
                                IdPedido: data.order.IdPedido, to: 'ordersReady',
                                callback: list => dispatch(orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.order.IdPedido,
                                    order: data.order
                                }))
                            }))
                        }
                    })
                    .off('NuevoPedidoDelivery2')
                    .on("NuevoPedidoDelivery2", (data) => {
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.order.IdSucursal))
                            || (data.order.IdSucursal == IdSucursal)
                        ) {
                            console.log("-NuevoPedidoDelivery2-", data)
                            dispatch(orderActions.moveBeetweenList({
                                IdPedido: data.order.IdPedido, to: 'ordersReady',
                                callback: list => dispatch(orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.order.IdPedido,
                                    order: data.order
                                }))
                            }))
                        }
                    })
                    .off('PedidoDeliveryAceptado')
                    .on("PedidoDeliveryAceptado", (data) => {
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal))
                            || (data.IdSucursal == IdSucursal)
                        ) {
                            console.log("-PedidoDeliveryAceptado-", data)
                            dispatch(orderActions.refreshDataOrder({
                                IdSucursal,
                                IdPedido: data.IdPedido,
                                order: data.order
                            }))
                        }
                    })
                    .off('OrderMapsTracking')
                    .on('OrderMapsTracking', async ({ mapsTracking }) => {
                        console.log("-OrderMapsTracking-", mapsTracking)
                        dispatch(orderActions.loadMapsTracking(mapsTracking))
                    })

                    .off('PedidoDesvinculadoRider')
                    .on("PedidoDesvinculadoRider", (data) => {
                        if (data.flagSolicitarDelivery) {
                            console.log("-PedidoDesvinculadoRider-", data)
                            dispatch(
                                orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.order.IdPedido,
                                    order: data.order
                                })
                            )
                        } else {
                            console.log("-PedidoDesvinculadoRider-", data)
                            dispatch(orderActions.moveBeetweenList({
                                IdPedido: data.order.IdPedido, to: 'ordersActive',
                                callback: list => dispatch(orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.order.IdPedido,
                                    order: data.order
                                }))
                            }))
                        }
                    })

                    .off('PedidoRetiradoSucursal')
                    .on("PedidoRetiradoSucursal", (data) => {
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal))
                            || (data.IdSucursal == IdSucursal)
                        ) {
                            console.log("-PedidoRetiradoSucursal-", data)
                            dispatch(orderActions.moveBeetweenList({
                                IdPedido: data.IdPedido, to: 'ordersEnd',
                                callback: list => dispatch(orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.IdPedido,
                                    order: data.order
                                }))
                            }))
                        }
                    })

                    .off('PedidoEntregado')
                    .on("PedidoEntregado", (data) => {
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal))
                            || (data.IdSucursal == IdSucursal)
                        ) {
                            console.log("-PedidoEntregado-", data)
                            dispatch(orderActions.moveBeetweenList({
                                IdPedido: data.IdPedido, to: 'ordersEnd',
                                callback: list => dispatch(orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.IdPedido,
                                    order: data.order,
                                }))
                            }))
                        }
                    })

                    .off('PedidoRechazado')
                    .on('PedidoRechazado', (data) => {
                        console.log("-PedidoRechazado-", data)
                        dispatch(orderActions.moveBeetweenList({
                            IdPedido: data.order.IdPedido,
                            to: 'ordersRejected',
                            callback: list => dispatch(orderActions.refreshDataOrder({
                                IdSucursal,
                                IdPedido: data.order.IdPedido,
                                order: data.order,
                            }))
                        }))
                    })

                    .off('PedidoReembolsado')
                    .on("PedidoReembolsado", (data) => {
                        console.log("-PedidoReembolsado-", data)
                        if (data.refundedFull && (data.waiting || data.refunded)) {
                            const moveTo =
                                (data.refundedFull && data.refunded) ?
                                    'ordersRefunded' :
                                    (data.refundedFull && data.waiting) ?
                                        'ordersRejected' :
                                        null

                            if (moveTo) {
                                dispatch(orderActions.moveBeetweenList({
                                    IdPedido: data.order.IdPedido,
                                    to: moveTo,
                                    callback: list => dispatch(orderActions.refreshDataOrder({
                                        IdSucursal,
                                        IdPedido: data.order.IdPedido,
                                        order: data.order,
                                    }))
                                }))
                            } else {
                                dispatch(orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.order.IdPedido,
                                    order: data.order,
                                }))
                            }
                        } else {
                            dispatch(orderActions.refreshDataOrder({
                                IdSucursal,
                                IdPedido: data.order.IdPedido,
                                order: data.order,
                            }))
                        }
                    })

                    .off('CierreForzadoPedido')
                    .on("CierreForzadoPedido", (data) => {
                        console.log("-CierreForzadoPedido-", data)
                        dispatch(orderActions.moveBeetweenList({
                            IdPedido: data.order.IdPedido,
                            to: 'ordersEnd',
                            callback: list =>
                                dispatch(orderActions.refreshDataOrder({
                                    IdSucursal,
                                    IdPedido: data.order.IdPedido,
                                    order: data.order,
                                }))
                        }))
                    })

                    .off('SplitOrderDelivery')
                    .on('SplitOrderDelivery', ({ order }) => {
                        console.log("-SplitOrderDelivery-", order)
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(order.IdSucursal))
                            || (order.IdSucursal == IdSucursal)
                        ) {
                            dispatch(orderActions.refreshDataOrder({
                                IdSucursal,
                                order,
                                callback: () => { }
                            }))
                        }
                    })
                    /**
                     * se ha cambiado la pausa de la sucursal ! 
                     */
                    .off('PlayPauseSucursal')
                    .on("PlayPauseSucursal", (data) => {
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal))
                            || (data.IdSucursal == IdSucursal)
                        ) {
                            console.log("-PlayPauseSucursal-", data)
                            dispatch(refreshScheduleSucursal({
                                schedule: data.schedule
                            }))
                        }
                    })
                    /** */
                    .off('SucursalSettingsModificado')
                    .on("SucursalSettingsModificado", (data) => {
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(data.IdSucursal))
                            || (data.IdSucursal == IdSucursal)
                        ) {
                            console.log("-SucursalSettingsModificado-", data)
                            dispatch(refreshSettings({
                                settings: data.settings
                            }))
                        }
                    })


                    .off('New Notification')
                    .on('New Notification', async function (data) {
                        console.log('New Notification: ', data)
                        if (data.notificaciones[0]?.emitPush) {
                            setNotificationTitle((data.notificaciones[0].Titulo) ? data.notificaciones[0].Titulo : '')
                            setNotificationMessage((data.notificaciones[0].Notificacion) ? data.notificaciones[0].Notificacion : '')
                            setNotificationPedido(data.notificaciones[0])
                            setNotificationCodigoPedido((data.notificaciones[0].order && data.notificaciones[0].order.Codigo) ? `${data.notificaciones[0].order.Codigo}-${data.notificaciones[0].order.Correlativo}` : null)
                            setNotificationAction(data.notificaciones[0].Tipo)
                            setNotificationType((data.notificaciones[0].Tipo === TIPO_SUCURSAL_NOTIFICACION_SUCCESS_RIDER) ?
                                'success'
                                :
                                (data.notificaciones[0].Tipo === TIPO_SUCURSAL_NOTIFICACION_INFO_RIDER) ?
                                    'info'
                                    :
                                    data.notificaciones[0].Tipo.toLocaleLowerCase()
                            )
                        }

                        refReactNotifications.current.props = {
                            ...refReactNotifications.current.props,
                            title: (data.notificaciones[0].Titulo) ? data.notificaciones[0].Titulo : '',
                            body: (data.notificaciones[0].Notificacion) ? data.notificaciones[0].Notificacion : '',
                            icon: AgilIcon,
                            tag: (data.notificaciones[0].Notificacion) ? data.notificaciones[0].Notificacion : '',
                        }
                        refReactNotifications.current.supported() && refReactNotifications.current.show()

                        // Acepta Pedido Rider
                        // Cambio Fecha Entrega
                        // Rider llega sucursal
                        // Pedido reembolsado
                        // Pedido refund order
                        // Imprimir ticket
                        // Imprimir ticket 2
                        // Emision Boleta

                        dispatch(notificationsActions.pushNew(data))

                        if (data.notificaciones[0].Tipo === TIPO_SUCURSAL_NOTIFICACION_SUCCESS_RIDER) {
                            dispatch(notificationsActions.pushRiderWaiting([{
                                IdPedido: data.notificaciones[0]?.order?.IdPedido,
                                Codigo: data.notificaciones[0]?.order?.Codigo,
                                Corelativo: data.notificaciones[0]?.order?.Correlativo,
                                entrega: {
                                    IdRider: 0,
                                    LLAVE: data.notificaciones[0]?.llaveRider,
                                    rider: {
                                        Nombre: data.notificaciones[0]?.nombreRider,
                                        Apellido: data.notificaciones[0]?.apellidoRider,
                                        Avatar_Img: data.notificaciones[0]?.avatarRider
                                    }
                                },
                                tiempoEntrega: {
                                    FechaLLegadaRiderSucursal: data.notificaciones[0]?.createdAt
                                }
                            }]))
                        }
                    })
                    .off('Push Notification')
                    .on("Push Notification", ({ Subject, Message, MessageAttributes, Duration = 60, Type = 'INFO' }) => {
                        console.log('Push Notification', Subject, Message, MessageAttributes, Duration, Type)
                        // const timeOutSeg = Number(MessageAttributes?.TIMEOUT?.Value || 0)

                        // setTimeout(() => {
                        //     let showMessage = true
                        //     if (MessageAttributes) {
                        //         if (MessageAttributes.RELOAD) {
                        //             const reloadTimeoutSeg = Number(MessageAttributes.RELOAD.Value || 0) + _sessionSucursalUsuarioIndex
                        //             setTimeout(() => {
                        //                 window.location.reload()
                        //             }, reloadTimeoutSeg * 1000)
                        //         }
                        //         if (MessageAttributes.HIDE) {
                        //             showMessage = false
                        //         }
                        //     }

                        //     if (showMessage && Message) {
                        //         Subject = Subject || '¡Hola!'
                        //         notification[Type.toLocaleLowerCase()]({ message: Subject, description: Message, duration: Duration })
                        //         refReactNotifications.current.props = {
                        //             ...refReactNotifications.current.props,
                        //             title: Subject,
                        //             body: Message,
                        //             icon: AgilIcon,
                        //         }
                        //         refReactNotifications.current.supported() && refReactNotifications.current.show()
                        //     }
                        // }, timeOutSeg * 1000)
                    })
                    .off('pedidoSeguimientos')
                    .on('pedidoSeguimientos', (data) => {
                        const { pedido, seguimientos } = data
                        const LsSelectedSucursales = getLocalStorage()

                        if (
                            (LsSelectedSucursales.length > 0 && LsSelectedSucursales.includes(pedido.IdSucursal))
                            || (pedido.IdSucursal == IdSucursal)
                        ) {
                            console.log("-pedidoSeguimientos-", data)
                            dispatch(orderActions.loadPedidoSeguimientos({ pedido, seguimientos }))
                        }
                    })
                    .off('PleaseDownloadNewPOS')
                    .on('PleaseDownloadNewPOS', function (data) {
                        if (data.Token === Token) {
                            console.log("-PleaseDownloadNewPOS-", data)
                            Axios.get(`${LAMBDA_POS}/getDownloadLinkPOS/${IdSucursal}`)
                                .then(({ data: { downloadLink } }) => {
                                    history.push({
                                        pathname: '/download-pos',
                                        search: `?downloadLink=${downloadLink}`
                                    })
                                })
                        }
                    })
                    .off('PedidosLiveInfo')
                    .on('PedidosLiveInfo', function (data) {
                        dispatch(orderActions.receiveSocketLiveInfo(data))
                    })

                    // MULTI SUCURSAL
                    .off('pedidosSucursalSocket')
                    .on('pedidosSucursalSocket', function (data) {
                        console.log("-pedidosSucursalSocket-", data)
                        const { IdSucursal } = Auth.payload.usuarioPerfil.sucursal
                        const { Token } = Auth
                        let newOrders = {}
                        const LsSelectedSucursales = getLocalStorage()

                        if (LsSelectedSucursales.length > 0) {
                            const headers = Object.keys(data?.orders)

                            headers.map((h) => {
                                newOrders = { ...newOrders, [h]: [] }
                                LsSelectedSucursales.map((i) => {
                                    const found = data?.orders[h].filter((e) => e?.IdSucursal === i)
                                    if (found.length > 0) {
                                        Array.prototype.push.apply(newOrders[h], found)
                                    }
                                })
                            })
                        } else {
                            const headers = Object.keys(data?.orders)

                            headers.map((h) => {
                                newOrders = { ...newOrders, [h]: [] }
                                const found = data?.orders[h].filter((e) => e?.IdSucursal === IdSucursal)
                                if (found.length > 0) {
                                    Array.prototype.push.apply(newOrders[h], found)
                                }
                            })
                        }

                        dispatch(orderActions.initOrders({
                            orders: newOrders,
                            IdSucursal,
                            Token
                        }, function () {

                        }))
                    })

                syncSocketLocalvsCloud({ socket, usuario: Auth.auth, Token })
                setvisibleModalNoSocketConexion(false)
            })
            .off('disconnect')
            .on('disconnect', () => {
                console.log('[SOCKET]  EL SOCKET SE HA DESCONECTADO')
                message.loading({ content: `Se ha perdido la conexión. Reconectando...`, key: 'message_socket', duration: 0 }) // 0 infinito
                setvisibleModalNoSocketConexion(true)
            })
            .off('connect')
            .on('connect', () => {
                console.log('[SOCKET] EL SOCKET SE HA CONECTADO')
                socket.emit('subscribeAsPOS', {
                    IdSucursal: Auth.payload.usuarioPerfil.sucursal.IdSucursal,
                    IdUsuario: Auth.auth.IdUsuario,
                    Token: Auth.Token,
                    desktop: JSON.parse(localStorage.getItem('desktop') || '{}')
                })
            })
            .off('reconnect')
            .on('reconnect', () => {
                console.log('[SOCKET] EL SOCKET SE HA RECONECTADO [loadAllRedux!!!]')
                message.success({ content: `Reconexión exitosa`, key: 'message_socket' })
                loadAllRedux()
            })
            .off('rejectSubscriptionPos')
            .on('rejectSubscriptionPos', () => {
                console.log('[SOCKET] rejectSubscriptionPos')
                //CUANDO (AL CONNECT) SOCKET DETECTA QUE ESTE USUARIO YA TIENE UNA SESIÓN
                messageUseHere();
            })
            .off('closeSubscriptionPos')
            .on('closeSubscriptionPos', () => {
                console.log('[SOCKET] closeSubscriptionPos')
                socket.emit('UnsubscribeAsPos', {}, () => {
                    message.error({ content: `Desconectado como POS`, key: 'message_socket' })
                    messageUseHere();
                })
            })
            .off('RefreshVirtualPos')
            .on('RefreshVirtualPos', (data) => {
                console.log('DATAAA', data)
                const { IdSucursal } = Auth.payload.usuarioPerfil.sucursal
                const { Token } = Auth

                dispatch(orderActions.initOrders({
                    orders: data,
                    IdSucursal,
                    Token
                }, function () {

                }))
            })
            ;
    }








    return (
        <>
            <SocketContext.Provider value={socket}>
                <GlobalNotification
                    title={notificationTitle}
                    message={notificationMessage}
                    color={notificationType}
                    showBtnOrder={(notificationAction === 'PedidoAceptado' || notificationAction === TIPO_SUCURSAL_NOTIFICACION_SUCCESS_RIDER) ? true : false}
                    pedido={notificationPedido}
                    codigoPedido={notificationCodigoPedido}
                    size='small'
                    actionClose={(notificationAction === 'PedidoAceptado') ? emitirPedidoAceptado : cleanNotification}
                />
                <DashboardContainer>
                    <DashboardGlobalStyles />
                    <Layout style={{ height: '100vh' }}>
                        <Topbar />
                        <Layout style={styles.layout}>
                            <Sidebar
                                sessionSucursalUsuarioIndex={sessionSucursalUsuarioIndex}
                            />
                            <Layout
                                className="isoContentMainLayout"
                                style={{
                                    height: appHeight
                                }}
                            >
                                <Content
                                    className="isomorphicContent"
                                    style={styles.content}
                                >


                                    {/* <button onClick={InitAudio}>init audio</button>
                <button onClick={StopAaudio}>stop audio</button> */}
                                    <DashboardRoutes />
                                </Content>
                                {/* <Footer style={styles.footer}>
                                    {siteConfig.footerText}
                                </Footer> */}
                            </Layout>
                        </Layout>
                        {/* <ThemeSwitcher /> */}
                    </Layout>
                </DashboardContainer>

            </SocketContext.Provider>


            <ReactNotifications
                onRef={() => refReactNotifications}
                ref={refReactNotifications}
            />

            <Modal
                title="Se ha perdido la conexion"
                visible={visibleModalNoSocketConexion}
                onOk={() => {
                    setvisibleModalNoSocketConexion(false)
                    window.location.reload()
                }}
                onCancel={() => {
                    setvisibleModalNoSocketConexion(false)
                    window.location.reload()
                }}
            >
                <center>
                    <img src='/images/NOSOCKETCONECTION.png' />
                </center>
            </Modal>
        </>
    )
}

function handleOnBeforeUnload(e) {
    e.preventDefault()

    console.log('previniendo cierre');
    return e.returnValue = '¿Está seguro que desea abandonar el POS?'
}

async function sleep(seg) {
    await new Promise((resolve, reject) => { setTimeout(resolve, seg * 1000) })
}