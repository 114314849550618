import { AccessTime, Lock, Moped } from '@mui/icons-material'
import {
    Avatar,
    Badge,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    keyframes,
    Typography,
    Box,
    ListItemAvatar,
    Divider,
    Chip,
    Button,
    useTheme,
    useMediaQuery
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { COLOR_BLACK, COLOR_ORANGE, COLOR_RED, COLOR_YELLOW } from '../../constants/color'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useHistory } from "react-router-dom"
import { leaveRiderWaiting } from '../../redux/sucursalNotificaciones/actions'

const TopbarRiderWaiting = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme.breakpoints.down('md'))
    const [open, setOpen] = useState(false)
    const { ridersWaiting } = useSelector(state => state.SucursalNotificaciones)
    const {
        orders,
        ordersActive,
        ordersReady,
        ordersEnd,
        ordersScheduled,
        ordersRejected,
        ordersRefunded
    } = useSelector(state => state.Ordenes)

    const blink = keyframes({
        '50%': {
            opacity: 1,
            boxShadow: `0 0 20px ${COLOR_RED}`,
        },
    })

    useEffect(() => {
        reviewRidersWaiting()
    }, [ordersReady, ridersWaiting])

    const reviewRidersWaiting = () => {
        ridersWaiting.map((e) => {
            const exists = ordersReady.find((x) => x?.IdPedido === e?.IdPedido)
            if (!exists) {
                dispatch(leaveRiderWaiting(e?.IdPedido))
            }
        })
    }

    const closeDrawer = () => {
        setOpen(false)
    }

    const goToOrder = (pedido) => {
        const orderFound = orders.find((e) => e?.Codigo === pedido)
        const activeFound = ordersActive.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)
        const readyFound = ordersReady.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)
        const endFound = ordersEnd.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)
        const scheduledFound = ordersScheduled.find((e) => e?.Codigo === pedido)
        const rejectedFound = ordersRejected.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)
        const refundedFound = ordersRefunded.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)

        let url = ''
        if (orderFound) {
            url = `/dashboard/orders?IdPedido=${pedido}`
        }
        if (activeFound) {
            url = `/dashboard/activeorders?IdPedido=${pedido}`
        }
        if (readyFound) {
            url = `/dashboard/readyorders?IdPedido=${pedido}`
        }
        if (endFound) {
            url = `/dashboard/endorders?IdPedido=${pedido}`
        }
        if (scheduledFound) {
            url = `/dashboard/scheduledOrders?IdPedido=${pedido}`
        }
        if (rejectedFound || refundedFound) {
            url = `/dashboard/rejectedOrders?IdPedido=${pedido}`
        }

        closeDrawer()
        if (url) {
            history.push(url)
        }
    }

    return (
        <>
            {
                (ridersWaiting.length) > 0 && (
                    <>
                        {
                            (Mediaquery) ?
                                <IconButton onClick={() => setOpen(true)}>
                                    <Badge
                                        badgeContent={ridersWaiting.length}
                                        color="error"
                                        sx={{
                                            '.MuiBadge-colorError': {
                                                opacity: 0.5,
                                                boxShadow: `0 0 5px ${COLOR_RED}`,
                                                animation: `${blink} 2s linear infinite`,
                                            }
                                        }}
                                    >
                                        <Moped color='error' fontSize='large' />
                                    </Badge>
                                </IconButton>
                                :
                                <Badge
                                    badgeContent={ridersWaiting.length}
                                    color="error"
                                    sx={{
                                        '.MuiBadge-colorError': {
                                            opacity: 0.5,
                                            boxShadow: `0 0 5px ${COLOR_RED}`,
                                            animation: `${blink} 2s linear infinite`,
                                        }
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        color='error'
                                        size='small'
                                        onClick={() => setOpen(true)}
                                        startIcon={<Moped />}
                                        sx={{ borderRadius: 100 }}
                                    >
                                        Riders en sucursal
                                    </Button>
                                </Badge>
                        }

                        <Drawer
                            anchor='right'
                            open={open}
                            onClose={closeDrawer}
                        >
                            <List sx={{ width: 400 }}>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography variant='h5'>
                                                    Riders en sucursal
                                                </Typography>
                                                <Chip
                                                    size='small'
                                                    color='error'
                                                    sx={{ ml: 1 }}
                                                    label={ridersWaiting.length}
                                                />
                                            </Box>
                                        }
                                    />
                                </ListItem>
                                {
                                    ridersWaiting.map((e, i) => {
                                        return (
                                            <ListItemButton
                                                key={i}
                                                onClick={() => goToOrder(e?.Codigo)}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        src={e?.RiderAvatar}
                                                        sx={{
                                                            width: 40,
                                                            height: 40,
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Typography variant='body1'>
                                                                #{e?.Codigo}
                                                            </Typography>
                                                            {/* {
                                                                (e?.FechaLLegada) && (
                                                                    <Typography
                                                                        variant='body1'
                                                                        sx={{
                                                                            display: 'flex',
                                                                            opacity: 0.7,
                                                                            color: (moment().diff(moment(e.FechaLLegada), 'minutes') >= 5) ?
                                                                                COLOR_RED
                                                                                :
                                                                                (moment().diff(moment(e.FechaLLegada), 'minutes') >= 1) ?
                                                                                    COLOR_ORANGE
                                                                                    :
                                                                                    COLOR_BLACK
                                                                        }}
                                                                    >
                                                                        <AccessTime sx={{ mr: 0.5 }} />
                                                                        {moment().diff(moment(e.FechaLLegada), 'minutes')} min
                                                                    </Typography>
                                                                )
                                                            } */}
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <Box
                                                            sx={{
                                                                display: 'grid',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'start'
                                                                }}
                                                            >
                                                                {e?.Rider}
                                                                <Typography
                                                                    variant='body2'
                                                                    sx={{
                                                                        display: 'flex',
                                                                        opacity: 0.7,
                                                                        ml: 1
                                                                    }}
                                                                >
                                                                    <Lock sx={{ mr: 0.5, fontSize: 16 }} />
                                                                    {e?.Llave}
                                                                </Typography>
                                                            </Box>
                                                            <Divider />
                                                        </Box>
                                                    }
                                                />
                                                <ListItemAvatar>
                                                    {
                                                        (e?.FechaLLegada) && (
                                                            <Typography
                                                                variant='h6'
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    fontWeight: 600,
                                                                    opacity: 0.7,
                                                                    color: (moment().diff(moment(e.FechaLLegada), 'minutes') >= 5) ?
                                                                        COLOR_RED
                                                                        :
                                                                        (moment().diff(moment(e.FechaLLegada), 'minutes') >= 1) ?
                                                                            COLOR_ORANGE
                                                                            :
                                                                            COLOR_BLACK
                                                                }}
                                                            >
                                                                <AccessTime sx={{ mr: 0.5 }} />
                                                                {/* {moment().diff(moment(e.FechaLLegada), 'minutes')}:{moment().format('ss')} min */}
                                                                {moment().diff(moment(e.FechaLLegada), 'minutes')}min
                                                            </Typography>
                                                        )
                                                    }
                                                </ListItemAvatar>
                                                {/* <ListItemText
                                                    primary={
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Typography variant='body1'>
                                                                #{e?.Codigo}
                                                            </Typography>
                                                            {
                                                                (e?.FechaLLegada) && (
                                                                    <Typography
                                                                        variant='body1'
                                                                        sx={{
                                                                            display: 'flex',
                                                                            opacity: 0.7,
                                                                            color: (moment().diff(moment(e.FechaLLegada), 'minutes') >= 5) ?
                                                                                COLOR_RED
                                                                                :
                                                                                (moment().diff(moment(e.FechaLLegada), 'minutes') >= 1) ?
                                                                                    COLOR_ORANGE
                                                                                    :
                                                                                    COLOR_BLACK
                                                                        }}
                                                                    >
                                                                        <AccessTime sx={{ mr: 0.5 }} />
                                                                        {moment().diff(moment(e.FechaLLegada), 'minutes')} min
                                                                    </Typography>
                                                                )
                                                            }
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <Box
                                                            sx={{
                                                                display: 'grid',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}
                                                            >
                                                                {e?.Rider}
                                                                <Typography
                                                                    variant='body2'
                                                                    sx={{
                                                                        display: 'flex',
                                                                        opacity: 0.7,
                                                                    }}
                                                                >
                                                                    <Lock sx={{ mr: 0.5, fontSize: 16 }} />
                                                                    {e?.Llave}
                                                                </Typography>
                                                            </Box>
                                                            <Divider />
                                                        </Box>
                                                    }
                                                /> */}
                                            </ListItemButton>
                                        )
                                    })
                                }
                            </List>
                        </Drawer>
                    </>
                )
            }
        </>
    )
}

export default TopbarRiderWaiting