import React, { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { AddCircle, Cancel, Delete, ExpandMore, RemoveCircle } from "@mui/icons-material"
import { COLOR_ABSOLUTE_WHITE, COLOR_RED, COLOR_WHITE } from "../../../constants/color"
import { PANEL_PRODUCTOS, PRODUCT_ADD_QUATITY, PRODUCT_REMOVE_QUATITY } from "../../../redux/pedidoEspecial/types"
import { setChangeQuantityProduct, setComment, setRemoveProduct, setTempProduct } from "../../../redux/pedidoEspecial/actions"
import Axios from "axios"
import { LAMBDA_POS } from "../../../config"
import { formatNumber } from "../../../helpers/formatNumber"
import ModalProducto from "./Modal/ModalProducto"

const Productos = ({ changePanel }) => {
    const dispatch = useDispatch()
    const {
        activePanel,
        comment,
        origin,
        deliveryType,
        products,
        totalPrice
    } = useSelector(state => state?.PedidoEspecial)
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))
    const [listProducts, setListProducts] = useState([])
    const [listFilteredProducts, setListFilteredProducts] = useState([])
    const [searchKeyword, setSearchKeyword] = useState('')
    const [loadingProducts, setLoadingProducts] = useState(false)
    const { Moneda } = useSelector(state => state?.Auth?.payload?.usuarioPerfil?.sucursal?.comercio?.nacionalidad)

    useEffect(() => {
        if (origin && origin?.IdSucursal && deliveryType) {
            getProducts()
        }
    }, [origin, deliveryType])

    useEffect(() => {
        if (searchKeyword.length > 3) {
            searchProduct()
        }
    }, [searchKeyword])

    const getProducts = async () => {
        setLoadingProducts(true)
        try {
            const res = await Axios.get(`${LAMBDA_POS}/getAllProduct/pedido/${origin?.IdSucursal}?tipoEntrega=${deliveryType}`)
            setListProducts(res?.data?.products?.categorias)
        } catch (err) {
            console.error(err)
        }
        setLoadingProducts(false)
    }

    const changeSelectedItem = async (product, cleanSearch) => {
        await dispatch(setTempProduct({ product }))
        if (cleanSearch) {
            cleanSearch()
        }
    }

    const changeQuantityProduct = async (type, position) => {
        await dispatch(setChangeQuantityProduct({ type, position }))
    }

    const removeProduct = async (position) => {
        await dispatch(setRemoveProduct({ position }))
    }

    const changeComment = async (comment) => {
        await dispatch(setComment({ comment }))
    }

    const searchProduct = () => {
        let filterList = []
        listProducts.map((e) => {
            const products = e.productos.filter(prod => !prod?.EstaPausado && prod?.Nombre.toUpperCase().includes(searchKeyword.toUpperCase()))
            products.map((p) => {
                filterList.push(p)
            })
        })
        setListFilteredProducts(filterList)
    }

    const cleanSearch = async () => {
        setListFilteredProducts([])
        setSearchKeyword('')
    }

    return (
        <>
            <Accordion
                expanded={(activePanel === PANEL_PRODUCTOS) ? true : false}
                onChange={() => changePanel(PANEL_PRODUCTOS)}
                sx={{ my: 1 }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack
                        direction={(Mediaquery) ? 'column' : 'row'}
                        justifyContent='space-between'
                        sx={{
                            width: {
                                lg: '50%',
                                md: '80%',
                                sm: '100%',
                            }
                        }}
                    >
                        <Typography variant="h6">
                            Elige los productos
                        </Typography>
                        {
                            (products.length > 0) && (
                                <Typography sx={{ opacity: 0.7 }}>
                                    {products.length} productos en carrito
                                </Typography>
                            )
                        }
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            px: {
                                md: 5,
                                xs: 0,
                            }
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label='Buscar productos'
                                    size="small"
                                    fullWidth
                                    value={searchKeyword}
                                    onChange={(e) => setSearchKeyword(e?.target?.value)}
                                    InputProps={{
                                        endAdornment: (listFilteredProducts.length > 0 || searchKeyword) && (<InputAdornment position="end">
                                            <IconButton onClick={cleanSearch}>
                                                <Cancel />
                                            </IconButton>
                                        </InputAdornment>)
                                        ,
                                    }}
                                />
                                {
                                    (loadingProducts) ?
                                        <Box
                                            sx={{
                                                mt: 2,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                        :
                                        <Box
                                            sx={{
                                                height: 400,
                                                overflowY: 'scroll',
                                            }}
                                        >
                                            {
                                                (listFilteredProducts.length > 0) ?
                                                    <Box sx={{ p: 1 }}>
                                                        <Box
                                                            sx={{
                                                                position: 'sticky',
                                                                top: 0,
                                                                py: 1,
                                                                zIndex: 1,
                                                                bgcolor: COLOR_ABSOLUTE_WHITE
                                                            }}
                                                        >
                                                            <Typography variant="h6">
                                                                {searchKeyword}
                                                            </Typography>
                                                            <Divider sx={{ my: 1 }} />
                                                        </Box>
                                                        {
                                                            listFilteredProducts.map((x, z) => {
                                                                return (
                                                                    <Stack
                                                                        key={z}
                                                                        direction='row'
                                                                        justifyContent='space-between'
                                                                        alignItems='center'
                                                                        sx={{
                                                                            my: 1,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => changeSelectedItem(x, true)}
                                                                    >
                                                                        <Stack
                                                                            direction='row'
                                                                            justifyContent='space-between'
                                                                            alignItems='center'
                                                                        >
                                                                            <Avatar
                                                                                src={x?.ImagenXS}
                                                                                variant="rounded"
                                                                                width={50}
                                                                                height={50}
                                                                                sx={{ mr: 1 }}
                                                                            />
                                                                            <Typography variant="body1" sx={{ ml: 1 }}>
                                                                                {x?.Nombre}
                                                                            </Typography>
                                                                        </Stack>
                                                                        <Typography variant="body1">
                                                                            {`${formatNumber({ num: x?.PrecioFinal, currency: Moneda })}`}
                                                                        </Typography>
                                                                    </Stack>
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                    :
                                                    <>
                                                        {
                                                            listProducts.map((e, i) => {
                                                                return (
                                                                    <Box key={i} sx={{ p: 1 }}>
                                                                        <Box
                                                                            sx={{
                                                                                position: 'sticky',
                                                                                top: 0,
                                                                                py: 1,
                                                                                zIndex: 1,
                                                                                bgcolor: COLOR_ABSOLUTE_WHITE
                                                                            }}
                                                                        >
                                                                            <Typography variant="h6">
                                                                                {e?.Nombre}
                                                                            </Typography>
                                                                            <Divider sx={{ my: 1 }} />
                                                                        </Box>
                                                                        {
                                                                            e.productos.filter((x) => !x?.EstaPausado).map((x, z) => {
                                                                                return (
                                                                                    <Stack
                                                                                        key={z}
                                                                                        direction='row'
                                                                                        justifyContent='space-between'
                                                                                        alignItems='center'
                                                                                        sx={{
                                                                                            my: 1,
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={() => changeSelectedItem(x, false)}
                                                                                    >
                                                                                        <Stack
                                                                                            direction='row'
                                                                                            justifyContent='space-between'
                                                                                            alignItems='center'
                                                                                        >
                                                                                            <Avatar
                                                                                                src={x?.ImagenXS}
                                                                                                variant="rounded"
                                                                                                width={50}
                                                                                                height={50}
                                                                                                sx={{ mr: 1 }}
                                                                                            />
                                                                                            <Typography variant="body1" sx={{ ml: 1 }}>
                                                                                                {x?.Nombre}
                                                                                            </Typography>
                                                                                        </Stack>
                                                                                        <Typography variant="body1">
                                                                                            {`${formatNumber({ num: x?.PrecioFinal, currency: Moneda })}`}
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                    </>
                                            }
                                        </Box>
                                }
                            </Grid>
                            {
                                (products.length > 0) && (
                                    <Grid item md={6} xs={12}>
                                        <Box
                                            sx={{
                                                px: {
                                                    md: 2,
                                                    xs: 0
                                                }
                                            }}
                                        >
                                            <Typography variant="h6">
                                                Carrito
                                            </Typography>
                                            <Divider sx={{ py: 1 }} />
                                            {
                                                products.map((p, pIndex) => {
                                                    return (
                                                        <Grid key={pIndex} container spacing={0}>
                                                            <Grid item md={2} xs={12}>
                                                                <Stack direction='row' alignItems='center' justifyContent='start'>
                                                                    <IconButton
                                                                        disabled={(p?.CantidadSeleccionada <= 1) ? true : false}
                                                                        onClick={() => changeQuantityProduct(PRODUCT_REMOVE_QUATITY, pIndex)}
                                                                    >
                                                                        <RemoveCircle
                                                                            size={14}
                                                                            color={(p?.CantidadSeleccionada <= 1) ? 'inherit' : 'primary'}
                                                                        />
                                                                    </IconButton>
                                                                    <Typography variant="h6" sx={{ mx: 1 }}>
                                                                        {p?.CantidadSeleccionada}
                                                                    </Typography>
                                                                    <IconButton onClick={() => changeQuantityProduct(PRODUCT_ADD_QUATITY, pIndex)}>
                                                                        <AddCircle
                                                                            size={14}
                                                                            color='primary'
                                                                        />
                                                                    </IconButton>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item md={7} xs={12}>
                                                                <Box>
                                                                    <Typography variant="h6">
                                                                        {p?.Nombre}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={3} xs={12}>
                                                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                                    <Typography variant="h6" sx={{ mr: 2 }}>
                                                                        {`${formatNumber({ num: p?.CostoTotal, currency: Moneda })}`}
                                                                    </Typography>
                                                                    <IconButton onClick={() => removeProduct(pIndex)}>
                                                                        <Delete
                                                                            size={18}
                                                                            sx={{
                                                                                bgcolor: COLOR_RED,
                                                                                borderRadius: 100,
                                                                                p: 0.4,
                                                                                color: COLOR_WHITE
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Stack>
                                                            </Grid>
                                                            {
                                                                (p.Comentario) && (
                                                                    <>
                                                                        <Grid item md={2} xs={12} />
                                                                        <Grid item md={10} xs={12}>
                                                                            <Typography variant="caption">
                                                                                {p.Comentario}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                (p.modificadores.length > 0) && (
                                                                    <>
                                                                        <Grid item md={2} xs={12} />
                                                                        <Grid item md={10} xs={12}>
                                                                            {
                                                                                p.modificadores.map((m, mIndex) => {
                                                                                    return (
                                                                                        <Box
                                                                                            key={mIndex}
                                                                                            sx={{
                                                                                                px: 2,
                                                                                                opacity: 0.7
                                                                                            }}
                                                                                        >
                                                                                            <Typography variant="body1">
                                                                                                {m?.Nombre}
                                                                                            </Typography>
                                                                                            {
                                                                                                m.opciones.map((o, oIndex) => {
                                                                                                    return (
                                                                                                        <Box
                                                                                                            key={oIndex}
                                                                                                            sx={{ px: 2 }}
                                                                                                        >
                                                                                                            <Typography variant="body2">
                                                                                                                {o?.Nombre} x {o?.CantidadSeleccionada}
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            }
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            <Divider sx={{ py: 1 }} />
                                            <Typography variant="h6" textAlign='end'>
                                                Subtotal: {`${formatNumber({ num: totalPrice, currency: Moneda })}`}
                                            </Typography>
                                            <TextField
                                                label='Indicaciones adicionales para el Rider'
                                                placeholder="Indica si es frágil, si debe mantener temperatura, si puede derramarse, etc."
                                                fullWidth
                                                multiline
                                                rows={4}
                                                sx={{ mt: 2 }}
                                                value={(comment) ? comment : ''}
                                                onChange={(e) => changeComment(e?.target?.value)}
                                            />
                                        </Box>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion >

            <ModalProducto />
        </>
    )
}

export default memo(Productos)