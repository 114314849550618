import React, { useState, useEffect, useContext } from 'react';
import { Icon, notification, TimePicker } from 'antd';
import moment from 'moment-timezone'
import { useSelector } from 'react-redux';

import { settingContext, timeoutMStoSave } from '../'
import { ID_SUC_TIEMPO_PREPA, DEFAULT_VALUE_TIEMPO_PREPARACION } from "@iso/redux/settings/types"


const format = 'mm:ss'
const [minutesDefault = 0, secondsDefault = 0] = DEFAULT_VALUE_TIEMPO_PREPARACION.split(':').map(n => Number(n))

export default () => {
    const Auth = useSelector(state => state.Auth)
    const { initialValues, saveSettingSucursal } = useContext(settingContext)
    const [defaultValue,] = useState(moment.tz(Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona).set({ minutes: minutesDefault, seconds: secondsDefault }))
    const [value, setvalue] = useState(moment.tz(Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona).set({ minutes: 0, seconds: 0 }))
    const [loading, setloading] = useState(false)
    const [, settimeoutsaveSettingSucursal] = useState()

    useEffect(() => {
        const [minutes = 0, seconds = 0] = (initialValues.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_TIEMPO_PREPA)?.Valor || DEFAULT_VALUE_TIEMPO_PREPARACION).split(':').map(n => Number(n))
        setvalue(
            moment.tz(Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona).set({
                minutes,
                seconds
            })
        )
    }, [Auth, initialValues])

    function handleOnChange(value, formatedValue) {
        setvalue(prevValue => {
            const nextValue = value || defaultValue

            if (nextValue.format(format) !== prevValue.format(format)) {
                debounceSaveSettingSucursal(formatedValue)
            }

            return nextValue
        })


    }

    async function debounceSaveSettingSucursal(formatedValue) {
        settimeoutsaveSettingSucursal(prev => {
            clearTimeout(prev)
            return setTimeout(() => {
                setloading(true)
                saveSettingSucursal({
                    Valor: formatedValue || DEFAULT_VALUE_TIEMPO_PREPARACION,
                    IdTipoConfiguracion: ID_SUC_TIEMPO_PREPA,
                }).then((result) => {
                    notification.success({
                        message: 'Tiempo de Preparación guardado',
                        key: ID_SUC_TIEMPO_PREPA,
                    })
                }).finally(() => {
                    setloading(false)
                })
            }, timeoutMStoSave);
        })
    }

    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <TimePicker
                    {...loading ? {
                        suffixIcon: <Icon type='loading'></Icon>
                    } : {}}
                    disabled={true}
                    style={{ width: '200px' }}
                    format={format}
                    value={value}
                    onChange={handleOnChange}
                ></TimePicker>
            </div>
        </>
    );

}