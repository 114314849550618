import { Col, Icon, notification, Row, Spin, Collapse, Tag } from 'antd'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import currencyFormatter from 'currency-formatter';

import currencyFormatterOptions from "../../../config/currencyFormatterOptions.json"

import { LAMBDA_POS } from '../../../config'
import { ID_PEDIDO_ESTADO_ACEPTADO, ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY, ID_PEDIDO_ESTADO_ENTREGADO, ID_PEDIDO_ESTADO_EN_CAMINO, ID_PEDIDO_ESTADO_INACTIVO, ID_PEDIDO_ESTADO_PENDIENTE, ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL, PED_ENTRE_TIPO_ENTRE_DELIVERY, PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA } from '../../../redux/ordenes/types';
import { fixTelefono } from '../../../helpers/fixes';
import PedidoEstadoTag from '../Tags/PedidoEstado.Tag';

const { Panel } = Collapse;


export default function ({ IdUsuario }) {
    const Auth = useSelector(state => state.Auth)
    const [currencyOptions, setcurrencyOptions] = useState({})

    const [usuario, setusuario] = useState({
        nacionalidad: {},
        pedidos: [],
        groupPedidos: []
    })
    const [loading, setloading] = useState(false)

    useEffect(() => {
        setcurrencyOptions(currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])
    }, [Auth])

    useEffect(() => {
        getHistoryUsuarioCliente()
    }, [IdUsuario])

    function getHistoryUsuarioCliente() {
        setloading(true)
        Axios.get(`${LAMBDA_POS}/getHistoryUsuarioCliente/${IdUsuario}`, {
            params: {
                IdSucursal: Auth.payload.usuarioPerfil.sucursal.IdSucursal
            }
        }).then(response => {
            setusuario(response.data.usuario)
        }).catch((err) => {
            notification.warning({ message: err.response?.data?.message || 'No es posible obtener esta información en estos momentos' })
        }).finally(() => {
            setloading(false)
        })
    }


    return (
        <>
            {
                loading ?
                    <Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} ></Icon>} /> :
                    <div style={{ padding: '0 0 100px 0' }} >
                        <center>
                            {
                                usuario.Imagen &&
                                <img
                                    style={{
                                        borderRadius: '50%',
                                        width: '150px',
                                        height: '150px',
                                    }}
                                    src={usuario.Imagen}
                                    alt={''}
                                />
                            }
                            <br></br>
                            <span style={{ textTransform: 'capitalize' }}>
                                {`${usuario.Nombre} ${usuario.Apellido}`}
                            </span>
                            <br></br>
                            {
                                usuario.Telefono && fixTelefono(usuario.nacionalidad, usuario.Telefono)
                            }
                        </center>


                        <div style={{ margin: '15px 0 0 0' }}>

                            <style>
                                {
                                    `
                                        .currency {
                                            text-align: right;
                                            width: 30%;
                                        }
                                        .currency:before {
                                            content: "${currencyOptions.symbol}";
                                            float: left;
                                            padding-right: 4px;
                                        }
                                    `
                                }
                            </style>

                            <Row>
                                <Col span={4}>
                                    total:
                                </Col>
                                <Col span={20}>
                                    <div className='currency'>
                                        <b>{currencyFormatter.format(usuario.total, { ...currencyOptions, symbol: '' })}</b>
                                    </div>
                                </Col>

                                <Col span={4}>
                                    max:
                                </Col>
                                <Col span={20}>
                                    <div className='currency'>
                                        {currencyFormatter.format(usuario.max, { ...currencyOptions, symbol: '' })}
                                    </div>
                                </Col>

                                <Col span={4}>
                                    prom:
                                </Col>
                                <Col span={20}>
                                    <div className='currency'>
                                        {currencyFormatter.format(usuario.avg, { ...currencyOptions, symbol: '' })}
                                    </div>
                                </Col>

                                <Col span={4}>
                                    min:
                                </Col>
                                <Col span={20}>
                                    <div className='currency'>
                                        {currencyFormatter.format(usuario.min, { ...currencyOptions, symbol: '' })}
                                    </div>
                                </Col>

                                <Col span={4}>
                                    cant:
                                </Col>
                                <Col span={20}>
                                    <div style={{ width: '30%' }}>
                                        {usuario.cant}
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <small>* El resumen anterior es en base a los pedidos pagados exitosamente</small>
                                </Col>

                                <Col span={24}>
                                    <div style={{ padding: '15px 0' }}>
                                        <Collapse>
                                            {
                                                usuario.groupPedidos.map((group, i) =>
                                                    <Panel key={i} header={<p>
                                                        {group.FechaCreacionYYYYMMDD}
                                                        <span style={{ float: 'right' }}>
                                                            {group.pedidos.length}
                                                        </span>
                                                    </p>} >
                                                        {
                                                            group.pedidos.map((pedido, i) =>
                                                                <Row key={i}>
                                                                    <Col span={8}>
                                                                        {pedido.Codigo}-{pedido.Corelativo}
                                                                    </Col>
                                                                    <Col span={8} >
                                                                        {currencyFormatter.format(pedido.TotalOC, currencyOptions)}
                                                                    </Col>
                                                                    <Col span={8} >
                                                                        <PedidoEstadoTag pedido={pedido} />
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <p>
                                                                            <br />
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    </Panel>
                                                )
                                            }
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col span={24}>
                                <p>
                                    <br />
                                    <br />
                                    <br />
                                </p>
                            </Col>
                        </Row>

                    </div>

            }
        </>
    )
}