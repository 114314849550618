
/**
 * 
 * @param {{PrefijoTelefonico: number}} nacionalidad 
 * @param {string | number} Telefono 
 * @returns {string}
 */
module.exports.fixTelefono = function (nacionalidad, Telefono) {
    const { PrefijoTelefonico } = nacionalidad

    let nextTelefono = Telefono.toString()

    if (nextTelefono.length === 8) {
        nextTelefono = `9${nextTelefono}`
    }
    nextTelefono = nextTelefono.substring(nextTelefono.length - 9)

    const nextTelefonoPart1 = nextTelefono[0]
    const nextTelefonoPart2 = nextTelefono.slice(1, 5)
    const nextTelefonoPart3 = nextTelefono.slice(5)

    return `+${PrefijoTelefonico}${nextTelefonoPart1} ${nextTelefonoPart2} ${nextTelefonoPart3}`
}