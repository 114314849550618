import React, { useEffect, useState } from 'react'
import { Row, Col, Popover } from 'antd'
import currencyFormatter from 'currency-formatter';
import currencyFormatterOptions from "../../../config/currencyFormatterOptions.json"
import { useSelector } from 'react-redux';
import { PED_ENTRE_TIPO_ENTRE_DELIVERY, PED_ENTRE_TIPO_ENTRE_MESA, ID_PEDIDO_ESTADO_PENDIENTE } from "../../../redux/ordenes/types";

export default ({ order, style = {} }) => {
    const Auth = useSelector(state => state.Auth)

    const [popoverDsctoVisible, setpopoverDsctoVisible] = useState(null)
    const [popoverDsctoContent, setpopoverDsctoContent] = useState(null)
    const [currencyOptions, setcurrencyOptions] = useState({})
    const [Propina, setPropina] = useState(0)
    const [PropinaPorc, setPropinaPorc] = useState(0)

    const [puntosNombre, setPuntosNombre] = useState('Puntos')

    useEffect(() => {
        setcurrencyOptions(
            currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda]
        )

        Auth.payload.usuarioPerfil.sucursal.comercio.programaPuntos.length &&
            setPuntosNombre(
                Auth.payload.usuarioPerfil.sucursal.comercio.programaPuntos[0]?.NombrePuntos || 'Puntos'
            )
    }, [Auth])

    useEffect(() => {
        console.log("[📝] ORDEN: ", order)
    }, [order])

    useEffect(() => {
        const PropinaNext = order?.entrega?.TipoEntrega === PED_ENTRE_TIPO_ENTRE_MESA ?
            order?.PropinaMesa :
            order?.Propina

        setPropina(PropinaNext)
        setPropinaPorc(Math.round(PropinaNext * 100 / order?.SubTotal))
    }, [order?.IdPedido, order?.entrega?.TipoEntrega, order?.PropinaMesa, order?.Propina, order?.SubTotal])


    return (
        <div style={{ ...style }}>
            <style>
                {
                    `
                        .currency {
                            text-align: right;
                            width: 40%;
                        }
                        .currency:before {
                            content: "${currencyOptions.symbol}";
                            float: left;
                            padding-right: 4px;
                        }
                    `
                }
            </style>
            <Row type="flex" justify="space-around" align="middle">
                <Col span={12}>
                    <b> Sub Total </b>
                </Col>
                {(Boolean(order?.SubtotalNormal) && order?.SubTotal !== order?.SubtotalNormal) ?
                    <Col span={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10
                        }}
                    >
                        <div className='currency'>
                            {currencyFormatter.format(order?.SubTotal, { ...currencyOptions, symbol: '' })}
                        </div>
                        <span style={{ textDecoration: 'line-through', fontSize: '.9rem' }}>
                            ${currencyFormatter.format(order?.SubtotalNormal, { ...currencyOptions, symbol: '' })}
                        </span>
                    </Col>
                    :
                    <Col span={12} style={{ textAlign: 'right' }} >
                        <div className='currency'>
                            {currencyFormatter.format(order?.SubTotal, { ...currencyOptions, symbol: '' })}
                        </div>
                    </Col>
                }

                {/* <Col span={12}>
                    <b> Propina </b>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }} >
                    <div className='currency'>
                        {currencyFormatter.format(order.Propina, { ...currencyOptions, symbol: '' })}
                    </div>
                </Col> */}

                {
                    order?.entrega?.TipoEntrega === PED_ENTRE_TIPO_ENTRE_DELIVERY &&
                    <>
                        <Col span={12}>
                            <b> Despacho </b>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }} >
                            <div className='currency'>
                                {currencyFormatter.format(order?.GastosEnvio, { ...currencyOptions, symbol: '' })}
                            </div>
                        </Col>
                    </>
                }

                {/* {
                    order?.entrega?.TipoEntrega === PED_ENTRE_TIPO_ENTRE_MESA &&
                    <>
                        <Col span={12}>
                            <b> Propina </b> ({PropinaPorc}%)
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }} >
                            <div className='currency'>
                                {currencyFormatter.format(Propina, { ...currencyOptions, symbol: '' })}
                            </div>
                        </Col>
                    </>
                } */}

                {order?.IdTipoPedido === 5 && order?.DsctoCuponSubtotal > 0 ?
                    <>
                        <Col span={12} >
                            <Popover
                                content={<p style={{ color: '#008dff' }}>
                                    {
                                        popoverDsctoContent && <>
                                            <b>{popoverDsctoContent.tipoValor.Nombre}</b>: {popoverDsctoContent.Valor}
                                        </>
                                    }
                                </p>}
                                // title="Title"
                                trigger="click"
                                visible={false}
                                onVisibleChange={(e) => {

                                }}
                            >
                                <p style={{ wordBreak: 'break-word' }}>
                                    <b> Dscto. </b>
                                </p>
                            </Popover>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right', color: 'red' }} >
                            <div className='currency'>
                                -{currencyFormatter.format(order?.DsctoCuponSubtotal, { ...currencyOptions, symbol: '' })}
                            </div>
                        </Col>
                    </>
                    :
                    (order?.cupones !== undefined && order?.cupones !== null) && (
                        order?.cupones.map((cupon, i) => <>
                            <Col key={i} span={12} >
                                <Popover
                                    content={<p style={{ color: '#008dff' }}>
                                        {
                                            popoverDsctoContent && <>
                                                <b>{popoverDsctoContent.tipoValor.Nombre}</b>: {popoverDsctoContent.Valor}
                                            </>
                                        }
                                    </p>}
                                    // title="Title"
                                    trigger="click"
                                    visible={popoverDsctoVisible === i}
                                    onVisibleChange={(e) => {
                                        if (e) {
                                            setpopoverDsctoContent(JSON.parse(cupon.pedido_cupon.Original))
                                            setpopoverDsctoVisible(i)
                                        } else {
                                            setpopoverDsctoVisible(null)
                                        }
                                    }}
                                >
                                    <p style={{ wordBreak: 'break-word' }}>
                                        <b> Dscto. {cupon.Nombre} </b>
                                    </p>
                                </Popover>
                            </Col>
                            <Col key={i} span={12} style={{ textAlign: 'right', color: 'red' }} >
                                <div className='currency'>
                                    -{currencyFormatter.format(cupon.pedido_cupon.Dscto, { ...currencyOptions, symbol: '' })}
                                </div>
                            </Col>
                        </>)
                    )
                }
                {
                    order.DsctoPuntos > 0 && <>
                        <>
                            <Col span={12}>
                                <b> Canje {puntosNombre} </b>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right', color: 'red' }} >
                                <div className='currency'>
                                    -{currencyFormatter.format(order.DsctoPuntos, { ...currencyOptions, symbol: '' })}
                                </div>
                            </Col>
                        </>

                    </>

                }

                <Col span={12}>
                    <b> Total </b>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }} >
                    <div className='currency'>
                        <b>
                            {currencyFormatter.format(order.TotalOC - order.Propina - order.PropinaAgil - order.CuotaServicioAgil, { ...currencyOptions, symbol: '' })}
                        </b>
                    </div>

                </Col>

                {
                    (order?.tipoPedido?.Codigo === 'PEDESPECIAL' && order?.IdEstado === ID_PEDIDO_ESTADO_PENDIENTE) && (
                        <>
                            <Col span={20} offset={1} pull={3}>
                                <hr style={{ border: 'transparent', borderTop: `1px solid #e7e9ec` }} />
                            </Col>
                            <Col span={12}>
                                <b> Propina Rider</b>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }} >
                                <div className='currency'>
                                    {currencyFormatter.format(order.Propina, { ...currencyOptions, symbol: '' })}
                                </div>
                            </Col>
                            <Col span={12}>
                                <b> Total + Propina</b>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }} >
                                <div className='currency'>
                                    {currencyFormatter.format(order.TotalOC, { ...currencyOptions, symbol: '' })}
                                </div>
                            </Col>
                        </>
                    )
                }
            </Row>

        </div>
    )
}