import React, { useEffect, useState } from "react"
import { Alert, AlertTitle, Avatar, Box, Button, Slide, Snackbar, Typography } from "@mui/material"
import { COLOR_BLUE, COLOR_GREEN, COLOR_LIGHT_GRAY, COLOR_WHITE } from "../../constants/color"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

const GlobalNotification = ({
    title,
    message,
    color = 'info',
    showBtnOrder = false,
    pedido = null,
    codigoPedido = null,
    avatar,
    size = 'large',
    actionClose
}) => {
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [transition, setTransition] = useState(undefined)
    const {
        orders,
        ordersActive,
        ordersReady,
        ordersEnd,
        ordersScheduled,
        ordersRejected,
        ordersRefunded
    } = useSelector(state => state.Ordenes)

    useEffect(() => {
        if (title || message || pedido) {
            showNotification()
        }
    }, [title, message, pedido])

    const Transition = (props) => {
        return <Slide {...props} direction="left" />
    }

    const closeNotification = () => {
        setOpen(false)
        if (actionClose) {
            actionClose(pedido)
        }
    }

    const showNotification = () => {
        setOpen(true)
        setTransition(() => Transition)
    }

    const goToOrder = (pedido) => {
        const orderFound = orders.find((e) => e?.Codigo === pedido)
        const activeFound = ordersActive.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)
        const readyFound = ordersReady.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)
        const endFound = ordersEnd.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)
        const scheduledFound = ordersScheduled.find((e) => e?.Codigo === pedido)
        const rejectedFound = ordersRejected.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)
        const refundedFound = ordersRefunded.find((e) => `${e?.Codigo}-${e?.Corelativo}` === pedido)

        let url = ''
        if (orderFound) {
            url = `/dashboard/orders?IdPedido=${pedido}`
        }
        if (activeFound) {
            url = `/dashboard/activeorders?IdPedido=${pedido}`
        }
        if (readyFound) {
            url = `/dashboard/readyorders?IdPedido=${pedido}`
        }
        if (endFound) {
            url = `/dashboard/endorders?IdPedido=${pedido}`
        }
        if (scheduledFound) {
            url = `/dashboard/scheduledOrders?IdPedido=${pedido}`
        }
        if (rejectedFound || refundedFound) {
            url = `/dashboard/rejectedOrders?IdPedido=${pedido}`
        }

        closeNotification()
        if (url) {
            history.push(url)
        }
    }

    return (
        <>
            <Snackbar
                open={open}
                onClose={closeNotification}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionComponent={transition}
                key={transition ? transition.name : ''}
            >
                <Alert
                    severity={color}
                    variant="filled"
                    onClose={closeNotification}
                    autoHideDuration={6000}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            {
                                (title) && (
                                    <AlertTitle>
                                        <Typography variant={(size === 'large') ? 'h5' : 'body1'} color={COLOR_WHITE}>
                                            {title}
                                        </Typography>
                                    </AlertTitle>
                                )
                            }

                            {
                                (message) && (
                                    <Typography variant={(size === 'large') ? 'h6' : 'body2'} color={COLOR_WHITE}>
                                        {message}
                                    </Typography>
                                )
                            }
                        </Box>
                        {
                            (avatar) && (
                                <Avatar
                                    src={avatar}
                                    sx={{
                                        width: 40,
                                        height: 40,
                                    }}
                                />
                            )
                        }
                    </Box>
                    {
                        (showBtnOrder && codigoPedido) && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 0.5
                                }}
                            >
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={() => goToOrder(codigoPedido)}
                                    sx={{
                                        bgcolor: COLOR_WHITE,
                                        color: (color === 'success') ? COLOR_GREEN : COLOR_BLUE,
                                        '&:hover': {
                                            bgcolor: COLOR_LIGHT_GRAY,
                                        }
                                    }}
                                >
                                    Ir al pedido #{codigoPedido}
                                </Button>
                            </Box>
                        )
                    }
                </Alert>
            </Snackbar>
        </>
    )
}

export default GlobalNotification