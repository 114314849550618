import React, { useState, useEffect, useContext } from 'react';
import { Select, notification } from 'antd';
import { settingContext } from '../'

import { ID_SUC_TIEMPO_DELIVERY, DEFAULT_VALUE_TIEMPO_DELIVERY } from "@iso/redux/settings/types"

const { Option } = Select;

const options = [
    { value: '10-20', text: '10-20' },
    { value: '10-25', text: '10-25' },
    { value: '20-45', text: '20-45' },
    { value: '30-65', text: '30-65' },
    { value: '40-85', text: '40-85' },
]


export default () => {
    const { IdSucursal, initialValues, saveSettingSucursal } = useContext(settingContext)
    const [selectedOption, setselectedOption] = useState(DEFAULT_VALUE_TIEMPO_DELIVERY)
    const [loading, setloading] = useState(false)


    useEffect(() => {
        setselectedOption(initialValues.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_TIEMPO_DELIVERY)?.Valor || DEFAULT_VALUE_TIEMPO_DELIVERY)
    }, [IdSucursal, initialValues])

    const handleChange = (value) => {
        setloading(true)

        saveSettingSucursal({
            Valor: value,
            IdTipoConfiguracion: ID_SUC_TIEMPO_DELIVERY,
        }).then((result) => {
            setselectedOption(value)
            notification.success({
                message: 'Tiempo de Delivery guardado',
                key: ID_SUC_TIEMPO_DELIVERY
            })
        }).finally(() => {
            setloading(false)
        })
    }

    return (
        <>
            <Select loading={loading} defaultValue={selectedOption} value={selectedOption} style={{ width: 200 }} onChange={handleChange} disabled={true}>
                {
                    options.map((option, i) => <Option key={i} value={option.value}>{option.text}</Option>)
                }
            </Select>
        </>
    );

}