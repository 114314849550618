import { CheckBox, CheckBoxOutlineBlank, FilterAlt, Store } from '@mui/icons-material'
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, List, ListItem, Popover, TextField, Typography } from '@mui/material'
import React, { useEffect, useState, useRef, memo } from 'react'
import { useSelector } from 'react-redux'

const useFilterSucursal = () => {
    const [selectedSucursalIds, setSelectedSucursalIds] = useState([])
    const [allSucursalIds, setAllSucursalIds] = useState([])
    const [sucursalFiltered, setSucursalFiltered] = useState([])
    const { usuarioPerfil, usuarioPerfiles } = useSelector(state => state?.Auth?.payload)
    const { comercio, IdSucursal } = usuarioPerfil?.sucursal
    const { IdComercio } = comercio
    const [listAvailables, setListAvailables] = useState([])
    const LsSelectedSucursales = localStorage.getItem('SelectedSucursales')

    useEffect(() => {
        getAvailables()
    }, [usuarioPerfiles])

    useEffect(() => {
        getIdsSucursales()
    }, [sucursalFiltered])

    const getAvailables = async () => {
        const list = []
        const listIds = []
        const filtered = usuarioPerfiles.filter((e) => e?.sucursal?.comercio?.IdComercio === IdComercio)
        if (filtered.length > 0) {
            filtered.map((e) => {
                const findListSucursal = listIds.find(ele => ele === e?.sucursal?.IdSucursal)

                if (!findListSucursal) {
                    list.push(e?.sucursal)
                    listIds.push(e?.sucursal?.IdSucursal)

                }
            })

            if (LsSelectedSucursales) {
                const LsIds = LsSelectedSucursales.split(',')
                const ListIds = []
                LsIds.map((i) => {
                    const found = usuarioPerfiles.find((e) => e?.sucursal?.IdSucursal === parseInt(i))
                    if (found) {

                        ListIds.push(found?.sucursal)
                    }
                })
                setSucursalFiltered(ListIds)
            } else {
                // const filteredFirst = usuarioPerfiles.find((e) => e?.sucursal?.IdSucursal === IdSucursal)
                setSucursalFiltered(list)
                localStorage.setItem('SelectedSucursales', listIds)
            }
            filtered.map((e) => {
                let sucursalInList = list.find(suc => suc.IdSucursal === e?.sucursal?.IdSucursal)
                let sucursalInListIds = listIds.find(suc => suc === e?.sucursal?.IdSucursal)
                if (!sucursalInList) {
                    list.push(e?.sucursal)
                }

                if (!sucursalInListIds) {
                    listIds.push(e?.sucursal?.IdSucursal)
                }
            })
        }

        setListAvailables(list)
        setAllSucursalIds(listIds)
    }

    const changeSucursal = async (e) => {
        setSucursalFiltered(e)
    }

    const getIdsSucursales = async () => {
        const list = []
        sucursalFiltered.map((e) => {
            list.push(e?.IdSucursal)
        })
        setSelectedSucursalIds(list)
    }

    const FilterSucursalLabel = ({ onclick = {} }) => {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={onclick}
                >
                    <FilterAlt
                        color='primary'
                        fontSize="small"
                        sx={{ mr: 1 }}
                    />
                    <Typography
                        variant='body1'
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                        }}
                    >
                        Viendo
                        <Typography
                            sx={{
                                fontWeight: 600,
                                ml: 0.5,
                                mr: 0.5
                            }}
                        >
                            {selectedSucursalIds.length}
                        </Typography>
                        sucursal de
                        <Typography
                            sx={{
                                fontWeight: 600,
                                ml: 0.5,
                                mr: 0.5
                            }}
                        >
                            {allSucursalIds.length}
                        </Typography>
                    </Typography>
                </Box>
            </>
        )
    }

    const FilterSucursalComponent = ({ variant = "standard", size = "medium" }) => {
        return (
            <>
                {
                    (listAvailables.length > 1) && (
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            limitTags={4}
                            options={listAvailables}
                            disableCloseOnSelect
                            getOptionLabel={(e) => `${e?.Titulo} - ${e?.comercio?.NombreReal || e?.comercio?.Nombre}`}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {`${option?.Titulo} - ${option?.comercio?.NombreReal || ''}`}
                                </li>
                            )}
                            onChange={(event, value) => {
                                changeSucursal(value)
                            }}
                            value={sucursalFiltered}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={variant}
                                    size={size}
                                    fullWidth
                                    label="Filtro de sucursales"
                                />
                            )}
                        />
                    )
                }
            </>
        )
    }

    return {
        FilterSucursalComponent,
        FilterSucursalLabel,
        selectedSucursalIds,
        allSucursalIds,
        sucursalFiltered,
        listAvailables
    }
}

export default useFilterSucursal