// VARIABLES
export const PANEL_TIPO_PEDIDO = 'PANEL_TIPO_PEDIDO'
export const PANEL_TIPO_ENTREGA = 'PANEL_TIPO_ENTREGA'
export const PANEL_TIPO_FECHA_ENTREGA = 'PANEL_TIPO_FECHA_ENTREGA'
export const PANEL_ORIGEN = 'PANEL_ORIGEN'
export const PANEL_DESTINO = 'PANEL_DESTINO'
export const PANEL_CONTENIDO_ADICIONAL = 'PANEL_CONTENIDO_ADICIONAL'
export const PANEL_PRODUCTOS = 'PANEL_PRODUCTOS'
export const PANEL_PREVIEW = 'PANEL_PREVIEW'

export const TYPE_FLASH = 'TYPE_FLASH'
export const TYPE_ESPECIAL = 'TYPE_ESPECIAL'

export const DELIVERY_TYPE_DELIVERY = 'DELIVERY_TYPE_DELIVERY'
export const DELIVERY_TYPE_RETIRO = 'DELIVERY_TYPE_RETIRO'

export const ORIGIN_TYPE_SUCURSAL = 'ORIGIN_TYPE_SUCURSAL'
export const ORIGIN_TYPE_DIRECCION = 'ORIGIN_TYPE_DIRECCION'

export const DATE_TYPE_EXPRESS = 'DATE_TYPE_EXPRESS'
export const DATE_TYPE_PROGRAMADO = 'DATE_TYPE_PROGRAMADO'

export const DESTINY_TYPE_SUCURSAL = 'DESTINY_TYPE_SUCURSAL'
export const DESTINY_TYPE_CLIENTE = 'DESTINY_TYPE_CLIENTE'

export const PRODUCT_ADD_OPTION = 'PRODUCT_ADD_OPTION'
export const PRODUCT_REMOVE_OPTION = 'PRODUCT_REMOVE_OPTION'

export const TEMP_PRODUCT_ADD_QUATITY = 'TEMP_PRODUCT_ADD_QUATITY'
export const TEMP_PRODUCT_REMOVE_QUATITY = 'TEMP_PRODUCT_REMOVE_QUATITY'

export const PRODUCT_ADD_QUATITY = 'PRODUCT_ADD_QUATITY'
export const PRODUCT_REMOVE_QUATITY = 'PRODUCT_REMOVE_QUATITY'

// REDUX
export const SET_SHOW_MODAL_PEDIDO_ESPECIAL = 'SET_SHOW_MODAL_PEDIDO_ESPECIAL'
export const SET_CLOSE_MODAL_PEDIDO_ESPECIAL = 'SET_CLOSE_MODAL_PEDIDO_ESPECIAL'
export const SET_ACTIVE_PANEL = 'SET_ACTIVE_PANEL'
export const SET_TYPE = 'SET_TYPE'
export const SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE'
export const SET_ORIGIN = 'SET_ORIGIN'
export const SET_USE_CAR = 'SET_USE_CAR'
export const SET_STORE_IS_OPEN = 'SET_STORE_IS_OPEN'
export const SET_DATE_TYPE = 'SET_DATE_TYPE'
export const SET_DELIVERY_DATETIME = 'SET_DELIVERY_DATETIME'
export const SET_DESTINY_TYPE = 'SET_DESTINY_TYPE'
export const SET_DESTINY_STORE = 'SET_DESTINY_STORE'
export const SET_DESTINY_STORE_ADMIN = 'SET_DESTINY_STORE_ADMIN'
export const SET_DESTINY_NEW_CLIENT = 'SET_DESTINY_NEW_CLIENT'
export const SET_DESTINY_CLIENT = 'SET_DESTINY_CLIENT'
export const SET_DESTINY_CLIENT_NAME = 'SET_DESTINY_CLIENT_NAME'
export const SET_DESTINY_CLIENT_LASTNAME = 'SET_DESTINY_CLIENT_LASTNAME'
export const SET_DESTINY_CLIENT_EMAIL = 'SET_DESTINY_CLIENT_EMAIL'
export const SET_DESTINY_CLIENT_PHONE = 'SET_DESTINY_CLIENT_PHONE'
export const SET_DESTINY_CLIENT_DIRECTION = 'SET_DESTINY_CLIENT_DIRECTION'
export const SET_DESTINY_CLIENT_ADDITIONAL_INFORMATION = 'SET_DESTINY_CLIENT_ADDITIONAL_INFORMATION'
export const SET_DESTINY_CONFIRM = 'SET_DESTINY_CONFIRM'
export const SET_CONTENT = 'SET_CONTENT'
export const SET_COMMENT = 'SET_COMMENT'
export const SET_TEMP_PRODUCT = 'SET_TEMP_PRODUCT'
export const SET_CLEAN_TEMP_PRODUCT = 'SET_CLEAN_TEMP_PRODUCT'
export const SET_RADIO_OPTION = 'SET_RADIO_OPTION'
export const SET_CHECKBOX_OPTION = 'SET_CHECKBOX_OPTION'
export const SET_COUNTER_OPTION = 'SET_COUNTER_OPTION'
export const SET_TEMP_PRODUCT_QUANTITY = 'SET_TEMP_PRODUCT_QUANTITY'
export const SET_TEMP_TO_CART = 'SET_TEMP_TO_CART'
export const SET_CHANGE_QUANTITY_PRODUCT = 'SET_CHANGE_QUANTITY_PRODUCT'
export const SET_REMOVE_PRODUCT = 'SET_REMOVE_PRODUCT'
export const SET_TEMP_COMMENT = 'SET_TEMP_COMMENT'
export const SET_TIP = 'SET_TIP'


export const ADD_PRODUCT = 'ADD_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const ADD_ONE_MORE = 'ADD_ONE_MORE'
export const SUBTRACT_ONE = 'SUBTRACT_ONE'
export const CLEAN_DATA = 'CLEAN_DATA'