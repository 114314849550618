import React, { useState, useEffect, useContext } from "react"
import { useSelector } from "react-redux"
import { Layout, Row, Col, Pagination, Button } from "antd"
import { ContactListWrapper } from "@iso/components/Orders/OrderList.style"
import { ContactCardWrapper } from "@iso/components/Orders/OrderCard.style"
import { InputSearch } from "@iso/components/uielements/input"
import { ContactsWrapper } from "./Orders.styles"
import Scrollbar from "@iso/components/utility/customScrollBar"
import OrderProducts from "./OrderDetails/Order-Products"
import OrderTotals from "./OrderDetails/Order-Totals"
import OrderClient from "./OrderDetails/Order-Client"
import OrderInfo from "./OrderDetails/Order-Info"
import OrderHeadDetail from "./OrderDetails/Order-HeadDetail"
import OrderScheduledCard from "./Cards/OrderScheduled.Card"
import { PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG, ID_PED_PAG_ESTA_PENDIENTE_PAGO } from "../../redux/ordenes/types"
import TicketDrawerButton from "./Actions/TicketDrawer.Button"
import PedidoSeguimientoButton from './Actions/PedidoSeguimiento.Button';
import { SocketContext } from "../Dashboard/Dashboard"
import OrderPayment from "./OrderDetails/Order-Payment"
import RejectButton from "./Actions/Reject.button"
import PayLinkButton from "./Actions/PayLinkButton"
import { useLocation } from 'react-router-dom'

const { Content } = Layout

export default () => {

    const searchByUri = useLocation().search
    const socket = useContext(SocketContext)
    const Auth = useSelector(state => state.Auth)
    const { ordersScheduled, ordersScheduledSelectedIndex } = useSelector(state => state.Ordenes)
    const [orders, setOrders] = useState(ordersScheduled)
    const [search, setSearch] = useState("")
    const [index, setIndex] = useState(ordersScheduledSelectedIndex)
    const [IdLista, setIdLista] = useState(1)
    const [loadingStackUpTicketToPrint, setloadingStackUpTicketToPrint] = useState(false)

    useEffect(() => {
        setOrders(locateOnPage(applySearch()))
    }, [ordersScheduled, search, IdLista])

    // SOLO APLICA SI SE ENVIA EL CODIGO DEL PEDIDO POR GET
    const IdPedido = new URLSearchParams(searchByUri).get('IdPedido')
    useEffect(() => {
        setSearch((IdPedido !== null) ? IdPedido : "")
    }, [ordersScheduled, IdPedido])

    const applySearch = () => search ? ordersScheduled.filter(o => o.Codigo.includes(search)) : ordersScheduled
    const locateOnPage = (ordersToPaginate) => {
        const limitMax = IdLista * 10 - 1   // 09 , 19, 29
        const limitMin = limitMax - 9       // 00 , 10, 20

        return ordersToPaginate.filter((o, i) => (limitMin <= i && i <= limitMax))
    }
    const handleOnChangeSearch = (value) => {
        setIdLista(1)
        setSearch(value)
    }
    const handleOnChangePagination = (page) => {
        setIdLista(page)
    }

    function handleOnClickStackUpTicketsToPrint() {
        setloadingStackUpTicketToPrint(true)

        socket.emit('StackUpTicketsToPrint', {
            Token: Auth.Token,
            IdsPedido: ordersScheduled.map(o => o.IdPedido),
            usuario: Auth.auth,
            filterPrinters: false
        }, function () {
            setloadingStackUpTicketToPrint(false)
        })
    }

    //

    const hrDetalle = <hr style={{ borderWidth: 1, borderColor: "rgba(233, 233, 233, 0.18)" }}></hr>

    return (
        <>
            <ContactsWrapper
                className="isomorphicContacts"
                style={{ background: "none" }}
            >
                {/* LEFT MENU */}
                <div className="isoContactListBar">
                    <ContactListWrapper className="isoContactListWrapper">
                        {/* <Button loading={loadingStackUpTicketToPrint} type='primary' onClick={handleOnClickStackUpTicketsToPrint}>¡Imprimir todos!</Button> */}
                        <TicketDrawerButton orders={orders} />
                        {/* <InputSearch
                            placeholder="Buscar Orden"
                            value={search}
                            onChange={e => handleOnChangeSearch(e.target.value)}
                            className="isoSearchBar"
                        /> */}
                        <div style={{ height: 20 }} />
                        {orders && orders.length > 0 ? (
                            <div className="isoContactList">
                                <Scrollbar
                                    className="contactListScrollbar"
                                    style={{ height: "calc(100vh - 200px)" }}
                                >
                                    {orders.map((order, i) =>
                                        <OrderScheduledCard key={i} order={order} i={i} active={index === i} onSelected={setIndex} ></OrderScheduledCard>
                                    )}
                                </Scrollbar>
                            </div>
                        ) : (
                            <span className="isoNoResultMsg">NO HAY DATOS</span>
                        )}
                    </ContactListWrapper>
                </div>
                {/* LEFT MENU */}

                {/* RIGTH INFO */}

                <Layout className="isoContactBoxWrapper">
                    {orders[index] && (
                        <Content className="isoContactBox">
                            <Scrollbar className="contactBoxScrollbar">

                                {/* INFO CABECERA */}
                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <OrderHeadDetail order={orders[index]}>
                                            <Row>
                                                <Col span={12}>
                                                    {/* <RejectButton pedido={orders[index]} /> */}

                                                    {
                                                        (orders[index]?.tipoPedido?.Codigo === 'PEDESPECIAL' && orders[index]?.clienteCompra?.pagos[0]?.IdEstado === ID_PED_PAG_ESTA_PENDIENTE_PAGO) && (
                                                            <PayLinkButton pedido={orders[index]} tipo="PAGO" />
                                                        )
                                                    }

                                                    {
                                                        (orders[index]?.clienteCompra?.pagos[0]?.IdEstado !== ID_PED_PAG_ESTA_PENDIENTE_PAGO) && (
                                                            <PayLinkButton pedido={orders[index]} tipo="SEGUIMIENTO" />
                                                        )
                                                    }

                                                </Col>
                                                <Col span={12}>
                                                    <div style={{ display: 'flex' }}>

                                                        {
                                                            orders[index].entrega.TipoFechaEntrega === PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG &&
                                                            <TicketDrawerButton orders={[orders[index]]} />
                                                        }
                                                        <div style={{ marginLeft: 10 }}>
                                                            <PedidoSeguimientoButton order={orders[index]} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <OrderPayment order={orders[index]} />
                                                </Col>
                                            </Row>
                                        </OrderHeadDetail>
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* DETALLE PRODUCTOS */}
                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <OrderProducts order={orders[index]} />
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* TOTALES */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <Row>
                                            <Col span={12}>
                                                <OrderTotals order={orders[index]}></OrderTotals>
                                            </Col>
                                        </Row>
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* DATOS CLIENTE */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <OrderClient order={orders[index]}></OrderClient>
                                    </div>
                                </ContactCardWrapper>

                                {hrDetalle}

                                {/* DATOS PEDIDO */}

                                <ContactCardWrapper className="isoContactCard">
                                    <div className="isoContactInfoWrapper">
                                        <Row>
                                            <Col span={14}>
                                                <OrderInfo order={orders[index]} />
                                            </Col>
                                            <Col span={10}>

                                            </Col>
                                        </Row>
                                    </div>
                                </ContactCardWrapper>
                            </Scrollbar>
                        </Content>
                    )}
                </Layout>
                {/* RIGTH INFO */}
            </ContactsWrapper>
            <center>
                <Pagination current={IdLista} total={ordersScheduled.length} onChange={handleOnChangePagination} />
            </center>
        </>
    )
}
