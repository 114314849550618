import { AddCircle, Close, RemoveCircle } from '@mui/icons-material'
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import React, { memo } from 'react'
import { formatNumber } from '../../../../helpers/formatNumber'
import { useDispatch, useSelector } from 'react-redux'
import {
    setCheckboxOption,
    setCleanTempProduct,
    setTempComment,
    setCounterOption,
    setRadioOption,
    setTempProductQuantity,
    setTempToCart
} from '../../../../redux/pedidoEspecial/actions'
import { PRODUCT_ADD_OPTION, TEMP_PRODUCT_ADD_QUATITY, PRODUCT_REMOVE_OPTION } from '../../../../redux/pedidoEspecial/types'

const ModalProducto = () => {
    const dispatch = useDispatch()
    const { Moneda } = useSelector(state => state?.Auth?.payload?.usuarioPerfil?.sucursal?.comercio?.nacionalidad)
    const { tempProduct } = useSelector(state => state?.PedidoEspecial)
    const theme = useTheme()
    const Mediaquery = useMediaQuery(theme?.breakpoints.down('md'))

    const closeModal = async () => {
        await dispatch(setCleanTempProduct())
    }

    const changeRadio = async (mod, option) => {
        await dispatch(setRadioOption({ mod, option }))
    }

    const changeCheckbox = async (mod, option) => {
        await dispatch(setCheckboxOption({ mod, option }))
    }

    const changeCounter = async (mod, option, type) => {
        await dispatch(setCounterOption({ mod, option, type }))
    }

    const changeTempProductQuantity = async (type) => {
        await dispatch(setTempProductQuantity({ type }))
    }

    const addTempToCart = async () => {
        await dispatch(setTempToCart())
    }

    const changeComment = async (comment) => {
        await dispatch(setTempComment({ comment }))
    }

    return (
        <>
            {
                (tempProduct) && (
                    <Dialog
                        open={(tempProduct) ? true : false}
                        fullWidth
                        maxWidth='md'
                        fullScreen={Mediaquery}
                    >
                        <DialogTitle>
                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                    <Avatar
                                        src={tempProduct?.ImagenXS}
                                        variant="rounded"
                                        width={50}
                                        height={50}
                                        sx={{ mr: 1 }}
                                    />
                                    <Typography variant="h6">
                                        {tempProduct?.Nombre}
                                    </Typography>
                                </Stack>
                                <IconButton onClick={closeModal}>
                                    <Close />
                                </IconButton>
                            </Stack>
                            <Typography variant="body2">
                                {tempProduct?.Descripcion}
                            </Typography>
                            <Divider sx={{ py: 1 }} />
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                sx={{
                                    p: {
                                        md: 3,
                                        xs: 0
                                    }
                                }}
                            >
                                <Grid container spacing={3}>
                                    {
                                        tempProduct.modificadores.map((e, i) => {
                                            return (
                                                <Grid item xs={12} key={i}>
                                                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                        <Typography variant="body1">
                                                            {e?.Nombre}
                                                        </Typography>
                                                        <Chip label={(e?.Opcional) ? 'Opcional' : 'Requerido'} variant={(e?.Opcional) ? 'outlined' : 'filled'} />
                                                    </Stack>
                                                    <Typography variant="caption">
                                                        {e?.Descripcion}
                                                    </Typography>
                                                    <Box sx={{ mt: 2 }}>
                                                        {
                                                            e.opciones.filter((x) => x?.activo).map((x, z) => {
                                                                return (
                                                                    <Box sx={{ px: 2 }} key={z}>
                                                                        {
                                                                            (x?.HtmlElement.toUpperCase() === 'RADIO') && (
                                                                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Radio
                                                                                                size='small'
                                                                                                onChange={() => changeRadio(e, x?.Id)}
                                                                                                checked={(x?.CantidadSeleccionada) ? true : false}
                                                                                            />
                                                                                        }
                                                                                        label={
                                                                                            <Typography variant='body2'>
                                                                                                {x?.Nombre}
                                                                                            </Typography>
                                                                                        }
                                                                                    />
                                                                                    {
                                                                                        (x?.PrecioFinal > 0) && (
                                                                                            <Typography variant='caption'>
                                                                                                {`${formatNumber({ num: x?.PrecioFinal, currency: Moneda })}`}
                                                                                            </Typography>
                                                                                        )
                                                                                    }
                                                                                </Stack>
                                                                            )
                                                                        }

                                                                        {
                                                                            (x?.HtmlElement.toUpperCase() === 'CHECKBOX') && (
                                                                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                size='small'
                                                                                                disabled={(!x?.CantidadSeleccionada && e?.CantidadSeleccionada && e?.CantidadSeleccionada === e?.Maximo) ? true : false}
                                                                                                onChange={() => changeCheckbox(e, x?.Id)}
                                                                                                checked={(x?.CantidadSeleccionada) ? true : false}
                                                                                            />
                                                                                        }
                                                                                        label={
                                                                                            <Typography variant='body2'>
                                                                                                {x?.Nombre}
                                                                                            </Typography>
                                                                                        }
                                                                                    />
                                                                                    {
                                                                                        (x?.PrecioFinal > 0) && (
                                                                                            <Typography variant='caption'>
                                                                                                {`${formatNumber({ num: x?.PrecioFinal, currency: Moneda })}`}
                                                                                            </Typography>
                                                                                        )
                                                                                    }
                                                                                </Stack>
                                                                            )
                                                                        }

                                                                        {
                                                                            (x?.HtmlElement.toUpperCase() === 'COUNTER') && (
                                                                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                                                    <Stack direction='row' alignItems='center' justifyContent='start'>
                                                                                        <IconButton
                                                                                            onClick={() => changeCounter(e, x?.Id, PRODUCT_REMOVE_OPTION)}
                                                                                            disabled={(!x?.CantidadSeleccionada) ? true : false}
                                                                                        >
                                                                                            <RemoveCircle
                                                                                                color={(!x?.CantidadSeleccionada) ? 'inherit' : 'primary'}
                                                                                                size={14}
                                                                                            />
                                                                                        </IconButton>
                                                                                        <Typography variant='h6' sx={{ mx: 1 }}>
                                                                                            {(x?.CantidadSeleccionada) ? x?.CantidadSeleccionada : 0}
                                                                                        </Typography>
                                                                                        <IconButton
                                                                                            onClick={() => changeCounter(e, x?.Id, PRODUCT_ADD_OPTION)}
                                                                                            disabled={(
                                                                                                (x?.CantidadSeleccionada && x?.CantidadSeleccionada === x?.CantidadMaxima)
                                                                                                || (e?.CantidadSeleccionada && e?.CantidadSeleccionada >= e?.Maximo)
                                                                                            ) ? true : false}
                                                                                        >
                                                                                            <AddCircle
                                                                                                color={(
                                                                                                    (x?.CantidadSeleccionada && x?.CantidadSeleccionada === x?.CantidadMaxima)
                                                                                                    || (e?.CantidadSeleccionada && e?.CantidadSeleccionada >= e?.Maximo)
                                                                                                ) ? 'inherit' : 'primary'}
                                                                                                size={14}
                                                                                            />
                                                                                        </IconButton>
                                                                                        <Typography variant='body2' sx={{ ml: 1 }}>
                                                                                            {x?.Nombre}
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                    {
                                                                                        (x?.PrecioFinal > 0) && (
                                                                                            <Typography variant='caption'>
                                                                                                {`${formatNumber({ num: x?.PrecioFinal, currency: Moneda })}`}
                                                                                            </Typography>
                                                                                        )
                                                                                    }
                                                                                </Stack>
                                                                            )
                                                                        }

                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                    <Grid item xs={12}>
                                        <TextField
                                            label='Indicaciones adicionales'
                                            placeholder="Sin ají, alérgico a la lactosa, vegano..."
                                            fullWidth
                                            multiline
                                            rows={4}
                                            sx={{ mt: 2 }}
                                            value={(tempProduct?.Comentario) ? tempProduct?.Comentario : ''}
                                            onChange={(e) => changeComment(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={8}>
                                    <Stack direction='row' alignItems='center' justifyContent='center'>
                                        <IconButton
                                            onClick={() => changeTempProductQuantity(PRODUCT_REMOVE_OPTION)}
                                            disabled={(tempProduct?.CantidadSeleccionada <= 1) ? true : false}
                                        >
                                            <RemoveCircle
                                                color={(tempProduct?.CantidadSeleccionada <= 1) ? 'inherit' : 'primary'}
                                                size={16}
                                            />
                                        </IconButton>
                                        <Typography variant='h5' sx={{ mx: 1 }}>
                                            {tempProduct?.CantidadSeleccionada}
                                        </Typography>
                                        <IconButton onClick={() => changeTempProductQuantity(TEMP_PRODUCT_ADD_QUATITY)}>
                                            <AddCircle
                                                color={'primary'}
                                                size={16}
                                            />
                                        </IconButton>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} direction='row' justifyContent='end'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size='large'
                                        onClick={addTempToCart}
                                    >
                                        Agregar {`${formatNumber({ num: tempProduct?.CostoTotal, currency: Moneda })}`}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    )
}

export default memo(ModalProducto)