import React, { useCallback, useEffect, useState } from 'react'
import {
    Badge,
    Button,
    Card,
    Col,
    Drawer,
    Icon,
    List,
    notification,
    Row,
    Skeleton,
    Spin,
    Tabs,
    Progress
} from 'antd';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { LAMBDA_SHOP } from '../../config';
import Scrollbar from "../../components/utility/customScrollBar"
import moment from 'moment-timezone';
import PedidoEstadoTag from '../Orders/Tags/PedidoEstado.Tag';
import currencyFormatter from 'currency-formatter';
import TicketDrawerButton from '../Orders/Actions/TicketDrawer.Button'

import currencyFormatterOptions from "../../config/currencyFormatterOptions.json"

const { TabPane } = Tabs;

export default function () {
    const Auth = useSelector(state => state.Auth)
    const IdSucursal = Auth.payload.usuarioPerfil.sucursal.IdSucursal
    const [availableScheduledHours, setavailableScheduledHours] = useState([])
    const [loading, setloading] = useState(false)

    useEffect(() => {
        getSucursalHorarios()
    }, [IdSucursal])

    const getSucursalHorarios = useCallback(
        function () {
            setloading(true)
            Axios
                .get(`${LAMBDA_SHOP}/getSucursalHorarios/${IdSucursal}`)
                .then(response => {
                    setavailableScheduledHours(response.data.availableScheduledHours)
                })
                .catch(console.error)
                .finally(() => {
                    setloading(false)
                })
        },
        [IdSucursal],
    )

    if (loading) {
        // return <Card loading={true} style={{ width: '100%', height: '100%' }} />
        return <Spin indicator={<Icon type="loading" style={{ fontSize: '2.5em' }} />} />
    }

    return <Tabs
        tabPosition='top'
        defaultActiveKey={availableScheduledHours[0]?.date}
    >
        {
            availableScheduledHours.map(day =>
                <TabPane
                    key={day.date}
                    tab={
                        <Badge
                            count={day.hours.reduce((aux, hour) => aux + hour.current, 0)}
                            style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                        >
                            <span>
                                {day.day} {day.date} &nbsp;&nbsp;
                            </span>
                        </Badge>
                    }
                >
                    <ListHours day={day} />
                </TabPane>
            )
        }
    </Tabs>
}

const ListHours = function ({ day }) {
    const hoursAux = day.hours
    const [hours, sethours] = useState(hoursAux.slice(0, 5))
    const [loading, setloading] = useState(false)
    const [drawerData, setDraweData] = useState({
        visible: false,
        title: '',
        content: <></>,
        contentProps: {}
    })


    const handleOnClickLoadMora = useCallback(
        () => {
            sethours(prev => hoursAux.slice(0, Math.min(prev.length + 5, hoursAux.length)))
        },
        [],
    )

    const handleOnClickHour = useCallback(
        (hour) => {
            if (!hour.current) {
                return notification.warning({ message: `Aun no hay pedidos registrados para las ${hour.hour} del ${day.day} ${day.date}` })
            }
            setDraweData({
                visible: true,
                title: `Pedidos ${day.day} ${day.date} | ${hour.hour}`,
                content: HourOrders,
                contentProps: {
                    day,
                    hour
                }
            })
        },
        [],
    )


    // return <Scrollbar
    //     style={{
    //         height: '60vh'
    //     }}
    // >

    // </Scrollbar>


    return <>
        <List
            dataSource={hours}
            loadMore={
                !loading &&
                hours.length < hoursAux.length &&
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button onClick={handleOnClickLoadMora}>Cargar Mas</Button>
                </div>
            }

            renderItem={hour => (
                <List.Item
                    style={{ paddingRight: 50 }}
                    actions={[
                        <a onClick={() => handleOnClickHour(hour)}>Pedidos</a>,
                    ]}
                >
                    <Skeleton avatar title={hour.hour} loading={false} active>
                        <List.Item.Meta
                            // avatar={
                            //     <Icon type="clock-circle" theme="twoTone" />
                            //     // <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                            // }
                            title={<a onClick={() => handleOnClickHour(hour)}>
                                {
                                    hour.current >= hour.limit ?
                                        <Icon style={{ fontSize: 20 }} type="warning" theme="twoTone" twoToneColor='rgb(255, 195, 0)' /> :
                                        <Icon style={{ fontSize: 20 }} type="clock-circle" theme="twoTone" />
                                }

                                &nbsp;
                                {hour.hour}
                            </a>}
                            description={
                                <p>
                                    {`${hour.current}/${hour.limit}`}
                                    <HourProgressBar {...hour} />
                                </p>
                            }
                        />
                        {/* <div>content</div> */}
                    </Skeleton>


                </List.Item>
            )}
        />
        <Drawer
            visible={drawerData.visible}
            title={drawerData.title}
            onClose={() => setDraweData(prev => ({ ...prev, visible: false }))}
            width={'30vw'}
            destroyOnClose
        >
            <drawerData.content {...drawerData.contentProps} />
        </Drawer>

    </>

}

const HourProgressBar = function ({ current, limit }) {
    const [percent, setpercent] = useState(0)

    useEffect(() => {
        setpercent(
            Math.round(
                Math.min(current * 100 / limit, 100)
            )
            // Math.floor(Math.random() * 101)
        )
    }, [current, limit])


    return <Progress
        strokeColor={
            percent >= 80 ?
                {
                    '0%': 'lightgreen',
                    '25%': 'green',
                    '75%': 'yellow',
                    '100%': 'red',
                } :
                percent >= 55 ?
                    {
                        '0%': 'lightgreen',
                        '25%': 'green',
                        '75%': 'green',
                        '100%': 'yellow',
                    } :
                    {
                        '0%': 'lightgreen',
                        '25%': 'green',
                        '75%': 'green',
                        '100%': 'green',
                    }

        }
        percent={percent}
    />
}

const HourOrders = function ({ day, hour }) {
    const Auth = useSelector(state => state.Auth)
    const tz = Auth.payload.usuarioPerfil.sucursal.zonaHoraria.Zona

    const {
        orders,
        ordersActive,
        ordersReady,
        ordersEnd,
        ordersScheduled,
    } = useSelector(state => state.Ordenes)
    const [hourOrders, sethourOrders] = useState([])
    const [hourOrdersActive, sethourOrdersActive] = useState([])
    const [hourOrdersReady, sethourOrdersReady] = useState([])
    const [hourOrdersEnd, sethourOrdersEnd] = useState([])
    const [hourOrdersScheduled, sethourOrdersScheduled] = useState([])
    const [currencyOptions, setcurrencyOptions] = useState({})

    useEffect(() => {
        setcurrencyOptions(currencyFormatterOptions[Auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])
    }, [Auth])


    useEffect(() => {
        const filter = o => {
            const FechaEstimadaEntregaMaxMoment = moment.utc(o.entrega.FechaEstimadaEntregaMax).tz(tz)
            return (
                FechaEstimadaEntregaMaxMoment.format('YYYY-MM-DD') === day.date &&
                Number(FechaEstimadaEntregaMaxMoment.format('HHmm')) === hour.hour
            )
        }

        sethourOrders(orders.filter(filter))
        sethourOrdersActive(ordersActive.filter(filter))
        sethourOrdersReady(ordersReady.filter(filter))
        sethourOrdersEnd(ordersEnd.filter(filter))
        sethourOrdersScheduled(ordersScheduled.filter(filter))

    }, [
        orders,
        ordersActive,
        ordersReady,
        ordersEnd,
        ordersScheduled,
        hour,
        tz
    ])


    return [
        ...hourOrders,
        ...hourOrdersActive,
        ...hourOrdersReady,
        ...hourOrdersEnd,
        ...hourOrdersScheduled,
    ].map((pedido, i) =>

        <Row key={i}>
            <Col span={6}>
                {pedido.Codigo}-{pedido.Corelativo}
            </Col>
            <Col span={6} >
                {currencyFormatter.format(pedido.TotalOC, currencyOptions)}
            </Col>
            <Col span={6} >
                <PedidoEstadoTag pedido={pedido} />
            </Col>
            <Col span={6} >
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <TicketDrawerButton orders={[pedido]} iconSize={1.5} />
                </div>
            </Col>
            <Col span={24}>
                <p>
                    <br />
                </p>
            </Col>
        </Row>
    )
}