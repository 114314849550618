export const GET_SCHEDULE_SUC = 'GET_SCHEDULE_SUC'
export const GET_SCHEDULE_SUC_SUCCESS = 'GET_SCHEDULE_SUC_SUCCESS'
export const REFRESH_SCHEDULE_SUC = 'REFRESH_SCHEDULE_SUC'

export const getScheduleSucursal = (IdComercio, IdSucursal, callback) => ({
    type: GET_SCHEDULE_SUC,
    payload: {
        IdComercio,
        IdSucursal,
        callback
    }
})

export const refreshScheduleSucursal = (payload) => ({
    type: REFRESH_SCHEDULE_SUC,
    payload
})