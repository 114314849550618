
import * as actions from './actions'

/**
 * @type {typeof ISucursalNotificacionesState}
 */
const INIT_STATE = {
    tz: 'America/Santiago',
    createdAt_I: '',
    createdAt_F: '',
    createdAtTZ_I: '',
    createdAtTZ_F: '',
    notificaciones: [],
    ridersWaiting: [],
}

/**
 * @param {typeof ISucursalNotificacionesState} state
 * @returns {typeof ISucursalNotificacionesState}
 */
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case actions.LOAD_ALL_SUCCESS:
            return {
                ...state,
                ...action.payload,
            }
        case actions.UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificaciones: state.notificaciones.map(notificacion =>
                    notificacion.Id === action.payload.Id ? action.payload : notificacion
                )
            }
        case actions.PUT_NEW_SUCCESS:
            return {
                ...state,
                notificaciones: [
                    ...action.payload.notificaciones,
                    ...state.notificaciones
                ]
            }

        case actions.LOAD_RIDERS_WAITING:
            if (action.payload) {
                action.payload.map((e) => {
                    const nombre = `${e.entrega.rider.Nombre.substring(0, 1).toUpperCase()}${e.entrega.rider.Nombre.substring(1, e.entrega.rider.Nombre.length).toLowerCase()}`
                    const apellido = `${e.entrega.rider.Apellido.substring(0, 1).toUpperCase()}${e.entrega.rider.Apellido.substring(1, e.entrega.rider.Apellido.length).toLowerCase()}`

                    state.ridersWaiting.push({
                        IdPedido: e.IdPedido,
                        Codigo: `${e.Codigo}-${e.Corelativo}`,
                        Rider: `${nombre} ${apellido}`,
                        RiderAvatar: e.entrega.rider.Avatar_Img,
                        FechaLLegada: e.tiempoEntrega.FechaLLegadaRiderSucursal,
                        Llave: e.entrega.LLAVE,
                    })
                })
            }

        case actions.LEAVE_RIDERS_WAITING:
            if (state.ridersWaiting.length > 0) {
                state.ridersWaiting = state.ridersWaiting.filter((e) => e.IdPedido !== action.payload)
            }

            return {
                ...state,
            }
        default:
            return state
    }
}


/**
 * @type {{
 *  tz,
 *  createdAt_I,
 *  createdAt_F,
 *  createdAtTZ_I,
 *  createdAtTZ_F,
 *  notificaciones: typeof ISucursalNotificacion[],
 *  ridersWaiting: typeof IRidersWaiting[],
 * }}
 */
export const ISucursalNotificacionesState = null
/**
 * @type {{
 *      Id: number
 *      Tipo: string
 *      Titulo: string
 *      Notificacion: string
 *      Descripcion: string
 *      Leido: boolean
 *      createdAt: string
 *      createdAtTZ: string
 *      avatarRider: string
 *      nombreRider: string
 *      order: any
 *  }}
 */
export const ISucursalNotificacion = null

/**
 * @type {{
 *      IdPedido: number
 *      Codigo: string
 *      Rider: string
 *      RiderAvatar: string
 *      FechaLLegada: string
 *      Llave: string
 *  }}
 */

export const IRidersWaiting = null