import React from 'react'
import { Tabs } from 'antd';
import CupoProgramacion from './CupoProgramacion';

const { TabPane } = Tabs;

export default function () {
    return <Tabs tabPosition='left' defaultActiveKey="1">
        <TabPane tab="Cupo programación" key="1">
            <CupoProgramacion />
        </TabPane>
    </Tabs>
}