import React from 'react'
import { Card, Col, Row, Divider } from 'antd'
import LayoutWrapper from '../../components/utility/layoutWrapper'

import { useSelector } from 'react-redux'
import ChangeAvatar from './ChangeAvatar'
import { AUTH_URL, AppKey } from '../../config'

const color = 'rgb(0, 197, 141)'
const IconPerson = <svg width="7em" height="7em" viewBox="0 0 16 16" className="bi bi-person" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
</svg>

const IconKey = <svg width="7em" height="7em" viewBox="0 0 16 16" className="bi bi-key-fill" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
</svg>

const styleTitleCard = { color, fontSize: 24, fontWeight: 700, lineHeight: 1.5 }



export default function () {
    const Auth = useSelector(state => state.Auth)
    return (
        <>
            <LayoutWrapper>
                <Divider orientation='left' style={{ margin: 0, padding: 0, paddingBottom: 15 }}>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <div>
                            <ChangeAvatar />
                        </div>
                        <div>
                            <br></br>
                            <br></br>
                            <span style={{ textTransform: 'capitalize', fontSize: 24, color: '#2d3446' }}>
                                &nbsp;
                                &nbsp;
                                {Auth.auth.Nombre} {Auth.auth.Apellido}
                            </span>
                        </div>

                    </div>

                </Divider>
                <Row gutter={[24, 48]} style={{ width: '100%' }}>
                    <Col span={12}>
                        <Card
                            style={{ width: '100%', cursor: 'pointer' }}
                            onClick={() => {
                                let url = new URL(AUTH_URL)
                                url.pathname = 'personal-info'
                                url.searchParams.append('return_url', window.location.href)
                                // url.searchParams.append('return_url', `${window.location.origin}/auth`)
                                url.searchParams.append('continue_url', window.location.href)
                                url.searchParams.append('app_key', AppKey)
                                window.location.replace(url.href)
                            }}
                        >
                            <Row>
                                <Col span={8}>
                                    {IconPerson}
                                </Col>
                                <Col span={16}>
                                    <h2 style={styleTitleCard}>Datos Personales</h2>
                                    <span style={{ fontSize: 14, lineHeight: 1.5 }}>
                                        Puedes Administrar tus datos personales
                                </span>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card
                            style={{ width: '100%', cursor: 'pointer' }}
                            onClick={() => {
                                let url = new URL(AUTH_URL)
                                url.pathname = 'reset-password'
                                url.searchParams.append('return_url', window.location.href)
                                // url.searchParams.append('return_url', `${window.location.origin}/auth`)
                                url.searchParams.append('continue_url', window.location.href)
                                url.searchParams.append('app_key', AppKey)
                                window.location.replace(url.href)
                            }}
                        >
                            <Row>
                                <Col span={8}>
                                    {IconKey}
                                </Col>
                                <Col span={16}>
                                    <h2 style={styleTitleCard}>Seguridad</h2>
                                    <span style={{ fontSize: 14, lineHeight: 1.5 }}>
                                        Puedes Administrar tu contraseña
                                </span>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </LayoutWrapper>
        </>
    )
}