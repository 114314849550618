export const PED_ENTRE_TIPO_FECHA_ENTRE_ANTES_POSIBLE = 1
export const PED_ENTRE_TIPO_FECHA_ENTRE_FECHA_PROG = 3

export const PED_ENTRE_TIPO_ENTRE_DELIVERY = 1
export const PED_ENTRE_TIPO_ENTRE_RETIRO_TIENDA = 2
export const PED_ENTRE_TIPO_ENTRE_MESA = 3

export const ID_TIPO_PAGO_CREDITO = 1
export const ID_TIPO_PAGO_DEBITO = 2
export const ID_TIPO_PAGO_MATCH = 3
export const ID_TIPO_PAGO_NO_ESPECIFICADO = 4


export const ID_MEDIO_PAGO_FLOW = 1
export const ID_MEDIO_PAGO_TRANSBANK = 2
export const ID_MEDIO_PAGO_MERCADO_PAGO = 3

export const PRINT_FORMAT_THERMAL = 'PRINT_FORMAT_THERMAL'
export const PRINT_FORMAT_PUNTO = 'PRINT_FORMAT_PUNTO'

export const PRINT_CONTENT_FULL = 'PRINT_CONTENT_FULL'
export const PRINT_CONTENT_PRODUCTS = 'PRINT_CONTENT_PRODUCTS'


export const ID_PEDIDO_ESTADO_PENDIENTE = 1
export const ID_PEDIDO_ESTADO_INACTIVO = 2
export const ID_PEDIDO_ESTADO_RECHAZADO = 3
export const ID_PEDIDO_ESTADO_ACEPTADO = 8
export const ID_PEDIDO_ESTADO_BUSCANDO_DELIVERY = 9
export const ID_PEDIDO_ESTADO_EN_CAMINO = 10
export const ID_PEDIDO_ESTADO_RETIRADO_SUCURSAL = 11
export const ID_PEDIDO_ESTADO_ENTREGADO = 12


export const ID_PEDIDO_TIPO_CALIFICACION_RIDER_LIKE = 7
export const ID_PEDIDO_TIPO_CALIFICACION_RIDER_DISLIKE = 8

export const PEDIDO_POS_MOTIVO_CALIFICACION_CONNOTACION_POSITIVA = 'P'
export const PEDIDO_POS_MOTIVO_CALIFICACION_CONNOTACION_NEGATIVA = 'N'
export const PEDIDO_POS_MOTIVO_CALIFICACION_OBJETIVO_RIDER = 'RIDER'


export const ID_PED_PAG_ESTA_ACTIVO = 1
export const ID_PED_PAG_ESTA_INACTIVO = 2
export const ID_PED_PAG_ESTA_PENDIENTE_PAGO = 13
export const ID_PED_PAG_ESTA_RECHAZADO = 14
export const ID_PED_PAG_ESTA_ANULADO = 15
export const ID_PED_PAG_ESTA_REEMBOLSADO = 16