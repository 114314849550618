import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Switch, Radio, Drawer, Icon, Progress, Spin, Button, Divider } from 'antd';
import { getPrinters } from '../../../library/printer-socket';
import { settingContext, timeoutMStoSave } from '../';
import { ID_SUC_IMPRESORA } from '../../../redux/settings/types';
import {
    PRINT_FORMAT_THERMAL,
    PRINT_FORMAT_PUNTO,
    PRINT_CONTENT_FULL,
    PRINT_CONTENT_PRODUCTS,
} from '../../../redux/ordenes/types';


const COPIES_LIMIT_VALUE = 5
const COPIES_MIN_VALUE = 1
const COPIES_SUM = 1
const SET_COPIES_ACTION_ADD = 'SET_COPIES_ACTION_ADD'
const SET_COPIES_ACTION_SUBSTRACT = 'SET_COPIES_ACTION_SUBSTRACT'


export default ({ onChange }) => {
    const [printers, setprinters] = useState([])
    const {
        initialValues,
        saveSettingSucursal,
        machineId,
        machineIdOriginal,
    } = useContext(settingContext)
    const [, settimeoutsaveSettingSucursal] = useState(null)
    const [loading, setloading] = useState(false)
    const [printerIndexSelected, setprinterIndexSelected] = useState(-1)
    const [drawerVisible, setdrawerVisible] = useState(false)
    const [showOK, setshowOK] = useState(false)


    useEffect(() => {
        const syncTimeut = setTimeout(() => {

            setloading(true)
            getPrinters()
                .then(socketPrinters => {
                    let sucursalPrinters = initialValues.find(ivalue => ivalue.IdTipoConfiguracion === ID_SUC_IMPRESORA)?.Valor || '[]'
                    sucursalPrinters = JSON.parse(sucursalPrinters)

                    if (machineId && machineIdOriginal) {
                        sucursalPrinters = sucursalPrinters.filter(sucursalPrinter => sucursalPrinter.machineId === machineId && sucursalPrinter.machineIdOriginal === machineIdOriginal)
                    } else {
                        sucursalPrinters = sucursalPrinters.filter(sucursalPrinter => !sucursalPrinter.machineId && !sucursalPrinter.machineIdOriginal)
                    }

                    setprinters(socketPrinters.map(p => {
                        const sucursalPrinter = sucursalPrinters.find(sp => sp.name === p)
                        return {
                            name: p,
                            checked: !!(sucursalPrinter && !sucursalPrinter.disabled),
                            format: sucursalPrinter?.format || PRINT_FORMAT_THERMAL,
                            content: sucursalPrinter?.content || PRINT_CONTENT_FULL,
                            copies: sucursalPrinter?.copies || COPIES_MIN_VALUE,
                            machineId,
                            machineIdOriginal
                        }
                    }))
                })
                .finally(() => {
                    setloading(false)
                    setshowOK(false)
                })

        }, 500);

        return () => {
            clearTimeout(syncTimeut)
        }
    }, [machineId, machineIdOriginal, initialValues])

    useEffect(() => {
        onChange(printers)
    }, [printers])


    function handleOnChangeSwitch(value, printer, i) {

        if (value) {
            setprinterIndexSelected(i)
            setdrawerVisible(true)
        }

        setprinters(prevPrinter => {
            const nextPrinters = prevPrinter.map(p =>
                p === printer ? {
                    ...p,
                    checked: value
                } : p
            )

            debounceSaveSettingSucursal(nextPrinters)

            return nextPrinters
        })
    }

    function handleOnChangeRadio(at, value, printer) {
        setprinters(prevPrinter => {
            const nextPrinters = prevPrinter.map(p =>
                p === printer ? {
                    ...p,
                    [at]: value
                } : p
            )

            debounceSaveSettingSucursal(nextPrinters)

            return nextPrinters
        })
    }

    function setCopies(action, printer) {
        setprinters(prevPrinter => {
            const nextPrinters = prevPrinter.map(p =>
                p === printer ? {
                    ...p,
                    copies:
                        action === SET_COPIES_ACTION_ADD ?
                            Math.min(COPIES_LIMIT_VALUE, p.copies + COPIES_SUM) :
                            action === SET_COPIES_ACTION_SUBSTRACT ?
                                Math.max(COPIES_MIN_VALUE, p.copies - COPIES_SUM) :
                                COPIES_MIN_VALUE
                } : p
            )

            debounceSaveSettingSucursal(nextPrinters)

            return nextPrinters
        })

    }

    function debounceSaveSettingSucursal(_printers) {
        settimeoutsaveSettingSucursal(prevTimeout => {
            clearTimeout(prevTimeout)
            setloading(false)
            return setTimeout(() => {
                setloading(true)
                saveSettingSucursal({
                    Valor: JSON.stringify(
                        _printers.map(p => ({
                            ...p,
                            checked: undefined,
                            disabled: !p.checked,
                            // machineId,
                            // machineIdOriginal,
                        }))
                    ),
                    IdTipoConfiguracion: ID_SUC_IMPRESORA,
                }).finally(() => {
                    // setloading(false)
                    setshowOK(true)

                })
            }, timeoutMStoSave)
        })
    }

    return (
        <>
            <Row gutter={[10, 15]}>
                {
                    printers.map((printer, i) =>
                        <Col key={i} span={12}>
                            <p>
                                <Switch loading={loading} checked={printer.checked} onChange={(value) => handleOnChangeSwitch(value, printer, i)}></Switch>
                                &nbsp;
                                {printer.name}
                                <span style={{ float: 'right' }}>
                                    <Icon style={{ fontSize: '20px' }} type="info-circle" theme="twoTone" onClick={() => { setprinterIndexSelected(i); setdrawerVisible(true) }} />
                                </span>
                            </p>
                        </Col>
                    )
                }
            </Row>
            {
                printers[printerIndexSelected] &&
                <Drawer
                    title={<p>

                        {printers[printerIndexSelected].name}
                        <span style={{ float: 'right', padding: '0 20px 0 0' }}>
                            <Switch loading={loading} checked={printers[printerIndexSelected].checked} onChange={(value) => handleOnChangeSwitch(value, printers[printerIndexSelected], printerIndexSelected)}></Switch>
                        </span>
                    </p>}
                    placement="right"
                    closable={true}
                    onClose={() => setdrawerVisible(false)}
                    visible={drawerVisible}

                    width='500px'
                >
                    <h4>Copias</h4>

                    <center>
                        {
                            showOK ?
                                <Progress type="circle" percent={100} /> :
                                loading ?
                                    <Spin
                                        tip={'Guardando...'}
                                        indicator={<Icon type='loading' style={{ fontSize: 120 }} ></Icon>}
                                    >
                                    </Spin> :
                                    <>
                                        <Progress
                                            strokeColor={{
                                                '0%': 'blue',
                                                '100%': 'green',
                                            }}
                                            type="circle"
                                            percent={(printers[printerIndexSelected].checked ? (printers[printerIndexSelected].copies * 100 / COPIES_LIMIT_VALUE) : 0)}
                                            format={porc => `${printers[printerIndexSelected].checked ? printers[printerIndexSelected].copies : ''}`}
                                        />
                                        <br></br>
                                        <Button.Group style={{ margin: '10px 0 0 0' }}>
                                            <Button disabled={!printers[printerIndexSelected].checked || loading} onClick={() => setCopies(SET_COPIES_ACTION_SUBSTRACT, printers[printerIndexSelected])} icon="minus" />
                                            <Button disabled={!printers[printerIndexSelected].checked || loading} onClick={() => setCopies(SET_COPIES_ACTION_ADD, printers[printerIndexSelected])} icon="plus" />
                                        </Button.Group>
                                    </>

                        }

                    </center>

                    <Divider></Divider>

                    <h4>Contenido</h4>

                    <center>

                        <Radio.Group size='small' disabled={!printers[printerIndexSelected].checked || loading} onChange={(e) => handleOnChangeRadio('content', e.target.value, printers[printerIndexSelected])} value={printers[printerIndexSelected].checked && printers[printerIndexSelected].content} buttonStyle="solid">
                            <Radio.Button value={PRINT_CONTENT_FULL}>Full</Radio.Button>
                            <Radio.Button value={PRINT_CONTENT_PRODUCTS}>Productos</Radio.Button>
                        </Radio.Group>
                    </center>

                    <Divider></Divider>
                    <h4>Impresora</h4>

                    <center>
                        <Radio.Group size='small' disabled={!printers[printerIndexSelected].checked || loading} onChange={(e) => handleOnChangeRadio('format', e.target.value, printers[printerIndexSelected])} value={printers[printerIndexSelected].checked && printers[printerIndexSelected].format} buttonStyle="solid">
                            <Radio.Button value={PRINT_FORMAT_THERMAL}>Termica</Radio.Button>
                            <Radio.Button value={PRINT_FORMAT_PUNTO}>Punto</Radio.Button>
                        </Radio.Group>
                    </center>


                </Drawer>
            }
        </>

    )

}
